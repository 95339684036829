import { FC, useEffect, useState } from 'react';
import {
  ICmbDataItem,
  useDeleteCMBlenderReportsMutation
} from 'src/store/api/cmBlender/reports';

import { Button, Divider, Form, Input, Modal, notification } from 'antd';
import styled from 'styled-components';

type IModal = {
  closeModal: () => void;
  open: boolean;
  report: ICmbDataItem;
};

export const DeleteFieldsModal: FC<IModal> = ({ closeModal, open, report }) => {
  const [deleteReports, { isLoading }] = useDeleteCMBlenderReportsMutation();

  const handleSubmit = () => {
    deleteReports(report.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted the report'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(true);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== report.collateralManager.name);
  }, [name, report.collateralManager.name]);
  const handleFinish = () => {
    if (!buttonDisable) {
      handleSubmit();
    }
  };
  return (
    <Modal
      title={'Delete Report'}
      open={open}
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          danger
          type={'primary'}
          loading={isLoading}
          disabled={buttonDisable}
          key="submit"
          onClick={() => form.submit()}
        >
          YES, Delete
        </Button>
      ]}
      width={572}
    >
      <Divider />
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Form.Item
          name={'name'}
          key={1}
          label={
            <FormLabel>
              Do you want to delete "
              <NpkText>{report.collateralManager.name}</NpkText>" report this
              action cannot be undone
            </FormLabel>
          }
        >
          <Input
            placeholder="Enter the NPK produced to confirm delete"
            autoComplete="off"
          />
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  );
};

const FormLabel = styled.p`
  display: inline-block;
`;
const NpkText = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
