import Button from 'antd/es/button';
import styled, { keyframes } from 'styled-components';

export const TableBody = styled.div`
  background-color: white;
`;
export const Container = styled.div`
  width: '100%';
  height: '100%';
  padding: 32px;
  .blink {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
export const Wrap = styled.div`
  width: '100%';
  height: '100%';
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
  margin-block: 32px;
`;

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BlinkingCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: #ff4d4f;
  border-radius: 50%;
  animation: ${blink} 1s infinite;
`;
export const ViewButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #1890ff;
`;
export const RawmaterialDetailWrap = styled.div`
  width: max(400px, 49%);
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  h4 {
    margin-inline: 16px;
  }
`;
export const TransporterDetailWrap = styled.div`
  width: max(400px, 49%);
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  h4 {
    margin-inline: 16px;
  }
`;
export const BlendingSiteContainer = styled.div`
  flex: 2;
  background-color: #fafafa;
`;
export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
export const OrdersBody = styled.div`
  display: flex;
  flex-direction: column;
`;
export const OrderDetailsContainer = styled.div`
  flex: 3;
`;
export const DetailsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-block: 36px;
`;
export const TransporterWrap = styled.div`
  width: max(400px, 49%);
  background: #fff;
  h4 {
    margin-inline: 16px;
  }
`;
export const CustomContainer = styled.div`
  padding: 15px;
  .bottom {
    border-bottom: 1px solid #d9d9d9;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  .arrows {
    cursor: pointer;
    height: 12px;
    width: 12px;
  }
`;
export const Label = styled.span`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-block-end: 8px;
`;
export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 48.5%;
  margin-block-end: 34px;
`;
export const InputWrap = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;
export const TextAreaItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block-end: 34px;
`;
export const AlterColor = styled(Button)`
  color: #1890ff;
  justify-content: space-between;
  margin-left: 70%;
`;
export const Border = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
  padding: 5px 12px;
`;

export const DetailRow = styled.div`
  width: '100%';
  height: '100%';
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
  margin-block: 32px;
  ${BlendingSiteContainer} {
    background-color: #ffffff;
    border-radius: 8px;
    padding-inline: 15px;
  }
`;
