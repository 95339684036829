import { Divider, Space, Table } from 'antd';
import styled from 'styled-components';
import { InfoSection, MaterialInfo } from 'src/pages/styles';

export const TableBody = styled.div`
  background-color: white;
`;

export const TableHeader = styled.div`
  width: '100%';
  justify-content: space-between;
  display: flex;
  height: 88px;
  padding: 20px 10px;
`;

export const ActionItem = styled.h4`
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : '')};
`;
export const ActionSite = styled.div`
  display: flex;
  column-gap: 16px;
`;
export const ViewButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #1890ff;
`;
export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
`;
export const TrackingInfoSection = styled(InfoSection)`
  align-items: center;
`;
export const TrackingMaterialInfo = styled(MaterialInfo)`
  flex: 0.7;
`;
export const AddFieldContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;
`;
export const CustomTable = styled(Table)`
  border: 0.5px dashed #8c8c8c;
  margin-bottom: 24px;
`;
export const CustomSpace = styled(Space)`
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
`;
export const CustomCard = styled.div`
  background-color: rgba(245, 245, 245, 1);
  display: flex;
  column-gap: 5px;
  padding: 12px 25px; ;
`;
export const CustomDivider = styled(Divider)`
  margin-top: 12px; ;
`;
export const SubHeading = styled.div`
  margin-top: 40px;
`;
export const GreyText = styled.div``;
export const StatusContainer = styled.div`
  display: flex;
  column-gap: 23px;
`;
