import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import {
  Button,
  Modal,
  Form,
  Input,
  Typography,
  Checkbox,
  Divider,
  Row,
  Col,
  notification
} from 'antd';
import { FC } from 'react';
import {
  CustomCard,
  GreyText,
  SubHeading
} from 'src/pages/clearingAgents/purchaseOrder/styles';
import {
  IPurchaseOrder,
  IResolveConflict,
  ITrackDischarge,
  useResolveConflictMutation
} from 'src/store/api';
import { formatNumber } from 'src/utils';
const { Text } = Typography;

interface IModal {
  open: boolean;
  closeModal: () => void;
  purchaseOrder: IPurchaseOrder;
  dischargeDetails: ITrackDischarge;
}
export const UpdateDischargeModal: FC<IModal> = ({
  open,
  closeModal,
  purchaseOrder,
  dischargeDetails
}) => {
  const [form] = Form.useForm();
  const status = Form.useWatch('status', form);
  const [handleResolveConflict, { isLoading }] = useResolveConflictMutation();
  const handleSubmit = (values: {
    quantityRecieved: number;
    status: boolean;
  }) => {
    const payload: IResolveConflict = {
      id: purchaseOrder.id,
      dischargeOrderId: dischargeDetails.id,
      data: {
        status: values.status ? 'confirm' : 'resolve'
      }
    };
    if (values.quantityRecieved) {
      payload.data.quantityRecieved = Number(values.quantityRecieved);
    }

    handleResolveConflict(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Flagged order resolved'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  return (
    <Modal
      open={open}
      title="Update discharge"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          loading={isLoading}
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
        >
          Resolve
        </Button>,

        <Button onClick={closeModal}>Cancel</Button>
      ]}
      width={432}
    >
      <Divider />
      <Row gutter={20}>
        <Col span={12}>
          <Text>Raw material discharged</Text>
          <Divider />
          <CustomCard>
            <GreyText>{dischargeDetails.rawMaterial.name}</GreyText>
            <Text>{`${formatNumber(
              dischargeDetails.quantityDischarged
            )} MT`}</Text>
            <CheckCircleFilled style={{ color: '#52C41A' }} />
          </CustomCard>
        </Col>
        <Col span={12}>
          <Text>Raw material recieved</Text>
          <Divider />
          <CustomCard>
            <GreyText>{dischargeDetails.rawMaterial.name}</GreyText>
            <Text>{`${formatNumber(
              dischargeDetails.quantityReceived
            )} MT`}</Text>
            <CloseCircleFilled style={{ color: '#FF4D4F' }} />
          </CustomCard>
        </Col>
      </Row>
      <SubHeading>
        <Text strong>Resolve conflict</Text>
      </SubHeading>
      <Divider />
      <Form
        layout={'vertical'}
        onFinish={handleSubmit}
        form={form}
        validateTrigger={['onBlur']}
      >
        <Form.Item name="quantityRecieved" label="Quantity" key={'8'}>
          <Input
            disabled={status}
            placeholder="Enter quantity "
            type={'number'}
            min="1"
            autoComplete='off'
          />
        </Form.Item>
        <Form.Item valuePropName="checked" key={'1'} name="status">
          <Checkbox>Disregard flag and confirm</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
