import { Role } from 'src/store/constant';
import { auth } from './auth';
import { blender } from './blender';
import { cmBlendingPlant } from './cmBlendingPlant';
import { clearingAgent } from './clearingAgent';
import { cmPort } from './cmPort';
import { nsiaRoutes } from './nsiaRoutes';

export const routes = {
  auth: auth,
  [Role.NSIA_ADMIN]: nsiaRoutes,
  [Role.CLEARING_AGENT]: clearingAgent,
  [Role.COLLATERAL_MANAGER]: cmPort,
  [Role.BLENDER]: blender,
  [Role.COLLATERAL_MANAGER_BLENDER]: cmBlendingPlant
};
