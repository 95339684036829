import { Space, Table, Tag } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useAppSelector } from 'src/store';
import {
  AdminType,
  IAdmin,
  ICollateralManagerBlendingPlant,
  useGetCollateralManagersBlendingPlantQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';
import { ActionItem } from '../../style';

type ICMBlendingPlant = {
  search: string;
  setEditModal: (prop: ICollateralManagerBlendingPlant | null) => void;
  setDeleteModal: (prop: ICollateralManagerBlendingPlant | null) => void;
};
export const CMBlendingPlant: FC<ICMBlendingPlant> = ({
  search,
  setEditModal,
  setDeleteModal
}) => {
  const [page, setPage] = useState(1);
  const user = useAppSelector((store) => store.auth.user!) as IAdmin;
  const { data: cmBlendingPlantList } =
    useGetCollateralManagersBlendingPlantQuery(
      generateUrl({
        limit: 10,
        search,
        page
      })
    );

  const collateralManagers = useMemo(() => {
    return (
      cmBlendingPlantList?.data.map((item, index) => ({
        key: index,
        blendingPlantName: item?.blendingSites
          ?.map((element) => element.name)
          .join(', '),
        status: item.isOnboarded,
        ...item
      })) ?? []
    );
  }, [cmBlendingPlantList]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phonenumber'
    },
    {
      title: 'Blending Plant',
      dataIndex: 'blendingPlantName',
      key: 'blending-plant'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: ICollateralManagerBlendingPlant) => {
        return (
          <Space>
            {record.isOnboarded ? (
              <Tag color={'green'}>Onboarded</Tag>
            ) : (
              <Tag color={'gold'}>Awaiting</Tag>
            )}
          </Space>
        );
      }
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_: unknown, clickedManager: any) => (
        <Space size="small">
          {user.type === AdminType.SUPER_ADMIN && (
            <>
              <ActionItem onClick={() => setEditModal(clickedManager)}>
                Edit
              </ActionItem>
              |
              <ActionItem
                color={'rgba(245, 34, 45, 1)'}
                onClick={() => {
                  setDeleteModal(clickedManager);
                }}
              >
                Delete
              </ActionItem>
            </>
          )}
        </Space>
      )
    }
  ];
  return (
    <Table
      pagination={{
        defaultCurrent: 1,
        pageSize: 10,
        total: cmBlendingPlantList?.pagination?.totalCount,
        onChange: (page) => {
          setPage(page);
        }
      }}
      columns={columns}
      dataSource={collateralManagers}
    />
  );
};
