import { Tag } from 'antd';
import { FC } from 'react';
import { Text } from 'src/components';
import { IOffTakings } from 'src/store/api';
import { IStock } from 'src/store/api/stock/interface';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

type IStockSummary = {
  orders: IStock[];

  offTakingDetail?: IOffTakings;
};
export const StockSummary: FC<IStockSummary> = ({ orders }) => {
  const totalRawMaterials = orders.reduce(
    (acc, order) => acc + order.quantity,
    0
  );
  return (
    <InfoSection>
      <QuantityInfo>
        <TextDetails>
          <Text className="titleText">
            Total available raw materials :{' '}
            <Text strong>{formatNumber(totalRawMaterials)}MT</Text>{' '}
          </Text>
          <Text strong></Text>
        </TextDetails>
        <Flex>
          {orders.map((order, index) => (
            <RawMaterialTag key={index}>
              <strong>
                <h2>{order.rawMaterial.name} - </h2>
              </strong>
              <Text>{formatNumber(order.quantity ?? 0)}MT</Text>
            </RawMaterialTag>
          ))}
        </Flex>
      </QuantityInfo>
    </InfoSection>
  );
};

const Flex = styled.div`
  display: flex;
  gap: 10px;
`;

const QuantityInfo = styled.div`
  flex-direction: column;
  border-radius: 4px;
  padding: 12px 16px;
  column-gap: 12px;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  row-gap: 16px;
  background-color: #fafafa;
  border-radius: 15px;
  width: fit-content;
`;

const TextDetails = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: space-between;
  font-weight: 600;
  column-gap: 4px;
  .titleText {
    color: #8c8c8c;
  }
`;

const InfoSection = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0px;
  background-color: #e6f7ff;
  padding: 12px;
  row-gap: 10px;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
  border-radius: 8px;
`;

const RawMaterialTag = styled(Tag)`
  font-size: 14px;
  font-style: normal;
  border-radius: 8px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  height: 32px;
  border: 1px solid #91d5ff;
  background-color: #ffffff;
  h2 {
    color: #1890ff;
    font-weight: 700;
    font-size: 14px;
  }
`;
