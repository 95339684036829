import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Modal, Typography, Button } from 'antd';
import { FC } from 'react';
import { Title } from 'src/components';

const { Text } = Typography;

interface IModal {
  open: boolean;
  closeModal: () => void;
  onRelease: React.MouseEventHandler<HTMLElement>;
}

export const NegativeReleaseModal: FC<IModal> = ({
  open,
  closeModal,
  onRelease
}) => {
  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button onClick={onRelease} type="primary" danger>
          Yes, Continue
        </Button>
      ]}
      width={417}
    >
      <TextWrapper>
        <TitleRow>
          <ExclamationCircleOutlined />
          <ShippingTitle level={4}>
            You are about to make a negative release
          </ShippingTitle>
        </TitleRow>
        <StyledText>
          Are you sure you want to make this release? You can choose to continue
          or cancel this process if you are not sure.
        </StyledText>
      </TextWrapper>
    </Modal>
  );
};

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled(Text)`
  font-size: 16px;
  line-height: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  svg {
    fill: #ffc53d;
    width: 17px;
    height: 17px;
  }
`;
const ShippingTitle = styled(Title)`
  margin-top: 8px;
  font-size: 16px;
`;
