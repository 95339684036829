import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  notification
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FC } from 'react';
import { useGetRawMaterialsQuery } from 'src/store/api';
import {
  IAddCmbReports,
  useAddCMBlenderReportsMutation
} from 'src/store/api/cmBlender/reports';
import styled from 'styled-components';

type IProps = {
  form: FormInstance;
  closeModal: () => void;
};

export const AddFields: FC<IProps> = ({ form, closeModal }) => {
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  const [addcmBlenderReports] = useAddCMBlenderReportsMutation();
  const handleSubmit = (values: IAddCmbReports) => {
    addcmBlenderReports(values)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Report Added succesfully.'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const utilized = [
    {
      rawMaterial: '',
      quantityUtilized: ''
    }
  ];

  const npkMade = [
    {
      npkType: '',
      quantiyProduced: 0
    }
  ];

  return (
    <Form
      layout={'vertical'}
      form={form}
      validateTrigger={'onBlur'}
      onFinish={(values) => handleSubmit(values)}
    >
      <Form.List name={'rawMaterialUtilized'} initialValue={utilized}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <>
                <Row key={index} align="middle">
                  <Col xs={{ span: 9, offset: 0 }} lg={{ span: 9, offset: 0 }}>
                    <FormItem
                      {...field}
                      name={[field.name, 'rawMaterial']}
                      label=" Raw Material"
                      rules={[
                        {
                          required: true,
                          message: 'Please input Raw Material!'
                        }
                      ]}
                    >
                      <Select placeholder="Select Raw Material" allowClear>
                        {rawMaterials?.data.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    lg={{ span: 10, offset: 1 }}
                  >
                    <FormItem
                      name={[field.name, 'quantityUtilized']}
                      label="Quantity Utilized"
                      rules={[
                        {
                          required: true,
                          message: 'Please input Quantity Utilized!'
                        }
                      ]}
                      fieldKey={[field.key, 'quantityUtilized']}
                    >
                      <NumberInput
                        placeholder="Enter Quantity Utilized"
                        autoComplete="off"
                      />
                    </FormItem>
                  </Col>
                  <Col lg={{ span: 4, offset: 0 }}>
                    <ExButton onClick={() => remove(index)} />
                  </Col>
                </Row>
              </>
            ))}
            <AddFieldContainer>
              <Button
                title="Add field"
                icon={<PlusOutlined />}
                onClick={() =>
                  add({
                    rawMaterial: '',
                    quantityUtilized: ''
                  })
                }
              >
                Add Field
              </Button>
            </AddFieldContainer>
          </>
        )}
      </Form.List>
      <Form.List name={'npkProduced'} initialValue={npkMade}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row key={index} align="middle">
                <Col xs={{ span: 9, offset: 0 }} lg={{ span: 9, offset: 0 }}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'npkType']}
                    label="Blend of NPK Produced"
                    rules={[
                      { required: true, message: 'Please input NPK Produced!' }
                    ]}
                  >
                    <Input
                      placeholder="Enter blend of NPK produced"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'quantiyProduced']}
                    label="Quantity"
                    rules={[
                      { required: true, message: 'Please input NPK Quantity!' }
                    ]}
                  >
                    <NumberInput
                      type="number"
                      placeholder="Enter Quantity used"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>

                <Col lg={{ span: 4, offset: 0 }}>
                  <ExButton onClick={() => remove(index)} />
                </Col>
              </Row>
            ))}
            <AddFieldContainer>
              <Button
                title="Add field"
                icon={<PlusOutlined />}
                onClick={() =>
                  add({
                    npk: '',
                    quantiyProduced: ''
                  })
                }
              >
                Add Field
              </Button>
            </AddFieldContainer>
          </>
        )}
      </Form.List>
    </Form>
  );
};
export const NumberInput = styled(InputNumber)`
  width: 100%;
`;
export const AddFieldContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;
`;
const ExButton = styled(CloseOutlined)`
  font-size: 20px;
  color: #ff7875;
  display: flex;
  justify-content: flex-end;
  margin-left: 30px;
  align-content: center;
  padding-right: 2px;
`;
