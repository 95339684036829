import { Spin, Table } from 'antd';
import { Search, Title } from 'src/components';
import {
  Container,
  TableBody,
  TableHeader
} from 'src/pages/blender/indent/style';
import { EditLogistics } from './EditLogistics';
import { DeleteModal, ViewCommentModal, ViewIndentRequest } from './index';
import { useIndentTableLogic } from './useIndentTableLogic';
import { AmendOrder } from './AmmendOrders';

export const IndentTable = () => {
  const {
    viewIndentRequest,
    setSearch,
    indentRequests,
    updatedColumn,
    onSearch,
    dataSource,
    isLoading,
    amendOrders,
    deleteIndentRequest,
    editIndent,
    setAmendOrders,
    columns,
    setDeleteIndentRequest,
    setViewIndentRequest,
    setEditIndent,
    setviewComment,
    viewComment,
    status
  } = useIndentTableLogic();

  return (
    <>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <Container>
          <TableBody>
            <TableHeader>
              <Title level={4}>Raw Materials Request</Title>
              <Search
                onSearch={onSearch}
                onChange={(e) => !e.target.value && setSearch('')}
                placeholder="Search"
                style={{ width: 264 }}
              />
            </TableHeader>
            <Table
              columns={status === 'updated' ? updatedColumn : columns}
              dataSource={dataSource}
              rowKey={'releaseId'}
              pagination={{
                total: indentRequests?.pagination.totalCount,
                pageSize: 10
              }}
            />
          </TableBody>
          {!!viewIndentRequest && (
            <ViewIndentRequest
              open={!!viewIndentRequest}
              indentRequest={viewIndentRequest}
              closeModal={() => setViewIndentRequest(null)}
            />
          )}
          {!!viewComment && (
            <ViewCommentModal
              open={!!viewComment}
              indentRequest={viewComment}
              closeModal={() => setviewComment(null)}
            />
          )}
          {!!amendOrders && (
            <AmendOrder
              open={!!amendOrders}
              closeModal={() => setAmendOrders(null)}
              indent={amendOrders!}
            />
          )}
          {!!editIndent && (
            <EditLogistics
              open={!!editIndent}
              closeModal={() => setEditIndent(null)}
              singleIndent={editIndent}
            />
          )}
          {!!deleteIndentRequest && (
            <DeleteModal
              id={deleteIndentRequest.id}
              open={!!deleteIndentRequest}
              releaseId={deleteIndentRequest.releaseId}
              closeModal={() => setDeleteIndentRequest(null)}
            />
          )}
        </Container>
      </Spin>
    </>
  );
};
