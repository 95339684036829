import { tagTypes } from 'src/store/api/baseApi/tagTypes';
import { baseApi } from 'src/store/api/baseApi';
import { ICmBlenderChart, ICmBlenderStats, ICmBlenderStock } from './interface';
import { Response } from 'src/store/genericInterface';

const cmpApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCmBlenderChart: builder.query<
      Response<ICmBlenderChart[]>,
      string | void
    >({
      query: (query) => ({
        url: `/collateral-manager-bs/chart-data?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Chart }]
    }),
    getCmBlenderStats: builder.query<Response<ICmBlenderStats>, string | void>({
      query: () => ({
        url: '/collateral-manager-bs/stat',
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Stats }]
    }),
    getCmBlenderStock: builder.query<
      Response<ICmBlenderStock[]>,
      string | void
    >({
      query: () => ({
        url: '/collateral-manager-bs/stock',
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Stock }]
    })
  })
});

export const {
  useGetCmBlenderChartQuery,
  useGetCmBlenderStatsQuery,
  useGetCmBlenderStockQuery
} = cmpApi;
