import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, DatePicker, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { formatNumber, generateUrl } from 'src/utils';
import styled from 'styled-components';
import { Container } from '../styles';
import { CustomTitleText } from './style';

import { useMemo, useState } from 'react';
import shortArrow from 'src/assets/shortArrow.svg';
import {
  RawMaterial,
  WarehouseSummaryItem,
  useGetRawMaterialsQuery,
  useGetWarehouseSummaryQuery
} from 'src/store/api';
import { WarehouseDownloadModal } from './WarehouseSummaryModal';

const { YearPicker } = DatePicker;

interface Stock {
  quantity: number;
  rawMaterial: RawMaterial;
}

interface DeliverySummaryItem {
  stock: Stock[];
  warehouse: string;
}

const WarehouseSummary = () => {
  const navigate = useNavigate();
  const dateValue = new Date();
  const currentYear = new Date().getFullYear();

  const [startDate, setStartDate] = useState(
    dayjs(new Date(currentYear, 0, 1)).format('YYYY-MM-DD')
  );
  const { data: gettinWarehouse, isLoading } = useGetWarehouseSummaryQuery(
    generateUrl({
      startDate: startDate
    })
  );
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  const [viewModal, setViewModal] = useState(false);
  const [viewDeliveries, setViewDeliveries] = useState(true);
  const [viewOfftake, setViewOfftake] = useState(true);
  const [viewRelease, setViewRelease] = useState(true);
  const updateStartDateEndDate = (dateValue: dayjs.Dayjs | null) => {
    const year = dateValue?.year() ?? new Date().getFullYear();

    const startDate = dayjs(new Date(year, 0, 1)).format('YYYY-MM-DD');
    setStartDate(startDate);
  };

  const columns = useMemo(() => {
    const yearTitle = {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      key: 'warehouse'
    };

    const dynamicColumn = rawMaterials?.data
      ? rawMaterials.data.map((materials) => ({
          title: materials.name,
          dataIndex: materials.id,
          key: materials.id
        }))
      : [];

    const returnData = [yearTitle, ...dynamicColumn];
    return returnData;
  }, [rawMaterials]);

  const allRawMaterialDefaultQuatity = useMemo(() => {
    return rawMaterials?.data.reduce<Record<string, string>>(
      (acc, rawMaterial) => {
        acc[rawMaterial.id] = '-';
        return acc;
      },
      {}
    );
  }, [rawMaterials]);

  const generateDataSource = (
    warehouseSummary: WarehouseSummaryItem[] = [],
    defaultRawMaterialsQuatity: Record<string, number | string>
  ) => {
    const totalRow = {
      ...defaultRawMaterialsQuatity
    };
    const data = warehouseSummary.map((item: DeliverySummaryItem) => {
      const deliveries = item.stock.reduce<Record<string, string>>(
        (acc, delivery) => {
          acc[delivery.rawMaterial.id] = `${formatNumber(
            delivery.quantity
          )} MT`;

          if (totalRow[delivery.rawMaterial.id] !== '-') {
            totalRow[delivery.rawMaterial.id] =
              Number(totalRow[delivery.rawMaterial.id]) + delivery.quantity;
          } else {
            totalRow[delivery.rawMaterial.id] = delivery.quantity;
          }
          return acc;
        },
        {}
      );

      return {
        warehouse: item.warehouse,
        ...defaultRawMaterialsQuatity,
        ...deliveries
      };
    });

    //format total row
    for (const total in totalRow) {
      if (totalRow[total] === '-') {
        totalRow[total] = `${formatNumber(0)} MT`;
      } else {
        totalRow[total] = `${formatNumber(totalRow[total])} MT`;
      }
    }
    totalRow['warehouse'] = 'Total';

    return [...data, totalRow];
  };
  const dataSource = useMemo(() => {
    return generateDataSource(gettinWarehouse?.data.delieverySummary, {
      ...(allRawMaterialDefaultQuatity ?? {})
    });
  }, [gettinWarehouse?.data.delieverySummary, allRawMaterialDefaultQuatity]);

  const offTakeSource = useMemo(() => {
    return generateDataSource(gettinWarehouse?.data.offtakeSummary, {
      ...(allRawMaterialDefaultQuatity ?? {})
    });
  }, [gettinWarehouse?.data.offtakeSummary, allRawMaterialDefaultQuatity]);
  const releaseSource = useMemo(() => {
    return generateDataSource(gettinWarehouse?.data.releaseSummary, {
      ...(allRawMaterialDefaultQuatity ?? {})
    });
  }, [gettinWarehouse?.data.releaseSummary, allRawMaterialDefaultQuatity]);

  return (
    <Spin size="large" spinning={isLoading}>
      {viewModal && (
        <WarehouseDownloadModal
          open={viewModal}
          closeModal={() => setViewModal(false)}
          dataSource={dataSource}
          offTakeSource={offTakeSource}
          releaseSource={releaseSource}
        />
      )}
      <Container>
        <HeadingWrapper>
          <div className="arrowFlex">
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginBottom: '10px', cursor: 'pointer' }}
            />
            <CustomTitleText level={4}>Reports</CustomTitleText>
          </div>
          <Button onClick={() => setViewModal(true)}>Download report</Button>
        </HeadingWrapper>
        <SummaryBody>
          <SummaryTitleWrapper>
            <TitleDiv>
              <h1>Warehouse Summary</h1>
              <p>Report</p>
            </TitleDiv>

            <YearPicker
              defaultValue={dayjs(dateValue)}
              disabledDate={(d) => {
                return d.isBefore('2023-12-31');
              }}
              onChange={(value) => updateStartDateEndDate(value)}
            />
          </SummaryTitleWrapper>
        </SummaryBody>

        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Raw Materials Deliveries</CustomTitleText>
          <ClickDiv
            isClicked={viewDeliveries}
            onClick={() => setViewDeliveries(!viewDeliveries)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewDeliveries && (
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowClassName={(record) =>
              record.warehouse === 'Total' ? 'total-row' : ''
            }
          />
        )}

        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Offtake/Sales</CustomTitleText>
          <ClickDiv
            isClicked={viewOfftake}
            onClick={() => setViewOfftake(!viewOfftake)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewOfftake && (
          <Table
            columns={columns}
            dataSource={offTakeSource}
            pagination={false}
            rowClassName={(record) =>
              record.warehouse === 'Total' ? 'total-row' : ''
            }
          />
        )}
        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Release</CustomTitleText>
          <ClickDiv
            isClicked={viewRelease}
            onClick={() => setViewRelease(!viewRelease)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewRelease && (
          <Table
            columns={columns}
            dataSource={releaseSource}
            pagination={false}
            rowClassName={(record) =>
              record.warehouse === 'Total' ? 'total-row' : ''
            }
          />
        )}
      </Container>
    </Spin>
  );
};

export default WarehouseSummary;

const ClickDiv = styled.div<{ isClicked: boolean }>`
  transform: ${({ isClicked }) => (isClicked ? 'rotate(90deg)' : 'rotate(0)')};
  cursor: pointer;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 24px;

  .arrowFlex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

const SummaryBody = styled.div`
  display: flex;
  background-color: #ffffff;
  padding: 15px;
  margin-top: 20px;
  gap: 10px;
`;

const SummaryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1.5;
`;
const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  height: 52px;
  h1 {
    height: 25px;
    margin: 0;
  }
  p {
    height: 25px;
    margin: 0;

    color: #006c33;
  }
`;
