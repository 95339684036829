import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IWarehouse, useDeleteWarehouseMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  warehouse: IWarehouse;
}

export const DeleteWarehouseModal: FC<IModal> = ({
  open,
  closeModal,
  warehouse
}) => {
  const [deleteWarehouse, { isLoading: isDeleteLoading }] =
    useDeleteWarehouseMutation();

  const handleSubmit = () => {
    deleteWarehouse(warehouse.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted a warehouse'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(true);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== warehouse.name);
  }, [name, warehouse.name]);

  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
    <Modal
      open={open}
      title="Delete Warehouse"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          danger
          type={'primary'}
          disabled={buttonDisable}
          onClick={() => form.submit()}
          loading={isDeleteLoading}
        >
          Yes, Delete
        </Button>
      ]}
      width={660}
    >
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Form.Item
          name={'name'}
          key={1}
          label={
            <FormLabel>
              Are you sure delete "
              <WarehouseText>{warehouse.name}</WarehouseText>" warehouse , this
              action cannot be undone
            </FormLabel>
          }
        >
          <Input placeholder="Enter the warehouse name to proceed" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const WarehouseText = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
