import { Button, Col, Form, Row, Modal } from 'antd';
import { FC, useMemo } from 'react';
import { NumberInput, TextArea } from 'src/components';
import { useAppDispatch } from 'src/store';
import { IIndentRequest, IOffTakings } from 'src/store/api/indent-request';
import {
  IndentRequestAction,
  setSelectedAction,
  setShowUpdateFields,
  setUpdatedValues
} from 'src/store/slices';
import styled from 'styled-components';
import { CustomContainer } from '../style';
import { StockSummary } from 'src/components/StockSummary';
import { IStock } from 'src/store/api/stock/interface';

interface IValues {
  feedback: string;
  orders: {
    id: string;
    quantity: number | null | string;
  }[];
}

type IProps = {
  indentRequest: IIndentRequest;
  setPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  closeModal: () => void;
  allStock?: IStock[] | undefined;
  offTaking?: IOffTakings;
};
export const OrderedRawMaterials: FC<IProps> = ({
  indentRequest,
  setPopUp,
  open,
  closeModal,
  allStock,
  offTaking
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onFinish = (values: IValues) => {
    const payload = {
      ...values,
      orders: values.orders.filter(
        ({ quantity }) => quantity !== null && quantity !== ''
      ) as {
        id: string;
        quantity: number;
      }[],
      isUpdate: true,
      feedback: values.feedback
    };
    const matchMaterials = allStock;

    if (matchMaterials && indentRequest) {
      const insufficientMaterials = values.orders.filter((order) => {
        const stockItem = matchMaterials.find(
          (item: { rawMaterial: { id: string } }) =>
            item.rawMaterial.id === order.id
        );

        const stockQuantity = stockItem?.quantity || 0;

        return stockQuantity < Number(order.quantity);
      });

      if (insufficientMaterials.length === 0) {
        dispatch(setUpdatedValues(payload));
        dispatch(setSelectedAction(IndentRequestAction.Accept));
      } else {
        setPopUp(true);
      }
    }
  };

  const onCancel = () => {
    dispatch(setShowUpdateFields(false));
    dispatch(
      setUpdatedValues({
        feedback: '',
        orders: [],
        isUpdate: false
      })
    );
    closeModal();
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      centered
      width={996}
      title="Update raw material request"
      footer={[
        <Button onClick={() => form.submit()} type={'primary'}>
          Update
        </Button>,
        <Button onClick={onCancel}>Cancel</Button>
      ]}
    >
      <Form layout={'vertical'} onFinish={onFinish} form={form}>
        <CustomContainer>
          {allStock && (
            <StockSummary offTakingDetail={offTaking} orders={allStock} />
          )}
          <Flex>
            <MaterialBody>
              <div className="bottom">
                <h1>Request</h1>
              </div>
              <WhiteBg>
                <FlexDiv className="bottom">
                  <h1>Raw material</h1>
                  <h1>Requested quantity</h1>
                </FlexDiv>
                {indentRequest.orders.map((order, index) => {
                  return (
                    <FlexDiv key={index}>
                      <p>{order.rawMaterial.name}</p>
                      <h1>{order.estimatedQuantity}</h1>
                    </FlexDiv>
                  );
                })}
              </WhiteBg>
            </MaterialBody>

            <UpdateForm indentRequest={indentRequest} />
          </Flex>
        </CustomContainer>
        <Form.Item
          name={'feedback'}
          rules={[{ required: true, message: 'Feedback is required' }]}
          label={'Comment'}
        >
          <TextArea
            rows={2}
            placeholder={
              'Let the blending site know why their order was updated'
            }
          ></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};

type IUpdateForm = {
  indentRequest: IIndentRequest;
};
const UpdateForm: FC<IUpdateForm> = ({ indentRequest }) => {
  const orders = useMemo(
    () =>
      indentRequest.orders.map((order) => ({
        id: order.rawMaterial.id,
        quantity: ''
      })),
    [indentRequest.orders]
  );

  return (
    <ListFlex>
      <div className="bottom">
        <h1>Feedback</h1>
      </div>
      <TextBody>
        <div className="header"> Quantity</div>
        <Form.List name={'orders'} initialValue={orders}>
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <Row key={index} gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'quantity']}
                      rules={[
                        { required: true, message: 'Please input new quantity' }
                      ]}
                    >
                      <NumberInput
                        placeholder={`Update quantity for ${indentRequest.orders[index].rawMaterial.name}`}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </TextBody>
    </ListFlex>
  );
};

const Flex = styled.div`
  display: flex;
  gap: 10px;
`;

const TextBody = styled.div`
  background-color: #fafafa;
  margin-top: 15px;
  border-radius: 8px;
  padding: 10px;
  .header {
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
  }
`;

const ListFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  border: 1px solid #d9f7be;
  background-color: #f6ffed;
  border-radius: 8px;
  padding: 16px;
  h1 {
    font-size: 14px;
    font-weight: 600;
  }
`;

const MaterialBody = styled.div`
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex: 3;
  height: fit-content;
  flex-direction: column;
  overflow-y: auto;
  background-color: #fafafa;
  gap: 10px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  border-radius: 8px;
  width: 30%;
  h1 {
    font-size: 14px;
    font-weight: 600;
  }
`;
const WhiteBg = styled(MaterialBody)`
  background-color: #ffffff;
  width: 100%;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    color: #52c41a;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
  h1 {
    color: #000000;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
`;
