import { Button, Form, Input, Modal, notification } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FC } from 'react';
import {
  IEditSupplierPayload,
  ISupplier,
  useEditSupplierMutation
} from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
  suppliers: ISupplier;
}

export const EditSupplierModal: FC<IModal> = ({
  open,
  closeModal,
  suppliers
}) => {
  const [handleEditSupplier, { isLoading: isEditLoading }] =
    useEditSupplierMutation();
  const [form] = Form.useForm();

  const name = Form.useWatch('suppliersName', form);

  const handleSubmit = () => {
    const payload: IEditSupplierPayload = {
      body: {
        name
      },
      id: suppliers.id
    };
    handleEditSupplier(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated suppliers details'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  return (
    <Modal
      open={open}
      title="Edit Supplier"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={isEditLoading}
        >
          Edit Supplier
        </Button>
      ]}
      width={660}
    >
      <Form
        layout="vertical"
        initialValues={{
          suppliersName: suppliers.name
        }}
        form={form}
        onFinish={handleSubmit}
      >
        <FormItem name="suppliersName" label="Suppliers Name" key={'1'}>
          <Input placeholder="Enter suppliers name" />
        </FormItem>
      </Form>
    </Modal>
  );
};
