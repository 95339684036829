import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IBlender, useDeleteBlendersMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  blenderDetails: IBlender;
}

export const DeleteBlenderModal: FC<IModal> = ({
  open,
  closeModal,
  blenderDetails
}) => {
  const [deleteBlender, { isLoading }] = useDeleteBlendersMutation();

  const handleSubmit = () => {
    deleteBlender(blenderDetails.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted a blender'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(true);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== blenderDetails.name);
  }, [name, blenderDetails.name]);

  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
    <Modal
      open={open}
      title="Delete Blender"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,

        <Button
          danger
          disabled={buttonDisable}
          type={'primary'}
          onClick={() => form.submit()}
          loading={isLoading}
        >
          Yes, Delete
        </Button>
      ]}
      width={660}
    >
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Form.Item
          key={1}
          name={'name'}
          label={
            <FormLabel>
              Are you sure delete "
              <HighlightedText>{blenderDetails.name}</HighlightedText>” this
              action cannot be undone
            </FormLabel>
          }
        >
          <Input placeholder="Enter the blender's name to proceed" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const HighlightedText = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
