import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Dropdown, Spin, Table, Tag } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  OfftakenSummary,
  Search,
  Title,
  ViewShippingDocument
} from 'src/components';
import {
  ButtonContainer,
  Container,
  CustomHeader,
  StatusContainer,
  TableBody,
  TableHeader,
  TitleRow,
  ViewButton
} from 'src/pages/styles';
import {
  IIndentRequest,
  IOffTakingDetail,
  IndentRequestStatus,
  OfftakingStatus,
  OfftakingStatusConfig,
  useGetIndentRequestByIdQuery,
  useGetOffTakingDetailsQuery
} from 'src/store/api';
import { dateTimeFormater, formatNumber } from 'src/utils';
import { CompleteOfftake, DispatchNote, ResolveIndentModal } from './component';
import LogisticsAccordion from 'src/components/LogisticsAccordion';

export const SingleReleaseOrder = () => {
  const navigate = useNavigate();
  const [, setSearch] = useState('');
  const [viewResolveIndent, setViewResolveIndent] =
    useState<IOffTakingDetail | null>(null);
  const [viewShippingDocuments, setViewShippingDocuments] =
    useState<IIndentRequest | null>(null);

  const [completeOfftakeModal, setCompleteOfftakeModal] =
    useState<IIndentRequest | null>(null);
  const [viewDispatchNote, setViewDispatchNote] =
    useState<IOffTakingDetail | null>(null);
  const { state } = useLocation();
  const [releaseOrder] = useState<IIndentRequest>(
    (state as { releaseOrder: IIndentRequest }).releaseOrder
  );

  const [activePanel, setActivePanel] = useState<number>(0);
  const [viewOrders, setViewOrders] = useState(false);

  const { data: indentRequest, isLoading } = useGetIndentRequestByIdQuery(
    releaseOrder.id
  );
  const { data: offTakingDetail, isLoading: isOfftakeLoading } =
    useGetOffTakingDetailsQuery(releaseOrder.id);

  const dataSource = useMemo(
    () =>
      offTakingDetail?.data.offTakings.map((offtaking, index) => ({
        sn: index + 1,
        tableRawMaterial: offtaking.rawMaterial.name,
        key: offtaking._id,
        tableQuantityDischarged: `${formatNumber(
          offtaking.quantityDischarged
        )}MT`,
        driversName: offtaking.driverName,
        mobileNo: offtaking.phone,
        truckId: offtaking.truckNo,
        tableWarehouse: offtaking.warehouse.name,
        date: dateTimeFormater(offtaking.createdAt),
        ...offtaking
      })),
    [offTakingDetail]
  );
  const columns = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn'
    },
    {
      title: 'Raw materials',
      dataIndex: 'tableRawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Warehouse',
      dataIndex: 'tableWarehouse',
      key: 'Warehouse'
    },
    {
      title: 'Qty discharged',
      dataIndex: 'tableQuantityDischarged',
      key: 'quantity'
    },
    {
      title: 'Driver name',
      dataIndex: 'driversName',
      key: 'driversName'
    },
    {
      title: 'Truck ID',
      dataIndex: 'truckId',
      key: 'truckId'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },

    {
      title: 'Order status',
      dataIndex: 'status',
      render: (_: any, record: IOffTakingDetail) => {
        const status = OfftakingStatusConfig[record.offTakingStatus];

        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
            {record.offTakingStatus === OfftakingStatus.FLAGGED && (
              <ViewButton onClick={() => setViewResolveIndent(record)}>
                Resolve
              </ViewButton>
            )}
            <ViewButton onClick={() => setViewDispatchNote(record)}>
              View Dispatch Note
            </ViewButton>
          </StatusContainer>
        );
      }
    }
  ];
  const buttonDisable =
    indentRequest?.data?.status === IndentRequestStatus.completed;

  return (
    <>
      <CustomHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Release order</Breadcrumb.Item>
          <Breadcrumb.Item>{indentRequest?.data?.releaseId}</Breadcrumb.Item>
        </Breadcrumb>
        <TitleRow>
          <Title level={3}>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginRight: '17px' }}
            />
            Release order - {indentRequest?.data?.releaseId}
          </Title>
          <ButtonContainer>
            <Button
              type="primary"
              onClick={() =>
                navigate(`/offtake-raw-materials/${indentRequest?.data?.id}`, {
                  state: { releaseOrder: releaseOrder }
                })
              }
              disabled={buttonDisable}
            >
              Offtake raw material
            </Button>
            <Button onClick={() => setViewShippingDocuments(releaseOrder)}>
              View document
            </Button>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 0,
                    label: 'Actions',
                    disabled: true
                  },
                  {
                    key: 1,
                    label: 'Complete Offtake',
                    disabled: buttonDisable,
                    onClick: () => setCompleteOfftakeModal(releaseOrder)
                  }
                ]
              }}
              placement="bottomRight"
            >
              <Button>...</Button>
            </Dropdown>
          </ButtonContainer>
        </TitleRow>
      </CustomHeader>
      <Container>
        <OfftakenSummary
          offTakingDetail={offTakingDetail?.data}
          orders={indentRequest?.data?.orders ?? []}
        />
        <Spin spinning={isLoading} tip="Loading" size="large">
          <LogisticsAccordion
            activePanel={activePanel}
            indentRequest={indentRequest?.data!}
            setActivePanel={setActivePanel}
            setViewOrders={setViewOrders}
            viewOrders={viewOrders}
          />
          <TableBody>
            <TableHeader>
              <Title level={4}>Offtake tracking</Title>
              <Search
                placeholder="Search"
                onChange={(e) => !e.target.value && setSearch('')}
                style={{ width: 264 }}
              />
            </TableHeader>
            <Table
              pagination={false}
              columns={columns}
              dataSource={dataSource}
            />
          </TableBody>
        </Spin>
        {!!viewShippingDocuments && (
          <ViewShippingDocument
            open={!!viewShippingDocuments}
            closeModal={() => setViewShippingDocuments(null)}
            shippingDocuments={viewShippingDocuments}
          />
        )}
        {!!completeOfftakeModal && (
          <CompleteOfftake
            open={!!completeOfftakeModal}
            closeModal={() => setCompleteOfftakeModal(null)}
            indentId={releaseOrder.id}
            releaseId={releaseOrder.releaseId}
          />
        )}
      </Container>
      {!!viewResolveIndent && (
        <ResolveIndentModal
          open={!!viewResolveIndent}
          closeModal={() => setViewResolveIndent(null)}
          offtakeDetails={viewResolveIndent}
          indentRequestId={releaseOrder.id}
        />
      )}
      {!!viewDispatchNote && (
        <DispatchNote
          open={!!viewDispatchNote}
          closeModal={() => setViewDispatchNote(null)}
          offtakenDetail={offTakingDetail?.data.offTakings}
          loading={isOfftakeLoading}
          id={viewDispatchNote._id}
          singleOfftake={viewDispatchNote}
        />
      )}
    </>
  );
};
