import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Space, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../styles';
import { CustomTitleText } from './style';

import { useState } from 'react';
import shortArrow from 'src/assets/shortArrow.svg';
import BalanceSummary from './components/BalanceSummary';
import { SummaryQuantityDownloadModal } from './components/SummaryQuantityModal';
import { useSummaryQuantity } from './useSummaryQuantity';

const SummaryQuantity = () => {
  const navigate = useNavigate();
  const dateValue = new Date();
  const {
    port,
    columns,
    warehouseDropDownItems,
    setPort,
    isLoading,
    closingBalance,
    releaseSource,
    offTakeSource,
    deliverySource,
    openingBalance,
    hidePortsDropdown,
    updateStartDateEndDate
  } = useSummaryQuantity();
  const [viewModal, setViewModal] = useState(false);
  const [viewRelease, setViewRelease] = useState(true);
  const [viewDeliveries, setViewDeliveries] = useState(true);
  const [viewOfftake, setViewOfftake] = useState(true);

  return (
    <Spin size="large" spinning={isLoading}>
      {viewModal && (
        <SummaryQuantityDownloadModal
          open={viewModal}
          closeModal={() => setViewModal(false)}
        />
      )}
      <Container>
        <HeadingWrapper>
          <div className="arrowFlex">
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginBottom: '10px', cursor: 'pointer' }}
            />
            <CustomTitleText level={4}>Reports</CustomTitleText>
          </div>
          <Button onClick={() => setViewModal(true)}>Download report</Button>
        </HeadingWrapper>
        <SummaryBody>
          <SummaryTitleWrapper>
            <TitleDiv>
              <h1>Summary Quantity</h1>
              <p>Overview</p>
            </TitleDiv>
            <div>
              {!hidePortsDropdown && (
                <Dropdown
                  menu={{
                    items: Object.values(warehouseDropDownItems),
                    onClick: (item) => setPort(item.key)
                  }}
                >
                  <Button loading={isLoading}>
                    <Space>
                      {warehouseDropDownItems?.[port]?.label}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}
              <DatePicker
                picker="year"
                defaultValue={dayjs(dateValue)}
                onChange={(value) => updateStartDateEndDate(value)}
              />
            </div>
          </SummaryTitleWrapper>
        </SummaryBody>
        <BalanceSummary
          openingBalance={openingBalance}
          closingBalance={closingBalance}
        />

        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Raw Materials Deliveries</CustomTitleText>
          <ClickDiv
            isClicked={viewDeliveries}
            onClick={() => setViewDeliveries(!viewDeliveries)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewDeliveries && (
          <Table
            columns={columns}
            dataSource={deliverySource}
            pagination={false}
            rowClassName={(record) =>
              record.yearMonth === 'Total' ? 'total-row' : ''
            }
          />
        )}
        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Release reports</CustomTitleText>
          <ClickDiv
            isClicked={viewRelease}
            onClick={() => setViewRelease(!viewRelease)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewRelease && (
          <Table
            columns={columns}
            dataSource={releaseSource}
            pagination={false}
            rowClassName={(record) =>
              record.yearMonth === 'Total' ? 'total-row' : ''
            }
          />
        )}
        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Offtake/Sales</CustomTitleText>
          <ClickDiv
            isClicked={viewOfftake}
            onClick={() => setViewOfftake(!viewOfftake)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewOfftake && (
          <Table
            columns={columns}
            dataSource={offTakeSource}
            pagination={false}
            rowClassName={(record) =>
              record.yearMonth === 'Total' ? 'total-row' : ''
            }
          />
        )}
      </Container>
    </Spin>
  );
};

export default SummaryQuantity;
const ClickDiv = styled.div<{ isClicked: boolean }>`
  transform: ${({ isClicked }) => (isClicked ? 'rotate(90deg)' : 'rotate(0)')};
  cursor: pointer;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 24px;

  .arrowFlex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

const SummaryBody = styled.div`
  display: flex;
  background-color: #ffffff;
  padding: 15px;
  margin-top: 20px;
  gap: 10px;
`;

const SummaryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1.5;
  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;
const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  height: 52px;
  h1 {
    height: 25px;
    margin: 0;
  }
  p {
    height: 25px;
    margin: 0;

    color: #006c33;
  }
`;
