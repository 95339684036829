import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IAdmin, useDeleteAdminMutation } from 'src/store/api/nsia/admin';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  admin: IAdmin;
}

export const DeleteAdminModal: FC<IModal> = ({ open, closeModal, admin }) => {
  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(true);
  const name = Form.useWatch('name', form);
  const [deleteAdmin, { isLoading }] = useDeleteAdminMutation();

  useEffect(() => {
    setButtonDisable(admin.name !== name);
  }, [admin.name, name]);

  const handleSubmit = () => {
    deleteAdmin(admin.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted an Admin.'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
    <Modal
      open={open}
      title="Delete admin"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,

        <Button
          danger
          disabled={buttonDisable}
          type={'primary'}
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Yes, Delete
        </Button>
      ]}
      width={660}
    >
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Form.Item
          key={1}
          name={'name'}
          label={
            <FormLabel>
              Do you want to delete “{' '}
              <HighlightedText>{admin.name}</HighlightedText>” , this action
              cannot be undone
            </FormLabel>
          }
        >
          <Input placeholder="Enter the admin name to proceed" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const HighlightedText = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
