import { DownloadOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { Text, Title } from 'src/components';
import { StockSummary } from 'src/components/StockSummary';
import {
  BlendingSiteContainer,
  DetailContainer,
  HeaderWrap,
  OrdersBody,
  Wrap
} from 'src/pages/nsia-admin/indentRequest/style';
import { TextDetails } from 'src/pages/styles';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useGetAllStockQuery } from 'src/store/api';
import {
  IIndentRequest,
  useGetOffTakingDetailsQuery
} from 'src/store/api/indent-request';
import { IndentRequestAction, setSelectedAction } from 'src/store/slices';
import { dateTimeFormater, formatNumber } from 'src/utils';
import styled from 'styled-components';
import { ActionModal } from './ActionModal';
import { OrderedRawMaterials } from './OrderedRawMaterials';
import { RenderOrders } from './RenderOrders';
const { Panel } = Collapse;

type IPendingRequestProp = {
  indentRequest: IIndentRequest;
  setPopUp: React.Dispatch<React.SetStateAction<boolean>>;
};
export const PendingRequest: FC<IPendingRequestProp> = ({
  indentRequest,
  setPopUp
}) => {
  const totalRequestTonnage = useMemo(
    () =>
      indentRequest.orders.reduce(
        (acc, curr) => acc + curr.estimatedQuantity,
        0
      ),
    [indentRequest]
  );
  const dispatch = useAppDispatch();
  const { data: offTakingDetail } = useGetOffTakingDetailsQuery(
    indentRequest.id
  );
  const { selectedAction } = useAppSelector((store) => store.indentRequest);
  const { data: allStock } = useGetAllStockQuery();

  const [updateModal, setUpdateModal] = useState(false);
  const [viewOrders, setViewOrders] = useState(true);

  const [activePanel, setActivePanel] = useState(0);

  return (
    <Container>
      {updateModal && (
        <OrderedRawMaterials
          indentRequest={indentRequest}
          setPopUp={setPopUp}
          open={updateModal}
          closeModal={() => setUpdateModal(false)}
          allStock={allStock?.data}
          offTaking={offTakingDetail?.data}
        />
      )}
      {allStock && (
        <StockSummary
          offTakingDetail={offTakingDetail?.data}
          orders={allStock.data}
        />
      )}

      <Wrap>
        {selectedAction !== IndentRequestAction.Default && (
          <ActionModal
            open={true}
            indentRequest={indentRequest}
            selectedAction={selectedAction}
            closeModal={() =>
              dispatch(setSelectedAction(IndentRequestAction.Default))
            }
          />
        )}
        <BlendingSiteContainer>
          <>
            <HeaderWrap>
              <Title level={4}>Order Details</Title>
              <HideButton
                status={!viewOrders}
                onClick={() => setViewOrders(!viewOrders)}
              >
                {viewOrders ? 'Close' : 'View'}
              </HideButton>
            </HeaderWrap>
            {viewOrders ? (
              <>
                <OrdersBody>
                  <HeaderWrap>
                    <Title level={5}> Request</Title>
                    <UpdateButton onClick={() => setUpdateModal(true)}>
                      Update Request
                    </UpdateButton>
                  </HeaderWrap>
                  <RenderOrders indentRequest={indentRequest} />
                </OrdersBody>
                <DetailContainer>
                  <DetailsDiv>
                    <HeaderWrap>
                      <Title level={5}>About blending site</Title>
                    </HeaderWrap>
                    <TextDetails>
                      <Text type="secondary">Blending Site </Text>
                      <Text strong>
                        {indentRequest.blender.blendingSite.name}
                      </Text>
                    </TextDetails>
                    <TextDetails>
                      <Text type="secondary">Location</Text>
                      <Text strong>
                        {indentRequest.blender.blendingSite?.location?.name}
                      </Text>
                    </TextDetails>
                    <TextDetails>
                      <Text type="secondary">Total request tonnage</Text>
                      <Text strong>{formatNumber(totalRequestTonnage)} MT</Text>
                    </TextDetails>
                    <TextDetails>
                      <Text type="secondary">Date</Text>
                      <Text strong>
                        {dateTimeFormater(indentRequest.createdAt)}
                      </Text>
                    </TextDetails>
                  </DetailsDiv>
                  <DetailsDiv>
                    {indentRequest.logistics.map((log, index) => (
                      <Fragment>
                        <Collapse
                          onChange={(activeKey) =>
                            setActivePanel(Number(activeKey))
                          }
                          accordion
                          expandIconPosition="end"
                          style={{ marginBottom: '10px', width: '100%' }}
                          activeKey={
                            activePanel === index ? String(index) : undefined
                          }
                        >
                          <Panel
                            key={index}
                            header={`Logistics company ${index + 1}`}
                          >
                            <LogisticsDiv>
                              <TextDetails>
                                <Text type="secondary">Company Name </Text>
                                <Text strong>{log.transporter}</Text>
                              </TextDetails>
                              <TextDetails>
                                <Text type="secondary">Contact Person</Text>
                                <Text strong>{log.contactPerson}</Text>
                              </TextDetails>
                              <TextDetails>
                                <Text type="secondary">Phone Number </Text>
                                <Text strong>{log.phone}</Text>
                              </TextDetails>
                              <TextDetails>
                                <Text type="secondary">Document</Text>
                                <DownloadWrapper>
                                  <Text strong className="viewText">
                                    {log.documents[0].name}
                                  </Text>
                                  <DownloadIcon
                                    rel="noreferrer"
                                    target={'_blank'}
                                    href={log.documents[0].url}
                                  >
                                    <DownloadOutlined />
                                  </DownloadIcon>
                                </DownloadWrapper>
                              </TextDetails>
                            </LogisticsDiv>
                          </Panel>
                        </Collapse>
                      </Fragment>
                    ))}
                  </DetailsDiv>
                </DetailContainer>
              </>
            ) : null}
          </>
        </BlendingSiteContainer>
      </Wrap>
    </Container>
  );
};

const DownloadWrapper = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: auto;
  .viewText {
    margin-inline: 15px;
    text-transform: uppercase;
  }
`;
const DownloadIcon = styled.a`
  color: #11b867;
`;
const HideButton = styled(Button)<{ status: boolean }>`
  color: ${({ status }) => (status ? 'green' : 'red')};
  border: none;
`;

const UpdateButton = styled(Button)`
  border: 1px solid #52c41a;
  color: #52c41a;
  border-radius: 2px;
`;

const Container = styled.div`
  padding: 20px;
  .bottom {
    border-bottom: 1px solid #d9d9d9;
  }
  .flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const LogisticsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  background-color: #fafafa;
`;
