import { ConfigProvider } from 'antd';
import Router from './routes/router';
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#52C41A',
          borderRadius: 2,
          colorText: 'rgba(0, 0, 0, 0.85);',
          fontFamily: 'Myriad Pro',
        },
      }}
    >
      <Router />
    </ConfigProvider>
  );
}

export default App;
