import { Button, Form, Input, Modal, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { ISupplier, useDeleteSupplierMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  supplier: ISupplier;
}

export const DeleteSupplier: FC<IModal> = ({ open, closeModal, supplier }) => {
  const [deleteSupplier, { isLoading: isDeleting }] =
    useDeleteSupplierMutation();

  const handleSubmit = () => {
    deleteSupplier(supplier.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted the supplier.'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  const [form] = Form.useForm();

  const name = Form.useWatch('name', form);
  useEffect(() => {
    if (name === supplier.name) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [name, supplier.name]);

  const handleFinish = () => {
    if (!buttonDisable) {
      handleSubmit();
    }
  };

  const [buttonDisable, setButtonDisable] = useState(false);

  return (
    <Modal
      open={open}
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          danger
          type={'primary'}
          disabled={buttonDisable}
          onClick={() => form.submit()}
          loading={isDeleting}
        >
          Yes, Delete
        </Button>
      ]}
      width={660}
    >
      <Form onFinish={handleFinish} form={form} layout={'vertical'}>
        <Form.Item
          name={'name'}
          key={1}
          label={
            <FormLabel>
              Are you sure delete <SupplierName>{supplier.name}</SupplierName>{' '}
              warehouse , this action cannot be undone
            </FormLabel>
          }
        >
          <Input placeholder="Enter the suppliers code" autoComplete="off" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;

const SupplierName = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
