import { BellOutlined, LogoutOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Layout } from 'antd';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'src/assets/authAssets/logo.png';
import { Title } from 'src/components/Typography';
import { RoleConfig, setUser, useAppDispatch, useAppSelector } from 'src/store';
import { useGetUnreadNotificationsCountQuery } from 'src/store/api/navigation';
import { generateUrl } from 'src/utils';
import styled from 'styled-components';
import { NotificationDrawer } from './NotificationDrawer';

const { Header } = Layout;

export const HeaderNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onLogOut = () => {
    dispatch(setUser(null));
    navigate('/');
  };
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const { data: unreadNotificationsCount } =
    useGetUnreadNotificationsCountQuery(generateUrl({ isRead: false }), {
      pollingInterval: 2000
    });
  const { name, role } = useAppSelector((state) => state.auth.user!);
  const userInitials = useMemo(() => {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }, [name]);
  const userRole = RoleConfig[role];
  return (
    <CustomHeader theme="light">
      <LogoImage src={logo} />
      <CustomMenuItem>
        <HeaderRow>
          <Dropdown
            placement="bottomRight"
            trigger={['click']}
            dropdownRender={() => (
              <CustomButton onClick={onLogOut} type="default">
                <LogoutOutlined />
                Logout
              </CustomButton>
            )}
          >
            <RoleDetails>
              <AvatarContainer>
                <AvatarText>{userInitials}</AvatarText>
              </AvatarContainer>
              <CustomTitle level={5}>{userRole.title}</CustomTitle>
            </RoleDetails>
          </Dropdown>
          <Button type="text" onClick={() => setOpenNotificationDrawer(true)}>
            <Badge count={unreadNotificationsCount?.data?.count ?? 0}>
              <BellOutlined style={{ fontSize: 16 }} />
            </Badge>
          </Button>
        </HeaderRow>
        {openNotificationDrawer && (
          <NotificationDrawer
            open={openNotificationDrawer}
            closeModal={() => setOpenNotificationDrawer(false)}
          />
        )}
      </CustomMenuItem>
    </CustomHeader>
  );
};

const CustomHeader = styled(Header)`
  padding: 0;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff !important;
  padding: 0px 50px;
  z-index: 1;
  box-shadow: inset 0px -2px 0px #f0f0f0;
  height: 45px;
`;

const RoleDetails = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background: #f0f0f0;
  padding: 16px 12px;
`;
const CustomTitle = styled(Title)`
  margin: 0;
  margin-bottom: 0 !important;
`;
const LogoImage = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;
const AvatarContainer = styled.div`
  background-color: #ff7a45;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CustomButton = styled(Button)`
  width: 200px;
  height: 40px;
  background-color: #fafafa;
  margin-top: -6px;
  text-align: left;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0);
`;
const CustomMenuItem = styled.div``;
const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const AvatarText = styled.h4`
  margin: 0;
  color: #ffffff;
  font-size: 14px;
`;
