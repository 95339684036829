import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RangePicker, Search } from 'src/components';
import { CustomTitle, HeadingWrapper } from 'src/pages/reports/style';
import { ButtonContainer } from 'src/pages/styles';
import { IUser, Role, useAppSelector } from 'src/store';
import { useGetReleaseReportsQuery } from 'src/store/api';
import {
  dateTimeFormater,
  endOfMonth,
  formatNumber,
  generateSerialNumber,
  generateUrl,
  startOfMonth
} from 'src/utils';
import styled from 'styled-components';
import { ReleaseReportDownloadModal } from './components/ReleaseReportDownloadModal';
import { Container, Wrapper } from './style';

export const ReleaseReports = () => {
  const [viewDownloadModal, setViewDownloadModal] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [queryParams, setParams] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });

  const user = useAppSelector((store) => store.auth.user) as IUser;

  const { data: getReleaseReports, isLoading } = useGetReleaseReportsQuery(
    generateUrl({
      limit: 10,
      search,
      page,
      ...queryParams
    })
  );

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParams({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };

  const releaseReports = useMemo(() => {
    return (
      getReleaseReports?.data.map((item, index) => ({
        key: generateSerialNumber(page, index),
        ...item,
        serialNumber: generateSerialNumber(page, index),
        releaseId: item.indentRequest.releasedId,
        rawMaterials: item.rawMaterial.name,
        blender: item.blender.name,
        warehouse: item.warehouse.name,
        balance: item.rawMaterial.balance,
        date: dateTimeFormater(item.offtaking.createdAt, 'MMM Do, YYYY'),
        qtyReleased: formatNumber(`${item.rawMaterial.quantityReleased}MT`),
        qtyOfftaken: formatNumber(`${item.rawMaterial.quantityOfftaken} MT`)
      })) ?? []
    );
  }, [getReleaseReports, page]);

  const nsiaColumn =
    user.role === Role.NSIA_ADMIN
      ? [
          {
            title: 'Blender (Offtaker)',
            dataIndex: 'blender',
            key: 'blender'
          }
        ]
      : [];
  const CMPortColumn =
    user.role === Role.COLLATERAL_MANAGER
      ? [
          {
            title: 'Blender (Offtaker)',
            dataIndex: 'blender',
            key: 'blender'
          }
        ]
      : [];
  const columns = [
    {
      title: 'S/N',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: 'Release ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },
    {
      title: 'Raw material',
      dataIndex: 'rawMaterials',
      key: 'rawMaterials'
    },
    ...nsiaColumn,
    ...CMPortColumn,
    {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      key: 'warehouse'
    },
    {
      title: 'Qty Released',
      dataIndex: 'qtyReleased',
      key: 'qtyReleased'
    },
    {
      title: 'Qty Offtaken',
      dataIndex: 'qtyOfftaken',
      key: 'qtyOfftaken'
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Container>
      {viewDownloadModal && (
        <ReleaseReportDownloadModal
          open={viewDownloadModal}
          urlQueryParam={generateUrl({
            download: 'true',
            search,
            ...queryParams
          })}
          closeModal={() => setViewDownloadModal(false)}
        />
      )}
      <HeadingWrapper>
        <div className="arrowFlex">
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          />
          <CustomTitle>Raw material release report</CustomTitle>
        </div>

        <ButtonContainer>
          <Button onClick={() => setViewDownloadModal(true)}>
            Download report
          </Button>
        </ButtonContainer>
      </HeadingWrapper>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <Wrapper>
          <InfoCardHeader>
            <CustomTitle></CustomTitle>
            <ActionContainer>
              <DropdownWrap>
                <RangePicker
                  defaultValue={[startOfMonth(), endOfMonth()]}
                  onChange={(datejs) => handleDateChange(datejs)}
                />
              </DropdownWrap>
              <Search
                placeholder="Search"
                style={{ width: 264 }}
                onSearch={onSearch}
                onChange={(e) => !e.target.value && setSearch('')}
              />
            </ActionContainer>
          </InfoCardHeader>
          <Table
            pagination={{
              total: getReleaseReports?.pagination?.totalCount,
              defaultCurrent: 1,
              pageSize: 10,
              onChange: (page) => {
                setPage(page);
              }
            }}
            columns={columns}
            dataSource={releaseReports}
          />
        </Wrapper>
      </Spin>
    </Container>
  );
};
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DropdownWrap = styled.div`
  margin-right: 20px;
`;
const InfoCardHeader = styled.div`
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 78px;
  display: flex;
  align-items: center;
  padding: 2px 24px;
`;
