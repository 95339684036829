import { FC } from 'react';
import { Button, Divider, Form, Input, Modal, notification } from 'antd';
import { Label } from 'src/pages/nsia-admin/indentRequest/style';
import styled from 'styled-components';
import { useDeleteIndentRequestMutation } from 'src/store/api';

type IModal = {
  closeModal: () => void;
  open: boolean;
  id: string;
  releaseId: string;
};
export const DeleteModal: FC<IModal> = ({
  closeModal,
  open,
  id,
  releaseId
}) => {
  const [deleteIndentRequest, { isLoading }] = useDeleteIndentRequestMutation();
  const [form] = Form.useForm();
  const confirmIndentOrderId = Form.useWatch('id', form);
  const handleSubmit = () => {
    deleteIndentRequest(id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted a pending request'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  const handleFinish = () => {
    if (releaseId === confirmIndentOrderId) {
      handleSubmit();
    }
  };
  return (
    <Modal
      title={'Delete Indent'}
      open={open}
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          type={'primary'}
          loading={isLoading}
          disabled={releaseId !== confirmIndentOrderId}
          key="submit"
          onClick={() => form.submit()}
        >
          Yes Delete
        </Button>
      ]}
      width={572}
    >
      <Divider />
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Label>
          Do you want to delete Indent <OrderId>{releaseId}</OrderId>, this
          action cannot be undone
        </Label>
        <Form.Item name="id">
          <Input
            placeholder="Enter raw material request id"
            autoComplete="off"
          />
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  );
};
const OrderId = styled.p`
  color: rgba(17, 184, 103, 1);
  display: inline;
  margin-inline: 3px;
`;
