import { Card, DatePicker } from 'antd';
import { BarChart } from 'src/components';
import styled from 'styled-components';
import { useGetRawMaterialChart } from './useGetRawMaterialChart';
import { endOfMonth, startOfMonth } from 'src/utils';

const { RangePicker } = DatePicker;

export const RawMaterialsDistribution = () => {
  const { handleDateChange, config } = useGetRawMaterialChart();

  return (
    <StyledCard title="Raw materials Distribution">
      <DropdownContainer>
        <DropdownWrap>
          <RangePicker
            defaultValue={[startOfMonth(), endOfMonth()]}
            onChange={handleDateChange}
          />
        </DropdownWrap>
      </DropdownContainer>
      <BarChart {...config} />
    </StyledCard>
  );
};
const StyledCard = styled(Card)`
  width: 60%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  height: fit-content;
`;
const DropdownContainer = styled.div`
  position: relative;
`;
const DropdownWrap = styled.div`
  display: flex;
  gap: 0.8rem;
  position: absolute;
  right: 0px;
  top: -70px;
`;
