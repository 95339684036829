import { Card } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { useGetBlendingPlantsQuery } from 'src/store/api';
import { Marker } from './Marker';

export const BlendingSiteLocation: FC = () => {
  const { data: blendingPlants } = useGetBlendingPlantsQuery();

  return (
    <StyledCard title="Blending Plant Geographical Location">
      <Container>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY! }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{
            lat: 9.082,
            lng: 8.6753
          }}
          defaultZoom={1}
        >
          {blendingPlants?.data.map((item, index) => (
            <Marker
              key={index}
              text={item.name}
              lat={item.location.coordinates[0]}
              lng={item.location.coordinates[1]}
            />
          ))}
        </GoogleMapReact>
      </Container>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 40px 0;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
const Container = styled.div`
  width: 100%;
  height: 400px;
`;
