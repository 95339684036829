import { Button, DatePicker, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Search } from 'src/components';
import { ButtonContainer } from 'src/pages/styles';
import {
  IVesselDischargeReport,
  useGetVesselDischargeReportQuery
} from 'src/store/api';
import { dateTimeFormater, formatNumber, generateUrl } from 'src/utils';
import { endOfMonth, startOfMonth } from 'src/utils/dateRange';
import styled from 'styled-components';
import { DischargeVesselDownloadModal } from './components/DischargeVesselDownloadModal';
import {
  Container,
  CustomTitleText,
  HeadingWrapper,
  TitleDiv,
  Wrapper
} from './style';

const { RangePicker } = DatePicker;

export const DischargeVesselReport = () => {
  const [viewDownloadModal, setViewDownloadModal] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [queryParams, setParams] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });
  const { data: getVesselDischargeReport, isLoading } =
    useGetVesselDischargeReportQuery(
      generateUrl({
        limit: 10,
        ...queryParams,
        search,
        page
      })
    );

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParams({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };
  const vesselDischargeReport = useMemo(() => {
    return (
      getVesselDischargeReport?.data?.map((item, index) => ({
        key: item.id ?? '',
        serialNumber: 1 + index,
        rawMaterials: item.rawMaterial.name,
        purchaseDate: dateTimeFormater(
          item.purchaseOrder.createdAt,
          'MMM Do, YYYY'
        ),
        date: dateTimeFormater(item.createdAt, 'MMM Do, YYYY'),
        port: item.port.name,
        quantityDischarged: formatNumber(`${item.quantityDischarged} MT`),
        vesselName: item.purchaseOrder.vesselName,
        total: formatNumber(`${item.quantityDischarged} MT`),
        orderName: item
      })) ?? []
    );
  }, [getVesselDischargeReport]);

  const columns = [
    {
      title: 'Order No.',
      dataIndex: 'orderName',
      key: 'orderName',
      render: (vesselReport: IVesselDischargeReport) => (
        <OrderDiv
          onClick={() =>
            navigate(
              `/reports/vessel-discharge-report/${vesselReport.purchaseOrder.id}`,
              {
                state: { vesselReport }
              }
            )
          }
        >
          {vesselReport.purchaseOrder.purchaseId}
        </OrderDiv>
      )
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port'
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vesselName',
      key: 'vesselName'
    },
    {
      title: 'Raw materials',
      dataIndex: 'rawMaterials',
      key: 'rawMaterials'
    },
    {
      title: 'Discharged Quantity',
      dataIndex: 'quantityDischarged',
      key: 'quantityDischarged'
    },
    {
      title: 'Purchase Date',
      dataIndex: 'purchaseDate',
      key: 'purchaseDate'
    },
    {
      title: 'Discharge Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total'
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      {viewDownloadModal && (
        <DischargeVesselDownloadModal
          open={viewDownloadModal}
          closeModal={() => setViewDownloadModal(false)}
          urlQueryParam={generateUrl({
            download: 'true',
            search,
            ...queryParams
          })}
        />
      )}
      <HeadingWrapper>
        <div className="arrowFlex">
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ marginBottom: '10px', cursor: 'pointer' }}
          />
          <CustomTitleText level={5}>Vessel Discharge Reports</CustomTitleText>
        </div>
        <ButtonContainer>
          <Button onClick={() => setViewDownloadModal(true)}>
            Download report
          </Button>
        </ButtonContainer>
      </HeadingWrapper>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <Wrapper>
          <InfoCardHeader>
            <TitleDiv>
              <h1>
                Purchase <span>Report</span>
              </h1>
            </TitleDiv>
            <ActionContainer>
              <DropdownWrap>
                <RangePicker
                  defaultValue={[startOfMonth(), endOfMonth()]}
                  onChange={(datejs) => handleDateChange(datejs)}
                />
              </DropdownWrap>
              <Search
                placeholder="Search"
                style={{ width: 264 }}
                onSearch={onSearch}
                onChange={(e) => !e.target.value && setSearch('')}
              />
            </ActionContainer>
          </InfoCardHeader>

          <Table
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: getVesselDischargeReport?.pagination?.totalCount,
              onChange: (page) => {
                setPage(page);
              }
            }}
            columns={columns}
            dataSource={vesselDischargeReport}
          />
        </Wrapper>
      </Spin>
    </Container>
  );
};

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DropdownWrap = styled.div`
  margin-right: 20px;
`;
const InfoCardHeader = styled.div`
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 78px;
  display: flex;
  align-items: center;
  padding: 7px 24px;
  margin-bottom: 15px;
`;

const OrderDiv = styled.button`
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;
