import {
  CustomHeader,
  CustomTitle,
  CardRow,
  CustomMain,
  ActionWrap
} from 'src/pages/reports/style';
import { TableBody, TableHeader } from 'src/pages/styles';
import { Spin, Table, Button, } from 'antd';
import PortIcon from 'src/assets/dashboardIcons/portsIcon.svg';
import { AnalyticsCard, RangePicker, Search, Title } from 'src/components';
import { RangeValue } from 'rc-picker/lib/interface';
import dayJs from 'dayjs';
import {
  useGetWarehouseReceiptsReportQuery,
  useGetWarehouseReceiptsStatsQuery
} from 'src/store/api/nsia/reports';
import { useMemo, useState, } from 'react';
import {
  dateTimeFormater,
  endOfMonth,
  formatNumber,
  generateSerialNumber,
  generateUrl,
  startOfMonth
} from 'src/utils';
import { IUser, Role, useAppSelector } from 'src/store';
import { WarehouseReceiptsDownloadModal } from './components/WarehouseReceiptsDownloadModal';

export const WarehouseReceiptsReport = () => {
  const [viewDownloadModal, setViewDownloadModal] = useState(false)
  const user = useAppSelector((store) => store.auth.user) as IUser;
  const [search, setSearchParam] = useState('');
  const [page, setPage] = useState(1);
  const [queryParam, setParam] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });

  const { data: getWarehouseReceiptsReport, isLoading } =
    useGetWarehouseReceiptsReportQuery(
      generateUrl({
        search,
        page,
        limit: 10,
        ...queryParam
      })
    );

  const { data: getWarehouseReceiptsStats } = useGetWarehouseReceiptsStatsQuery(
    generateUrl({ ...queryParam, search })
  );
  const handleSearch = (searchValue: string) => {
    setSearchParam(searchValue);
  };
  const handleDateChange = (datejs: RangeValue<dayJs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParam({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };


  const warehouseReports = useMemo(() => {
    return (
      getWarehouseReceiptsReport?.data.map((item, index) => ({
        key: generateSerialNumber(page, index),
        ...item,
        serialNum: generateSerialNumber(page, index),
        collateralManager: item.collateralManager.name,
        rawMaterial: item.rawMaterial.name,
        date: dateTimeFormater(item.createdAt),
        warehouse: item.warehouse.name,
        vesselName: item.purchaseOrder.vesselName,
        quantity: formatNumber(`${item.quantityReceived} MT`)
      })) ?? []
    );
  }, [getWarehouseReceiptsReport, page]);


  const nsiaColumn =
    user.role === Role.NSIA_ADMIN
      ? [
        {
          title: 'Collateral Manager',
          dataIndex: 'collateralManager',
          key: 'collateralManager'
        }
      ]
      : [];

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'serialNum',
      key: 'serialNum'
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vesselName',
      key: 'vesselName'
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      key: 'warehouse'
    },
    ...nsiaColumn,
    {
      title: 'Raw Material',
      dataIndex: 'rawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  return (
    <>
      {viewDownloadModal && (
        <WarehouseReceiptsDownloadModal
          open={viewDownloadModal}
          urlQueryParam={generateUrl({
            download: 'true',
            search,
            ...queryParam
          })}
          closeModal={() => setViewDownloadModal(false)}
        />
      )}
      <CustomHeader>
        <CustomTitle>Warehouse report (Receipts)</CustomTitle>

        <Button onClick={() => setViewDownloadModal(true)}>

          Download report
        </Button>
      </CustomHeader>

      <CustomMain>
        <CustomHeader>
          <CustomTitle>Receipt Report</CustomTitle>
          <ActionWrap>
            <RangePicker
              defaultValue={[startOfMonth(), endOfMonth()]}
              onChange={(datejs) => handleDateChange(datejs)}
            />
            <Search
              onChange={(e) => !e.target.value && setSearchParam('')}
              placeholder="Search"
              style={{ width: 264 }}
              onSearch={handleSearch}
            />
          </ActionWrap>
        </CustomHeader>
        <CardRow>
          <AnalyticsCard
            title="Total Raw Materials Received"
            value={`${getWarehouseReceiptsStats?.data.totalRawMaterialReceived} MT`}
            icon={PortIcon}
            width="100%"
          />
        </CardRow>

        <TableBody>
          <TableHeader>
            <Title level={4}>View Reports</Title>
          </TableHeader>
          <Spin spinning={isLoading} tip="Loading" size="large">
            <Table
              dataSource={warehouseReports}
              columns={columns}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                total: getWarehouseReceiptsReport?.pagination.totalCount ?? 0,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </Spin>
        </TableBody>
      </CustomMain>
    </>
  );
};
