import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Role, useAppSelector } from 'src/store';
import {
  ICollateralManagerPort,
  MonthlyReport,
  useGetAllWarehousesQuery,
  useGetMasterReportsQuery,
  useGetRawMaterialsQuery,
  useGetWarehouseReportsByIdQuery
} from 'src/store/api';
import { formatNumber, generateUrl } from 'src/utils';

export const useSummaryQuantity = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [hidePortsDropdown, setHidePortsDropdown] = useState(false);
  const [port, setPort] = useState('');
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const { data: rawMaterials } = useGetRawMaterialsQuery(undefined, {
    skip: user?.role === Role.COLLATERAL_MANAGER
  });

  const { data: warehouse } = useGetAllWarehousesQuery();

  const urlQueryParam = generateUrl({
    startDate: startDate,
    endDate: endDate,
    warehouse: port
  });

  const { data: adminWarehouseReport, isLoading: isAdminLoading } =
    useGetMasterReportsQuery(urlQueryParam, {
      skip: user?.role === Role.COLLATERAL_MANAGER
    });
  const { data: cmWarehouseReport, isLoading: IsCmLoading } =
    useGetWarehouseReportsByIdQuery(
      {
        startDate: startDate,
        endDate: endDate,
        port: (user as ICollateralManagerPort)?.port?.id
      },
      { skip: user?.role === Role.NSIA_ADMIN }
    );

  const warehouseReport =
    user?.role === Role.COLLATERAL_MANAGER
      ? cmWarehouseReport
      : adminWarehouseReport;

  const isLoading =
    user?.role === Role.COLLATERAL_MANAGER ? isAdminLoading : IsCmLoading;

  const allRawMaterialDefaultQuatity = useMemo(() => {
    return rawMaterials?.data.reduce<Record<string, string>>(
      (acc, rawMaterial) => {
        acc[rawMaterial.id] = '-';
        return acc;
      },
      {}
    );
  }, [rawMaterials]);

  const warehouseDropDownItems = useMemo(() => {
    type IMenuItem = { label: string; key: string };
    const obj: Record<string, IMenuItem> = {
      '': {
        label: 'Master',
        key: ''
      }
    };
    warehouse?.data.forEach((item) => {
      obj[item.id] = {
        label: item.code,
        key: item.id
      };
    });
    setPort(Object.keys(obj)[0] ?? '');
    return obj;
  }, [warehouse]);

  const openingBalance = useMemo(() => {
    const transformRawMaterial =
      rawMaterials?.data.map((rawMaterial) => ({
        name: rawMaterial.name,
        balance: 0
      })) ?? [];

    if ((warehouseReport?.data?.openingBalances ?? []).length === 0) {
      return transformRawMaterial;
    }

    return transformRawMaterial.map((rawMaterial) => {
      const rawMaterialBalance = warehouseReport?.data.openingBalances.find(
        (openingBalance) => openingBalance.rawMaterial.name === rawMaterial.name
      );
      return {
        ...rawMaterial,
        balance: rawMaterialBalance?.quantity ?? 0
      };
    });
  }, [warehouseReport, rawMaterials]);

  const columns = useMemo(() => {
    const yearTitle = {
      title: 'Year-Month',
      dataIndex: 'yearMonth',
      key: 'yearMonth'
    };

    const dynamicColumn = rawMaterials?.data
      ? rawMaterials.data.map((materials, index) => ({
          title: materials.name,
          dataIndex: materials.id,
          key: index
        }))
      : [];

    const returnData = [yearTitle, ...dynamicColumn];
    return returnData;
  }, [rawMaterials]);

  const generateDataSource = (
    reports: MonthlyReport[] = [],
    rawMaterialsDefaultQuatity: Record<string, string>
  ) => {
    const totalRow: Record<string, number | string> = {
      ...rawMaterialsDefaultQuatity
    };
    const data = reports.map((item, index) => {
      const deliveries = item.rawMaterial.reduce<
        Record<string, string | number>
      >((acc, delivery) => {
        acc[delivery.id] = `${formatNumber(delivery.quantity)} MT`;

        // calculate and store total of each raw material
        if (totalRow[delivery.id] !== '-') {
          totalRow[delivery.id] =
            Number(totalRow[delivery.id]) + delivery.quantity;
        } else {
          totalRow[delivery.id] = delivery.quantity;
        }
        return acc;
      }, {});
      const yearMonth = dayjs()
        .set('y', item.year)
        .set('M', item.month - 1)
        .format('MMM-YYYY');
      return {
        yearMonth,
        ...rawMaterialsDefaultQuatity,
        key: yearMonth + index,
        ...deliveries
      };
    });

    //format total row
    for (const total in totalRow) {
      if (totalRow[total] === '-') {
        totalRow[total] = `${formatNumber(0)} MT`;
      } else {
        totalRow[total] = `${formatNumber(totalRow[total])} MT`;
      }
    }
    totalRow['yearMonth'] = 'Total';
    return [...data, totalRow];
  };
  const deliverySource = useMemo(() => {
    return generateDataSource(warehouseReport?.data.deliveryReport, {
      ...(allRawMaterialDefaultQuatity ?? {})
    });
  }, [warehouseReport?.data.deliveryReport, allRawMaterialDefaultQuatity]);

  const offTakeSource = useMemo(() => {
    return generateDataSource(warehouseReport?.data.offTakeReport, {
      ...(allRawMaterialDefaultQuatity ?? {})
    });
  }, [warehouseReport?.data.offTakeReport, allRawMaterialDefaultQuatity]);

  const releaseSource = useMemo(() => {
    return generateDataSource(warehouseReport?.data.releaseReport, {
      ...(allRawMaterialDefaultQuatity ?? {})
    });
  }, [warehouseReport?.data.releaseReport, allRawMaterialDefaultQuatity]);

  const getReportSumPerRawMaterial = (report: MonthlyReport[]) => {
    return report.reduce<
      Record<
        string,
        {
          name: string;
          balance: number;
        }
      >
    >((acc, current) => {
      current.rawMaterial.forEach((rawM) => {
        if (rawM.name in acc) {
          acc[rawM.name].balance += rawM.quantity;
        } else {
          acc[rawM.name] = {
            name: rawM.name,
            balance: rawM.quantity
          };
        }
      });
      return acc;
    }, {});
  };
  const closingBalance = useMemo(() => {
    const deliveryReport = getReportSumPerRawMaterial(
      warehouseReport?.data.deliveryReport ?? []
    );
    const offtakeReport = getReportSumPerRawMaterial(
      warehouseReport?.data.offTakeReport ?? []
    );
    return openingBalance.map((rmOpening) => {
      const offTake = offtakeReport?.[rmOpening.name]?.balance ?? 0;
      const delivery = deliveryReport?.[rmOpening.name]?.balance ?? 0;
      return {
        name: rmOpening.name,
        balance: rmOpening.balance + delivery - offTake
      };
    });
  }, [openingBalance, warehouseReport]);

  const updateStartDateEndDate = (dateValue: dayjs.Dayjs | null) => {
    const year = dateValue?.year() ?? new Date().getFullYear();
    const startDate = dayjs(new Date(year, 0, 1)).format('YYYY-MM-DD');
    setStartDate(startDate);

    const endDate = dayjs(new Date(year, 11, 31, 23, 59, 59, 999)).format(
      'YYYY-MM-DD'
    );
    setEndDate(endDate);
  };
  useEffect(() => {
    if (user?.role === Role.COLLATERAL_MANAGER) {
      setHidePortsDropdown(true);
    }
  }, [user]);
  return {
    port,
    setPort,
    isLoading,
    warehouseDropDownItems,
    allRawMaterialDefaultQuatity,
    openingBalance,
    startDate,
    endDate,
    columns,
    deliverySource,
    offTakeSource,
    releaseSource,
    closingBalance,
    hidePortsDropdown,
    updateStartDateEndDate
  };
};
