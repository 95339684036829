import { Button, Modal, Form, Input, Select, notification } from 'antd';
import { FC } from 'react';
import {
  IAddCleringAgent,
  IClearingAgent,
  useEditClearingAgentMutation,
  useGetAllPortsQuery
} from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
  agentDetails: IClearingAgent;
}

export const EditClearingAgents: FC<IModal> = ({
  open,
  closeModal,
  agentDetails
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [editClearingAgent, { isLoading }] = useEditClearingAgentMutation();
  const { data: ports } = useGetAllPortsQuery();

  const handleSubmit = (data: Partial<IAddCleringAgent>) => {
    const payload: Partial<IAddCleringAgent> = {};
    if (data.name !== agentDetails.name) {
      payload.name = data.name;
    }

    if (data.phone !== agentDetails.phone) {
      payload.phone = data.phone;
    }

    if (data.port !== agentDetails.port.id) {
      payload.port = data.port;
    }
    editClearingAgent({ body: payload, id: agentDetails.id })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated clearing agent details'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  return (
    <Modal
      open={open}
      title="Edit Clearing agent"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          loading={isLoading}
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
        >
          Update
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        initialValues={{
          name: agentDetails.name,
          location: agentDetails.location,
          email: agentDetails.email,
          phone: agentDetails.phone,
          port: agentDetails?.port?.id
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name"
          key={'1'}
          rules={[
            { required: true, message: 'Clearing agent name is required' }
          ]}
        >
          <Input placeholder="Enter Clearing agent name" autoComplete='off' />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email address"
          key={'3'}
          rules={[
            { required: true, message: 'Clearing agent email is required' }
          ]}
        >
          <Input disabled placeholder="Enter address" autoComplete='off' />
        </Form.Item>
        <Form.Item
          name="phone"
          hasFeedback
          label="Phone number"
          key={'4'}
          rules={[
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input placeholder="Enter phone number" autoComplete='off' />
        </Form.Item>
        <Form.Item
          name="port"
          label="Port"
          rules={[{ required: true, message: 'port is required' }]}
          key={'5'}
        >
          <Select placeholder="Select Port" allowClear>
            {ports?.data.map((port) => (
              <Option key={port.id} value={port.id}>
                {port.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
