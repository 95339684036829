import { Button, Form, Modal, Spin, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FC, useMemo } from 'react';
import {
  IIndentRequest,
  useCreateAmendOrderMutation,
  useGetOffTakingDetailsQuery,
  useGetRawMaterialsQuery
} from 'src/store/api';
import styled from 'styled-components';
import { AmendOrderRow } from './AmmendOrderRow';

interface IModal {
  open: boolean;
  closeModal: () => void;
  indent: IIndentRequest;
}

export const AmendOrder: FC<IModal> = ({ open, closeModal, indent }) => {
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  const [form] = Form.useForm();
  const { data: offtakeDetails, isLoading: isLoadingOfftakeDetails } =
    useGetOffTakingDetailsQuery(indent.id);

  const [orderAmend, { isLoading: isAmending }] = useCreateAmendOrderMutation();

  const initialValues = useMemo(() => {
    if (offtakeDetails?.data) {
      const { totalOfftakingGroupedByRawMaterial } = offtakeDetails.data;
      const formValue: Record<
        string,
        {
          rawMaterial: string;
          offtaken: number;
          releaseQuantity: number;
        }
      > = {};
      indent.orders.forEach((order) => {
        formValue[order.rawMaterial.id] = {
          rawMaterial: order.rawMaterial.id,
          offtaken:
            totalOfftakingGroupedByRawMaterial?.[order.rawMaterial.id]
              ?.totalOfftakenQuantity ?? 0,
          releaseQuantity: order.estimatedQuantity ?? 0
        };
      });
      form.setFieldValue(
        'amendedOrders',
        Object.values(formValue).map((item) => ({
          rawMaterial: item.rawMaterial,
          estimatedQuantity: item.releaseQuantity
        }))
      );
      return formValue;
    }
  }, [offtakeDetails, form, indent]);

  const handleSubmit = (payload: {
    amendedOrders: Array<{
      rawMaterial: string;
      estimatedQuantity: number;
    }>;
    blenderFeedback: string;
  }) => {
    orderAmend({
      body: {
        amendedOrders: payload.amendedOrders,
        blenderFeedback: payload.blenderFeedback
      },
      id: indent.id
    })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Your order has been amended'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  return (
    <Modal
      open={open}
      key={'32'}
      title={
        <span>
          Amend order for <em>{indent.releaseId}</em>
        </span>
      }
      onCancel={closeModal}
      centered
      width={1000}
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          loading={isAmending}
          type="primary"
          onClick={() => form.submit()}
        >
          {' '}
          Amend order
        </Button>
      ]}
    >
      <Spin spinning={isLoadingOfftakeDetails}>
        <Form
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={handleSubmit}
        >
          <Form.List name={'amendedOrders'}>
            {(fields) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <AmendOrderRow
                      index={index}
                      form={form}
                      initialValues={initialValues}
                      rawMaterials={rawMaterials?.data}
                      key={field.key}
                      field={field}
                    />
                  );
                })}
              </>
            )}
          </Form.List>
          <br />
          <Form.Item
            name={'blenderFeedback'}
            label="Feedback"
            rules={[{ required: true, message: 'Feedback is required' }]}
          >
            <StyledTextArea
              placeholder="Attach note for NSIA operator"
              required
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

const StyledTextArea = styled(TextArea)`
  border-radius: 8px;
`;
