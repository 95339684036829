import React, { useMemo } from 'react';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';
import { Text } from 'src/components';
import { Tag } from 'antd';

interface BalanceProps {
  openingBalance: { name: string; balance: number }[];
  closingBalance: { name: string; balance: number }[];
}

const BalanceSummary: React.FC<BalanceProps> = ({
  openingBalance,
  closingBalance
}) => {
  const totalOpeningBalance = useMemo(
    () => openingBalance.reduce((total, balance) => total + balance.balance, 0),
    [openingBalance]
  );
  const totalClosingBalance = useMemo(
    () => closingBalance.reduce((total, balance) => total + balance.balance, 0),
    [closingBalance]
  );

  return (
    <SummaryDiv>
      <QuantityInfo>
        <TextDetails>
          <Text>OPENING BALANCE: </Text>
          <Text className="titleText">
            {formatNumber(totalOpeningBalance)} MT
          </Text>
        </TextDetails>
        <div className="flex">
          {openingBalance.map((balance, index) => (
            <RawMaterialTag color="white" key={index}>
              <p>{balance.name}-</p>
              <Text>{formatNumber(balance.balance)}MT</Text>
            </RawMaterialTag>
          ))}
        </div>
      </QuantityInfo>
      <Seperate />
      <QuantityInfo>
        <TextDetails>
          <Text>CLOSING BALANCE: </Text>
          <Text className="titleText">
            {formatNumber(totalClosingBalance)} MT
          </Text>
        </TextDetails>
        <div className="flex">
          {closingBalance.map((balance, index) => (
            <RawMaterialTag color="white" key={index}>
              <p>{balance.name} - </p> {''}
              <Text> {formatNumber(balance.balance)}MT</Text>
            </RawMaterialTag>
          ))}
        </div>
      </QuantityInfo>
    </SummaryDiv>
  );
};

export default BalanceSummary;

const RawMaterialTag = styled(Tag)`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #91d5ff;
  background-color: #ebf8ff;
  height: 32px;
  gap: 2px;
  p {
    color: #096dd9;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 24px;

  .arrowFlex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

const SummaryDiv = styled.div`
  display: flex;
  background-color: #e6f7ff;
  width: 100%;
  margin-top: 10px;
`;
const Seperate = styled.div`
  background-color: #8c8c8c;
  width: 1px;
`;
const QuantityInfo = styled.div`
  flex-direction: column;
  min-width: fit-content;
  border-radius: 4px;
  padding: 12px 16px;
  column-gap: 12px;
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  row-gap: 16px;
  .flex {
    display: flex;
    gap: 10px;
  }
`;

const TextDetails = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: space-between;
  font-weight: 600;
  column-gap: 4px;
  .titleText {
    color: #8c8c8c;
  }
`;
