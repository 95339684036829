import { IDischargeRawMaterial } from 'src/pages/clearingAgents/purchaseOrder/components/interface';
import {
  IClearingAgent,
  ICollateralManagerPort,
  IPort,
  IRawMaterial,
  ISupplier,
  IWarehouse
} from 'src/store/api';
import { IAdmin } from '../nsia/admin';

export enum DOCTYPE {
  PAAR = 'shipping document',
  FACT = 'statement of fact'
}
export interface ICreatePurchaseOrder {
  orders: {
    rawMaterial: string;
    estimatedQuantity: string | number;
  }[];
  vesselName: string;
  arrivalTime: string;
  portQuantities: {
    port: string;
    quantity: number;
  }[];
  clearingAgent: string;
  supplier: string;
}
export interface IUploadImage {
  id: string;
  files: FormData;
}
export interface IDocument {
  name: string;
  url: string;
  documentType: DOCTYPE;
}
export interface IPurchaseOrder {
  orders: [
    {
      rawMaterial: IRawMaterial;
      estimatedQuantity: number | string;
    }
  ];
  vesselName: string;
  arrivalTime: string;
  portQuantities: Array<{ port: IPort; quantity: number }>;
  clearingAgent: IClearingAgent;
  id: string;
  purchaseId: string;
  status: string;
  paarStatus: string;
  createdAt: string;
  createdBy: IAdmin;
  dischargeOrders: ITrackDischarge[];
  documents: IDocument[];
  supplier: ISupplier;
}
export interface IUploadStatement {
  id: string;
  files: FormData;
}

export interface IDischargeVessel {
  id: string;
  data: {
    dischargeOrders: IDischargeRawMaterial[];
  };
}

export interface ITrackDischarge {
  dischargedBy: {
    id: string;
    name: string;
  };
  feedback: string;
  collateralManager: ICollateralManagerPort;
  purchaseOrder: {
    id: string;
    orders: [
      {
        rawMaterial: IRawMaterial;
        estimatedQuantity: number | string;
      }
    ];
    vesselName: string;
  };
  quantityDischarged: number;
  quantityReceived: number;
  rawMaterial: IRawMaterial;
  updatedAt: string;
  createdAt: string;
  status: string;
  warehouse: IWarehouse;
  id: string;
}

export interface IFetchDischargeOrder {
  purchaseId: string;
  query: string;
}

export interface IDischargeOrder {
  orders: [
    {
      rawMaterial: IRawMaterial;
      estimatedQuantity: number | string;
    }
  ];
  ports: IPort[];
  id: string;
  status: string;
  vesselName: string;
  arrivalTime: string;
}

export interface IResolveConflict {
  id: string;
  dischargeOrderId: string;
  data: {
    status: string;
    quantityRecieved?: number;
  };
}
export interface IConfirmPurchaseOrder {
  id: string;
  purchaseId: string;
  data: {
    quantityReceived: number;
    feedback?: string;
  };
}
