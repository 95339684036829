import { Card, Col, Row, Typography } from 'antd';
import moment from 'moment';
import { FC, useMemo } from 'react';
import {
  ConfirmCardContainer,
  MaterialInfo,
  TextDetails
} from 'src/pages/nsia-admin/purchaseOrder/styles';
import { useAppSelector } from 'src/store';
import { IBlender, useGetRawMaterialsQuery } from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';
import { IIndentRequest } from './interface';

const { Text } = Typography;
type IProps = {
  summary: IIndentRequest;
};
export const Confirm: FC<IProps> = ({ summary }) => {
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  const user = useAppSelector((state) => state.auth.user!) as IBlender;
  const selectedRawMaterials = useMemo(() => {
    return summary.orders.map((item) => {
      const rawMaterial = rawMaterials?.data.find(
        (rawMaterial) => rawMaterial.id === item.rawMaterial
      );
      return { ...item, rawMaterial };
    });
  }, [rawMaterials, summary]);

  const tonnage = useMemo(
    () =>
      summary.orders.reduce(
        (acc, curr) => acc + Number(curr.estimatedQuantity),
        0
      ),
    [summary]
  );

  const transportCompanies = summary.logistics.length;

  return (
    <ConfirmCardContainer>
      <MaterialInfo>
        <TextDetails>
          <Text disabled>Blending Site:</Text>
          <CustomText strong>{user.blendingSite?.name}</CustomText>
        </TextDetails>
        <TextDetails>
          <Text disabled>Locations:</Text>
          <CustomText strong>{user.blendingSite?.location?.name}</CustomText>
        </TextDetails>
        <TextDetails>
          <Text disabled>Tonnage:</Text>
          <Text strong>{`${formatNumber(tonnage)}MT`}</Text>
        </TextDetails>
        <TextDetails>
          <Text disabled>Date:</Text>
          <Text strong>{moment(new Date()).format('DD-MM-YYYY')}</Text>
        </TextDetails>
      </MaterialInfo>
      <div className="site-card-wrapper">
        <Row gutter={24}>
          <Col span={24}>
            <Card
              title="Raw Material"
              extra={<EstimateQuantity>Quantity</EstimateQuantity>}
              className="card"
            >
              {selectedRawMaterials.map((item) => (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <RawMaterialName>{item.rawMaterial?.name}</RawMaterialName>
                  </Col>
                  <Col span={12}>
                    <RawMaterialQuantity>{`${formatNumber(
                      item.estimatedQuantity
                    )}MT`}</RawMaterialQuantity>
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card title="Transporter" className="card2">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Transporter company</Keys>
                </Col>
                <Col span={12}>
                  <Values>{transportCompanies}</Values>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Consent Letters</Keys>
                </Col>
                <Col span={12}>
                  <Values>{transportCompanies}</Values>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </ConfirmCardContainer>
  );
};

const Keys = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
`;
const Values = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  text-align: end;
  display: flex;
  justify-content: flex-end;
`;
const EstimateQuantity = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
`;
const RawMaterialName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
`;
const RawMaterialQuantity = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  display: flex;
  justify-content: flex-end;
`;
const CustomText = styled(Text)`
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
