import { Button, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import PortIcon from 'src/assets/dashboardIcons/portsIcon.svg';
import { AnalyticsCard, RangePicker, Search } from 'src/components';
import { TableBody } from 'src/pages/styles';
import { IUser, Role, useAppSelector } from 'src/store';
import {
  useGetRawMaterialUsageQuery,
  useGetRawMaterialUsageStatsQuery
} from 'src/store/api/nsia/reports';
import {
  dateTimeFormater,
  endOfMonth,
  formatNumber,
  generateSerialNumber,
  generateUrl,
  startOfMonth
} from 'src/utils';
import { RawMaterialUsageDownloadModal } from './components/RawMaterialUsageDownloadModal';
import {
  ActionWrap,
  CardRow,
  CustomHeader,
  CustomMain,
  CustomTitle
} from './style';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export const RawMaterialUsage = () => {
  const [viewDownloadModal, setViewDownloadModal] = useState(false);
  const [search, setSearchParam] = useState('');
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [queryParam, setParam] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });
  const user = useAppSelector((store) => store.auth.user) as IUser;
  const { data: getRawMaterialUsage, isLoading } = useGetRawMaterialUsageQuery(
    generateUrl({
      search,
      limit: 10,
      page,
      ...queryParam
    })
  );

  const { data: getRawMaterialUsageStats } = useGetRawMaterialUsageStatsQuery(
    generateUrl({ ...queryParam, search })
  );

  const handleSearch = (searchValue: string) => {
    setSearchParam(searchValue);
  };
  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParam({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };

  const source = useMemo(() => {
    return (
      getRawMaterialUsage?.data.map((item, index) => ({
        ...item,
        key: generateSerialNumber(page, index),
        serialNum: generateSerialNumber(page, index),
        blender: item.blendingSite.name,
        rawMaterial: item.rawMaterial.name,
        quantity: formatNumber(`${item.quantity} MT`),
        date: dateTimeFormater(item.createdAt)
      })) ?? []
    );
  }, [getRawMaterialUsage, page]);

  const nsiaColumn =
    user.role === Role.NSIA_ADMIN
      ? [
          {
            title: 'Blender',
            dataIndex: 'blender',
            key: 'blender'
          }
        ]
      : [];

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'serialNum',
      key: 'serialNum'
    },
    ...nsiaColumn,
    {
      title: 'Raw Material',
      dataIndex: 'rawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  return (
    <>
      {viewDownloadModal && (
        <RawMaterialUsageDownloadModal
          open={viewDownloadModal}
          urlQueryParam={generateUrl({
            download: 'true',
            search,
            ...queryParam
          })}
          closeModal={() => setViewDownloadModal(false)}
        />
      )}
      <CustomHeader>
        <div className="arrowFlex">
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          />
          <CustomTitle>Raw Material Usage</CustomTitle>
        </div>

        <Button onClick={() => setViewDownloadModal(true)}>
          {' '}
          Download report
        </Button>
      </CustomHeader>

      <CustomMain>
        <CustomHeader>
          <CustomTitle>Raw Material Usage</CustomTitle>
          <ActionWrap>
            <RangePicker
              defaultValue={[startOfMonth(), endOfMonth()]}
              onChange={(datejs) => handleDateChange(datejs)}
            />
            <Search
              onChange={(e) => !e.target.value && setSearchParam('')}
              placeholder="Search"
              style={{ width: 264 }}
              onSearch={handleSearch}
            />
          </ActionWrap>
        </CustomHeader>

        <CardRow>
          <AnalyticsCard
            title="Total Raw Materials Used"
            value={getRawMaterialUsageStats?.data.totalRawMaterialsUsed}
            icon={PortIcon}
            width={'100%'}
          />
        </CardRow>

        <TableBody>
          <Spin spinning={isLoading} tip="Loading" size="large">
            <Table
              dataSource={source}
              columns={columns}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                total: getRawMaterialUsage?.pagination?.totalCount ?? 0,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </Spin>
        </TableBody>
      </CustomMain>
    </>
  );
};
