import { FC } from 'react';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

interface Iprops {
  icon?: string;
  title?: string;
  value?: number | string;
  width?: string;
  height?: string;
}

export const AnalyticsCard: FC<Iprops> = ({
  icon,
  title,
  value = 0,
  width,
  height
}) => {
  return (
    <Container width={width} height={height}>
      <CardIcon src={icon} />
      <TextDiv>
        <CardTitle>{title}</CardTitle> <CardValue>{typeof value === "number" ? formatNumber(value) : value}</CardValue>
      </TextDiv>
    </Container>
  );
};

interface IContainer {
  width?: string;
  height?: string;
}
const Container = styled.div`
  width: ${(props: IContainer) => (props.width ? props.width : '24%')};
  height: ${(props: IContainer) => (props.height ? props.height : '96px')};
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;
`;

const CardIcon = styled.img`
  margin-right: 16px;
`;

const TextDiv = styled.div``;
const CardTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(140, 140, 140, 1);
`;
const CardValue = styled.h2`
  margin: 0;
  padding: 0;
  color: rgba(67, 67, 67, 1);
  font-size: 16px;
  font-weight: 600;
`;
