import { useNavigate } from 'react-router-dom';
import reportsIcon from 'src/assets/reportsIcon.svg';
import shortArrow from 'src/assets/shortArrow.svg';
import { Title } from 'src/components';
import { IUser, Role, useAppSelector } from 'src/store';
import styled from 'styled-components';
import { Container } from '../styles';

const MasterReport = () => {
  const user = useAppSelector((store) => store.auth.user) as IUser;

  const navigate = useNavigate();

  const nsiaView: { title: string; link: string }[] | false =
    user.role === Role.NSIA_ADMIN
      ? [
          {
            title: 'Purchase Vessel Report',
            link: '/reports/vessel-discharge-report'
          },
          {
            title: 'Warehouse Summary quantity',
            link: '/reports/summaryQuantity'
          },
          {
            title: 'Warehouse summary',
            link: '/reports/warehouseSummary'
          }
        ]
      : false;

  const reportsData = [
    ...(nsiaView || []),
    {
      title: 'Raw Material Release',
      link: '/reports/release-reports'
    },
    {
      title: 'Raw Material Usage',
      link: '/reports/raw-material-usage'
    },
    {
      title: 'Production summary',
      link: '/reports/production-summary-report'
    },
    {
      title: 'Amendments',
      link: '/reports/amendment-reports'
    }
  ];

  return (
    <Container>
      <ReportsBody>
        <Title level={4}>All reports</Title>
        <ReportsGrid>
          {reportsData.map((report, index) => (
            <ReportsCard key={index}>
              <div className="icon">
                <img src={reportsIcon} alt="report" />
              </div>
              <div className="titleDiv" onClick={() => navigate(report.link)}>
                <p>{report.title}</p>
                <img src={shortArrow} alt="" />
              </div>
            </ReportsCard>
          ))}
        </ReportsGrid>
      </ReportsBody>
    </Container>
  );
};

export default MasterReport;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 24px;
`;

const ReportsCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 6px;
  width: 330px;
  height: 120px;
  padding: 20px;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .titleDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

const ReportsBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const ReportsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f6ffed;
  gap: 10px;
  padding: 10px;
`;
