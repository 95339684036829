import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IPort, useDeletePortMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  portDetails: IPort;
}

export const DeletePortModal: FC<IModal> = ({
  open,
  closeModal,
  portDetails
}) => {
  const [deletePort, { isLoading: isDeleteLoading }] = useDeletePortMutation();
  const handleSubmit = () => {
    if (!portDetails?.id) {
      return;
    }
    deletePort(portDetails.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted a port'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(false);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== portDetails.name);
  }, [name, portDetails.name]);

  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
    <>
      <Modal
        open={open}
        title="Delete Port"
        onOk={closeModal}
        onCancel={closeModal}
        centered
        footer={[
          <Button onClick={closeModal}>Cancel</Button>,

          <Button
            danger
            type={'primary'}
            disabled={buttonDisable}
            onClick={() => form.submit()}
            loading={isDeleteLoading}
          >
            Yes, Delete
          </Button>
        ]}
        width={660}
      >
        <Form layout={'vertical'} form={form} onFinish={handleFinish}>
          <Form.Item
            name={'name'}
            label={
              <FormLabel>
                Are you sure delete "<PortTitle>{portDetails?.name}</PortTitle>”
                port, this action cannot be undone
              </FormLabel>
            }
          >
            <Input placeholder="Enter the port name to proceed" autoComplete='off' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const PortTitle = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
