import { Space, Table, Input } from 'antd';
import { Typography } from 'antd';
import { FC, useMemo, useState } from 'react';
import { IRawMaterial, useGetRawMaterialsQuery } from 'src/store/api';
import { generateUrl } from 'src/utils';
import {
  ActionItem,
  Container,
  TableHeader
} from 'src/pages/nsia-admin/utilities/styles';
import { AddModal } from './AddModal';
import { DeleteModal } from './DeleteModal';

type IRawMaterialProps = {
  closeAddModal: () => void;
  openAddModal: boolean;
};
const { Title } = Typography;
const { Search } = Input;
export const RawMaterial: FC<IRawMaterialProps> = ({
  closeAddModal,
  openAddModal
}) => {
  const [deleteRM, setDeleteRM] = useState<IRawMaterial | null>(null);
  const [search, setSearch] = useState('');
  const { data } = useGetRawMaterialsQuery(
    generateUrl({
      limit: 10,
      search
    })
  );
  const rawMaterials = useMemo(() => {
    return (
      data?.data.map((item, index) => ({
        key: item.id,
        sn: index + 1,
        ...item
      })) ?? []
    );
  }, [data]);

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'sn',
      key: 's/n'
    },
    {
      title: 'Raw materials',
      dataIndex: 'name',
      key: 'raw-material'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_: unknown, record: IRawMaterial) => (
        <Space size="small">
          <ActionItem
            color={'rgba(245, 34, 45, 1)'}
            onClick={() => {
              setDeleteRM(record);
            }}
          >
            Delete
          </ActionItem>
        </Space>
      )
    }
  ];

  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Container>
      {openAddModal && (
        <AddModal open={openAddModal} closeModal={closeAddModal} />
      )}
      {!!deleteRM && (
        <DeleteModal
          rawMaterial={deleteRM}
          open={!!deleteRM}
          closeModal={() => setDeleteRM(null)}
        />
      )}
      <TableHeader>
        <Title level={4}>Raw material</Title>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          onChange={(e) => !e.target.value && setSearch('')}
          style={{ width: 264 }}
        />
      </TableHeader>
      <Table columns={columns} pagination={false} dataSource={rawMaterials} />
    </Container>
  );
};
