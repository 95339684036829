import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageHeader } from '@ant-design/pro-layout';
import { Input, Spin, Table, Tabs, Tag, Typography } from 'antd';
import { Container } from 'src/pages/styles';
import { IPurchaseOrder, useGetAllPurchaseOrderQuery } from 'src/store/api';
import { formatNumber, generateUrl } from 'src/utils';
import styled from 'styled-components';
import { ORDER_STATUS } from './enum';
import { TableBody, TableHeader, ViewButton } from './styles';

const { Search } = Input;
const { Title } = Typography;

export const PurchaseOrder = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { hash } = useLocation();

  const status = hash.replace('#', '');

  const { data: purchaseOrderList, isLoading } = useGetAllPurchaseOrderQuery(
    generateUrl({
      status: status,
      limit: 10,
      page,
      search
    })
  );
  const onChange = (key: string) => {
    navigate(`/purchase-order#${key}`);
  };
  const source = useMemo(() => {
    return purchaseOrderList?.data.map((item) => {
      let estimatedQuantity = 0;
      let rawMaterials: string[] = [];
      item.orders.forEach((order) => {
        estimatedQuantity += Number(order.estimatedQuantity);
        rawMaterials.push(order?.rawMaterial?.name);
      });
      return {
        selectedPort: item.portQuantities
          .map((portQuantity) => portQuantity.port.name)
          .join(', '),
        key: item.id,
        estimatedQuantity: formatNumber(`${estimatedQuantity} MT`),
        selectedRawMaterials: rawMaterials.join(', '),
        ...item
      };
    });
  }, [purchaseOrderList]);

  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'purchaseId',
      key: 'purchaseId'
    },
    {
      title: 'Port',
      dataIndex: 'selectedPort',
      key: 'selectedPort'
    },
    {
      title: 'Vessel name',
      dataIndex: 'vesselName',
      key: 'vesselName'
    },
    {
      title: 'Raw materials',
      dataIndex: 'selectedRawMaterials',
      key: 'rawMaterials'
    },
    {
      title: 'Estimated Quantity (MT)',
      dataIndex: 'estimatedQuantity',
      key: 'quantity'
    },

    {
      title: 'Order status',
      dataIndex: 'orderStatus',
      render: (_: any, record: IPurchaseOrder) => {
        const status = record.status as keyof typeof ORDER_STATUS;
        return (
          <Tag color={ORDER_STATUS[status].color}>
            {ORDER_STATUS[status].name}
          </Tag>
        );
      }
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, record: IPurchaseOrder) => {
        return (
          <ViewButton
            onClick={() =>
              navigate(`/purchase-order/${record.id}`, {
                state: { purchaseOrder: record }
              })
            }
          >
            Details
          </ViewButton>
        );
      }
    }
  ];

  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      <CustomHeader>
        <Title>Purchase order</Title>
      </CustomHeader>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <Container>
          <TableBody>
            <TableHeader>
              <Title level={4}>Orders</Title>
              <Search
                placeholder="Search by vessel name"
                onSearch={onSearch}
                onChange={(e) => !e.target.value && setSearch('')}
                style={{ width: 264 }}
              />
            </TableHeader>
            <CustomTab
              defaultActiveKey={status}
              activeKey={status}
              onChange={onChange}
              items={[
                {
                  label: `All Orders`,
                  key: '',
                  children: (
                    <Table
                      pagination={{
                        defaultCurrent: 1,
                        pageSize: 10,
                        total: purchaseOrderList?.pagination?.totalCount,
                        onChange: (page) => {
                          setPage(page);
                        }
                      }}
                      columns={columns}
                      dataSource={source}
                    />
                  )
                },
                {
                  label: `Active`,
                  key: 'active',
                  children: (
                    <Table
                      pagination={{
                        defaultCurrent: 1,
                        pageSize: 10,
                        total: purchaseOrderList?.pagination?.totalCount,
                        onChange: (page) => {
                          setPage(page);
                        }
                      }}
                      columns={columns}
                      dataSource={source}
                    />
                  )
                },
                {
                  label: `Completed`,
                  key: 'completed',
                  children: (
                    <Table
                      pagination={{
                        defaultCurrent: 1,
                        pageSize: 10,
                        total: purchaseOrderList?.pagination?.totalCount,
                        onChange: (page) => {
                          setPage(page);
                        }
                      }}
                      columns={columns}
                      dataSource={source}
                    />
                  )
                }
              ]}
            />
          </TableBody>
        </Container>
      </Spin>
    </>
  );
};
const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;

const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
