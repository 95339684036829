import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Tabs, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CreateIndent, IndentTable } from './components';

const { Title } = Typography;

export const IndentOrders = () => {
  const [createIndent, setCreateIndent] = useState(false);

  const navigate = useNavigate();
  const onChange = (key: string) => {
    navigate(`/raw-material-request#${key}`);
  };
  const { hash } = useLocation();
  const status = useMemo(() => hash.replace('#', ''), [hash]);
  const operations = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => setCreateIndent(true)}
    >
      Create request
    </Button>
  );

  return (
    <>
      <CustomHeader>
        <Title>Raw Material Request</Title>
      </CustomHeader>

      <CustomTab
        tabBarExtraContent={operations}
        defaultActiveKey={status}
        activeKey={status}
        onChange={onChange}
        items={[
          {
            label: `All Requests`,
            key: '',
            children: <IndentTable />
          },
          {
            label: `Active`,
            key: 'off-taking',
            children: <IndentTable />
          },
          {
            label: `Pending`,
            key: 'pending',
            children: <IndentTable />
          },
          {
            label: `Approved`,
            key: 'approved',
            children: <IndentTable />
          },
          {
            label: `Rejected`,
            key: 'rejected',
            children: <IndentTable />
          },
          {
            label: `Completed`,
            key: 'completed',
            children: <IndentTable />
          }
        ]}
      />
      {createIndent && (
        <CreateIndent
          closeModal={() => setCreateIndent(false)}
          open={createIndent}
        />
      )}
    </>
  );
};

const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
