import { baseApi } from 'src/store/api/baseApi';
import { tagTypes } from 'src/store/api/baseApi/tagTypes';
import { PaginatedResponse, Response } from 'src/store/genericInterface';
import {
  IAmendReport,
  IFetchSingleVessel,
  IProductionSummaryReport,
  IProductionSummaryStats,
  IRawMaterialReceipts,
  IRawMaterialUsage,
  IRawMaterialUsageStats,
  IReleaseReports,
  IVesselDischargeReport,
  IVesselDischargeReportStats,
  IWarehouseOfftakeReportResponse,
  IWarehouseReceiptsReport,
  IWarehouseReceiptsStats,
  IWarehouseStock,
  MasterReportResponse,
  RawMaterialReceiptResponse,
  SingleVesselResponse,
  WarehouseSummary
} from './interface';

const reportApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRawMaterialReceipt: builder.query<
      RawMaterialReceiptResponse<IRawMaterialReceipts>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/raw-materials-received?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.RawMaterialsReciepts, id: query }
      ]
    }),
    getVesselDischargeReportStats: builder.query<
      Response<IVesselDischargeReportStats>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/stat/raw-materials-discharged?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.NsiaReports, id: query }
      ]
    }),
    getVesselDischargeReport: builder.query<
      PaginatedResponse<IVesselDischargeReport>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/raw-materials-discharged?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.NsiaReports, id: query }
      ]
    }),
    getRawMaterialUsage: builder.query<
      PaginatedResponse<IRawMaterialUsage>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/raw-material-useage?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.RawMaterialUsage, id: query }
      ]
    }),
    getRawMaterialUsageStats: builder.query<
      Response<IRawMaterialUsageStats>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/stat/raw-material-usage?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.RawMaterialUsage, id: query }
      ]
    }),
    getProductionSummaryReport: builder.query<
      PaginatedResponse<IProductionSummaryReport>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/production-summary?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.ProductionSummary, id: query }
      ]
    }),
    getProductionSummaryStats: builder.query<
      Response<IProductionSummaryStats>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/stat/production-summary?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.ProductionSummary, id: query }
      ]
    }),
    reportsWarehouseOfftakes: builder.query<
      IWarehouseOfftakeReportResponse,
      string
    >({
      query: (query = '') => ({
        url: `/reports/warehouse-offtakes?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.ReportsWarehouse, id: query }
      ]
    }),
    getReleaseReports: builder.query<IReleaseReports, string>({
      query: (query = '') => ({
        url: `/reports/release-report?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.ProductionSummary, id: query }
      ]
    }),
    getWarehouseReceiptsReport: builder.query<
      PaginatedResponse<IWarehouseReceiptsReport>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/warehouse-receipt?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.WarehouseReceipts, id: query }
      ]
    }),
    getWarehouseReceiptsStats: builder.query<
      Response<IWarehouseReceiptsStats>,
      string
    >({
      query: (query = '') => ({
        url: `/reports/stat/warehouse-receipt?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.WarehouseReceipts, id: query }
      ]
    }),
    getWarehouseStockSummary: builder.query<
      Response<IWarehouseStock[]>,
      string
    >({
      query: (query) => ({
        url: `/reports/warehouse-stock-summary?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.WarehouseStockSummary, query }
      ]
    }),
    getMasterReports: builder.query<MasterReportResponse, string>({
      query: (query) => ({
        url: `/warehouse-reports?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.MasterReport, query }
      ]
    }),
    getWarehouseReportsById: builder.query<
      MasterReportResponse,
      { port: string; startDate: string; endDate: string }
    >({
      query: (query) => ({
        url: `/warehouse-reports/${query.port}`,
        method: 'GET',
        params: query
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.MasterReport, query }
      ]
    }),
    getWarehouseSummary: builder.query<WarehouseSummary, string>({
      query: (query) => ({
        url: `/warehouse-reports/summary-report?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.WarehouseSummary, query }
      ]
    }),
    getSingleVessel: builder.query<SingleVesselResponse, IFetchSingleVessel>({
      query: (payload) => ({
        url: `/reports/vessel-report/${payload.id}?port=${payload.port}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, arg) => [
        { type: tagTypes.NsiaReports, ...arg }
      ]
    }),
    amendmentReport: builder.query<PaginatedResponse<IAmendReport>, string>({
      query: (query) => ({
        url: `/reports/amendment-report?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, arg) => [
        { type: tagTypes.NsiaReports, arg }
      ]
    })
  })
});

export const {
  useGetWarehouseSummaryQuery,
  useGetMasterReportsQuery,
  useGetVesselDischargeReportStatsQuery,
  useGetRawMaterialReceiptQuery,
  useLazyGetRawMaterialReceiptQuery,
  useGetVesselDischargeReportQuery,
  useLazyGetVesselDischargeReportQuery,
  useGetRawMaterialUsageQuery,
  useLazyGetRawMaterialUsageQuery,
  useGetRawMaterialUsageStatsQuery,
  useGetProductionSummaryReportQuery,
  useLazyGetProductionSummaryReportQuery,
  useGetProductionSummaryStatsQuery,
  useReportsWarehouseOfftakesQuery,
  useLazyReportsWarehouseOfftakesQuery,
  useGetReleaseReportsQuery,
  useLazyGetReleaseReportsQuery,
  useGetWarehouseReceiptsReportQuery,
  useLazyGetWarehouseReceiptsReportQuery,
  useGetWarehouseReceiptsStatsQuery,
  useGetWarehouseStockSummaryQuery,
  useGetSingleVesselQuery,
  useAmendmentReportQuery,
  useGetWarehouseReportsByIdQuery
} = reportApi;
