export const ORDER_STATUS = {
  order_placed: {
    name: 'Order placed',
    color: 'default',
    status: 'order_placed'
  },
  discharging: {
    name: 'Discharging',
    color: 'gold',
    status: 'discharging'
  },
  vessel_arrived: {
    name: 'Vessel arrived',
    color: 'default',
    status: 'vessel_arrived'
  },
  vessel_cleared: {
    name: 'Vessel cleared',
    color: 'green',
    status: 'vessel_cleared'
  }
};

export const PAAR_STATUS = {
  sent: { name: 'Sent', color: 'green', text: 'View Shipping Documents' },
  not_sent: {
    name: 'Not Sent',
    color: 'default',
    text: 'Send Shipping Documents'
  }
};

export enum PurchaseOrderStatus {
  ORDER_PLACED = 'order_placed',
  DISCHARGING = 'discharging',
  VESSEL_ARRIVED = 'vessel_arrived',
  VESSEL_CLEARED = 'vessel_cleared'
}
