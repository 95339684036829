import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import { useGetBlenderChartQuery } from 'src/store/api/blender/api';
import { generateUrl } from 'src/utils';

export const UseGetBlenderRawMaterialsChart = () => {
  const [queryParams, setParams] = useState({
    startDate: dayjs(new Date()).startOf('month').toISOString(),
    endDate: dayjs(new Date()).endOf('month').toISOString()
  });

  const { data: blendersChart } = useGetBlenderChartQuery(
    generateUrl(queryParams)
  );

  const data = useMemo(() => {
    const res: {
      name: string;
      rawmaterial: string;
      amount: number;
    }[] = [];
    blendersChart?.data.forEach((item) => {
      res.push({
        name: 'RELEASED',
        rawmaterial: item.name,
        amount: item.released
      });
      res.push({
        name: 'OFFTAKEN',
        rawmaterial: item.name,
        amount: item.offtaken
      });
    });
    return res;
  }, [blendersChart]);

  const config = {
    data,
    isGroup: true,
    xField: 'rawmaterial',
    yField: 'amount',
    seriesField: 'name',
    color: ['#6FCE40', '#EB2F96'],
    marginRatio: 0.4
  };

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParams({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };
  return {
    config,
    handleDateChange
  };
};
