import { PaginatedResponse, Response } from 'src/store/genericInterface';
import { baseApi } from 'src/store/api/baseApi';
import { tagTypes } from 'src/store/api/baseApi/tagTypes';
import {
  IAddBlender,
  IAddCleringAgent,
  IEditClearingAgent,
  IBlender,
  IClearingAgent,
  IEditBlender,
  ICollateralManagerPort,
  IAddCollateralManagerPort,
  IEditCollateralManagerPort,
  IAddCollateralManagerPlant,
  ICollateralManagerBlendingPlant,
  IEditCollateralManagerBlendingPlant
} from './interface';

const stakeholdersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    addClearingAgent: builder.mutation<
      Response<IClearingAgent>,
      IAddCleringAgent
    >({
      query: (payload) => ({
        url: '/clearing-agent',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.ClearingAgent }]
    }),
    getAllClearingAgents: builder.query<
      PaginatedResponse<IClearingAgent>,
      string | void
    >({
      query: (query) => ({
        url: `/clearing-agent?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.ClearingAgent }]
    }),
    getClearingAgentById: builder.query<Response<IClearingAgent>, string>({
      query: (id) => ({
        url: `/clearing-agent/${id}`,
        method: 'GET'
      }),
      providesTags: (_, __, id) => [
        { type: tagTypes.ClearingAgent },
        { type: id }
      ]
    }),
    editClearingAgent: builder.mutation<
      Response<IClearingAgent>,
      IEditClearingAgent
    >({
      query: (payload) => ({
        url: `/clearing-agent/${payload.id}`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.ClearingAgent }]
    }),
    deleteClearingAgent: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/clearing-agent/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.ClearingAgent }]
    }),
    // blenders api
    addBlender: builder.mutation<Response<IBlender>, IAddBlender>({
      query: (payload) => ({
        url: '/blenders',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.Blender }]
    }),
    getAllBlenders: builder.query<PaginatedResponse<IBlender>, string | void>({
      query: (query) => ({
        url: `/blenders?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Blender }]
    }),
    deleteBlenders: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/blenders/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.Blender }]
    }),
    editBlender: builder.mutation<Response<IBlender>, IEditBlender>({
      query: (payload) => ({
        url: `/blenders/${payload.id}`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.Blender }]
    }),
    // collateral manager's api
    addCollateralManagerPort: builder.mutation<
      Response<ICollateralManagerPort>,
      IAddCollateralManagerPort
    >({
      query: (payload) => ({
        url: '/collateral-managers',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.CollateralManagerPort }]
    }),
    getCollateralManagersPort: builder.query<
      PaginatedResponse<ICollateralManagerPort>,
      string | void
    >({
      query: (query) => ({
        url: `/collateral-managers?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.CollateralManagerPort }]
    }),
    deleteCollateralManagerPort: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/collateral-managers/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.CollateralManagerPort }]
    }),
    editCollateralManagerPort: builder.mutation<
      Response<ICollateralManagerPort>,
      IEditCollateralManagerPort
    >({
      query: (payload) => ({
        url: `/collateral-managers/${payload.id}`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.CollateralManagerPort }]
    }),
    getCollateralManagersBlendingPlant: builder.query<
      PaginatedResponse<ICollateralManagerBlendingPlant>,
      string | void
    >({
      query: (query) => ({
        url: `/collateral-manager-bs?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.CollateralManagerBlendingPlant }]
    }),
    addCollateralManagerBlendingPlant: builder.mutation<
      Response<ICollateralManagerBlendingPlant>,
      IAddCollateralManagerPlant
    >({
      query: (payload) => ({
        url: '/collateral-manager-bs',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.CollateralManagerBlendingPlant }]
    }),
    editCollateralManagerBlendingPlant: builder.mutation<
      Response<ICollateralManagerBlendingPlant>,
      IEditCollateralManagerBlendingPlant
    >({
      query: (payload) => ({
        url: `/collateral-manager-bs/collateral-manager/${payload.id}`,
        method: 'PATCH',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.CollateralManagerBlendingPlant }]
    }),
    deleteCollateralManagerBlendingPlant: builder.mutation<
      Response<null>,
      string
    >({
      query: (id) => ({
        url: `/collateral-manager-bs/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.CollateralManagerBlendingPlant }]
    })
  })
});

export const {
  useAddClearingAgentMutation,
  useGetAllClearingAgentsQuery,
  useGetClearingAgentByIdQuery,
  useEditClearingAgentMutation,
  useDeleteClearingAgentMutation,
  useAddBlenderMutation,
  useGetAllBlendersQuery,
  useDeleteBlendersMutation,
  useEditBlenderMutation,
  useAddCollateralManagerPortMutation,
  useDeleteCollateralManagerPortMutation,
  useEditCollateralManagerPortMutation,
  useGetCollateralManagersPortQuery,
  useGetCollateralManagersBlendingPlantQuery,
  useAddCollateralManagerBlendingPlantMutation,
  useEditCollateralManagerBlendingPlantMutation,
  useDeleteCollateralManagerBlendingPlantMutation,
  useLazyGetAllClearingAgentsQuery
} = stakeholdersApi;
