import { Table, Button, Spin, } from 'antd';
import {
  CustomHeader,
  CustomTitle,
  CardRow,
  CustomMain
} from 'src/pages/reports/style';
import dayjs from 'dayjs';
import { IUser, Role, useAppSelector } from 'src/store';
import { TableBody, TableHeader } from 'src/pages/styles';
import PortIcon from 'src/assets/dashboardIcons/portsIcon.svg';
import { AnalyticsCard, Search, Title, RangePicker } from 'src/components';
import {
  useGetRawMaterialReceiptQuery,
} from 'src/store/api/nsia/reports';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState, } from 'react';
import {
  dateTimeFormater,
  generateSerialNumber,
  generateUrl,
  endOfMonth,
  startOfMonth,
  formatNumber
} from 'src/utils';
import { ActionWrap } from './style';
import { RawMaterialReceiptDownloadModal } from './components/RawMaterialsReceiptDownloadModal';

export const RawMaterialReceipt = () => {
  const [viewDownloadModal, setViewDownloadModal] = useState(false)
  const [search, setSearchParam] = useState('');
  const [page, setPage] = useState(1);
  const handleSearch = (searchValue: string) => {
    setSearchParam(searchValue);
  };
  const user = useAppSelector((store) => store.auth.user) as IUser;
  const [queryParam, setParam] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });

  const { data: getRawMaterialReceipt, isLoading } =
    useGetRawMaterialReceiptQuery(
      generateUrl({
        ...queryParam,
        search,
        page,
        limit: 10
      })
    );

  const source = useMemo(() => {
    return (
      getRawMaterialReceipt?.data.map((item, index) => ({
        ...item,
        key: item.offtaking.id,
        serialNum: generateSerialNumber(page, index),
        releaseId: item.releaseId,
        blender: item.blender.name,
        rawMaterial: item.rawMaterial.name,
        quantity: formatNumber(`${item.rawMaterial.quantityReceived} MT`),
        date: dateTimeFormater(item.offtaking.createdAt)
      })) ?? []
    );
  }, [getRawMaterialReceipt, page]);

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParam({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };

  const nsiaColumn =
    user.role === Role.NSIA_ADMIN
      ? [
        {
          title: 'Blender',
          dataIndex: 'blender',
          key: 'blender'
        }
      ]
      : [];

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'serialNum',
      key: 'serialNum'
    },
    {
      title: 'Release ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },
    ...nsiaColumn,
    {
      title: 'Raw Material',
      dataIndex: 'rawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  return (
    <>
      {viewDownloadModal && (
        <RawMaterialReceiptDownloadModal
          open={viewDownloadModal}
          urlQueryParam={generateUrl({
            download: 'true',
            search,
            ...queryParam
          })}
          closeModal={() => setViewDownloadModal(false)}
        />
      )}
      <CustomHeader>
        <CustomTitle>Raw Material Receipt Report</CustomTitle>
        <Button onClick={() => { setViewDownloadModal(true) }}>
          Download report
        </Button>
      </CustomHeader>

      <CustomMain>
        <CustomHeader>
          <CustomTitle>Raw material receipt</CustomTitle>
          <ActionWrap>
            <RangePicker
              defaultValue={[startOfMonth(), endOfMonth()]}
              onChange={(datejs) => handleDateChange(datejs)}
            />
            <Search
              onSearch={handleSearch}
              onChange={(e) => !e.target.value && setSearchParam('')}
              placeholder="Search"
              style={{ width: 264 }}
            />
          </ActionWrap>
        </CustomHeader>

        <CardRow>
          <AnalyticsCard
            title="Total Raw Materials Received"
            value={`${formatNumber(getRawMaterialReceipt?.totalRawMaterialReceived ?? 0)}MT`}
            icon={PortIcon}
            width="100%"
          />
        </CardRow>

        <TableBody>
          <TableHeader>
            <Title level={4}>View Reports</Title>
          </TableHeader>
          <Spin spinning={isLoading} tip="Loading" size="large">
            <Table
              dataSource={source}
              columns={columns}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                total: getRawMaterialReceipt?.pagination.totalCount ?? 0,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </Spin>
        </TableBody>
      </CustomMain>
    </>
  );
};
