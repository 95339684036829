import { Card, Col, Divider, Row } from 'antd';
import { FC, useMemo } from 'react';
import { ConfirmCard } from 'src/pages/nsia-admin/purchaseOrder/styles';
import {
  useGetClearingAgentByIdQuery,
  useGetRawMaterialsQuery,
  useGetSingleSupplierQuery
} from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';
import { IPurchaseOrderSummary } from './interface';

export const Confirm: FC<{ orderSummary: IPurchaseOrderSummary }> = ({
  orderSummary
}) => {
  const { data: allRawMaterials } = useGetRawMaterialsQuery();
  const { data: agentDetail } = useGetClearingAgentByIdQuery(
    orderSummary.clearingAgent
  );
  const { data: supplierDetail } = useGetSingleSupplierQuery(
    orderSummary.supplier
  );

  const estimatedTonnage = useMemo(() => {
    return orderSummary.orders.reduce(
      (acc, current) => acc + Number(current.estimatedQuantity),
      0
    );
  }, [orderSummary]);

  const selectedRawMaterials = useMemo(() => {
    if (allRawMaterials?.data.length) {
      return orderSummary.orders.map((item) => {
        const a = allRawMaterials.data.find(
          (rawM) => rawM.id === item.rawMaterial
        );
        return {
          name: a?.name,
          quantity: item.estimatedQuantity,
          id: item.rawMaterial
        };
      });
    }
    return [];
  }, [orderSummary, allRawMaterials]);
  return (
    <ConfirmCard>
      <Divider />
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col span={12}>
            <Card
              title="Raw Material"
              extra={
                <EstimateQuantity>Estimated Quantity (MT)</EstimateQuantity>
              }
              className="card"
            >
              {selectedRawMaterials.map((item) => (
                <Row key={item.id} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <RawMaterialName>{item.name}</RawMaterialName>
                  </Col>
                  <Col span={12}>
                    <RawMaterialQuantity>{item.quantity}MT</RawMaterialQuantity>
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>

          <Col span={12}>
            <Card title="Purchase Details" className="card2">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Ports of discharge</Keys>
                </Col>
                <Col span={12}>
                  <Values>{orderSummary.portQuantities.length}</Values>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Vessel name</Keys>
                </Col>
                <Col span={12}>
                  <Values>{orderSummary.vesselName}</Values>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Estimated tonnage</Keys>
                </Col>
                <Col span={12}>
                  <Values>{formatNumber(estimatedTonnage)}MT</Values>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Estimated time of arrival</Keys>
                </Col>
                <Col span={12}>
                  <Values>
                    {orderSummary.arrivalTime.format('YYYY-MM-DD')}
                  </Values>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Supplier</Keys>
                </Col>
                <Col span={12}>
                  <Values>{supplierDetail?.data.name ?? 'N/A'}</Values>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Keys>Clearing agent</Keys>
                </Col>
                <Col span={12}>
                  <Values>{agentDetail?.data.name ?? 'N/A'}</Values>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </ConfirmCard>
  );
};

const Keys = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
`;
const Values = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  display: flex;
  justify-content: flex-end;
  text-align: right;
`;
const EstimateQuantity = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
`;
const RawMaterialName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
`;
const RawMaterialQuantity = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  display: flex;
  justify-content: flex-end;
`;
