import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IBlendingSite, useDeleteSiteMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  blendingSiteDetails: IBlendingSite;
}

export const DeleteBlendingSiteModal: FC<IModal> = ({
  open,
  closeModal,
  blendingSiteDetails
}) => {
  const [deleteSite, { isLoading: isDeleteLoading }] = useDeleteSiteMutation();

  const handleSubmit = () => {
    if (!blendingSiteDetails?.id) {
      return;
    }
    deleteSite(blendingSiteDetails.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted blending site'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };


  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(true);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== blendingSiteDetails.name);
  }, [name, blendingSiteDetails.name]);
  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
      <Modal
        open={open}
        title="Delete Blending Site"
        onOk={closeModal}
        onCancel={closeModal}
        centered
        footer={[
          <Button onClick={closeModal}>Cancel</Button>,
          <Button
            danger
            type={'primary'}
            disabled={buttonDisable}
            onClick={() => form.submit()}
            loading={isDeleteLoading}
          >
            Yes, Delete
          </Button>
        ]}
        width={660}
      >
        <Form layout={'vertical'} form={form} onFinish={handleFinish}>
          <Form.Item
            name={'name'}
            label={
              <FormLabel>
                Are you sure delete"
                <BlendingSite>{blendingSiteDetails.name}</BlendingSite>”
                blending site , this action cannot be undone
              </FormLabel>
            }
          >
            <Input placeholder="Enter the blending site name to proceed" autoComplete='off' />
          </Form.Item>
        </Form>
      </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const BlendingSite = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
