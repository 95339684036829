import { baseApi } from 'src/store/api/baseApi';
import { tagTypes } from 'src/store/api/baseApi/tagTypes';
import { PaginatedResponse, Response } from 'src/store/genericInterface';
import {
  IAddBlendingSitePayload,
  IAddPortPayload,
  IAddSupplier,
  IAddWarehousePayload,
  IBlendingSite,
  IEditPortPayload,
  IEditSitePayload,
  IEditSupplierPayload,
  IEditWarehousePayload,
  IPort,
  IRawMaterial,
  ISupplier,
  IWarehouse,
  WarehouseStock
} from './interface';

const utilitiesApi = baseApi.injectEndpoints({
  // ports endpoints
  endpoints: (builder) => ({
    addPort: builder.mutation<Response, IAddPortPayload>({
      query: (payload) => ({
        url: '/utilities/ports',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.Ports }, { type: tagTypes.Admin }]
    }),
    getAllPorts: builder.query<PaginatedResponse<IPort>, string | void>({
      query: (query = '') => ({
        url: `/utilities/ports?${query}`,
        method: 'GET'
      }),
      providesTags: ['Ports']
    }),
    deletePort: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/utilities/ports/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.Ports }, { type: tagTypes.Admin }]
    }),
    editPort: builder.mutation<Response<IPort>, IEditPortPayload>({
      query: (payload) => ({
        url: `/utilities/ports/${payload.id}`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [{ type: 'Ports' }]
    }),
    // blending site endpoints

    addSites: builder.mutation<Response, IAddBlendingSitePayload>({
      query: (payload) => ({
        url: '/utilities/blending-sites',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.Sites }, { type: tagTypes.Admin }]
    }),
    getAllSites: builder.query<PaginatedResponse<IBlendingSite>, string | void>(
      {
        query: (query) => ({
          url: `/utilities/blending-sites?${query}`,
          method: 'GET'
        }),
        providesTags: [{ type: tagTypes.Sites }]
      }
    ),
    editSite: builder.mutation<Response<IBlendingSite>, IEditSitePayload>({
      query: (payload) => ({
        url: `/utilities/blending-sites/${payload.id}`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [
        { type: tagTypes.Sites },
        { type: tagTypes.BlendingPlants }
      ]
    }),
    deleteSite: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/utilities/blending-sites/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [
        { type: tagTypes.Sites },
        { type: tagTypes.BlendingPlants },
        { type: tagTypes.Admin }
      ]
    }),
    // warehouse endpoints
    addWarehouse: builder.mutation<Response, IAddWarehousePayload>({
      query: (payload) => ({
        url: '/utilities/warehouse',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.Warehouses }, { type: tagTypes.Admin }]
    }),
    getAllWarehouses: builder.query<
      PaginatedResponse<IWarehouse>,
      string | void
    >({
      query: (query = '') => ({
        url: `/utilities/warehouse?${query}`,
        method: 'GET'
      }),
      providesTags: (_, __, query) => [
        { type: tagTypes.Warehouses, id: query ?? '' }
      ]
    }),
    getWarehouseStock: builder.query<Response<WarehouseStock[]>, string>({
      query: (id) => ({
        url: `/utilities/warehouse/${id}/stock`,
        method: 'GET'
      }),
      providesTags: (_result, _err, id) => [
        { type: tagTypes.WarehouseStock, id: id }
      ]
    }),
    deleteWarehouse: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/utilities/warehouse/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.Warehouses }, { type: tagTypes.Admin }]
    }),
    editWarehouse: builder.mutation<
      Response<IWarehouse>,
      IEditWarehousePayload
    >({
      query: (payload) => ({
        url: `/utilities/warehouse/${payload.id}`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.Warehouses }, { type: tagTypes.Admin }]
    }),
    getRawMaterials: builder.query<Response<IRawMaterial[]>, string | void>({
      query: (query = '') => ({
        url: `/utilities/raw-materials?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.RawMaterials }]
    }),
    addRawMaterial: builder.mutation<
      Response<IRawMaterial>,
      {
        name: string;
        description: string;
      }
    >({
      query: (payload) => ({
        url: '/utilities/raw-materials',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [
        { type: tagTypes.RawMaterials },
        { type: tagTypes.Admin }
      ]
    }),
    deleteRawMaterial: builder.mutation<Response, string>({
      query: (id) => ({
        url: `/utilities/raw-materials/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.RawMaterials }]
    }),
    //supplier endpoints
    addSupplier: builder.mutation<Response, IAddSupplier>({
      query: (payload) => ({
        url: '/utilities/supplier',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.Suppliers }, { type: tagTypes.Admin }]
    }),
    getAllSuppliers: builder.query<PaginatedResponse<ISupplier>, string | void>(
      {
        query: (query = '') => ({
          url: `/utilities/supplier?${query}`,
          method: 'GET'
        }),
        providesTags: [{ type: tagTypes.Suppliers }]
      }
    ),
    editSupplier: builder.mutation<Response<IPort>, IEditSupplierPayload>({
      query: (payload) => ({
        url: `/utilities/supplier/${payload.id}`,
        method: 'PATCH',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.Suppliers }]
    }),
    deleteSupplier: builder.mutation<Response, string>({
      query: (id) => ({
        url: `/utilities/supplier/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.Suppliers }]
    }),
    getSingleSupplier: builder.query<Response<ISupplier>, string>({
      query: (id) => ({
        url: `/utilities/supplier/${id}`,
        method: 'GET'
      }),
      providesTags: (_result, _err) => [{ type: tagTypes.Suppliers }]
    })
  })
});

export const {
  useGetSingleSupplierQuery,
  useDeleteSupplierMutation,
  useEditSupplierMutation,
  useAddSupplierMutation,
  useGetAllSuppliersQuery,
  useAddPortMutation,
  useGetAllPortsQuery,
  useDeletePortMutation,
  useEditPortMutation,
  useAddSitesMutation,
  useAddWarehouseMutation,
  useGetAllWarehousesQuery,
  useLazyGetAllWarehousesQuery,
  useDeleteWarehouseMutation,
  useEditWarehouseMutation,
  useGetAllSitesQuery,
  useEditSiteMutation,
  useDeleteSiteMutation,
  useGetRawMaterialsQuery,
  useAddRawMaterialMutation,
  useDeleteRawMaterialMutation,
  useGetWarehouseStockQuery
} = utilitiesApi;
