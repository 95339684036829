import { Button, Divider, Form, Modal } from 'antd';
import { FC } from 'react';
import greenTick from 'src/assets/greenTick.svg';
import orangeQuantity from 'src/assets/orangeQuestion.svg';
import { TextArea } from 'src/components';
import { IIndentRequest, IOffTakings, IOrderAmend } from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

interface IModal {
  amendOrder: IOrderAmend;
  open: boolean;
  closeModal: () => void;
  detailOfftake: IOffTakings | undefined;
  indent?: IIndentRequest;
}

export const AmendRequest: FC<IModal> = ({ amendOrder, open, closeModal }) => {
  const getAmendedStatus = (id: string) => {
    const amendedmaterial = amendOrder.orders.find(
      (material) => material.rawMaterial.id === id
    );

    return (
      amendedmaterial?.previousQuantity === amendedmaterial?.requestedQuantity
    );
  };

  return (
    <Modal
      title="Amendment request"
      open={open}
      onCancel={closeModal}
      centered
      footer={[<Button onClick={closeModal}>Close</Button>]}
      width={996}
    >
      <>
        <Container>
          <RequestDiv>
            <TitleDiv>
              <h1>Request</h1>
            </TitleDiv>
            <RequestBody>
              <FlexDiv className="bottom">
                <h1>Raw material</h1>
                <h1>Previous quantity</h1>
                <h1>Amendment</h1>
              </FlexDiv>
              {amendOrder.orders.map((request, index) => (
                <FlexDiv key={index}>
                  <p>{request.rawMaterial.name}</p>
                  <h1>{formatNumber(request.previousQuantity)} MT</h1>
                  <h1>{formatNumber(request.requestedQuantity)} MT</h1>
                </FlexDiv>
              ))}
            </RequestBody>

            <StyledText
              value={amendOrder.blenderFeedback}
              disabled
              placeholder="Attached note from blending site regarding amendment request"
            />
          </RequestDiv>
          {amendOrder && amendOrder.status !== 'pending' && (
            <FeedbackDiv
              rejected={amendOrder.status === 'rejected'}
              layout="vertical"
            >
              <TitleDiv>
                <h1>Feedback</h1>
              </TitleDiv>
              <MaterialBody>
                <>
                  <FlexDiv className="bottom">
                    <h1>Raw material</h1>
                    <h1>Feedback</h1>
                  </FlexDiv>
                  {amendOrder.orders.map((feedback, index) => {
                    const isAmend = getAmendedStatus(feedback.rawMaterial.id);

                    return (
                      <FlexDiv key={index}>
                        <p>{feedback.rawMaterial.name}</p>
                        <div className="flexIcon">
                          {isAmend ? (
                            <div className="feedback-flex">
                              <h1 className="greenText">
                                {formatNumber(feedback.approvedQuantity ?? 0)}{' '}
                                MT{' '}
                              </h1>
                              <StyledImage src={greenTick} alt="approved" />
                            </div>
                          ) : amendOrder.status === 'rejected' ? (
                            <h1>N/A</h1>
                          ) : (
                            <div className="feedback-flex">
                              <h1 className="orangeText">
                                {formatNumber(feedback.approvedQuantity ?? 0)}{' '}
                                MT{' '}
                              </h1>
                              <StyledImage src={orangeQuantity} alt="amend" />
                            </div>
                          )}
                        </div>
                      </FlexDiv>
                    );
                  })}
                </>
              </MaterialBody>

              <StyledText
                value={amendOrder.nsiaFeedback ?? ''}
                disabled={true}
                placeholder="Attached note from NSIA regarding amendment request
  "
              />
            </FeedbackDiv>
          )}
        </Container>
        <Divider />
      </>
    </Modal>
  );
};

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledText = styled(TextArea)`
  margin-top: 20px;
  border-radius: 8px;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  margin: 20px 0;
`;

const MaterialBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow-y: scroll;
  background-color: #fafafa;
  gap: 10px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  padding: 10px;
  border-radius: 8px;
  margin-top: 15px;
`;

const RequestBody = styled(MaterialBody)`
  background-color: #ffffff;
  gap: 5px;
`;

const RequestDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f0f0f0;
  flex: 1;
  border-radius: 8px;
  .bottom {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
  }
`;
const FeedbackDiv = styled(Form)<{ rejected: boolean }>`
  border: 1px solid #f4ffb8;
  background-color: ${({ rejected }) => (!rejected ? 'f6ffed' : '#f5b1ae')};
  .bottom {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
  }
  .orangeText {
    color: orange;
  }
  .greenText {
    color: green;
  }
  .feedback-flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
`;
const TitleDiv = styled.div`
  padding: 5px;
  border-bottom: 1px solid #d9d9d9;
  h1 {
    font-weight: 600;
    font-size: 14px;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    color: #52c41a;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
  h1 {
    color: #000000;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
`;
