import {
  Dashboard,
  PurchaseOrder,
  SinglePurchaseOrder
} from 'src/pages/clearingAgents';
import { DischargeVesselReport } from 'src/pages/reports';
import SingleDischargeReport from 'src/pages/reports/SingleDischargeVessel';

export const clearingAgent = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/purchase-order',
    element: <PurchaseOrder />
  },
  {
    path: '/purchase-order/:id',
    element: <SinglePurchaseOrder />
  },
  {
    path: '/reports',
    element: <DischargeVesselReport />
  },
  {
    path: '/reports/vessel-discharge-report/:id',
    element: <SingleDischargeReport />
  }
];
