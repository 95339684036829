import { Button, Form, Input, Modal, notification } from 'antd';
import { FC } from 'react';
import { TextArea, Title } from 'src/components';
import {
  IConfirmPurchaseOrder,
  IPurchaseOrder,
  ITrackDischarge,
  useConfirmPurchaseOrderMutation
} from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled, { css } from 'styled-components';
interface IModal {
  open: boolean;
  closeModal: () => void;
  purchaseOrder: IPurchaseOrder;
  purchaseDetails: ITrackDischarge;
}
interface IFormField {
  quantityReceived: number;
  feedback?: string;
}

export const DischargeOrderModal: FC<IModal> = ({
  open,
  closeModal,
  purchaseOrder,
  purchaseDetails
}) => {
  const [form] = Form.useForm<IFormField>();
  const quantityReceived = Form.useWatch('quantityReceived', form);
  const [confirmOrder, { isLoading }] = useConfirmPurchaseOrderMutation();

  const shouldFlag =
    Boolean(quantityReceived) &&
    Number(quantityReceived) !== Number(purchaseDetails.quantityDischarged);

  const onFinish = (values: IFormField) => {
    const payload: IConfirmPurchaseOrder = {
      id: purchaseDetails.id,
      purchaseId: purchaseOrder.id,
      data: { quantityReceived: Number(values.quantityReceived) }
    };
    if (values.feedback) {
      payload.data.feedback = values.feedback;
    }
    confirmOrder(payload)
      .unwrap()
      .then(() => {
        if (shouldFlag) {
          notification['success']({
            message: 'Discharged raw material flagged successfully'
          });
        } else {
          notification['success']({
            message: 'Discharged raw material confirmed successfully'
          });
        }
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  const handleSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      open={open}
      title="Discharge order details"
      onCancel={closeModal}
      centered
      footer={[
        <Button
          loading={isLoading}
          onClick={handleSubmit}
          type="primary"
          htmlType="submit"
        >
          {shouldFlag ? 'Yes, Flag discharge' : 'Next'}
        </Button>,
        <Button onClick={closeModal}>Cancel</Button>
      ]}
      width={433}
    >
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        <MaterialTitle level={5}>Raw material discharged</MaterialTitle>
        <TableHeader>
          {`${purchaseDetails?.rawMaterial.name} :`}
          {` ${formatNumber(purchaseDetails.quantityDischarged)} MT`}
        </TableHeader>
        <MaterialTitle level={5}>Raw material recieved</MaterialTitle>
        <Form.Item
          label={`${purchaseDetails?.rawMaterial.name}`}
          name={'quantityReceived'}
          rules={[{ required: true, message: 'Quantity Received is required' }]}
        >
          <CustomInput
            type={'number'}
            setColour={shouldFlag}
            placeholder="Enter quantity recieved"
            autoComplete="off"
          />
        </Form.Item>
        {shouldFlag && (
          <Form.Item
            label="Are you sure you want to flag this order? If yes, put your feedback below"
            name={'feedback'}
            rules={[{ required: true, message: 'Feedback is required' }]}
          >
            <TextArea placeholder="Feedback" required />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
const TableHeader = styled.div`
  width: '393px';
  justify-content: center;
  display: flex;
  font-weight: bold;
  padding: 12px 16px;
  align-self: center;
  background-color: #f5f5f5;
`;
const MaterialTitle = styled(Title)`
  border-style: none none solid;
  border-color: #f5f5f5;
`;
const CustomInput = styled(Input)<{ setColour: boolean }>`
  ${({ setColour }) =>
    setColour &&
    css`
      border-color: red !important;
    `};
`;
