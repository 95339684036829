import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification
} from 'antd';
import { FC } from 'react';
import { CustomCard, GreyText, SubHeading } from 'src/pages/styles';
import {
  IOffTakingDetail,
  IResolveIndent,
  useResolveIndentRequestMutation
} from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  indentRequestId: string;
  offtakeDetails: IOffTakingDetail;
}

const { Text } = Typography;
export const ResolveFlag: FC<IModal> = ({
  open,
  closeModal,
  indentRequestId,
  offtakeDetails
}) => {
  const [form] = Form.useForm();
  const status = Form.useWatch('status', form);
  const recievedQuantity = Form.useWatch('quantityRecieved', form);
  const [handleResolveConflict, { isLoading }] =
    useResolveIndentRequestMutation();
  const handleSubmit = (values: {
    quantityRecieved: number;
    status: boolean;
  }) => {
    const payload: IResolveIndent = {
      id: indentRequestId,
      offTakingId: offtakeDetails._id,
      data: {
        status: values.status ? 'confirm' : 'resolve'
      }
    };
    if (values.quantityRecieved) {
      payload.data.quantityReceived = Number(values.quantityRecieved);
    }

    handleResolveConflict(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Flagged order resolved'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  return (
    <Modal
      open={open}
      title="Resolve indent"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          disabled={!status && !recievedQuantity}
          loading={isLoading}
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
        >
          Resolve
        </Button>,

        <Button onClick={closeModal}>Cancel</Button>
      ]}
      width={469}
    >
      <Divider />
      <Row gutter={20}>
        <Col span={12}>
          <Text>Raw material discharged</Text>
          <Divider />
          <CustomCard>
            <GreyText>{offtakeDetails.rawMaterial.name}</GreyText>
            <Text>{`${formatNumber(
              offtakeDetails.quantityDischarged
            )} MT`}</Text>
            <CheckCircleFilled style={{ color: '#52C41A' }} />
          </CustomCard>
        </Col>
        <Col span={12}>
          <Text>Raw material recieved</Text>
          <Divider />
          <CustomCard>
            <GreyText>{offtakeDetails.rawMaterial.name}</GreyText>
            <Text>
              {offtakeDetails?.quantityReceived
                ? `${formatNumber(offtakeDetails?.quantityReceived)} MT`
                : 'N/A'}
            </Text>
            <CloseCircleFilled style={{ color: '#FF4D4F' }} />
          </CustomCard>
        </Col>
      </Row>
      <SubHeading>
        <Text strong>Resolve conflict</Text>
      </SubHeading>
      <Divider />
      <Form
        layout={'vertical'}
        onFinish={handleSubmit}
        form={form}
        validateTrigger={['onBlur']}
      >
        <Form.Item name="quantityRecieved" label="Quantity" key={'8'}>
          <Input
            disabled={status}
            placeholder="Enter quantity "
            type={'number'}
            min="1"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item valuePropName="checked" key={'1'} name="status">
          <Checkbox
            onChange={() => {
              form.setFieldValue('quantityRecieved', '');
            }}
          >
            Disregard flag and confirm
          </Checkbox>
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  );
};

export const Tag = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  margin-block-end: 40px;
`;
export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
