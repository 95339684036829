import { Spin, Table, Tag } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, Title } from 'src/components';
import {
  Container,
  StatusContainer,
  TableBody
} from 'src/pages/blender/indent/style';
import { TableHeader } from 'src/pages/cmBlendingPlant/indent/style';
import { ViewButton } from 'src/pages/styles';
import {
  IIndentRequest,
  IndentRequestStatusConfig,
  useGetAllIndentRequestQuery
} from 'src/store/api/indent-request';
import { DASH, dateTimeFormater, generateUrl } from 'src/utils';

export const IndentTable: FC = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { hash } = useLocation();
  const status = useMemo(() => hash.replace('#', ''), [hash]);

  const queryParams = useMemo(
    () =>
      generateUrl({
        search,
        status,
        page
      }),
    [search, status, page]
  );
  const { data: indentList, isLoading } =
    useGetAllIndentRequestQuery(queryParams);

  const dataSource = useMemo(() => {
    const data = indentList?.data.map((item) => {
      return {
        ...item,
        key: item.id,
        status: item.status,
        date: dateTimeFormater(item.createdAt),
        issuedBy: item.updatedBy ? `${item.updatedBy.name}` : DASH
      };
    });
    return data;
  }, [indentList]);

  const columns = [
    {
      title: 'Raw Material Request ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },
    {
      title: 'Issued by',
      dataIndex: 'issuedBy',
      key: 'issuedBy'
    },

    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
          </StatusContainer>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: IIndentRequest) => (
        <ViewButton
          onClick={() =>
            navigate(`/raw-material-request/${record.id}`, {
              state: { releaseOrder: record }
            })
          }
        >
          Details
        </ViewButton>
      )
    }
  ];

  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      <Container>
        <TableBody>
          <TableHeader>
            <Title level={4}>Raw material Request</Title>
            <Search
              onChange={(e) => !e.target.value && setSearch('')}
              onSearch={onSearch}
              placeholder="Search"
              style={{ width: 264 }}
            />
          </TableHeader>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: indentList?.pagination?.totalCount,
              onChange: (page) => {
                setPage(page);
              }
            }}
          />
        </TableBody>
      </Container>
    </Spin>
  );
};
