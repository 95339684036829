import styled from 'styled-components';
import { Card, DatePicker } from 'antd';
import { BarChart } from 'src/components';
import { UseGetBlenderRawMaterialsChart } from './useGetBlenderRawMaterialsChart';
import { endOfMonth, startOfMonth } from 'src/utils';

const { RangePicker } = DatePicker;

export const MaterialsDistributionChart = () => {
  const { config, handleDateChange } = UseGetBlenderRawMaterialsChart();

  return (
    <StyledCard title={'Raw Materials Distribution'}>
      <DropdownContainer>
        <DropdownWrap>
          <RangePicker
            defaultValue={[startOfMonth(), endOfMonth()]}
            onChange={handleDateChange}
          />
        </DropdownWrap>
      </DropdownContainer>
      <BarChart {...config} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 60%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;

const DropdownContainer = styled.div`
  position: relative;
`;
const DropdownWrap = styled.div`
  display: flex;
  gap: 0.8rem;
  position: absolute;
  right: 0px;
  top: -70px;
`;
