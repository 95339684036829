import { Divider, Modal } from 'antd';
import { FC } from 'react';
import { ICmbDataItem } from 'src/store/api/cmBlender/reports';
import { dateTimeFormater } from 'src/utils';
import styled from 'styled-components';

interface IProps {
  open: boolean;
  closeModal: () => void;
  reports: ICmbDataItem;
}

export const ViewReportsModal: FC<IProps> = ({ open, closeModal, reports }) => {
  return (
    <Modal open={open} onCancel={closeModal} footer={null} centered width={640}>
      <TitleDiv>
        <h2>Daily Production</h2>

        <h1>{dateTimeFormater(reports.createdAt)}</h1>
      </TitleDiv>
      <DashedDiv>
        <RawMaterialDiv>
          <BodyFlex className="subHeading">
            <p className="value">Raw material</p>
            <p className="value">Quantity</p>
          </BodyFlex>
          {reports.rawMaterialUtilized.map((material, index) => {
            return (
              <BodyFlex key={index}>
                <p className="header">{material.rawMaterial.name}</p>
                <p className="value">{material.quantityUtilized}</p>
              </BodyFlex>
            );
          })}
        </RawMaterialDiv>
        <NpkDiv>
          <BodyFlex className="subHeading">
            <p className="value">NPK</p>
            <p className="value">Quantity</p>
          </BodyFlex>

          {reports.npkProduced.map((npk, index) => {
            return (
              <BodyFlex key={index}>
                <p className="header">{npk.npkType.title}</p>
                <p className="value">{npk.quantiyProduced}</p>
              </BodyFlex>
            );
          })}
        </NpkDiv>
      </DashedDiv>
      <Divider />
    </Modal>
  );
};

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 10px;
  background-color: #e6f7ff;
  padding: 12px;
  gap: 15px;
  h1 {
    color: var(--Neutral-8, #595959);
    font-family: 'Myriad Pro';
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
`;

const DashedDiv = styled.div`
  display: flex;
  padding: 20px;
  border: 0.5px dashed var(--Neutral-5, #d9d9d9);
  background-color: #fafafa;
  margin-top: 20px;
  border-radius: 8px;
  .subHeading {
    border-bottom: 0.5px solid var(--Neutral-5, #d9d9d9);
  }
`;

const NpkDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--Neutral-5, #d9d9d9);
  flex: 1;
  padding: 24px;
  gap: 10px;
  .header {
    color: var(--Polar-Green-6, #52c41a);
    font-family: 'Myriad Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  .value {
    color: var(--Neutral-8, #595959);
    font-family: 'Myriad Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
`;

const RawMaterialDiv = styled(NpkDiv)`
  border-left: none;
`;
const BodyFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
