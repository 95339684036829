import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal, Space, Table } from 'antd';
import { FC, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import Logo from 'src/assets/authAssets/logo.png';
import { Text, Title } from 'src/components';
import { IUser, useAppSelector } from 'src/store';
import { useGetVesselDischargeReportQuery } from 'src/store/api';
import { dateTimeFormater, formatNumber } from 'src/utils';
import { TableHeader } from '../style';
import {
  ButtonWrap,
  CustomButton,
  DownloadWrapper,
  HeadingWrapper,
  MainHeader,
  TextWrap
} from './style';
interface IModal {
  open: boolean;
  urlQueryParam: string;
  closeModal: () => void;
}

export const DischargeVesselDownloadModal: FC<IModal> = ({
  open,
  closeModal,
  urlQueryParam
}) => {
  const user = useAppSelector((store) => store.auth.user) as IUser;
  const csvRef = useRef<any>(null);
  const pdfRef = useRef<HTMLDivElement>(null);
  const [showPagination, setShowPagination] = useState(true);
  const { data: getVesselDischargeReport } =
    useGetVesselDischargeReportQuery(urlQueryParam);

  const vesselDischargeReport = useMemo(() => {
    return (
      getVesselDischargeReport?.data?.map((item, index) => ({
        key: item.id,
        ...item,
        serialNumber: index + 1,
        collateralManager: item.collateralManager.name,
        rawMaterials: item.rawMaterial.name,
        date: dateTimeFormater(item.createdAt),
        port: item.port.name,
        quantityDischarged: formatNumber(`${item.quantityDischarged} MT`),
        vesselId: item.purchaseOrder.vesselName
      })) ?? []
    );
  }, [getVesselDischargeReport]);

  const onDownloadClick = () => {
    csvRef.current?.link.click();
  };
  const columns = [
    {
      title: 'S/N',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: 'Vessel ID',
      dataIndex: 'vesselId',
      key: 'vesselId'
    },
    {
      title: 'Raw materials',
      dataIndex: 'rawMaterials',
      key: 'rawMaterials'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantityDischarged',
      key: 'quantityDischarged'
    },
    {
      title: 'Collateral Manger',
      dataIndex: 'collateralManager',
      key: 'collateralManager'
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  const reactToPrintContent = () => {
    return pdfRef.current;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Discharge Vessel Reports',
    async onBeforeGetContent() {
      setShowPagination(false);
    },
    onAfterPrint() {
      setShowPagination(true);
    },
    pageStyle: `@media print {
          @page {
            size: A4 landscape;
            margin: 24px;
          }
        }`
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
    },
    {
      key: '2',
      label: (
        <>
          <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
          <CSVLink
            data={vesselDischargeReport}
            asyncOnClick={true}
            filename={`Discharge Vessel Report ${new Date().toUTCString()}.csv`}
            headers={columns.map((column) => ({
              label: column.title,
              key: column.dataIndex
            }))}
            ref={csvRef}
          ></CSVLink>
        </>
      )
    }
  ];

  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <ButtonWrap>
          <Button onClick={closeModal}>Cancel</Button>{' '}
          <Dropdown
            menu={{
              items
            }}
          >
            <Button type="primary">
              <Space>
                Download Report
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </ButtonWrap>
      ]}
      width={1230}
    >
      <DownloadWrapper ref={pdfRef}>
        <MainHeader>
          <img src={Logo} alt="logo" />
        </MainHeader>

        <HeadingWrapper>
          <TextWrap>
            <Title level={5}>Discharge Vessel Reports</Title>
            <Text>Generated by</Text>
          </TextWrap>

          <TextWrap>
            <Text>{`Date: ${dateTimeFormater(new Date())} `} </Text>
            <Title level={5}>{user.name}</Title>
          </TextWrap>
        </HeadingWrapper>

        <TableHeader>
          <Title level={5}>View Report</Title>
        </TableHeader>
        <Table
          pagination={
            showPagination
              ? {
                  total: getVesselDischargeReport?.pagination?.totalCount,
                  defaultCurrent: 1,
                  pageSize: 10
                }
              : false
          }
          columns={columns}
          dataSource={vesselDischargeReport}
        />
      </DownloadWrapper>
    </Modal>
  );
};
