import styled from 'styled-components';

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    align-self: center;
    margin-block: 33px 47px;
    object-fit: contain;
  }
`;

export const InfoDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  p {
    color: var(--Neutral-8, #595959);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const DashedDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px dashed #8c8c8c;
  border-radius: 8px;
  padding: 20px;
`;
export const DetailsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding: 16px;
`;
export const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  p {
    color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
    font-family: 'Myriad Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const DetailsValue = styled.div`
  display: flex;
  flex-direction: column;
  height: 22px;
  align-items: center;
  min-width: 35px;
  align-items: flex-end;
  p {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: 'Myriad Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: right;
    margin: 0;
  }
`;
