import { useEffect, useMemo, useState } from 'react';
import {
  IWarehouseRawMaterialDistribution,
  useGetAllWarehousesQuery,
  useLazyGetAdminChartQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';

type IParams = {
  startDate: string;
  endDate: string;
};
export const useGetWarehouseChartData = ({ startDate, endDate }: IParams) => {
  const { data: warehouses, isLoading: isWarehouseLoading } =
    useGetAllWarehousesQuery();
  const [warehouse, setWarehouse] = useState<string>('');
  const [getAdminChart, { data: adminChart }] = useLazyGetAdminChartQuery();

  const warehouseDropDownItems = useMemo(() => {
    const obj: {
      [key: string]: {
        label: string;
        key: string;
      };
    } = {};
    warehouses?.data.forEach((item) => {
      obj[item.id] = {
        label: item.code,
        key: item.id
      };
    });
    setWarehouse(Object.keys(obj)[0] ?? '');
    return obj;
  }, [warehouses]);
  const data = useMemo(() => {
    const res: {
      name: string;
      rawmaterial: string;
      amount: number;
    }[] = [];
    adminChart?.data.forEach((item) => {
      const data = item as IWarehouseRawMaterialDistribution;
      res.push({
        name: 'IMPORTED',
        rawmaterial: data.name,
        amount: data.imported
      });
      res.push({
        name: 'OFFTAKE',
        rawmaterial: data.name,
        amount: data.offtake
      });
    });
    return res;
  }, [adminChart]);
  const warehouseChartConfig = {
    data,
    isGroup: true,
    xField: 'rawmaterial',
    yField: 'amount',
    seriesField: 'name',
    color: ['#1890FF', '#EB2F96'],

    marginRatio: 0.4
  };
  useEffect(() => {
    if (warehouse && startDate && endDate) {
      getAdminChart(
        generateUrl({
          warehouse,
          startDate,
          endDate
        })
      );
    }
    // eslint-disable-next-line
  }, [startDate, endDate, warehouse]);
  return {
    warehouseDropDownItems,
    isWarehouseLoading,
    warehouseChartConfig,
    warehouse,
    setWarehouse
  };
};
