export const ORDER_STATUS = {
  order_placed: {
    name: 'Order placed',
    color: 'default',
    status: 'order_placed',
    buttonText: 'Confirm Arrival'
  },
  flagged: {
    color: 'red',
    name: 'Flagged',
    update: true
  },
  discharging: {
    name: 'Discharging',
    color: 'gold',
    status: 'discharging',
    buttonText: 'Discharge vessel'
  },
  vessel_arrived: {
    name: 'Vessel arrived',
    color: 'default',
    status: 'vessel_arrived',
    buttonText: 'Discharge vessel'
  },
  vessel_cleared: {
    name: 'Vessel cleared',
    color: 'green',
    status: 'vessel_cleared',
    buttonText: 'Discharge vessel'
  }
};
export const DISCHARGE_ORDER_STATUS = {
  flagged: {
    color: 'red',
    name: 'Flagged',
    update: 'Update'
  },
  confirmed: {
    color: 'green',
    name: 'Confirmed',
    update: 'None'
  },
  discharged: {
    color: 'default',
    name: 'Discharged',
    update: 'Confirm'
  }
};
