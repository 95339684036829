import { Space, Table, Input } from 'antd';
import { Typography } from 'antd';
import { AddWarehouseModal } from './component/AddWarehouseModal';
import { FC, useMemo, useState } from 'react';
import { DeleteWarehouseModal } from './component/DeleteWarehouseModal';
import {
  ActionItem,
  Container,
  RawMaterialTag,
  TableBody,
  TableHeader
} from 'src/pages/nsia-admin/utilities/styles';
import styled from 'styled-components';
import { generateUrl } from 'src/utils';
import { IWarehouse, useGetAllWarehousesQuery } from 'src/store/api';
import { EditWarehouseModal } from './component/EditWarehouseModal';
import { EyeOutlined } from '@ant-design/icons';
import { ViewWarehouseStock } from './component/ViewStockModal';

interface IProps {
  add: boolean;
  closeModal: () => void;
}

const { Title } = Typography;
const { Search } = Input;

export const Warehouse: FC<IProps> = ({ add, closeModal }) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [material, setMaterial] = useState<IWarehouse | null>(null);

  const [deleteModal, setDeleteModal] = useState<IWarehouse | null>(null);
  const [editModal, setEditModal] = useState<IWarehouse | null>(null);

  const { data: getWarehouse } = useGetAllWarehousesQuery(
    generateUrl({
      limit: 10,
      search,
      page
    })
  );

  const warehouses = useMemo(() => {
    return (
      getWarehouse?.data.map((item) => ({
        key: item.id,
        locationName: item.location.name,
        portName: item.port.name,
        ...item
      })) ?? []
    );
  }, [getWarehouse]);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'location'
    },
    {
      title: 'Port',
      dataIndex: 'portName',
      key: 'port'
    },
    {
      title: 'Raw Material',
      key: 'rawMaterial',
      render: (record: any) => (
        <RawMaterialTag
          onClick={() => {
            setMaterial(record);
          }}
          color="blue"
        >
          <EyeOutlined /> View Raw Materials
        </RawMaterialTag>
      )
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_: any, record: IWarehouse) => (
        <Space size="small">
          <ActionItem
            onClick={() => {
              setEditModal(record);
            }}
          >
            Edit
          </ActionItem>{' '}
          |
          <ActionItem
            color={'rgba(245, 34, 45, 1)'}
            onClick={() => {
              setDeleteModal(record);
            }}
          >
            Delete
          </ActionItem>
        </Space>
      )
    }
  ];

  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Container>
      <AddWarehouseModal open={add} closeModal={() => closeModal()} />
      {!!editModal && (
        <EditWarehouseModal
          open={!!editModal}
          closeModal={() => setEditModal(null)}
          wareHouseDetails={editModal}
        />
      )}
      {!!material && (
        <ViewWarehouseStock
          open={!!material}
          closeModal={() => setMaterial(null)}
          warehouse={material}
        />
      )}
      {!!deleteModal && (
        <DeleteWarehouseModal
          open={!!deleteModal}
          closeModal={() => setDeleteModal(null)}
          warehouse={deleteModal}
        />
      )}
      <TableBody>
        <TableHeader>
          <Title level={4}>Warehouse</Title>
          <SearchBox placeholder="Search" onSearch={onSearch} />
        </TableHeader>
        <Table
          pagination={{
            defaultCurrent: 1,
            pageSize: 10,
            total: getWarehouse?.pagination?.totalCount,
            onChange: (page) => {
              setPage(page);
            }
          }}
          columns={columns}
          dataSource={warehouses}
        />
      </TableBody>
    </Container>
  );
};

const SearchBox = styled(Search)`
  width: 264px;
`;
