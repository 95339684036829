import { Button, Form, Input, Modal, notification, Select } from 'antd';
import { FC, useState } from 'react';
import {
  IAddBlender,
  IBlender,
  useEditBlenderMutation,
  useGetAllSitesQuery
} from 'src/store/api';
import { generateUrl, useDebounce } from 'src/utils';

interface IModal {
  open: boolean;
  closeModal: () => void;
  blenderDetails: IBlender;
}

export const EditBlenderModal: FC<IModal> = ({
  open,
  closeModal,
  blenderDetails
}) => {
  const [input, setInput] = useState('');

  const debouncedInput = useDebounce(input);

  const { data: blendingSite } = useGetAllSitesQuery(
    generateUrl({
      limit: 20,
      search: debouncedInput,
      page: 1
    })
  );

  const { Option } = Select;
  const [form] = Form.useForm();
  const [editBlender, { isLoading }] = useEditBlenderMutation();

  const handleSubmit = (data: Partial<IAddBlender>) => {
    const payload: Partial<IAddBlender> = {};
    if (data.name !== blenderDetails.name) {
      payload.name = data.name;
    }

    if (data.phone !== blenderDetails.phone) {
      payload.phone = data.phone;
    }
    if (data.blendingSite !== blenderDetails.blendingSite.id) {
      payload.blendingSite = data.blendingSite;
    }
    editBlender({ body: payload, id: blenderDetails.id })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated blenders details'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const handleSearch = (value: string) => {
    setInput(value);
  };

  return (
    <Modal
      open={open}
      title="Edit Blender"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          onClick={() => form.submit()}
          loading={isLoading}
          htmlType="submit"
          type="primary"
        >
          Update
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
        initialValues={{
          name: blenderDetails?.name,
          blendingSite: blenderDetails.blendingSite.id,
          emailAddress: blenderDetails?.email,
          phone: blenderDetails?.phone
        }}
      >
        <Form.Item name="name" label="Name" key={'1'}>
          <Input placeholder="Enter Blender's name" autoComplete="off" />
        </Form.Item>

        <Form.Item name="emailAddress" label="Email address" key={'3'}>
          <Input disabled placeholder="Enter address" autoComplete="off" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="phone"
          label="Phone number"
          key={'4'}
          rules={[
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input placeholder="Enter phone number" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="blendingSite"
          label="Blending Plant"
          rules={[{ required: true, message: 'blending site is required' }]}
          key={'5'}
        >
          <Select
            showSearch
            onSearch={handleSearch}
            filterOption={false}
            onClear={() => setInput('')}
            placeholder="Select blending site"
            allowClear
          >
            {blendingSite?.data.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
