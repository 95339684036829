import { Table, Typography, Input, Space } from 'antd';
import { ActionItem, Container, TableHeader } from '../styles';
import { FC, useMemo, useState } from 'react';
import { ISupplier, useGetAllSuppliersQuery } from 'src/store/api';
import { generateUrl } from 'src/utils';
import {
  AddSupplierModal,
  DeleteSupplier,
  EditSupplierModal
} from './components';

interface IProps {
  add: boolean;
  closeModal: () => void;
}

const { Title } = Typography;
const { Search } = Input;

export const Supplier: FC<IProps> = ({ add, closeModal }) => {
  const [page, setPage] = useState(1);

  const [supplier, setSupplier] = useState<ISupplier | null>(null);
  const [search, setSearch] = useState('');
  const [editModal, setEditModal] = useState<ISupplier | null>(null);

  const { data } = useGetAllSuppliersQuery(
    generateUrl({
      limit: 10,
      search,
      page
    })
  );

  const supplierData = useMemo(() => {
    return data?.data.map((item, index) => ({
      ...item,
      supplierCode: item.code,
      supplierName: item.name,
      sn: index + 1
    }));
  }, [data]);

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'sn',
      key: 's/n'
    },
    {
      title: 'Supplier Code',
      dataIndex: 'supplierCode',
      key: 'supplierCode'
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplierName',
      key: 'supplierName'
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_: any, record: ISupplier) => (
        <Space size={'small'}>
          <ActionItem onClick={() => setEditModal(record)}>Edit</ActionItem>
          <ActionItem
            color={'rgba(245, 34, 45, 1)'}
            onClick={() => setSupplier(record)}
          >
            Delete
          </ActionItem>
        </Space>
      )
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      {!!supplier && (
        <DeleteSupplier
          supplier={supplier}
          open={!!supplier}
          closeModal={() => setSupplier(null)}
        />
      )}
      {!!editModal && (
        <EditSupplierModal
          closeModal={() => setEditModal(null)}
          open={!!editModal}
          suppliers={editModal}
        />
      )}
      <TableHeader>
        <Title level={4}>Suppliers</Title>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          onChange={(e) => !e.target.value && setSearch('')}
          style={{ width: 264 }}
        />
      </TableHeader>
      <AddSupplierModal open={add} closeModal={() => closeModal()} />
      <Table
        columns={columns}
        dataSource={supplierData}
        pagination={{
          defaultCurrent: 1,
          pageSize: 10,
          total: data?.pagination?.totalCount,
          onChange: (page) => {
            setPage(page);
          }
        }}
      />
    </Container>
  );
};
