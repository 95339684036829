import { Response } from 'src/store/genericInterface';
import { baseApi } from '../../baseApi';
import { tagTypes } from '../../baseApi/tagTypes';
import { IAddCmbReports, ICmbReportTable } from './interface';

const cmBlenderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCMBlenderReports: builder.query<ICmbReportTable, string>({
      query: (query) => ({
        url: `/daily-production-report?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.CMBlendingReports }]
    }),
    addCMBlenderReports: builder.mutation<
      Response<ICmbReportTable>,
      IAddCmbReports
    >({
      query: (payload) => ({
        url: `/daily-production-report`,
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.CMBlendingReports }]
    }),
    deleteCMBlenderReports: builder.mutation<Response<null | any>, string>({
      query: (id) => ({
        url: `/daily-production-report/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.CMBlendingReports }]
    }),
    confirmReports: builder.mutation<
      Response<ICmbReportTable>,
      { id: string; status: string }
    >({
      query: (payload) => ({
        url: `/daily-production-report/confirm-report/${payload.id}`,
        method: 'PATCH',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.CMBlendingReports }]
    })
  })
});

export const {
  useGetCMBlenderReportsQuery,
  useAddCMBlenderReportsMutation,
  useDeleteCMBlenderReportsMutation,
  useConfirmReportsMutation
} = cmBlenderApi;
