import {
  CarryOutFilled,
  ClockCircleFilled,
  HomeFilled,
  ReconciliationFilled,
  SafetyCertificateFilled,
  SnippetsFilled,
  UsergroupAddOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import leftTrigger from 'src/assets/leftTrigger.svg';
import rightTrigger from 'src/assets/rightTrigger.svg';
import styled from 'styled-components';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Dashboard', '/', <HomeFilled />),
  getItem('Purchase Order', '/purchase-order', <ReconciliationFilled />),
  getItem(
    'Raw Material Request',
    '/raw-material-request',
    <ClockCircleFilled />
  ),
  getItem('Stakeholders', '/stakeholders', <UsergroupAddOutlined />),
  getItem('Utilities', '/utilities', <CarryOutFilled />),
  getItem('Reports', '/reports', <SnippetsFilled />),
  getItem('Admin Management', '/admin-management', <SafetyCertificateFilled />)
];

export const NsiaSideBar = () => {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  return (
    <CustomSider
      trigger={null}
      theme="light"
      width={250}
      collapsed={collapsed}
      collapsible
      onCollapse={(value) => setCollapsed(value)}
    >
      <SideBarWrapper>
        <UpperLevelMenu>
          <div className="logo" />
          <CustomMenu
            defaultSelectedKeys={[pathname]}
            mode={'inline'}
            activeKey={pathname}
            items={items}
            onClick={(menuInfo) => {
              navigate(menuInfo.key);
            }}
          />
        </UpperLevelMenu>
        <TriggerButtonContainer>
          <TriggerButton onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <TriggerImg src={leftTrigger} />
            ) : (
              <TriggerImg src={rightTrigger} />
            )}
          </TriggerButton>
        </TriggerButtonContainer>
      </SideBarWrapper>
    </CustomSider>
  );
};

const CustomSider = styled(Sider)`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;
const SideBarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
const UpperLevelMenu = styled.div`
  overflow-y: auto;
`;
const CustomMenu = styled(Menu)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
`;
const TriggerButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: -13px;
  z-index: 20;
  z-index: 20;
`;
const TriggerButton = styled.div``;
const TriggerImg = styled.img``;
