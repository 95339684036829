import { Col, Form, FormInstance, FormListFieldData, Row, Select } from 'antd';
import { FC, useEffect } from 'react';
import { NumberInput } from 'src/components';
import { IRawMaterial } from 'src/store/api';

type IProp = {
  field: FormListFieldData;
  rawMaterials?: IRawMaterial[];
  index: number;
  form: FormInstance;
  initialValues?: Record<
    string,
    {
      rawMaterial: string;
      offtaken: number;
      releaseQuantity: number;
    }
  >;
};
export const AmendOrderRow: FC<IProp> = ({
  field,
  rawMaterials,
  index,
  form,
  initialValues
}) => {
  const orders = Form.useWatch('amendedOrders', form) as Array<{
    rawMaterial: string;
    estimatedQuantity: string | number;
  }>;
  const row = orders?.[index];
  const offTaken = initialValues?.[row?.rawMaterial]?.offtaken ?? 0;
  const released = initialValues?.[row?.rawMaterial]?.releaseQuantity ?? 0;
  useEffect(() => {
    if (released && row?.estimatedQuantity === '') {
      const updatedOrder = [...orders];
      updatedOrder[index] = { ...row, estimatedQuantity: released };
      form.setFieldValue('amendedOrders', updatedOrder);
    }
    // eslint-disable-next-line
  }, [orders, released]);
  return (
    <Row
      key={field.key}
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      align="middle"
    >
      <Col span={8}>
        <Form.Item
          name={[field.name, 'rawMaterial']}
          label={'Raw Material'}
          rules={[
            {
              required: true,
              message: 'Raw material is required'
            }
          ]}
        >
          <Select disabled placeholder="Select Raw Material">
            {rawMaterials?.map((item) => (
              <Select.Option key={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={'Offtaken Quantity (MT)'}>
          <NumberInput disabled value={offTaken} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={[field.name, 'estimatedQuantity']}
          label="Estimated Quantity (MT)"
          rules={[
            { required: true, message: 'Estimated quantity is required' },
            () => ({
              validator(_, value) {
                if (value && offTaken > value) {
                  return Promise.reject(
                    new Error(
                      'Quantity should not be below what has already been offtaken'
                    )
                  );
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <NumberInput
            min={1}
            placeholder=" Enter quantity"
            autoComplete="off"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
