import { Input, Space, Table, Tag, Typography } from 'antd';
import { StatusContainer } from 'src/pages/styles';

import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useAppSelector } from 'src/store';
import {
  AdminType,
  IAdmin,
  IBlender,
  useGetAllBlendersQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';
import { ActionItem, Container, TableBody, TableHeader } from '../style';
import {
  AddBlendersModal,
  DeleteBlenderModal,
  EditBlenderModal
} from './components';

type IAddBlenders = {
  setAddBlenders: Dispatch<SetStateAction<boolean>>;
  addBlenders: boolean;
};
const { Title } = Typography;
const { Search } = Input;

export const Blenders: FC<IAddBlenders> = ({ setAddBlenders, addBlenders }) => {
  const [deleteModal, setDeleteModal] = useState<IBlender | null>(null);
  const [editModal, setEditModal] = useState<IBlender | null>(null);
  const user = useAppSelector((store) => store.auth.user!) as IAdmin;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data: allBlenders } = useGetAllBlendersQuery(
    generateUrl({
      limit: 10,
      search,
      page
    })
  );
  const blendersList = useMemo(() => {
    return (
      allBlenders?.data?.map((item) => ({
        key: item.id,
        blendingPlant: item?.blendingSite?.name,
        ...item
      })) ?? []
    );
  }, [allBlenders]);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phonenumber'
    },
    {
      title: 'Blending Site',
      dataIndex: 'blendingPlant',
      key: 'blendingSite'
    },
    {
      title: 'Status',
      key: 'status',
      render: (agent: IBlender) => {
        return (
          <StatusContainer>
            {agent.isOnboarded ? (
              <Tag color="green">Onboarded</Tag>
            ) : (
              <Tag color="gold">Awaiting</Tag>
            )}
          </StatusContainer>
        );
      }
    },
    {
      title: 'Actions',
      key: 'operation',
      render: (_: unknown, clickedBlender: IBlender) => (
        <Space size="small">
          {user.type === AdminType.SUPER_ADMIN && (
            <>
              <ActionItem
                onClick={() => {
                  setEditModal(clickedBlender);
                }}
              >
                Edit
              </ActionItem>
              |
              <ActionItem
                color={'rgba(245, 34, 45, 1)'}
                onClick={() => {
                  setDeleteModal(clickedBlender);
                }}
              >
                Delete
              </ActionItem>
            </>
          )}
        </Space>
      )
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      {addBlenders && (
        <AddBlendersModal
          open={addBlenders}
          closeModal={() => setAddBlenders(false)}
        />
      )}
      {!!deleteModal && (
        <DeleteBlenderModal
          open={!!deleteModal}
          blenderDetails={deleteModal}
          closeModal={() => setDeleteModal(null)}
        />
      )}
      {!!editModal && (
        <EditBlenderModal
          open={!!editModal}
          blenderDetails={editModal}
          closeModal={() => setEditModal(null)}
        />
      )}
      <TableBody>
        <TableHeader>
          <Title level={4}>Blenders</Title>
          <Search
            onSearch={onSearch}
            onChange={(e) => !e.target.value && setSearch('')}
            placeholder="Search"
            style={{ width: 264 }}
          />
        </TableHeader>
        <Table
          pagination={{
            defaultCurrent: 1,
            pageSize: 10,
            total: allBlenders?.pagination?.totalCount,
            onChange: (page) => {
              setPage(page);
            }
          }}
          columns={columns}
          dataSource={blendersList}
        />
      </TableBody>
    </Container>
  );
};
