import { PageHeader } from '@ant-design/pro-layout';
import styled from 'styled-components';

export const Container = styled.div`
  height: max-content;
  margin-left: auto;
`;
export const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
export const HeaderDiv = styled.div`
  height: 70px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
  }
`;
export const Body = styled.div`
  width: 95%;
  margin: auto;
`;

export const CardDiv = styled.div`
  margin: 40px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ChartDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  margin-bottom: 40px;
`;

export const ChartAnalyticsCardDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-block-end: 8px;
  width: 100%;
  flex-wrap: nowrap;
  .greyText {
    font-family: 'Myriad Pro';
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
  .greyBold {
    width: 62px;
    height: 20px;
    font-family: 'Myriad Pro';
    font-size: 12px;
    line-height: 20px;
    color: #262626;
  }
`;
