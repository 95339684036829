import { useMemo, useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  StatusContainer,
  TableHeader,
  ViewButton
} from 'src/pages/cmPort/purchase-order/styles';
import {
  IPurchaseOrder,
  ITrackDischarge,
  useGetDischargeOrdersQuery,
  useGetSinglePurchaseOrderQuery
} from 'src/store/api';
import { Tag, Table, Spin } from 'antd';
import { DischargeOrderModal } from './DischargeOrderModal';
import { DISCHARGE_ORDER_STATUS } from 'src/pages/cmPort/purchase-order/enum';
import { DASH, dateTimeFormater, formatNumber, generateUrl } from 'src/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomHeader } from 'src/pages/styles';
import { Search, Title } from 'src/components';

export const DischargeOrders = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>(
    (state as { purchaseOrder: IPurchaseOrder }).purchaseOrder
  );
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data: fetchDischargeOrders } = useGetDischargeOrdersQuery({
    purchaseId: purchaseOrder.id,
    query: generateUrl({
      limit: 10,
      search,
      page
    })
  });
  const [viewPurchaseOrderModalVisible, setViewPurchaseOrderModalVisible] =
    useState<ITrackDischarge | null>(null);

  const { data, isLoading } = useGetSinglePurchaseOrderQuery(purchaseOrder.id);
  const source = useMemo(() => {
    return fetchDischargeOrders?.data.map((item) => {
      return {
        tableRawMaterial: item.rawMaterial.name,
        tableCollateralManager: item.collateralManager.name,
        key: item.id,
        tableQuantityDischarged: item.quantityDischarged
          ? `${formatNumber(item.quantityDischarged)} MT`
          : DASH,
        tableWarehouse: item.warehouse.name,
        tableQuantityRecieved: item.quantityReceived
          ? `${formatNumber(item.quantityReceived)} MT`
          : DASH,
        date: dateTimeFormater(item.updatedAt),
        ...item
      };
    });
  }, [fetchDischargeOrders]);

  const columns = [
    {
      title: 'Raw materials',
      dataIndex: 'tableRawMaterial',
      key: 'tableRawMaterial'
    },
    {
      title: 'Quantity dicharged',
      dataIndex: 'tableQuantityDischarged',
      key: 'quantity'
    },
    {
      title: 'Quantity recieved',
      dataIndex: 'tableQuantityRecieved',
      key: 'tableQuantityRecieved'
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },

    {
      title: 'Order Status',
      dataIndex: 'status',
      render: (_: any, record: ITrackDischarge) => {
        const status = record.status as keyof typeof DISCHARGE_ORDER_STATUS;
        return (
          <StatusContainer>
            <Tag color={DISCHARGE_ORDER_STATUS[status].color}>
              {DISCHARGE_ORDER_STATUS[status].name}
            </Tag>
            {DISCHARGE_ORDER_STATUS[status].update !== 'None' && (
              <ViewButton
                onClick={() => {
                  setViewPurchaseOrderModalVisible(record);
                }}
              >
                {DISCHARGE_ORDER_STATUS[status].update}
              </ViewButton>
            )}
          </StatusContainer>
        );
      }
    }
  ];
  useEffect(() => {
    if (data) {
      setPurchaseOrder(data.data);
    }
  }, [data]);

  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      <CustomHeader>
        <Title level={3}>
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ marginRight: '17px' }}
          />{' '}
          Purchase order ID - {purchaseOrder.purchaseId}
        </Title>
      </CustomHeader>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <Container>
          {!!viewPurchaseOrderModalVisible && (
            <DischargeOrderModal
              purchaseDetails={viewPurchaseOrderModalVisible}
              purchaseOrder={purchaseOrder}
              open={!!viewPurchaseOrderModalVisible}
              closeModal={() => setViewPurchaseOrderModalVisible(null)}
            />
          )}

          <TableHeader>
            <Title level={4}>Raw materials discharge</Title>
            <Search
              placeholder="Search"
              onSearch={onSearch}
              onChange={(e) => !e.target.value && setSearch('')}
              style={{ width: 264 }}
            />
          </TableHeader>
          <Table
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              onChange: (page) => {
                setPage(page);
              }
            }}
            columns={columns}
            dataSource={source}
          />
        </Container>
      </Spin>
    </>
  );
};
