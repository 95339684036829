import styled from 'styled-components';

export const TableBody = styled.div`
  background-color: white;
`;
export const IndentContainer = styled.div`
  height: max-content;
  margin-left: auto;
`;
export const Container = styled.div`
  width: '100%';
  height: '100%';
  padding: 32px;
`;
export const TableHeader = styled.div`
  width: '100%';
  justify-content: space-between;
  display: flex;
  height: 88px;
  align-items: center;
  padding: 10px 10px;
`;

export const ActionItem = styled.h4`
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : '')};
`;
export const ActionSite = styled.div`
  display: flex;
  column-gap: 16px;
`;
export const StatusContainer = styled.div`
  display: flex;
  column-gap: 23px;
`;
