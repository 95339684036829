import { Button, Modal, Typography } from 'antd';
import moment from 'moment';
import { FC, useMemo } from 'react';
import Logo from 'src/assets/authAssets/logo.png';
import {
  SummaryWrapper,
  TextWrapper
} from 'src/pages/nsia-admin/purchaseOrder/styles';
import { IPurchaseOrder } from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';
import { MainHeader } from './ViewPaarModal';

const { Title } = Typography;

interface IModal {
  open: boolean;
  summary: IPurchaseOrder;
  closeSummary: () => void;
}

export const SummaryResult: FC<IModal> = ({ closeSummary, open, summary }) => {
  const estimatedTonnage = useMemo(() => {
    return summary.orders.reduce(
      (acc, current) => acc + Number(current.estimatedQuantity),
      0
    );
  }, [summary]);
  return (
    <>
      <Modal
        open={open}
        centered={true}
        closable={false}
        footer={[<Button onClick={closeSummary}>Close & Continue</Button>]}
        width={660}
      >
        <TextWrapper>
          <MainHeader>
            <img src={Logo} alt="logo" />
          </MainHeader>
          <Title level={3}>Purchase order</Title>
          <p>
            Purchase order with ID <strong>{summary.purchaseId}</strong> was
            issued and approved by <strong>{summary?.vesselName}</strong> on
            <strong>{` ${moment(summary.createdAt).format(
              'Do MMMM YYYY'
            )}. `}</strong>
            Details of the order are listed below and all the operators involved
            as well. if there is any issue revert to the proper channel.
          </p>
        </TextWrapper>
        <SummaryWrapper title="Raw Material" bordered={false}>
          {summary.orders.map((item, index) => (
            <OrderContainer key={index}>
              <RawMaterialName>
                {item.rawMaterial.name.toUpperCase()}
              </RawMaterialName>
              <RawMaterialQuantity>
                {formatNumber(item.estimatedQuantity)} MT
              </RawMaterialQuantity>
            </OrderContainer>
          ))}
        </SummaryWrapper>

        <SummaryWrapper title="Purchase Details" bordered={false}>
          <OrderContainer>
            <Keys>Ports of discharge</Keys>
            <Values>{summary.portQuantities.length}</Values>
          </OrderContainer>
          <OrderContainer>
            <Keys>Vessel name</Keys>
            <Values>{summary.vesselName}</Values>
          </OrderContainer>
          <OrderContainer>
            <Keys>Estimated tonnage</Keys>
            <Values>{formatNumber(estimatedTonnage)} MT</Values>
          </OrderContainer>
          <OrderContainer>
            <Keys>Estimated time of arrival</Keys>
            <Values>{moment(summary.arrivalTime).format('YYYY-MM-DD')}</Values>
          </OrderContainer>

          <OrderContainer>
            <Keys>Clearing agent</Keys>
            <Values>{summary.clearingAgent.name}</Values>
          </OrderContainer>
        </SummaryWrapper>
      </Modal>
    </>
  );
};

const Keys = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;
const Values = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: flex-end;
`;
const OrderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
  margin-top: -10px;
`;
const RawMaterialName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
`;
const RawMaterialQuantity = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;
