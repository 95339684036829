import styled from 'styled-components';

export const DownloadWrapper = styled.div`
  margin-inline: 10px;
`;
export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    align-self: center;
    margin-block: 33px 47px;
    object-fit: contain;
  }
`;
export const HeadingWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;
export const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 18px;
  padding-block: 7px;
  h5 {
    margin: 0 !important;
  }
  span {
    margin: 0;
  }
`;
export const ButtonWrap = styled.div``;

export const CustomButton = styled.div`
  background: #ffffff;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
  padding-inline: 5px;
`;
