import { Form, FormInstance, Input, notification, Select } from 'antd';
import { FC, useRef } from 'react';
import {
  IAddCollateralManagerPort,
  useAddCollateralManagerPortMutation,
  useGetAllPortsQuery,
  useLazyGetAllWarehousesQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';

interface IModal {
  closeModal: () => void;
  form: FormInstance<IAddCollateralManagerPort>;
  setCMLoading: (value: React.SetStateAction<boolean>) => void;
}

export const AddCMPort: FC<IModal> = ({ closeModal, form, setCMLoading }) => {
  const { data: ports } = useGetAllPortsQuery();
  const [addCollateralManager] = useAddCollateralManagerPortMutation();
  const [getPortWarehouses, { data: warehouses }] =
    useLazyGetAllWarehousesQuery();

  const handleSubmit = (payload: IAddCollateralManagerPort) => {
    setCMLoading(true);
    addCollateralManager(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a collateral manager.'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      })
      .finally(() => {
        setCMLoading(false);
      });
  };
  const { Option } = Select;

  const prevPort = useRef(form.getFieldValue('port'));

  const handleSelect = (port: string) => {
    if (prevPort.current !== port) {
      form.setFieldsValue({ warehouses: [] });
      prevPort.current = port;
      getPortWarehouses(generateUrl({ port }));
    }
  };

  return (
    <Form
      layout={'vertical'}
      form={form}
      validateTrigger={['onBlur']}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="name"
        label="Name"
        key={'1'}
        rules={[
          { required: true, message: 'Collateral manager name is required' }
        ]}
      >
        <Input autoComplete="off" placeholder="Enter Collateral manager name" />
      </Form.Item>
      <Form.Item
        name="port"
        rules={[{ required: true, message: 'Port is required' }]}
        label="Port"
        key={'2'}
      >
        <Select onSelect={handleSelect} placeholder="Select Port" allowClear>
          {ports?.data.map((port) => (
            <Option key={port.id} value={port.id}>
              {port.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="email"
        label="Email address"
        key={'3'}
        rules={[
          { required: true, message: 'Collateral manager email is required' }
        ]}
      >
        <Input autoComplete="off" placeholder="Enter address" />
      </Form.Item>
      <Form.Item
        name="phone"
        hasFeedback
        label="Phone number"
        key={'4'}
        rules={[
          {
            required: true,
            message: 'Collateral manager phone number is required'
          },
          {
            pattern: /^((\+234)|0)[0-9]{10}$/,
            message: 'please enter a valid number'
          }
        ]}
      >
        <Input autoComplete="off" placeholder="Enter phone number" />
      </Form.Item>
      <Form.Item
        name="warehouses"
        label="Warehouse"
        rules={[{ required: true, message: 'warehouse is required' }]}
        key={'5'}
      >
        <Select
          placeholder="Select Warehouse"
          mode="tags"
          allowClear
          tokenSeparators={[',']}
        >
          {warehouses?.data.map((warehouse) => (
            <Option key={warehouse.id} value={warehouse.id}>
              {warehouse.code}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
