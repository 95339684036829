import { Tabs, Typography } from 'antd';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomHeader } from 'src/pages/styles';
import styled from 'styled-components';
import { IndentTable } from './components';

const { Title } = Typography;

export const IndentOrders = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const status = useMemo(() => hash, [hash]);

  const onChange = (key: string) => {
    navigate(`/raw-material-request${key}`);
  };

  return (
    <>
      <CustomHeader>
        <Title>Release order</Title>
      </CustomHeader>

      <CustomTab
        defaultActiveKey={status}
        activeKey={status}
        onChange={onChange}
        items={[
          {
            label: `All Requests`,
            key: '',
            children: <IndentTable />
          },
          {
            label: `Active`,
            key: '#off-taking',
            children: <IndentTable />
          },
          {
            label: `Approved`,
            key: '#approved',
            children: <IndentTable />
          },
          {
            label: `Completed`,
            key: '#completed',
            children: <IndentTable />
          },
          {
            label: `Rejected`,
            key: '#rejected',
            children: <IndentTable />
          }
        ]}
      />
    </>
  );
};
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
