import { Button, Modal, Form, Input, notification } from 'antd';
import { FC } from 'react';
import {
  IInviteAdminPayload,
  useInviteAdminMutation
} from 'src/store/api/nsia/admin';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const CreateAdminModal: FC<IModal> = ({ open, closeModal }) => {
  const [form] = Form.useForm();
  const [inviteAdmin, { isLoading }] = useInviteAdminMutation();

  const handleSubmit = (values: IInviteAdminPayload) => {
    inviteAdmin(values)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a new Admin.'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  return (
    <Modal
      open={open}
      title="Add New Admin"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          loading={isLoading}
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
        >
          Add Admin
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name"
          key={'1'}
          rules={[{ required: true, message: 'Admin name is required' }]}
        >
          <Input placeholder="Enter full name" autoComplete='off' />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email address"
          key={'3'}
          rules={[{ required: true, message: 'Admin email is required' }]}
        >
          <Input placeholder="Enter address" autoComplete='off' />
        </Form.Item>
        <Form.Item
          name="phone"
          hasFeedback
          label="Phone number"
          key={'4'}
          rules={[
            {
              required: true,
              message: 'Admin phone number is required'
            },
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input placeholder="Enter phone number" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};
