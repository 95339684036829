import {
  Dashboard,
  IndentOrders,
  ReleaseOrder
} from 'src/pages/cmBlendingPlant';
import { Reports } from 'src/pages/cmBlendingPlant/report';
import {
  ProductionSummaryReport,
  RawMaterialReceipt,
  RawMaterialUsage,
  ReleaseReports
} from 'src/pages/reports';
import { AmendmentReports } from 'src/pages/reports/AmendmentReports';
import MasterReport from 'src/pages/reports/MasterReport';

export const cmBlendingPlant = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/raw-material-request',
    element: <IndentOrders />
  },
  {
    path: '/raw-material-request/:id',
    element: <ReleaseOrder />
  },
  {
    path: '/daily-productions',
    element: <Reports />
  },
  {
    path: '/raw-material-receipt',
    element: <RawMaterialReceipt />
  },
  {
    path: '/raw-material-usage',
    element: <RawMaterialUsage />
  },

  {
    path: '/reports',
    element: <MasterReport />
  },

  {
    path: '/reports/raw-material-usage',
    element: <RawMaterialUsage />
  },

  {
    path: '/reports/amendment-reports',
    element: <AmendmentReports />
  },
  {
    path: '/reports/release-reports',
    element: <ReleaseReports />
  },
  {
    path: '/reports/production-summary-report',
    element: <ProductionSummaryReport />
  }
];
