import { Space, Table, Tag } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, Title } from 'src/components';
import { TableHeader } from 'src/pages/styles';
import { useAppSelector } from 'src/store';
import {
  IIndentRequest,
  IndentRequestStatusConfig,
  useGetAllIndentRequestQuery
} from 'src/store/api/indent-request';
import { dateTimeFormater, generateUrl } from 'src/utils';
import { BlinkingCircle, Container, TableBody, ViewButton } from '../style';

export const IndentTable = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const { role } = useAppSelector((state) => state.auth.user!);
  const { hash } = useLocation();
  const status = useMemo(() => hash.replace('#', ''), [hash]);
  const [page, setPage] = useState(1);
  const queryParams = useMemo(
    () =>
      generateUrl({
        search,
        status,
        page
      }),
    [search, status, page]
  );

  const { data: indentRequests } = useGetAllIndentRequestQuery(queryParams);

  const dataSource = useMemo(() => {
    return indentRequests?.data.map((item) => ({
      ...item,
      key: item.releaseId,
      status: item.status,
      date: dateTimeFormater(item.createdAt),
      time: dateTimeFormater(item.createdAt, 'LT'),
      blendingSite: item.blender?.blendingSite?.name
    }));
  }, [indentRequests]);

  const columns = [
    {
      title: 'Raw material Request ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },
    {
      title: 'Blending site',
      dataIndex: 'blendingSite',
      key: 'blendingSite'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <Space>
            <Tag color={status.color}>{status.name}</Tag>
          </Space>
        );
      }
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <div className="blink">
            <ViewButton
              onClick={() =>
                navigate(`/raw-Material-request/${request.id}`, {
                  state: request
                })
              }
            >
              {status?.action?.[role] ?? 'Details'}
            </ViewButton>
            {request.hasPendingAmendment ? (
              <BlinkingCircle title="You have a pending Amendment" />
            ) : null}{' '}
          </div>
        );
      }
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      <TableBody>
        <TableHeader>
          <Title level={4}>All raw materials request</Title>
          <Search
            onSearch={onSearch}
            onChange={(e) => !e.target.value && setSearch('')}
            placeholder="Search"
            style={{ width: 264 }}
          />
        </TableHeader>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            defaultCurrent: 1,
            pageSize: 20,
            total: indentRequests?.pagination?.totalCount,
            onChange: (page) => {
              setPage(page);
            }
          }}
        />
      </TableBody>
    </Container>
  );
};
