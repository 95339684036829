import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Select, Spin } from 'antd';
import { FC } from 'react';
import { NumberInput } from 'src/components';
import { AddFieldContainer } from 'src/pages/clearingAgents/purchaseOrder/styles';
import { IPurchaseOrder, useGetAllWarehousesQuery } from 'src/store/api';
import { generateUrl } from 'src/utils';

const { Option } = Select;

type IProps = {
  portId: string;
  purchaseOrder: IPurchaseOrder;
  form: FormInstance;
};
export const DischargeForm: FC<IProps> = ({ purchaseOrder, portId, form }) => {
  const { data: warehouses, isLoading } = useGetAllWarehousesQuery(
    generateUrl({ port: portId, limit: 100 })
  );

  const vesselUpdate = [
    {
      rawMaterial: '',
      quantityDischarged: '',
      collateralManager: '',
      warehouse: ''
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <Form layout={'vertical'} form={form} validateTrigger={'onBlur'}>
        <Form.List name={'vesselUpdate'} initialValue={vesselUpdate}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={index} align="middle">
                  <Col xs={{ span: 8, offset: 0 }}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'rawMaterial']}
                      label="Raw material"
                      fieldKey={[field.key, 'rawMaterial']}
                      rules={[
                        { required: true, message: 'Raw materials is required' }
                      ]}
                    >
                      <Select placeholder="Select Raw Material" allowClear>
                        {purchaseOrder.orders.map((item) => (
                          <Option
                            onClick={() => {}}
                            value={item.rawMaterial.id}
                          >
                            {item.rawMaterial.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 7, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                    <Form.Item
                      name={[field.name, 'quantityDischarged']}
                      label="Quantity"
                      fieldKey={[field.key, 'quantityDischarged']}
                      rules={[
                        { required: true, message: 'quantity is required' }
                      ]}
                    >
                      <NumberInput placeholder="Enter quantity" />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 8, offset: 1 }}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'warehouse']}
                      label="Warehouse"
                      rules={[
                        { required: true, message: 'Warehouse is required' }
                      ]}
                    >
                      <Select placeholder="Select Warehouse" allowClear>
                        {warehouses?.data?.map((warehouse) => {
                          return (
                            <Option key={warehouse.id} value={warehouse.id}>
                              {warehouse.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 1, offset: 0 }}>
                    <CloseCircleOutlined
                      style={{
                        fontSize: '1.8rem',
                        color: '#FF7875',
                        paddingLeft: '7px',
                        paddingTop: '5px'
                      }}
                      onClick={() => remove(index)}
                    />
                  </Col>
                </Row>
              ))}
              <AddFieldContainer>
                <Button
                  title="Add field"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    add({
                      rawMaterial: '',
                      quantityDischarged: '',
                      collateralManager: '',
                      warehouse: ''
                    })
                  }
                >
                  Add Field
                </Button>
              </AddFieldContainer>
            </>
          )}
        </Form.List>
      </Form>
    </Spin>
  );
};
