import { Card, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { FC, useMemo, useState } from 'react';
import { BarChart } from 'src/components';
import { useGetClearingAgentChartsQuery } from 'src/store/api';
import { endOfMonth, generateUrl, startOfMonth } from 'src/utils';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

export const MaterialsDistributionChart: FC = () => {
  const [queryParams, setParams] = useState({
    startDate: dayjs(new Date()).startOf('month').toISOString(),
    endDate: dayjs(new Date()).endOf('month').toISOString()
  });

  const { data: clearingAgentChart } = useGetClearingAgentChartsQuery(
    generateUrl(queryParams)
  );

  const data = useMemo(() => {
    const res: {
      name: string;
      rawmaterial: string;
      amount: number;
    }[] = [];

    clearingAgentChart?.data.forEach((item) => {
      res.push({
        name: 'ASSIGNED',
        rawmaterial: item.name,
        amount: item.assigned
      });
      res.push({
        name: 'CLEARED',
        rawmaterial: item.name,
        amount: item.cleared
      });
    });
    return res;
  }, [clearingAgentChart]);

  const config = {
    data,
    isGroup: true,
    xField: 'rawmaterial',
    yField: 'amount',
    seriesField: 'name',
    color: ['#1890FF', '#6FCE40'],
    marginRatio: 0.4,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    }
  };

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParams({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };

  return (
    <StyledCard title="Raw Materials Distribution">
      <DropdownContainer>
        <DropdownWrap>
          <RangePicker
            defaultValue={[startOfMonth(), endOfMonth()]}
            onChange={handleDateChange}
          />
        </DropdownWrap>
      </DropdownContainer>

      <BarChart {...config} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;

const DropdownContainer = styled.div`
  position: relative;
`;
const DropdownWrap = styled.div`
  display: flex;
  gap: 0.8rem;
  position: absolute;
  right: 0px;
  top: -70px;
`;
