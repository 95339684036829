import { PaginatedResponse, Response } from 'src/store/genericInterface';
import { baseApi } from '../baseApi';
import { tagTypes } from '../baseApi/tagTypes';
import {
  IAcceptAmendment,
  IAmendOrderPayload,
  IAmendOrdersResponse,
  IBlenderResolveIndent,
  IEditLogistics,
  IIndentRequest,
  IIndentRequestPayload,
  IOffTakings,
  IOfftakeMaterial,
  IOrderAmend,
  IResolveBlenderIndent,
  IResolveCmBlendIndent,
  IResolveIndent,
  IUpdateIndentRequest
} from './interface';

const indentRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createIndentRequest: builder.mutation<
      Response<IIndentRequest>,
      IIndentRequestPayload
    >({
      query: (payload) => ({
        url: `/indent-request`,
        method: 'POST',
        data: payload,
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    getAllIndentRequest: builder.query<
      PaginatedResponse<IIndentRequest>,
      string | void
    >({
      query: (query) => ({
        url: `/indent-request?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.IndentRequest, query }
      ]
    }),
    getIndentRequestById: builder.query<Response<IIndentRequest>, string>({
      query: (id) => ({
        url: `/indent-request/${id}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, id) => [
        { type: tagTypes.IndentRequest, id }
      ]
    }),
    createOfftakeRawMaterial: builder.mutation<
      Response<IIndentRequest>,
      IOfftakeMaterial
    >({
      query: (payload) => ({
        url: `/indent-request/${payload.releaseOrderId}/off-take`,
        method: 'POST',
        data: payload.data
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: tagTypes.IndentRequest, id: arg.releaseOrderId },
        { type: tagTypes.TrackOffTaking, id: arg.releaseOrderId }
      ]
    }),
    updateIndentRequest: builder.mutation<
      Response<IIndentRequest>,
      { orderId: string; data: IUpdateIndentRequest }
    >({
      query: (payload) => ({
        url: `/indent-request/${payload.orderId}`,
        method: 'PUT',
        data: payload.data
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    resolveIndentRequest: builder.mutation<
      Response<IIndentRequest>,
      IResolveIndent
    >({
      query: (payload) => ({
        url: `/indent-request/${payload.id}/resolve-flagged/${payload.offTakingId}`,
        method: 'PATCH',
        data: payload.data
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    cmBlendResolveIndentRequest: builder.mutation<
      Response<IIndentRequest>,
      IResolveCmBlendIndent
    >({
      query: (payload) => ({
        url: `/indent-request/${payload.id}/resolve-flagged/${payload.offTakingId}`,
        method: 'PATCH',
        data: payload.data
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    blenderResolveIndent: builder.mutation<
      Response<IBlenderResolveIndent>,
      IResolveBlenderIndent
    >({
      query: (payload) => ({
        url: `/indent-request/${payload.indentRequestId}/confirm-offtaking/${payload.id}`,
        method: 'PATCH',
        data: payload.data
      }),
      invalidatesTags: [
        { type: tagTypes.IndentRequest },
        { type: tagTypes.TrackOffTaking },
        { type: tagTypes.BlenderStats }
      ]
    }),
    handleIndentRequest: builder.mutation<
      Response<IIndentRequest>,
      IResolveIndent
    >({
      query: (payload) => ({
        url: `/indent-request/${payload.id}/handle-flagged/${payload.offTakingId}`,
        method: 'PATCH',
        data: payload.data
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    completeOfftake: builder.mutation<Response<IIndentRequest>, string>({
      query: (payload) => ({
        url: `/indent-request/${payload}/complete-offtaking`,
        method: 'PATCH'
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    getOffTakingDetails: builder.query<Response<IOffTakings>, string>({
      query: (id) => ({
        url: `/indent-request/${id}/track-off-taking`,
        method: 'GET'
      }),
      providesTags: (_result, _err, id) => [
        { type: tagTypes.TrackOffTaking, id },
        { type: tagTypes.IndentRequest }
      ]
    }),
    deleteIndentRequest: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/indent-request/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    editIndentRequest: builder.mutation<
      Response<IIndentRequest>,
      IEditLogistics
    >({
      query: (payload) => ({
        url: `/indent-request/logistics/${payload.id}`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    createAmendOrder: builder.mutation<
      Response<IAmendOrdersResponse>,
      IAmendOrderPayload
    >({
      query: (payload) => ({
        url: `/indent-request/${payload.id}/amendment-request`,
        method: 'POST',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    }),
    getAmendOrders: builder.query<IAmendOrdersResponse, string>({
      query: (id) => ({
        url: `/indent-request/${id}/amendment-requests`,
        method: 'GET'
      }),
      providesTags: (_result, _err, id) => [
        { type: tagTypes.IndentRequest },
        { type: tagTypes.AmendedOrders, id }
      ]
    }),
    acceptAmendment: builder.mutation<Response<IOrderAmend>, IAcceptAmendment>({
      query: (payload) => ({
        url: `/indent-request/${payload.id}/amendment-request`,
        method: 'PUT',
        data: payload.body
      }),
      invalidatesTags: [{ type: tagTypes.IndentRequest }]
    })
  })
});

export const {
  useCreateIndentRequestMutation,
  useGetAllIndentRequestQuery,
  useGetIndentRequestByIdQuery,
  useUpdateIndentRequestMutation,
  useCreateOfftakeRawMaterialMutation,
  useGetOffTakingDetailsQuery,
  useResolveIndentRequestMutation,
  useHandleIndentRequestMutation,
  useCompleteOfftakeMutation,
  useDeleteIndentRequestMutation,
  useEditIndentRequestMutation,
  useBlenderResolveIndentMutation,
  useCmBlendResolveIndentRequestMutation,
  useCreateAmendOrderMutation,
  useGetAmendOrdersQuery,
  useAcceptAmendmentMutation
} = indentRequestApi;
