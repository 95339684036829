import { Role } from 'src/store/constant';

export enum IndentRequestStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  'off-taking' = 'off-taking',
  completed = 'completed'
}
export const IndentRequestStatusConfig: Record<
  IndentRequestStatus,
  { color: string; name: string; action?: Record<string, string> }
> = {
  [IndentRequestStatus.pending]: {
    color: 'orange',
    name: 'Pending order',
    action: {
      [Role.NSIA_ADMIN]: 'Process'
    }
  },
  [IndentRequestStatus.approved]: {
    color: 'cyan',
    name: 'Approved',
    action: {
      [Role.COLLATERAL_MANAGER]: 'Start Offtake'
    }
  },
  [IndentRequestStatus.rejected]: {
    color: 'red',
    name: 'Rejected'
  },
  [IndentRequestStatus['off-taking']]: {
    color: 'blue',
    name: 'Off taking',
    action: {
      [Role.COLLATERAL_MANAGER]: 'Offtake'
    }
  },
  [IndentRequestStatus.completed]: {
    color: 'green',
    name: 'Completed'
  }
};
export enum OfftakingStatus {
  'in-transit' = 'in-transit',
  CONFIRMED = 'confirmed',
  FLAGGED = 'flagged',
  AWAITING_TRUCK_ID = 'awaiting-truck-id',
  REJECTED = 'rejected'
}
export const BlenderOfftakingStatusConfig = {
  [OfftakingStatus['in-transit']]: {
    color: 'orange',
    name: 'In Transit',
    action: ''
  },
  [OfftakingStatus.CONFIRMED]: {
    color: 'green',
    name: 'Confirmed',
    action: ''
  },
  [OfftakingStatus.FLAGGED]: {
    color: 'red',
    name: 'Flagged',
    action: 'Resolve'
  },
  [OfftakingStatus.AWAITING_TRUCK_ID]: {
    color: 'gold',
    name: 'Awaiting ID',
    action: 'Confirm'
  },
  [OfftakingStatus.REJECTED]: {
    color: 'red',
    name: 'Rejected',
    action: 'Rejected'
  }
};
export const OfftakingStatusConfig = {
  [OfftakingStatus['in-transit']]: {
    color: 'orange',
    name: 'In Transit',
    action: 'Confirm'
  },
  [OfftakingStatus.CONFIRMED]: {
    color: 'green',
    name: 'Confirmed',
    action: ''
  },
  [OfftakingStatus.FLAGGED]: {
    color: 'red',
    name: 'Flagged',
    action: 'Resolve'
  },
  [OfftakingStatus.AWAITING_TRUCK_ID]: {
    color: 'gold',
    name: 'Awaiting ID',
    action: ''
  },
  [OfftakingStatus.REJECTED]: {
    color: 'red',
    name: 'Rejected',
    action: 'Rejected'
  }
};

export enum ReportStatus {
  pending = 'pending',
  confirmed = 'confirmed',
  rejected = 'rejected'
}

export const ReportStatusConfig: Record<
  ReportStatus,
  { color: string; name: string; action?: string }
> = {
  [ReportStatus.confirmed]: {
    color: 'green',
    name: 'confirmed',
    action: ''
  },
  [ReportStatus.pending]: {
    color: 'orange',
    name: 'pending',
    action: 'confirm'
  },
  [ReportStatus.rejected]: {
    color: 'red',
    name: 'rejected',
    action: 'rejected'
  }
};

export enum AmendStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected'
}

export const AmendStatusConfig: Record<
  AmendStatus,
  { color: string; name: string; action?: string }
> = {
  [AmendStatus.approved]: {
    color: 'blue',
    name: 'approved',
    action: ''
  },
  [AmendStatus.pending]: {
    color: 'orange',
    name: 'pending',
    action: 'confirm'
  },
  [AmendStatus.rejected]: {
    color: 'red',
    name: 'rejected',
    action: 'rejected'
  }
};
