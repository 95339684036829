import { Button, Modal, Form, Input, notification } from 'antd';
import { FC } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { GooglePlace } from 'src/components';
import { IAddPortPayload, useAddPortMutation } from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
  portDetails?: { name: string; location: string; operator: string };
}

export const AddPortModal: FC<IModal> = ({ open, closeModal }) => {
  const [handleAddPorts, { isLoading }] = useAddPortMutation();
  const [form] = Form.useForm();

  const handleSelected = async (data: {
    label: string;
    value: { place_id: string };
  }) => {
    const [res] = await geocodeByPlaceId(data.value.place_id);
    form.setFieldValue('location', {
      name: res.formatted_address,
      latitude: res.geometry.location.lat(),
      longitude: res.geometry.location.lng()
    });
  };
  const handleSubmit = (payload: IAddPortPayload) => {
    handleAddPorts(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a port to system'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  return (
    <Modal
      visible={open}
      title="Add Port"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
          loading={isLoading}
        >
          Add Port
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name"
          key={'1'}
          rules={[{ required: true, message: 'Port name is required' }]}
        >
          <Input autoComplete='off' placeholder="Enter port name" />
        </Form.Item>
        <Form.Item
          name="location"
          rules={[{ required: true, message: 'Port location is required' }]}
          label="Location"
          key={'2'}
        >
          <GooglePlace onChange={handleSelected} />
        </Form.Item>
        <Form.Item
          name="operator"
          label="Operator"
          rules={[{ required: true, message: 'operator is required' }]}
          key={'3'}
        >
          <Input autoComplete='off' placeholder="Enter Operator" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
