import { DownOutlined } from '@ant-design/icons';
import { Modal, Button, Table, Dropdown, Space, MenuProps } from 'antd';
import {
    ButtonWrap,
    CustomButton,
    DownloadWrapper,
    MainHeader,
    TextWrap,
    HeadingWrapper
} from './style';
import { FC, useMemo, useRef, useState } from 'react';
import Logo from 'src/assets/authAssets/logo.png';
import { useAppSelector, IUser, } from 'src/store';
import { useReportsWarehouseOfftakesQuery } from 'src/store/api';
import { dateTimeFormater, formatNumber, generateSerialNumber } from 'src/utils';
import { TableHeader } from '../style';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import { Text, Title } from 'src/components';

interface IModal {
    open: boolean;
    urlQueryParam: string;
    closeModal: () => void;
}

export const WarehouseOfftakesDownloadModal: FC<IModal> = ({
    open,
    closeModal,
    urlQueryParam
}) => {
    const user = useAppSelector((store) => store.auth.user) as IUser;
    const csvRef = useRef<any>(null);
    const pdfRef = useRef<HTMLDivElement>(null);
    const [showPagination, setShowPagination] = useState(true);

    const { data: getWarehouseOfftakes } =
        useReportsWarehouseOfftakesQuery(urlQueryParam);

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'serialNum',
            key: 'serialNum'
        },
        {
            title: 'Blender (Offtaker)',
            dataIndex: 'offtaker',
            key: 'offtaker'
        },
        {
            title: 'Collateral Manager',
            dataIndex: 'collateralManager',
            key: 'collateralManager'
        },
        {
            title: 'Raw Material',
            dataIndex: 'rawMaterial',
            key: 'rawMaterial'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        }
    ];

    const onDownloadClick = () => {
        csvRef.current?.link.click();
    };



    const warehouseOfftakesReport = useMemo(() => {
        return (
            getWarehouseOfftakes?.data?.map((item, index) => ({
                ...item,
                serialNum: generateSerialNumber(1, index),
                offtaker: item.blender.name,
                key: item.offtaking.id,
                collateralManager: item.collateralManager.name,
                rawMaterial: item.rawMaterial.name,
                quantity: formatNumber(`${item.offtaking.quantity} MT`),
                date: dateTimeFormater(item.offtaking.createdAt),
            })) ?? []
        );
    }, [getWarehouseOfftakes]);

    const reactToPrintContent = () => {
        return pdfRef.current;
    };

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: 'Warehouse Offtakes Reports',
        async onBeforeGetContent() {
            setShowPagination(false);
        },
        onAfterPrint() {
            setShowPagination(true);
        },
        pageStyle: `@media print {
      @page {
        size: A4 landscape;
        margin: 24px;
      }
    }`
    });

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
        },
        {
            key: '2',
            label: (
                <>
                    <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
                    <CSVLink
                        data={warehouseOfftakesReport}
                        asyncOnClick={true}
                        filename={`Warehouse Offtakes Report ${new Date().toUTCString()}.csv`}
                        headers={columns.map((column) => ({
                            label: column.title,
                            key: column.dataIndex
                        }))}
                        ref={csvRef}
                    ></CSVLink>
                </>
            )
        },
    ];

    return (
        <Modal
            open={open}
            closable={false}
            centered
            footer={[
                <ButtonWrap>
                    <Button onClick={closeModal}>Cancel</Button>{' '}
                    <Dropdown
                        menu={{
                            items
                        }}
                    >
                        <Button type={'primary'}>
                            <Space>
                                Download report
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </ButtonWrap>
            ]}
            width={1230}
        >
            <DownloadWrapper ref={pdfRef}>
                <MainHeader>
                    <img src={Logo} alt="logo" />
                </MainHeader>

                <HeadingWrapper>
                    <TextWrap>
                        <Title level={5}>Warehouse Offtakes Reports</Title>
                        <Text>Generated by</Text>
                    </TextWrap>

                    <TextWrap>
                    <Text>{`Date: ${dateTimeFormater(new Date())} `} </Text>
                        <Title level={5}>{user.name}</Title>
                    </TextWrap>
                </HeadingWrapper>

                <TableHeader>
                    <Title level={5}>View Report</Title>
                </TableHeader>
                <Table
                    pagination={
                        showPagination
                            ? {
                                total: getWarehouseOfftakes?.pagination?.totalCount,
                                defaultCurrent: 1,
                                pageSize: 10
                            }
                            : false
                    }
                    columns={columns}
                    dataSource={warehouseOfftakesReport}
                />
            </DownloadWrapper>
        </Modal>
    );
};
