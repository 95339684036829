import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum IndentRequestAction {
  Accept,
  Reject,
  Default
}
const initialState: {
  showUpdateFields: boolean;
  orders: { id: string; quantity: number }[];
  feedback: string;
  isUpdate?: boolean;
  selectedAction: IndentRequestAction;
} = {
  showUpdateFields: false,
  orders: [],
  feedback: '',
  isUpdate: false,
  selectedAction: IndentRequestAction.Default
};
export const indentRequest = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setShowUpdateFields: (state, action: PayloadAction<boolean>) => {
      state.showUpdateFields = action.payload;
      return state;
    },
    setUpdatedValues: (
      state,
      action: PayloadAction<{
        feedback: string;
        orders: { id: string; quantity: number }[];
        isUpdate?: boolean;
      }>
    ) => {
      state.orders = action.payload.orders;
      state.feedback = action.payload.feedback;
      state.isUpdate = action.payload.isUpdate;
      return state;
    },
    setSelectedAction: (state, action: PayloadAction<IndentRequestAction>) => {
      state.selectedAction = action.payload;
      return state;
    },
    setClearIndent: (state) => {
      state = initialState;
      return state;
    }
  }
});

export const {
  setClearIndent,
  setShowUpdateFields,
  setUpdatedValues,
  setSelectedAction
} = indentRequest.actions;
