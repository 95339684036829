import { Input, Typography, Radio, RadioChangeEvent } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { TableHeader, Container, ActionSite, TableBody } from '../style';
import {
  ICollateralManagerBlendingPlant,
  ICollateralManagerPort
} from 'src/store/api';
import { AddCollateralManager } from './AddCollateralManagerModal';
import { CMBlendingPlant } from './blending-plant';
import { DeleteCollateralManager } from './DeleteCollateralManagerModal';
import { EditCollateralManager } from './EditCollateralManagerModal';

import { MANAGER_TYPE } from './enum';
import { CMPortTable } from './port';

type IAddCollateralManager = {
  setAddCollateralManager: Dispatch<SetStateAction<boolean>>;
  addCollateralManager: boolean;
};
const { Title } = Typography;
const { Search } = Input;

export const CollateralManager: FC<IAddCollateralManager> = ({
  setAddCollateralManager,
  addCollateralManager
}) => {
  const [editModal, setEditModal] = useState<
    ICollateralManagerPort | ICollateralManagerBlendingPlant | null
  >(null);
  const [deleteModal, setDeleteModal] = useState<
    ICollateralManagerPort | ICollateralManagerBlendingPlant | null
  >(null);
  const [search, setSearch] = useState('');
  const [managerType, setManagerType] = useState<MANAGER_TYPE>(
    MANAGER_TYPE.PORT
  );
  const handleManagerChange = (e: RadioChangeEvent) => {
    setManagerType(e.target.value);
  };

  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      {addCollateralManager && (
        <AddCollateralManager
          open={addCollateralManager}
          managerType={managerType}
          closeModal={() => setAddCollateralManager(false)}
        />
      )}
      {!!editModal && (
        <EditCollateralManager
          open={!!editModal}
          type={managerType}
          collateralManager={editModal}
          closeModal={() => setEditModal(null)}
        />
      )}
      {!!deleteModal && (
        <DeleteCollateralManager
          open={!!deleteModal}
          type={managerType}
          collateralManager={deleteModal}
          closeModal={() => setDeleteModal(null)}
        />
      )}
      <TableBody>
        <TableHeader>
          <Title level={4}>Collateral Managers</Title>
          <ActionSite>
            <Radio.Group onChange={handleManagerChange} value={managerType}>
              <Radio.Button value={MANAGER_TYPE.PORT}>Ports</Radio.Button>
              <Radio.Button value={MANAGER_TYPE.BLENDING_PLANT}>
                Blending plant
              </Radio.Button>
            </Radio.Group>
            <Search
              onSearch={onSearch}
              onChange={(e) => !e.target.value && setSearch('')}
              placeholder="Search"
              style={{ width: 264 }}
            />
          </ActionSite>
        </TableHeader>
        {managerType === MANAGER_TYPE.PORT ? (
          <CMPortTable
            search={search}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
          />
        ) : (
          <CMBlendingPlant
            search={search}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
          />
        )}
      </TableBody>
    </Container>
  );
};
