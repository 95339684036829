import { Card } from 'antd';
import { FC } from 'react';
import { Text } from 'src/components';
import styled from 'styled-components';

interface Iprops {
  title?: string;
}

export const NpkProduction: FC<Iprops> = () => {
  return (
    <StyledCard title="NPK  Production">
      <TextWrap>
        <Text>No production at this time</Text>
      </TextWrap>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-block-start: 16px;
  width: 100%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 64px;
  background: #fafbfc;
  border-radius: 6px;
`;
