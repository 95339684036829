import { Login, ResetPassword, ForgotPassword } from 'src/pages/auth';

export const auth = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: 'reset-password',
    element: <ResetPassword />
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />
  }
];
