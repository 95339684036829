import { Button, Form, Input, Modal, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IPurchaseOrder, useConfirmVesselArrivalMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  purchaseOrder: IPurchaseOrder;
  closeModal: () => void;
  open: boolean;
}

export const ConfirmVesselArrival: FC<IModal> = ({
  open,
  closeModal,
  purchaseOrder
}) => {
  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(false);
  const name = Form.useWatch('name', form);
  const [confirmVesselArrived, { isLoading }] =
    useConfirmVesselArrivalMutation();

  const handleFinish = () => {
    if (!buttonDisable) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    confirmVesselArrived(purchaseOrder.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Vessel arrival confirmed successfully'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  useEffect(() => {
    setButtonDisable(name !== purchaseOrder.vesselName);
  }, [purchaseOrder, name]);

  return (
    <Modal
      open={open}
      title="Vessel confirmation status"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,

        <Button
          type="primary"
          disabled={buttonDisable}
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Yes, Confirm
        </Button>
      ]}
      width={432}
    >
      <InfoText>
        To continue please confirm that the vessel for purchase order
        <EmphasizedText>{purchaseOrder.vesselName}</EmphasizedText>, arrived by
        entering vessel name.
      </InfoText>
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Form.Item name={'name'} key={1} label={'Vessel name'}>
          <Input placeholder="Enter vessel name" autoComplete="off" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EmphasizedText = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
  white-space: nowrap;
`;
const InfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: rgba(0, 0, 0, 0.85);
`;
