import { Divider, Modal } from 'antd';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import { FC } from 'react';
import { AddFields } from './AddFields';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const AddFieldsModal: FC<IModal> = ({ open, closeModal }) => {
  const [form] = Form.useForm<[]>();
  return (
    <Modal
      open={open}
      onCancel={closeModal}
      onOk={closeModal}
      centered
      title="Add Report"
      footer={[
        <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
          Confirm
        </Button>
      ]}
      width={660}
    >
      <Divider />
      <AddFields form={form} closeModal={closeModal} />
      <Divider />
    </Modal>
  );
};
