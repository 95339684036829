import { FC } from 'react';
import { Column } from '@ant-design/plots';

export const BarChart: FC<any> = (config) => {
  return (
    <Column
      {...config}
      maxColumnWidth={30}
      label={{
        position: 'bottom'
      }}
    />
  );
};
