import { Button, Form, Input, Modal, Select, notification } from 'antd';
import { FC, useState } from 'react';
import { IModal } from 'src/pages/nsia-admin/stakeholders/collateralManager/interface';
import {
  IAddCollateralManagerPlant,
  ICollateralManagerBlendingPlant,
  useEditCollateralManagerBlendingPlantMutation,
  useGetAllSitesQuery
} from 'src/store/api';
import { generateUrl, useDebounce } from 'src/utils';

const { Option } = Select;
export const EditCMBlendingPlant: FC<
  IModal<ICollateralManagerBlendingPlant>
> = ({ open, closeModal, collateralManager }) => {
  const [form] = Form.useForm();

  const [editCollateralManagerPlant, { isLoading }] =
    useEditCollateralManagerBlendingPlantMutation();

  const [input, setInput] = useState('');

  const debouncedInput = useDebounce(input);

  const { data: blendingSites } = useGetAllSitesQuery(
    generateUrl({
      limit: 20,
      search: debouncedInput,
      page: 1
    })
  );
  const handleSubmit = (data: Partial<IAddCollateralManagerPlant>) => {
    const payload: Partial<IAddCollateralManagerPlant> = {};
    if (data.name !== collateralManager.name) {
      payload.name = data.name;
    }
    if (data.phone !== collateralManager.phone) {
      payload.phone = data.phone;
    }
    if (
      JSON.stringify(data.blendingSites) !==
      JSON.stringify(collateralManager.blendingSites)
    ) {
      payload.blendingSites = data.blendingSites;
    }
    editCollateralManagerPlant({ body: payload, id: collateralManager.id })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated collateral manager details'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const handleSearch = (value: string) => {
    setInput(value);
  };
  return (
    <Modal
      visible={open}
      title="Edit Collateral manager"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          onClick={() => form.submit()}
          loading={isLoading}
          htmlType="submit"
          type="primary"
        >
          Update
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
        initialValues={{
          name: collateralManager.name,
          emailAddress: collateralManager.email,
          phonenumber: collateralManager.phone,
          blendingSites: collateralManager.blendingSites.map((item) => item.id)
        }}
      >
        <Form.Item name="name" label="Name" key={'1'}>
          <Input
            autoComplete="off"
            placeholder="Enter Collateral manager name"
          />
        </Form.Item>

        <Form.Item name="emailAddress" label="Email address" key={'3'}>
          <Input autoComplete="off" disabled placeholder="Enter address" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="phonenumber"
          label="Phone number"
          key={'4'}
          rules={[
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input autoComplete="off" placeholder="Enter phone number" />
        </Form.Item>
        <Form.Item name="blendingSites" label="Blending Plants" key={'5'}>
          <Select
            placeholder="Select blending plants"
            mode="tags"
            allowClear
            showSearch
            onSearch={handleSearch}
            filterOption={false}
            onClear={() => setInput('')}
            tokenSeparators={[',']}
          >
            {blendingSites?.data.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
