import { Button, Modal, Form, Input, notification } from 'antd';
import { FC } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import {
  IAddBlendingSitePayload,
  IBlendingSite,
  useEditSiteMutation
} from 'src/store/api';
import GooglePlace from 'src/components/GooglePlace';

interface IModal {
  open: boolean;
  closeModal: () => void;
  siteDetails: IBlendingSite;
}

export const EditSiteModal: FC<IModal> = ({
  open,
  closeModal,
  siteDetails
}) => {
  const [handleEditSite, { isLoading }] = useEditSiteMutation();
  const handleOk = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  const [form] = Form.useForm();
  const handleSelected = async (data: {
    label: string;
    value: { place_id: string };
  }) => {
    const [res] = await geocodeByPlaceId(data.value.place_id);
    form.setFieldValue('location', {
      name: res.formatted_address,
      latitude: res.geometry.location.lat(),
      longitude: res.geometry.location.lng()
    });
  };

  const handleSubmit = (payload: IAddBlendingSitePayload) => {
    const payloadData: Partial<IAddBlendingSitePayload> = {};
    if (payload.name !== siteDetails.name) {
      payloadData.name = payload.name;
    }
    if (
      payload.location.name.toLowerCase() !==
      siteDetails.location.name.toLowerCase()
    ) {
      payloadData.location = payload.location;
    }
    if (!siteDetails.id) {
      return;
    }
    handleEditSite({ body: payloadData, id: siteDetails.id })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated blending site details'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
        closeModal();
      });
  };
  return (
    <>
      <Modal
        open={open}
        title="Edit Blending Site"
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => form.submit()}
          >
            Update
          </Button>
        ]}
        width={660}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            name: siteDetails.name,
            location: { name: siteDetails.location.name }
          }}
          onFinish={handleSubmit}
        >
          <Form.Item name="name" label="Name" key={'1'}>
            <Input placeholder="Enter blending site name" autoComplete='off' />
          </Form.Item>
          <Form.Item name="location" label="Location" key={'2'}>
            <GooglePlace
              defaultInputValue={siteDetails.location.name}
              onChange={handleSelected}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
