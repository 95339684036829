import { Button, Modal, Form, Input, notification } from 'antd';
import { FC } from 'react';
import {
  IAdmin,
  IEditAdminPayload,
  useUpdateAdminMutation
} from 'src/store/api/nsia/admin';

interface IModal {
  open: boolean;
  closeModal: () => void;
  admin: IAdmin;
}

export const UpdateAdminModal: FC<IModal> = ({ open, closeModal, admin }) => {
  const [form] = Form.useForm();

  const [updateAdmin, { isLoading }] = useUpdateAdminMutation();

  const handleSubmit = (values: IEditAdminPayload) => {
    const data: IEditAdminPayload = {};
    if (values.phone !== admin.phone) {
      data.phone = values.phone;
    }
    if (values.name !== admin.name) {
      data.name = values.name;
    }
    updateAdmin({ id: admin.id, data })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated Admin details.'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  return (
    <Modal
      open={open}
      title="Edit admin details"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          loading={isLoading}
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
        >
          Update
        </Button>,
        <Button onClick={closeModal} htmlType="submit">
          Cancel
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        initialValues={{
          name: admin.name,
          email: admin.email,
          phone: admin.phone
        }}
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label="Name" key={'1'}>
          <Input placeholder="Enter full name" autoComplete='off' />
        </Form.Item>

        <Form.Item name="email" label="Email address" key={'3'}>
          <Input disabled placeholder="Enter address" autoComplete='off' />
        </Form.Item>
        <Form.Item
          name="phone"
          hasFeedback
          label="Phone number"
          key={'4'}
          rules={[
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input placeholder="Enter phone number" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};
