import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Table, Modal, MenuProps } from 'antd';

import styled from 'styled-components';

import Logo from 'src/assets/authAssets/logo.png';

import { useMemo, useState, useRef, FC } from 'react';
import shortArrow from 'src/assets/shortArrow.svg';
import { useGetRawMaterialsQuery } from 'src/store/api';
import { Title } from 'src/components';
import { useReactToPrint } from 'react-to-print';
import { Container } from 'src/pages/styles';
import { CSVLink } from 'react-csv';

interface IModal {
  open: boolean;
  closeModal: () => void;
  dataSource: Array<{ [key: string]: string | number }>;
  offTakeSource: Array<{ [key: string]: string | number }>;
  releaseSource: Array<{ [key: string]: string | number }>;
}

export const WarehouseDownloadModal: FC<IModal> = ({
  open,
  closeModal,
  dataSource,
  offTakeSource,
  releaseSource
}) => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const csvRef = useRef<any>(null);

  const { data: rawMaterials } = useGetRawMaterialsQuery();

  const [viewOfftake, setViewOfftake] = useState(true);
  const [viewRelease, setViewRelease] = useState(true);

  const columns = useMemo(() => {
    const yearTitle = {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      key: 'warehouse'
    };

    const dynamicColumn = rawMaterials?.data
      ? rawMaterials.data.map((materials, index) => ({
          title: materials.name,
          dataIndex: materials.id,
          key: index
        }))
      : [];

    const returnData = [yearTitle, ...dynamicColumn];
    return returnData;
  }, [rawMaterials]);

  const reactToPrintContent = () => {
    return pdfRef.current;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Summary Quantity Reports',

    pageStyle: `@media print {
      @page {
        size: A4 landscape;
        margin: 24px;
      }
    }`
  });
  const onDownloadClick = () => {
    csvRef.current?.link.click();
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
    },
    {
      key: '2',
      label: (
        <>
          <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
          <CSVLink
            data={releaseSource}
            asyncOnClick={true}
            filename={`Raw material Deliveries ${new Date().toUTCString()}.csv`}
            headers={columns.map((column) => ({
              label: column.title,
              key: column.dataIndex
            }))}
            ref={csvRef}
          ></CSVLink>
        </>
      )
    }
  ];

  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <ButtonWrap>
          <Button onClick={closeModal}>Cancel</Button>{' '}
          <Dropdown
            menu={{
              items
            }}
          >
            <Button type={'primary'}>
              <Space>
                Download Report
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </ButtonWrap>
      ]}
      width={1230}
    >
      <Container ref={pdfRef}>
        <MainHeader>
          <img src={Logo} alt="logo" />
        </MainHeader>

        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Raw Materials Deliveries</CustomTitleText>
        </HeadingWrapper>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowClassName={(record) =>
            record.warehouse === 'Total' ? 'total-row' : ''
          }
        />

        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Offtake/Sales</CustomTitleText>
          <ClickDiv
            isClicked={viewOfftake}
            onClick={() => setViewOfftake(!viewOfftake)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewOfftake && (
          <Table
            columns={columns}
            dataSource={offTakeSource}
            pagination={false}
            rowClassName={(record) =>
              record.warehouse === 'Total' ? 'total-row' : ''
            }
          />
        )}
        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Release</CustomTitleText>
          <ClickDiv
            isClicked={viewRelease}
            onClick={() => setViewRelease(!viewRelease)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewRelease && (
          <Table
            columns={columns}
            dataSource={releaseSource}
            pagination={false}
            rowClassName={(record) =>
              record.warehouse === 'Total' ? 'total-row' : ''
            }
          />
        )}
      </Container>
    </Modal>
  );
};

const ClickDiv = styled.div<{ isClicked: boolean }>`
  transform: ${({ isClicked }) => (isClicked ? 'rotate(90deg)' : 'rotate(0)')};
  cursor: pointer;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 24px;

  .arrowFlex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

const ButtonWrap = styled.div``;

export const CustomTitleText = styled(Title)`
  margin: 0 !important;
`;

export const CustomButton = styled.div`
  background: #ffffff;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
  padding-inline: 5px;
`;
export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    align-self: center;
    margin-block: 33px 47px;
    object-fit: contain;
  }
`;
