import { Button, Divider, Form, Modal } from 'antd';
import { FC, useState } from 'react';

import { IIndentRequest } from 'src/store/api/indent-request';
import { IndentRequestAction } from 'src/store/slices';
import { AcceptForm } from './AcceptForm';
import { RejectForm } from './RejectForm';

interface IModal {
  open: boolean;
  selectedAction: IndentRequestAction;
  closeModal: () => void;
  indentRequest: IIndentRequest;
}

export const ActionModal: FC<IModal> = ({
  open,
  closeModal,
  selectedAction,
  indentRequest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      title={
        selectedAction === IndentRequestAction.Accept
          ? 'Send release order'
          : 'Reject Request'
      }
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          type={'primary'}
          loading={isLoading}
          key="submit"
          onClick={() => form.submit()}
        >
          {selectedAction === IndentRequestAction.Accept
            ? 'Send release order'
            : 'Continue'}
        </Button>
      ]}
      width={572}
    >
      <Divider />
      {selectedAction === IndentRequestAction.Accept ? (
        <AcceptForm
          closeModal={closeModal}
          form={form}
          setIsLoading={setIsLoading}
          orderId={indentRequest.id}
          indentRequest={indentRequest}
        />
      ) : (
        <RejectForm
          closeModal={closeModal}
          form={form}
          setIsLoading={setIsLoading}
          orderId={indentRequest.id}
          indentRequest={indentRequest}
        />
      )}
      <Divider />
    </Modal>
  );
};
