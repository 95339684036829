import { Card } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import { TextWrap } from 'src/pages/styles';

export const NpkProduction: FC = () => {
  return (
    <StyledCard title="NPK  Production">
      <TextWrap>
        {/* TODO: implement with report system */}
        {/* <RawMaterialName>UREA</RawMaterialName>
        <RawMaterialQuantity>23,000MT</RawMaterialQuantity> */}
      </TextWrap>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  flex: 1;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
