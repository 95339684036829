import { Button, Form, Input, Modal, Select, notification } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { IModal } from 'src/pages/nsia-admin/stakeholders/collateralManager/interface';
import {
  IAddCollateralManagerPort,
  ICollateralManagerPort,
  useEditCollateralManagerPortMutation,
  useGetAllPortsQuery,
  useLazyGetAllWarehousesQuery
} from 'src/store/api';
import { useDebounce, generateUrl } from 'src/utils';

export const EditCMPort: FC<IModal<ICollateralManagerPort>> = ({
  open,
  closeModal,
  collateralManager
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [editCollateralManagerPort, { isLoading }] =
    useEditCollateralManagerPortMutation();
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input);
  const [getPortWarehouses, { data: warehouses }] =
    useLazyGetAllWarehousesQuery();

  const { data: allPorts } = useGetAllPortsQuery(
    generateUrl({
      limit: 20,
      search: debouncedInput,
      page: 1
    })
  );

  const handleSearch = (value: string) => {
    setInput(value);
  };

  const handleSubmit = (data: Partial<IAddCollateralManagerPort>) => {
    const payload: Partial<IAddCollateralManagerPort> = { ...data };
    if (data.name !== collateralManager.name) {
      payload.name = data.name;
    }
    if (data.phone !== collateralManager.phone) {
      payload.phone = data.phone;
    }
    editCollateralManagerPort({ body: payload, id: collateralManager.id })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated collateral manager details'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const prevPort = useRef(form.getFieldValue('port'));

  const handleSelect = (port: string) => {
    if (prevPort.current !== port) {
      form.setFieldsValue({ warehouses: [] });
      prevPort.current = port;
      getPortWarehouses(generateUrl({ port }));
    }
  };

  useEffect(() => {
    getPortWarehouses(generateUrl({ port: collateralManager.port.id }));
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      open={open}
      title="Edit Collateral manager"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          onClick={() => form.submit()}
          htmlType="submit"
          loading={isLoading}
          type="primary"
        >
          Update
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
        initialValues={{
          name: collateralManager.name,
          emailAddress: collateralManager.email,
          phonenumber: collateralManager.phone,
          port: collateralManager.port.id,
          warehouses: collateralManager.warehouses?.map((item) => item.id)
        }}
      >
        <Form.Item name="name" label="Name" key={'1'}>
          <Input
            autoComplete="off"
            placeholder="Enter Collateral manager name"
          />
        </Form.Item>

        <Form.Item name="emailAddress" label="Email address" key={'2'}>
          <Input autoComplete="off" disabled placeholder="Enter address" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="phonenumber"
          label="Phone number"
          key={'3'}
          rules={[
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input placeholder="Enter phone number" autoComplete="off" />
        </Form.Item>

        <Form.Item name="port" label="Port" key={'4'}>
          <Select
            placeholder="Select Ports"
            allowClear
            onSearch={handleSearch}
            showSearch
            onSelect={handleSelect}
            onClear={() => setInput('')}
          >
            {allPorts?.data.map((port) => (
              <Option key={port.id} value={port.id}>
                {port.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="warehouses"
          label="Warehouse"
          rules={[{ required: true, message: 'warehouse is required' }]}
          key={'5'}
        >
          <Select
            placeholder="Select Warehouse"
            mode="tags"
            allowClear
            tokenSeparators={[',']}
          >
            {warehouses?.data.map((warehouse) => (
              <Option key={warehouse.id} value={warehouse.id}>
                {warehouse.code}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
