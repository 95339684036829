import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RangePicker } from 'src/components';
import {
  CustomTitleText,
  HeadingWrapper,
  TitleDiv
} from 'src/pages/reports/style';
import { useAmendmentReportQuery, useGetAllSitesQuery } from 'src/store/api';
import {
  dateTimeFormater,
  endOfMonth,
  formatNumber,
  generateUrl,
  startOfMonth
} from 'src/utils';
import styled from 'styled-components';
import { Container, Wrapper } from './style';
import AmmendReportDownload from './components/AmmendReportDownload';

export const AmendmentReports = () => {
  const [queryParams, setParams] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });
  const navigate = useNavigate();
  const { data: blendingPlants } = useGetAllSitesQuery();
  const [blendingSite, setBlendingSite] = useState('');
  const [page, setPage] = useState(1);
  const [viewModal, setViewModal] = useState(false);

  const { isLoading, data: amendmentReport } = useAmendmentReportQuery(
    generateUrl({
      ...queryParams,
      blendingSiteId: blendingSite,
      page,
      limit: 10
    })
  );

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParams({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };

  const columns = [
    {
      title: 'Raw Material Request ID',
      dataIndex: 'requestID',
      key: 'requestID'
    },
    {
      title: 'Blending SIte',
      dataIndex: 'blendSite',
      key: 'blendSite'
    },
    {
      title: 'Raw material',
      dataIndex: 'rawMaterials',
      key: 'rawMaterials'
    },
    {
      title: 'Initial Request',
      dataIndex: 'initialRequest',
      key: 'initialRequest'
    },
    {
      title: 'Amended Update',
      dataIndex: 'amendUpdate',
      key: 'amendUpdate'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];
  const blendingSiteDownItems = useMemo(() => {
    const obj: {
      [key: string]: {
        label: string;
        key: string;
      };
    } = {
      '': {
        label: 'All Blending Site',
        key: ''
      }
    };
    blendingPlants?.data.forEach((item) => {
      obj[item.id] = {
        label: item.name,
        key: item.id
      };
    });
    setBlendingSite(Object.keys(obj)[0] ?? '');
    return obj;
  }, [blendingPlants]);
  const dataSource = useMemo(() => {
    return (
      amendmentReport?.data.map((report, index) => ({
        key: index,
        requestID: report.indentRequest.releaseId,
        blendSite: report.blendingSite.name,
        rawMaterials: report.rawMaterial.name,
        amendUpdate: formatNumber(report.amendedQuantity),
        initialRequest: formatNumber(report.initialQuantity),
        date: dateTimeFormater(report.date, 'MMM Do, YYYY')
      })) ?? []
    );
  }, [amendmentReport]);
  return (
    <Container>
      {viewModal && (
        <AmmendReportDownload
          open={viewModal}
          closeModal={() => setViewModal(false)}
          dataSource={dataSource}
          columns={columns}
        />
      )}
      <HeadingWrapper>
        <div className="arrowFlex">
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ marginBottom: '10px', cursor: 'pointer' }}
          />
          <CustomTitleText level={4}>Reports</CustomTitleText>
        </div>
        <Button onClick={() => setViewModal(true)}>Download report</Button>
      </HeadingWrapper>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <Wrapper>
          <InfoCardHeader>
            <TitleDiv>
              <h1>
                Amendment <span>Report</span>
              </h1>
            </TitleDiv>

            <ActionContainer>
              <DropdownWrap>
                <Dropdown
                  menu={{
                    items: Object.values(blendingSiteDownItems),
                    onClick: (item) => setBlendingSite(item.key)
                  }}
                >
                  <Button loading={isLoading}>
                    <Space>
                      {blendingSiteDownItems?.[blendingSite]?.label}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </DropdownWrap>
              <RangePicker
                defaultValue={[startOfMonth(), endOfMonth()]}
                onChange={(datejs) => handleDateChange(datejs)}
              />
            </ActionContainer>
          </InfoCardHeader>

          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: amendmentReport?.pagination.totalCount ?? 0,
              onChange: (page) => {
                setPage(page);
              }
            }}
          />
        </Wrapper>
      </Spin>
    </Container>
  );
};
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  align-items: center;
`;
const DropdownWrap = styled.div`
  margin-right: 20px;
`;
const InfoCardHeader = styled.div`
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 78px;
  display: flex;
  align-items: center;
  padding: 2px 24px;
`;
