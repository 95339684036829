import { PaginatedResponse, Response } from 'src/store/genericInterface';
import { baseApi } from 'src/store/api/baseApi';
import { tagTypes } from 'src/store/api/baseApi/tagTypes';
import {
  IConfirmPurchaseOrder,
  ICreatePurchaseOrder,
  IDischargeVessel,
  IFetchDischargeOrder,
  IPurchaseOrder,
  IResolveConflict,
  ITrackDischarge,
  IUploadImage,
  IUploadStatement
} from './interface';
import { ICollateralManagerPort } from '../nsia';

const purchaseOrderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPurchaseOrder: builder.mutation<
      Response<IPurchaseOrder>,
      ICreatePurchaseOrder
    >({
      query: (payload) => ({
        url: `/purchase-orders`,
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.PurchaseOrder }]
    }),
    uploadPaar: builder.mutation<Response<IPurchaseOrder>, IUploadImage>({
      query: (payload) => ({
        url: `/purchase-orders/${payload.id}/upload-paar`,
        method: 'POST',
        data: payload.files
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: tagTypes.PurchaseOrder },
        { type: tagTypes.PurchaseOrder, id: arg.id }
      ]
    }),
    confirmVesselArrival: builder.mutation<Response<null>, string>({
      query: (id) => ({
        url: `/purchase-orders/${id}/confirm-vessel`,
        method: 'POST'
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: tagTypes.PurchaseOrder },
        { type: tagTypes.PurchaseOrder, id: arg }
      ]
    }),
    dischargeVessels: builder.mutation<
      Response<IPurchaseOrder>,
      IDischargeVessel
    >({
      query: (payload) => ({
        url: `/purchase-orders/${payload.id}/discharge-vessel`,
        method: 'POST',
        data: payload.data
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: tagTypes.PurchaseOrder },
        { type: tagTypes.PurchaseOrder, id: arg.id }
      ]
    }),
    getAllPurchaseOrder: builder.query<
      PaginatedResponse<IPurchaseOrder>,
      string | void
    >({
      query: (query = '') => ({
        url: `/purchase-orders?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.PurchaseOrder }]
    }),
    getSinglePurchaseOrder: builder.query<Response<IPurchaseOrder>, string>({
      query: (purchaseId) => ({
        url: `/purchase-orders/${purchaseId}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, purchaseId) => [
        { type: tagTypes.PurchaseOrder, id: purchaseId }
      ]
    }),
    getCollateralManagerByPortId: builder.query<
      PaginatedResponse<ICollateralManagerPort>,
      string
    >({
      query: (portId) => ({
        url: `/utilities/ports/${portId}/collateral-managers`,
        method: 'GET'
      }),
      providesTags: (_result, _err, arg) => [{ type: tagTypes.Ports, id: arg }]
    }),
    getDischargeOrders: builder.query<
      PaginatedResponse<ITrackDischarge>,
      IFetchDischargeOrder
    >({
      query: (payload) => ({
        url: `/purchase-orders/${payload.purchaseId}/discharged-raw-materials`,
        method: 'GET'
      }),
      providesTags: (_result, _err, arg) => [
        { type: tagTypes.PurchaseOrder, id: arg.purchaseId }
      ]
    }),
    resolveConflict: builder.mutation<
      Response<IPurchaseOrder>,
      IResolveConflict
    >({
      query: (payload) => ({
        url: `/purchase-orders/${payload.id}/handle-flagged/${payload.dischargeOrderId}`,
        method: 'PATCH',
        data: payload.data
      }),
      invalidatesTags: [{ type: tagTypes.PurchaseOrder }]
    }),
    confirmPurchaseOrder: builder.mutation<
      Response<IPurchaseOrder>,
      IConfirmPurchaseOrder
    >({
      query: (payload) => ({
        url: `/discharged-raw-materials/${payload.id}/confirm-order`,
        method: 'PATCH',
        data: payload.data
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: tagTypes.PurchaseOrder },
        { type: tagTypes.Stock },
        { type: tagTypes.PurchaseOrder, id: arg.purchaseId }
      ]
    }),
    clearVesselClearingAgent: builder.mutation<
      Response<IPurchaseOrder>,
      IUploadStatement
    >({
      query: (payload) => ({
        url: `/purchase-orders/${payload.id}/clear-vessel`,
        method: 'POST',
        data: payload.files
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: tagTypes.PurchaseOrder },
        { type: tagTypes.PurchaseOrder, id: arg.id }
      ]
    })
  })
});

export const {
  useCreatePurchaseOrderMutation,
  useGetAllPurchaseOrderQuery,
  useUploadPaarMutation,
  useGetSinglePurchaseOrderQuery,
  useConfirmVesselArrivalMutation,
  useGetCollateralManagerByPortIdQuery,
  useLazyGetCollateralManagerByPortIdQuery,
  useDischargeVesselsMutation,
  useResolveConflictMutation,
  useClearVesselClearingAgentMutation,
  useConfirmPurchaseOrderMutation,
  useGetDischargeOrdersQuery
} = purchaseOrderApi;
