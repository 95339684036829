import { Button, Col, Divider, Modal, Row, Typography } from 'antd';
import { FC } from 'react';
import { ConfirmCardContainer } from 'src/pages/nsia-admin/purchaseOrder/styles';
import {
  IIndentRequest,
  IndentRequestStatusConfig
} from 'src/store/api/indent-request';
import styled from 'styled-components';

const { Text } = Typography;
interface IModal {
  open: boolean;
  indentRequest: IIndentRequest;
  closeModal: () => void;
}
export const ViewCommentModal: FC<IModal> = ({
  open,
  closeModal,
  indentRequest
}) => {
  const status = IndentRequestStatusConfig[indentRequest.status];
  return (
    <Modal
      open={open}
      title="Comment"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      width={660}
      footer={[
        <Button onClick={closeModal} htmlType="submit" type="default">
          Cancel
        </Button>
      ]}
    >
      <Divider />
      <ConfirmCardContainer>
        <div className="site-card-wrapper">
          {status.name === IndentRequestStatusConfig.rejected.name ? (
            <CommentDetailsSection>
              <Text>Reason for disapproval</Text>
              <CommentDetails>
              {indentRequest?.feedback??'No comment'}
              </CommentDetails>
            </CommentDetailsSection>
          ) : (
            <>
              {indentRequest?.orders.map((order, index) => {
                return (
                  <Row gutter={16} key={index}>
                    <Col span={12}>
                      <Text>Request</Text>
                      <Divider />
                      <MaterialDetails>
                        <Text disabled>{`${order.rawMaterial.name}:`}</Text>
                        <Text strong>{order.estimatedQuantity}</Text>
                      </MaterialDetails>
                    </Col>

                    <Col span={12}>
                      <Text>Update</Text>
                      <Divider />
                      <MaterialDetails>
                        <Text disabled>{`${order.rawMaterial.name}:`}</Text>
                        <Text strong>{order.previousQuantity}</Text>
                      </MaterialDetails>
                    </Col>
                  </Row>
                );
              })}
              <CommentDetailsSection>
                <Text strong>Comment</Text>
                <CommentDetails>
                  {indentRequest?.feedback??'No comment'}
                </CommentDetails>
              </CommentDetailsSection>
              <Divider />
            </>
          )}
        </div>
      </ConfirmCardContainer>
    </Modal>
  );
};

const MaterialDetails = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  width: 100;
  padding: 12px 0;
  text-align: center;
  margin-block: 5px;
`;

const CommentDetailsSection = styled.div`
  margin: 24px 0;
`;
const CommentDetails = styled.p`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 13px;
  min-height: 98px;
  padding: 5px 12px;
  background: #f5f5f5;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
`;
