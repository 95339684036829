import { FC } from 'react';
import { Form, Select, Row, Col } from 'antd';
import { useGetRawMaterialsQuery } from 'src/store/api';
import { NumberInput } from 'src/components';
const { Option } = Select;

export const SelectRawMaterial: FC = () => {
  const { data: rawMaterials } = useGetRawMaterialsQuery();

  const orders = [{ rawMaterial: '', estimatedQuantity: '' }];
  return (
    <Form.List name={'orders'} initialValue={orders}>
      {(fields) => (
        <>
          {fields.map((field, index) => (
            <Row
              key={index}
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              align="middle"
            >
              <Col span={11}>
                <Form.Item
                  {...field}
                  name={[field.name, 'rawMaterial']}
                  label={'Raw Material'}
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <Select placeholder="Select Raw Material" allowClear>
                    {rawMaterials?.data.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name={[field.name, 'estimatedQuantity']}
                  fieldKey={[field.key, 'estimatedQuantity']}
                  label="Estimated Quantity (MT)"
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <NumberInput
                    type={'number'}
                    placeholder=" Enter quantity"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </>
      )}
    </Form.List>
  );
};
