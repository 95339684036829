import { Button, Tabs, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ClearingAgents } from './clearingAgents';
import { useState } from 'react';
import { CollateralManager } from './collateralManager';
import { Blenders } from './blenders';
import { useAppSelector } from 'src/store';
import { AdminType, IAdmin } from 'src/store/api/nsia/admin';
const { Title } = Typography;

export const Stakeholders = () => {
  const [addClearingAgent, setAddClearingAgent] = useState(false);
  const [addCollateralManager, setAddCollateralManager] = useState(false);
  const [addBlenders, setAddBlenders] = useState(false);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const user = useAppSelector((store) => store.auth.user!) as IAdmin;
  const onChange = (key: string) => {
    navigate(`/stakeholders#${key}`);
  };
  const operations = {
    '#clearing-agent': (
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setAddClearingAgent(true)}
      >
        Add Clearing agents
      </Button>
    ),
    '#collateral-manager': (
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setAddCollateralManager(true)}
      >
        Add Collateral manager
      </Button>
    ),
    '#blenders': (
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setAddBlenders(true)}
      >
        Add Blenders
      </Button>
    )
  };
  type operationsKey = keyof typeof operations;
  return (
    <>
      <CustomHeader>
        <Title>Stakeholders</Title>
      </CustomHeader>

      <CustomTab
        tabBarExtraContent={
          user.type === AdminType.SUPER_ADMIN
            ? operations[(hash as operationsKey) || '#clearing-agent']
            : undefined
        }
        defaultActiveKey="1"
        onChange={onChange}
        items={[
          {
            label: `Clearing Agent`,
            key: 'clearing-agent',
            children: (
              <ClearingAgents
                setAddClearingAgent={setAddClearingAgent}
                addClearingAgent={addClearingAgent}
              />
            )
          },
          {
            label: `Collateral manager`,
            key: 'collateral-manager',
            children: (
              <CollateralManager
                setAddCollateralManager={setAddCollateralManager}
                addCollateralManager={addCollateralManager}
              />
            )
          },
          {
            label: `Blenders`,
            key: 'blenders',
            children: (
              <Blenders
                addBlenders={addBlenders}
                setAddBlenders={setAddBlenders}
              />
            )
          }
        ]}
      />
    </>
  );
};

const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
