import { Space, Table, Input } from 'antd';
import { Typography } from 'antd';
import { AddPortModal } from './component/AddPortModal';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { DeletePortModal } from './component/DeletePortModal';
import { EditPortModal } from './component/EditPortModal';
import { TableHeader, Container, ActionItem } from '../styles';
import { IPort, useGetAllPortsQuery } from 'src/store/api';
import { generateUrl } from 'src/utils';

type IAddPortModal = {
  setAddPortModalVisible: Dispatch<SetStateAction<boolean>>;
  addPortModalVisible: boolean;
};
const { Title } = Typography;
const { Search } = Input;
export const Ports: FC<IAddPortModal> = ({
  setAddPortModalVisible,
  addPortModalVisible
}) => {
  const [deleteModal, setDeleteModal] = useState<IPort | null>(null);
  const [editModal, setEditModal] = useState<IPort | null>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [clickedPort] = useState({
    name: '',
    location: '',
    operator: ''
  });
  const { data: getPorts } = useGetAllPortsQuery(
    generateUrl({
      limit: 10,
      search,
      page
    })
  );
  const ports = useMemo(() => {
    return (
      getPorts?.data.map((item) => ({
        key: item.id,
        ...item,
        locationName: item.location.name
      })) ?? []
    );
  }, [getPorts]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'location'
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      key: 'operator'
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_: unknown, record: IPort) => (
        <Space size="small">
          <ActionItem
            onClick={() => {
              setEditModal(record);
            }}
          >
            Edit
          </ActionItem>
          |
          <ActionItem
            color={'rgba(245, 34, 45, 1)'}
            onClick={() => {
              setDeleteModal(record);
            }}
          >
            Delete
          </ActionItem>

        </Space>
      )
    }
  ];

  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Container>
      {addPortModalVisible && (
        <AddPortModal
          open={addPortModalVisible}
          closeModal={() => setAddPortModalVisible(false)}
          portDetails={clickedPort}
        />
      )}
      {!!editModal && (
        <EditPortModal
          open={!!editModal}
          portDetails={editModal}
          closeModal={() => setEditModal(null)}
        />
      )}
      {!!deleteModal && (
        <DeletePortModal
          open={!!deleteModal}
          portDetails={deleteModal}
          closeModal={() => setDeleteModal(null)}
        />
      )}

      <TableHeader>
        <Title level={4}>Ports</Title>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          onChange={(e) => !e.target.value && setSearch('')}
          style={{ width: 264 }}
        />
      </TableHeader>
      <Table
        pagination={{
          defaultCurrent: 1,
          pageSize: 10,
          total: getPorts?.pagination?.totalCount,
          onChange: (page) => {
            setPage(page);
          }
        }}
        columns={columns}
        dataSource={ports}
      />
    </Container>
  );
};
