import { FC } from 'react';
import styled from 'styled-components';
import bro from 'src/assets/Bro.svg';

export const ComingSoon: FC = () => {
  return (
    <Container>
      <Wrap>
        <ComingSoonImg src={bro} />
        <ComingSoonText>Coming soon!</ComingSoonText>
      </Wrap>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrap = styled.div``;
const ComingSoonImg = styled.img`
  width: 431.51px;
  height: 383.48px;
`;
const ComingSoonText = styled.h6`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #8c8c8c;
  text-align: center;
  margin: 50px 0 0 0;
`;
