import {
  ApprovedIndentOrder,
  DailyProducts,
  Dashboard,
  IndentOrders
} from 'src/pages/blender';
import {
  ProductionSummaryReport,
  RawMaterialReceipt,
  RawMaterialUsage,
  ReleaseReports
} from 'src/pages/reports';
import { AmendmentReports } from 'src/pages/reports/AmendmentReports';
import MasterReport from 'src/pages/reports/MasterReport';

export const blender = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/raw-material-request',
    element: <IndentOrders />
  },
  {
    path: '/raw-material-request/:id',
    element: <ApprovedIndentOrder />
  },
  {
    path: '/raw-material-reciepts',
    element: <RawMaterialReceipt />
  },
  {
    path: '/raw-material-usage',
    element: <RawMaterialUsage />
  },
  {
    path: '/release-reports',
    element: <ReleaseReports />
  },

  {
    path: '/daily-productions',
    element: <DailyProducts />
  },
  {
    path: '/reports',
    element: <MasterReport />
  },
  {
    path: '/reports/raw-material-usage',
    element: <RawMaterialUsage />
  },

  {
    path: '/reports/amendment-reports',
    element: <AmendmentReports />
  },
  {
    path: '/reports/release-reports',
    element: <ReleaseReports />
  },
  {
    path: '/reports/production-summary-report',
    element: <ProductionSummaryReport />
  }
];
