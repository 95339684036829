import { Button, Modal, Form, Input, Select, notification } from 'antd';
import { FC, useState } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { GooglePlace } from 'src/components';
import {
  IAddWarehousePayload,
  useAddWarehouseMutation,
  useGetAllPortsQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const AddWarehouseModal: FC<IModal> = ({ open, closeModal }) => {
  const [handleAddWarehouse, { isLoading }] = useAddWarehouseMutation();
  const [nameValue, setNameValue] = useState('');
  const [code, setCode] = useState('');
  const { Option } = Select;
  const [form] = Form.useForm();

  const handleSelected = async (data: {
    label: string;
    value: { place_id: string };
  }) => {
    const [res] = await geocodeByPlaceId(data.value.place_id);
    form.setFieldValue('location', {
      name: res.formatted_address,
      latitude: res.geometry.location.lat(),
      longitude: res.geometry.location.lng()
    });
  };

  const handleSubmit = (payload: IAddWarehousePayload) => {
    handleAddWarehouse(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added warehouse.'
        });
        form.resetFields();
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const { data: getPorts } = useGetAllPortsQuery(generateUrl({}));

  return (
    <>
      <Modal
        open={open}
        title="Add warehouse"
        onOk={closeModal}
        onCancel={closeModal}
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
          >
            Add Warehouse
          </Button>
        ]}
        width={660}
      >
        <Form layout={'vertical'} form={form} onFinish={handleSubmit}>
          <Form.Item
            name="name"
            label="Name"
            key={'1'}
            rules={[{ required: true, message: 'Warehouse name is required' }]}
          >
            <Input
              placeholder="Enter Warehouse Name"
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="code"
            label="Warehouse code"
            key={'1'}
            rules={[{ required: true, message: 'Warehouse code is required' }]}
          >
            <Input
              placeholder="Enter Warehouse code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            key={'2'}
            rules={[
              { required: true, message: 'Warehouse location is required' }
            ]}
          >
            <GooglePlace onChange={handleSelected} />
          </Form.Item>
          <Form.Item
            name="port"
            label="Port"
            key={'3'}
            rules={[{ required: true, message: 'Port name is required' }]}
          >
            <Select placeholder="Select Port" allowClear>
              {getPorts?.data.map((port, index) => (
                <Option key={index} value={port.id}>
                  {port.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
