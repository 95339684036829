export const tagTypes = {
  Ports: 'Ports',
  Warehouses: 'Warehouses',
  Sites: 'Sites',
  ClearingAgent: 'ClearingAgent',
  Blender: 'Blender',
  CollateralManagerPort: 'CollateralManagerPort',
  Admin: 'Admin',
  Chart: 'Chart',
  AdminManagement: 'AdminManagement',
  BlendingPlants: 'BlendingPlants',
  CollateralManagerBlendingPlant: 'CollateralManagerBlendingPlant',
  RawMaterials: 'RawMaterials',
  PurchaseOrder: 'PurchaseOrder',
  IndentRequest: 'IndentRequest',
  TrackOffTaking: 'TrackOffTaking',
  AmendedOrders: 'AmendedOrders',
  Stock: 'Stock',
  Stats: 'Stats',
  ClearingAgentStats: 'ClearingAgentStats',
  CMBlendingReports: 'CMBlendingReports',
  NPKS: 'npk',
  BlenderStats: 'BlenderStats',
  RawMaterialsReciepts: 'RawMaterialsReciepts',
  NsiaReports: 'NsiaReports',
  RawMaterialUsage: 'RawMaterialUsage',
  ProductionSummary: 'ProductionSummary',
  ReportsWarehouse: 'ReportsWarehouse',
  WarehouseReceipts: 'WarehouseReceipts',
  WarehouseStockSummary: 'WarehouseStockSummary',
  Notifications: 'Notifications',
  NotificationsCount: 'NotificationsCount',
  MasterReport: 'MasterReport',
  WarehouseSummary: 'WarehouseSummary',
  Suppliers: 'Suppliers',
  WarehouseStock: 'WarehouseStock'
};
