import { FC } from 'react';
import {
  ICollateralManagerBlendingPlant,
  ICollateralManagerPort
} from 'src/store/api';
import { EditCMBlendingPlant } from './blending-plant';
import { MANAGER_TYPE } from './enum';
import { IModalWithType } from './interface';
import { EditCMPort } from './port';

export const EditCollateralManager: FC<IModalWithType> = ({
  type,
  collateralManager,
  ...rest
}) => {
  return type === MANAGER_TYPE.PORT ? (
    <EditCMPort
      collateralManager={collateralManager as ICollateralManagerPort}
      {...rest}
    />
  ) : (
    <EditCMBlendingPlant
      collateralManager={collateralManager as ICollateralManagerBlendingPlant}
      {...rest}
    />
  );
};
