import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Collapse,
  Form,
  FormListFieldData,
  Input,
  Row,
  UploadFile
} from 'antd';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DraggerDiv } from './Logistics';
import { IFileUpload } from './interface';
import { fileSizeInMegabytes } from 'src/utils/uploadToCloudinary';

const { Panel } = Collapse;

interface IPanelProps {
  field: FormListFieldData;
  index: number;
  remove: (index: number) => void;
  onchange: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}

const LogisticsFieldPanel: React.FC<IPanelProps> = ({
  field,
  index,
  remove,
  onchange,
  value
}) => {
  const [fileList, setFileList] = useState<UploadFile<File>[]>();
  const onRemove = (file: UploadFile) => {
    if (!fileList) return;
    const newFileList = fileList.filter(
      (existingFile) => existingFile.uid !== file.uid
    );
    setFileList(newFileList);
  };

  const beforeUpload = (file: RcFile) => {
    if (fileList) {
      setFileList([...fileList, file]);
    } else {
      setFileList([file]);
    }
    return false;
  };
  const fileSize = fileSizeInMegabytes(fileList);

  return (
    <PanelContainer>
      <Collapse
        accordion
        onChange={(activeKey) => onchange(Number(activeKey))}
        expandIconPosition="end"
        style={{ marginBottom: '10px', width: '95%' }}
        activeKey={value === index ? String(index) : undefined}
      >
        <Panel header={`Logistics company ${index + 1}`} key={index}>
          <StyledPanel>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item
                  label="Transporter"
                  {...field}
                  name={[field.name, 'transporter']}
                  rules={[
                    {
                      required: true,
                      message: 'Transporter is required'
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter transporter name"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...field}
                  name={[field.name, 'contactPerson']}
                  label="Contact Person"
                  rules={[
                    {
                      required: true,
                      message: 'Contact Person`s name is required'
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter contact person's name"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item
                  {...field}
                  name={[field.name, 'documents']}
                  label="Upload Consent letter ( .PDF, PNG, JPG | 10MB)"
                  rules={[
                    {
                      required: true,
                      message: `Documents Should not exceed 10MB, File size is ${fileSize}MB `,
                      validator(_, _value: IFileUpload) {
                        if (fileSize && fileSize > 10) {
                          return Promise.reject(
                            new Error('File Size too Large')
                          );
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Dragger
                    beforeUpload={beforeUpload}
                    onRemove={(file) => onRemove(file)}
                    accept=".pdf,  .jpg, .jpeg, .png"
                  >
                    <DraggerDiv>
                      <UploadOutlined />
                      <p>Click to Upload</p>
                    </DraggerDiv>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  {...field}
                  name={[field.name, 'phone']}
                  label="Phone number"
                  rules={[
                    {
                      required: true,
                      pattern: /^((\+234)|0)[0-9]{10}$/,
                      message: 'please enter a valid number'
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter Contact persons phone number"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
          </StyledPanel>
        </Panel>
      </Collapse>
      <CloseCircleOutlined
        style={{
          fontSize: '1.8rem',
          color: '#FF7875',
          position: 'absolute',
          right: '15px',
          top: '20px'
        }}
        onClick={() => remove(index)}
      />
    </PanelContainer>
  );
};

export default LogisticsFieldPanel;

const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const PanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 15px;
  width: 100%;
  position: relative;
  padding-right: 20px;
`;
