import { Button, Modal, notification, Typography } from 'antd';
import { FC } from 'react';
import {
  CustomSpace,
  CustomTable
} from 'src/pages/clearingAgents/purchaseOrder/styles';
import {
  IPurchaseOrder,
  useDischargeVesselsMutation,
  useGetAllWarehousesQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';
import { IDischargeRawMaterial } from './interface';
const { Text } = Typography;

interface IModal {
  open: boolean;
  summary: { summaryDetails: IDischargeRawMaterial[]; portId: string };
  closeSummaryModal: () => void;
  purchaseOrder: IPurchaseOrder;
}
export const DischargeVesselsSummaryModal: FC<IModal> = ({
  open,
  closeSummaryModal,
  summary,
  purchaseOrder
}) => {
  const [handleDischargeVessel, { isLoading }] = useDischargeVesselsMutation();

  const { data: warehouses } = useGetAllWarehousesQuery(
    generateUrl({ port: summary.portId, limit: 100 })
  );

  const handleSubmit = () => {
    const payload = {
      id: purchaseOrder.id,
      data: { dischargeOrders: summary.summaryDetails }
    };
    handleDischargeVessel(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully discharged a vessel'
        });
        closeSummaryModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const selectedItems = summary.summaryDetails.map((selectedItem) => {
    const warehouse = warehouses?.data.find(
      (warehouseItem) => warehouseItem.id === selectedItem.warehouse
    );
    const rawMaterial = purchaseOrder.orders.find(
      (items) => selectedItem.rawMaterial === items.rawMaterial.id
    );
    return {
      warehouseName: warehouse?.name ?? 'N/A',
      port: warehouse?.port?.name ?? 'N/A',
      rawMaterial: rawMaterial?.rawMaterial.name,
      quantity: selectedItem.quantityDischarged
    };
  });

  const columns = [
    {
      title: 'Raw materials',
      dataIndex: 'rawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port'
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouseName',
      key: 'warehouse'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ];
  return (
    <Modal
      open={!!open}
      title="Discharge Vessel  Summary"
      onOk={closeSummaryModal}
      onCancel={closeSummaryModal}
      centered
      footer={[
        <Button onClick={closeSummaryModal}>Back</Button>,

        <Button
          onClick={() => handleSubmit()}
          htmlType="submit"
          type="primary"
          loading={isLoading}
        >
          Yes, Discharge
        </Button>
      ]}
      width={660}
    >
      <CustomSpace>
        <Text>
          Please carefully check and confirm the details of the discharge below
          before you go ahead, this action cannot be undone.
        </Text>
      </CustomSpace>
      <CustomTable
        columns={columns}
        dataSource={selectedItems}
        pagination={false}
      />
    </Modal>
  );
};
