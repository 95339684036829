import { Card } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useGetCmPortStockQuery } from 'src/store/api/cmport/dashboard';
import { StockChart } from 'src/components';
import { RawMaterialTextColors } from 'src/utils';

export const RawMaterialInStock = () => {
  const { data: cmPortStock } = useGetCmPortStockQuery();
  const totalStock = useMemo(() => {
    return (
      cmPortStock?.data.reduce(
        (acc, current) => acc + Number(current.availableQuantity),
        0
      ) ?? 0
    );
  }, [cmPortStock]);

  return (
    <StyledCard title="Raw materials in stock">
      {cmPortStock?.data.map((rawMaterial, index) => {
        return [
          <StockChart
            rawMaterialName={rawMaterial.name}
            key={index}
            totalStock={totalStock}
            rawMaterialQuantity={rawMaterial.availableQuantity}
            color={RawMaterialTextColors[index % 4]}
          />
        ];
      })}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-block-start: 16px;
  width: 100%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
