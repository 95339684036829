import moment from 'moment';

export const dateTimeFormater = (
  dateTime: string | Date | number,
  format = 'MMM Do, YYYY HH:mm a'
) => {
  return moment(dateTime).format(format);
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
