import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Spin, Table, Tag } from 'antd';
import styled from 'styled-components';
import { ConfirmOfftake, ResolveFlag } from './index';

import { FC, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  OfftakenSummary,
  Search,
  Title,
  ViewShippingDocument
} from 'src/components';
import { CustomHeader, TitleRow, ViewButton } from 'src/pages/styles';
import {
  IIndentRequest,
  IOffTakingDetail,
  OfftakingStatus,
  OfftakingStatusConfig,
  useGetIndentRequestByIdQuery,
  useGetOffTakingDetailsQuery
} from 'src/store/api';
import { dateTimeFormater, formatNumber } from 'src/utils';
import { Container, StatusContainer, TableBody, TableHeader } from '../style';

export const ReleaseOrder: FC = () => {
  const navigate = useNavigate();
  const [, setSearch] = useState('');
  const [openResolveModal, setOpenResolveModal] =
    useState<IOffTakingDetail | null>(null);
  const [openConfirmModal, setOpenConfirmModal] =
    useState<IOffTakingDetail | null>(null);
  const { state } = useLocation();
  const handleView = (record: IOffTakingDetail) => {
    if (record.offTakingStatus === OfftakingStatus['in-transit']) {
      setOpenConfirmModal(record);
    } else if (record.offTakingStatus === OfftakingStatus.FLAGGED) {
      setOpenResolveModal(record);
    }
  };
  const [viewShippingDocuments, setViewShippingDocuments] =
    useState<IIndentRequest | null>(null);

  const [releaseOrder] = useState<IIndentRequest>(
    (state as { releaseOrder: IIndentRequest }).releaseOrder
  );

  const { data: offTakingDetail, isLoading } = useGetOffTakingDetailsQuery(
    releaseOrder?.id
  );
  const { data: indentData } = useGetIndentRequestByIdQuery(releaseOrder.id);

  const dataSource = useMemo(
    () =>
      offTakingDetail?.data.offTakings.map((offtaking, index) => ({
        sn: index + 1,
        key: offtaking._id,
        tableRawMaterial: offtaking.rawMaterial.name,
        tableQuantityDischarged: `${formatNumber(
          offtaking.quantityDischarged
        )}MT`,
        tableQuantityRecieved: offtaking.quantityReceived
          ? `${formatNumber(offtaking.quantityReceived)}MT`
          : 'N/A',
        tableWarehouse: offtaking.warehouse.name,
        truckDriver: offtaking.driverName,
        mobileNumber: offtaking.phone,
        truckId: offtaking.truckNo,
        date: dateTimeFormater(offtaking.createdAt),
        ...offtaking
      })),
    [offTakingDetail]
  );

  const columns = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn'
    },
    {
      title: 'Raw materials',
      dataIndex: 'tableRawMaterial',
      key: 'rawMaterials'
    },
    {
      title: 'Quantity discharged',
      dataIndex: 'tableQuantityDischarged',
      key: 'quantity'
    },
    {
      title: 'Truck Driver',
      dataIndex: 'truckDriver',
      key: 'truckDriver'
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber'
    },
    {
      title: 'Truck ID',
      dataIndex: 'truckId',
      key: 'truckId'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      render: (_: any, record: IOffTakingDetail) => {
        const status = OfftakingStatusConfig[record.offTakingStatus];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
            {record.offTakingStatus === OfftakingStatus['in-transit'] && (
              <ViewButton onClick={() => handleView(record)}>
                {status.action}
              </ViewButton>
            )}
          </StatusContainer>
        );
      }
    }
  ];

  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      {!!openResolveModal && (
        <ResolveFlag
          open={!!openResolveModal}
          closeModal={() => setOpenResolveModal(null)}
          indentRequestId={releaseOrder.id}
          offtakeDetails={openResolveModal}
        />
      )}
      {!!openConfirmModal && (
        <ConfirmOfftake
          open={!!openConfirmModal}
          closeModal={() => setOpenConfirmModal(null)}
          offtakeDetails={openConfirmModal}
        />
      )}
      <CustomHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Release order</Breadcrumb.Item>
          <Breadcrumb.Item>{releaseOrder.releaseId}</Breadcrumb.Item>
        </Breadcrumb>
        <AlterTitleRow>
          <Title level={3}>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginRight: '17px' }}
            />
            Release order - {releaseOrder.releaseId}
          </Title>
          <Button onClick={() => setViewShippingDocuments(releaseOrder)}>
            View document
          </Button>
        </AlterTitleRow>
      </CustomHeader>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <Container>
          <OfftakenSummary
            offTakingDetail={offTakingDetail?.data}
            orders={indentData?.data.orders!}
          />
          <TableBody>
            <TableHeader>
              <Title level={4}>Discharge tracking</Title>
              <Search
                placeholder="Search"
                onSearch={onSearch}
                onChange={(e) => !e.target.value && setSearch('')}
                style={{ width: 264 }}
              />
            </TableHeader>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </TableBody>
        </Container>
      </Spin>
      {!!viewShippingDocuments && (
        <ViewShippingDocument
          open={!!viewShippingDocuments}
          closeModal={() => setViewShippingDocuments(null)}
          shippingDocuments={viewShippingDocuments}
        />
      )}
    </>
  );
};

const AlterTitleRow = styled(TitleRow)`
  margin-block: 22px;
  h3 {
    margin-block: 0;
  }
`;
