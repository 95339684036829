import {
  Body,
  CardDiv,
  CardRow,
  Container,
  CustomHeader,
  SideDetails
} from './style';
import StakeholdersIcon from 'src/assets/dashboardIcons/stakeholdersIcon.svg';
import TrucksIcon from 'src/assets/dashboardIcons/trucksIcon.svg';
import { AnalyticsCard } from 'src/components';
import {
  NpkProduction,
  RawMaterialsDistribution,
  RecentRequests,
  RawMaterialInStock
} from './components';
import { Typography } from 'antd';
import { useGetCmBlenderStatsQuery } from 'src/store/api/cmBlender/dashboard';
import { useAppSelector } from 'src/store';
import { ICollateralManagerBlendingPlant } from 'src/store/api';
const { Title } = Typography;

export const Dashboard = () => {
  const { data: getCmBlenderStats } = useGetCmBlenderStatsQuery();
  const user = useAppSelector((state)=> state.auth.user as ICollateralManagerBlendingPlant)  
  return (
    <Container>
      <CustomHeader>
        <Title>Collateral Manager- {user.blendingSite.name} </Title>
      </CustomHeader>
      <Body>
        <CardDiv>
          <RawMaterialsDistribution />
          <SideDetails>
            <CardRow>
              <AnalyticsCard
                width="48.5%"
                title="Total release"
                icon={StakeholdersIcon}
                value={getCmBlenderStats?.data.totalReleased}
              />
              <AnalyticsCard
                width="48.5%"
                title="Goods in transit"
                icon={TrucksIcon}
                value={getCmBlenderStats?.data.goodsInTransit}
              />
            </CardRow>
            <NpkProduction title={'NPK  Production'} />
            <RawMaterialInStock />
          </SideDetails>
        </CardDiv>
        <RecentRequests />
      </Body>
    </Container>
  );
};
