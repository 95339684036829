import { useEffect, useMemo, useState } from 'react';
import {
  IBlendingSitesRawMaterialDistribution,
  useGetAllSitesQuery,
  useLazyGetAdminChartQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';

type IParams = {
  startDate: string;
  endDate: string;
};
export const useGetBlendingSiteChartData = ({
  startDate,
  endDate
}: IParams) => {
  const { data: blendingSites, isLoading: isBlendingSiteLoading } =
    useGetAllSitesQuery();
  const [blendingSite, setBlendingSite] = useState<string>('');

  const [getAdminChart, { data: adminChart }] = useLazyGetAdminChartQuery();

  const blendingSiteDropDownItems = useMemo(() => {
    const obj: {
      [key: string]: {
        label: string;
        key: string;
      };
    } = {};
    blendingSites?.data.forEach((item) => {
      obj[item.id] = {
        label: item.name,
        key: item.id
      };
    });
    setBlendingSite(Object.keys(obj)[0] ?? '');
    return obj;
  }, [blendingSites]);
  const data = useMemo(() => {
    const res: {
      name: string;
      rawMaterial: string;
      amount: number;
    }[] = [];
    adminChart?.data.forEach((item) => {
      const data = item as IBlendingSitesRawMaterialDistribution;
      res.push({
        name: 'RELEASED',
        rawMaterial: item.name,
        amount: data.released
      });
      res.push({
        name: 'OFFTAKE',
        rawMaterial: item.name,
        amount: data.offtaken
      });
    });
    return res;
  }, [adminChart]);
  const blendingSiteChartConfig = {
    data,
    isGroup: true,
    xField: 'rawMaterial',
    yField: 'amount',
    seriesField: 'name',
    color: ['#52C41A', '#EB2F96'],
    marginRatio: 0.4
  };
  useEffect(() => {
    if (blendingSite && startDate && endDate) {
      getAdminChart(
        generateUrl({
          blendingSite,
          startDate,
          endDate
        })
      );
    }
    // eslint-disable-next-line
  }, [startDate, endDate, blendingSite]);
  return {
    blendingSite,
    isBlendingSiteLoading,
    blendingSiteChartConfig,
    setBlendingSite,
    blendingSiteDropDownItems
  };
};
