import { PaginatedResponse, Response } from 'src/store/genericInterface';
import { baseApi } from '../baseApi';
import { tagTypes } from '../baseApi/tagTypes';
import { INotification } from './interface';

const notificationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query<
      PaginatedResponse<INotification>,
      string | void
    >({
      query: (query) => ({
        url: `/notifications?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.Notifications, query }
      ]
    }),
    getUnreadNotificationsCount: builder.query<
      Response<{
        count: number;
        category: string;
      }>,
      string
    >({
      query: (payload) => ({
        url: `/notifications/count?${payload}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, _query) => [
        { type: tagTypes.NotificationsCount }
      ]
    }),
    updateNotification: builder.mutation<
      Response<INotification>,
      { notifications: string[] }
    >({
      query: (payload) => ({
        url: `/notifications`,
        method: 'PUT',
        data: payload
      }),
      invalidatesTags: (_result, _err) => [
        { type: tagTypes.Notifications },
        { type: tagTypes.NotificationsCount }
      ]
    })
  })
});

export const {
  useGetAllNotificationsQuery,
  useGetUnreadNotificationsCountQuery,
  useUpdateNotificationMutation
} = notificationsApi;
