import { Space, Spin, Table, Tag } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, Title } from 'src/components';
import {
  Container,
  TableBody,
  TableHeader
} from 'src/pages/blender/indent/style';
import { ViewButton } from 'src/pages/styles';
import { useAppSelector } from 'src/store';
import {
  IIndentRequest,
  IndentRequestStatusConfig,
  useGetAllIndentRequestQuery
} from 'src/store/api/indent-request';
import { dateTimeFormater, generateUrl } from 'src/utils';

export const ReleaseTable = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const { role } = useAppSelector((state) => state.auth.user!);
  const { hash } = useLocation();
  const status = useMemo(() => hash.replace('#', ''), [hash]);
  const queryParams = useMemo(
    () =>
      generateUrl({
        search,
        status
      }),
    [search, status]
  );
  const { data: getIndentRequests, isLoading } =
    useGetAllIndentRequestQuery(queryParams);

  const columns = [
    {
      title: 'Raw Material Request ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },
    {
      title: 'Blending site',
      dataIndex: 'blendingSite',
      key: 'blendingSite'
    },
    {
      title: 'Issued by',
      dataIndex: 'issuedBy',
      key: 'issuedBy'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <Space>
            <Tag color={status.color}>{status.name}</Tag>
          </Space>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: unknown, record: IIndentRequest) => {
        const status = IndentRequestStatusConfig[record.status];
        return (
          <ViewButton
            onClick={() =>
              navigate(`/release-instructions/${record.id}`, {
                state: { releaseOrder: record }
              })
            }
          >
            {status?.action?.[role] ?? 'Details'}
          </ViewButton>
        );
      }
    }
  ];

  const data = useMemo(() => {
    return getIndentRequests?.data.map((item) => ({
      ...item,
      status: item.status,
      key: item.id,
      date: dateTimeFormater(item.createdAt, 'll'),
      blendingSite: item.blender.blendingSite.name,
      issuedBy: item.updatedBy.name
    }));
  }, [getIndentRequests]);

  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      <Container>
        <TableBody>
          <TableHeader>
            <Title level={4}></Title>
            <Search
              onSearch={onSearch}
              placeholder="Search"
              onChange={(e) => !e.target.value && setSearch('')}
              style={{ width: 264 }}
            />
          </TableHeader>
          <Table columns={columns} dataSource={data} />
        </TableBody>
      </Container>
    </Spin>
  );
};
