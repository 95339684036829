import { Title } from 'src/components';
import styled from 'styled-components';

export const Container = styled.div`
  height: max-content;
  margin-left: auto;
  padding: 32px;
  .total-row {
    background: #e6f7ff;
    font-weight: 600;
    font-size: 16px;
  }
`;
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 24px;
  .arrowFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;
export const CustomTitleText = styled(Title)`
  margin: 0 !important;
`;
export const Wrapper = styled.div`
  width: '100%';
  height: '100%';
  padding: 32px;
  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      color: #52c41a;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export const InfoCardHeader = styled.div`
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 78px;
  display: flex;
  align-items: center;
  padding-left: 24px;
`;
export const InfoCardContainer = styled.div`
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  padding: 32px 40px;
`;
export const TableHeader = styled.div`
  width: '100%';
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 88px;
  background-color: #ffffff;
  padding: 0 24px;
`;
export const CustomHeader = styled.div`
  background-color: #fff;
  height: 68px;
  display: flex;
  padding-inline: 24px;
  align-items: center;
  justify-content: space-between;
  .arrowFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;
export const CustomTitle = styled.h4`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
`;
export const CustomMain = styled.div`
  padding: 32px;
`;
export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  background: #fafafa;
  box-shadow: inset -1px 0px 0px #d9d9d9, inset 0px 1px 0px #d9d9d9,
    inset 0px -1px 0px #d9d9d9;
  column-gap: 1rem;
`;
export const ActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 24px;
  font-weight: 700;
  h1 {
    height: 25px;
    margin: 0;
  }
  span {
    color: #006c33;
  }
`;
