import { Button, Table, Tag } from 'antd';
import { FC, useMemo } from 'react';
import { AmendStatusConfig, IOrderAmend } from 'src/store/api';
import { dateTimeFormater } from 'src/utils';
import styled from 'styled-components';
import { StatusContainer } from '../style';

interface IProps {
  amendedOrderDetails: IOrderAmend[] | undefined;
  isLoading: boolean;
  setViewRequest: React.Dispatch<React.SetStateAction<IOrderAmend | null>>;
}

export const AmendOrderTable: FC<IProps> = ({
  amendedOrderDetails,
  isLoading,
  setViewRequest
}) => {
  const columns = [
    {
      title: 'S/N',
      dataIndex: 'sn',
      key: 'sn'
    },
    {
      title: 'Amend Request ID',
      dataIndex: 'amendID',
      key: 'amendID',
      render: (_: any, record: IOrderAmend) => {
        return (
          <>
            <p className="amendId">{`AR -${record.id.slice(0, 9)}`}</p>
          </>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, record: IOrderAmend) => {
        const status = AmendStatusConfig[record.status];
        return (
          <StatusContainer>
            <StyledTag color={status.color}>{status.name}</StyledTag>
          </StatusContainer>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: IOrderAmend) => {
        return (
          <ViewButton onClick={() => setViewRequest(record)}>View</ViewButton>
        );
      }
    }
  ];
  const dataSource = useMemo(() => {
    return (
      amendedOrderDetails?.map((details, index) => {
        return {
          ...details,
          sn: index + 1,
          amendID: `${details.id}`.toUpperCase(),
          status: details.status,
          date: dateTimeFormater(details.createdAt)
        };
      }) || []
    );
  }, [amendedOrderDetails]);

  return (
    <Container>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  transition: width 3s ease-in-out;
  .amendId {
    text-transform: uppercase;
  }
`;
const ViewButton = styled(Button)`
  color: blue;
  border: none;
`;

const StyledTag = styled(Tag)`
  text-transform: capitalize;
`;
