import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useGetWarehouseChartData } from './useGetWarehouseChartData';
import { useGetBlendingSiteChartData } from './useGetBlendingSiteChartData';
import { ChartTab } from './enum';
import { RangeValue } from 'rc-picker/lib/interface';
import { endOfMonth, startOfMonth } from 'src/utils';

const useRawMaterialDistributionData = ({activeTab}:{activeTab:string}) => {
  const [dateRange, setDateRange] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });

  const handleDateChange = (dateRange: RangeValue<dayjs.Dayjs>) => {
    if (dateRange && dateRange[0] !== null && dateRange[1] !== null) {
      setDateRange({
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString()
      });
    }
  };

  const {
    isWarehouseLoading,
    warehouseDropDownItems,
    warehouseChartConfig,
    setWarehouse,
    warehouse
  } = useGetWarehouseChartData({ ...dateRange });

  const {
    isBlendingSiteLoading,
    blendingSite,
    blendingSiteDropDownItems,
    blendingSiteChartConfig,
    setBlendingSite
  } = useGetBlendingSiteChartData({ ...dateRange });

  const isLoading = useMemo(
    () =>
      activeTab === ChartTab.Warehouse
        ? isWarehouseLoading
        : isBlendingSiteLoading,
    [activeTab, isWarehouseLoading, isBlendingSiteLoading]
  );

  const dropDown = useMemo(
    () =>
      activeTab === ChartTab.Warehouse
        ? {
          label:
            warehouseDropDownItems[warehouse]?.label ?? ChartTab.Warehouse,
          menu: warehouseDropDownItems,
          onClick: (key: string) => {
            setWarehouse(key);
          }
        }
        : {
          label:
            blendingSiteDropDownItems[blendingSite]?.label ??
            ChartTab.BlendingSite,
          menu: blendingSiteDropDownItems,
          onClick: (key: string) => {
            setBlendingSite(key);
          }
        },
    [
      activeTab,
      warehouseDropDownItems,
      warehouse,
      blendingSiteDropDownItems,
      blendingSite,
      setWarehouse,
      setBlendingSite
    ]
  );

  const chartConfig = useMemo(
    () =>
      activeTab === ChartTab.Warehouse
        ? warehouseChartConfig
        : blendingSiteChartConfig,
    [activeTab, warehouseChartConfig, blendingSiteChartConfig]
  );

  return {
    chartConfig,
    isLoading,
    dropDown,
    handleDateChange
  };
};

export default useRawMaterialDistributionData;
