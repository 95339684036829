import { Button, Dropdown, MenuProps, Modal, Space, Table } from 'antd';
import { FC, useRef } from 'react';
import { Container, CustomTitleText } from '../style';
import { ButtonWrap, CustomButton, MainHeader } from './style';
import Logo from 'src/assets/authAssets/logo.png';
import { useReactToPrint } from 'react-to-print';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';

interface IModal {
  open: boolean;
  closeModal: () => void;
  columns: {
    title: string;
    dataIndex: string;
    key: string;
  }[];
  dataSource: {
    key: number;
    requestID: string;
    blendSite: string;
    rawMaterials: string;
    amendUpdate: string;
    initialRequest: string;
    date: string;
  }[];
}
const AmmendReportDownload: FC<IModal> = ({
  open,
  closeModal,
  dataSource,
  columns
}) => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const csvRef = useRef<any>(null);

  const reactToPrintContent = () => {
    return pdfRef.current;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Amendment Reports',

    pageStyle: `@media print {
      @page {
        size: A4 landscape;
        margin: 24px;
      }
    }`
  });

  const onDownloadClick = () => {
    csvRef.current?.link.click();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
    },
    {
      key: '2',
      label: (
        <>
          <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
          <CSVLink
            data={dataSource}
            asyncOnClick={true}
            filename={`Amendment Reports ${new Date().toUTCString()}.csv`}
            headers={columns.map((column) => ({
              label: column.title,
              key: column.dataIndex
            }))}
            ref={csvRef}
          ></CSVLink>
        </>
      )
    }
  ];

  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <ButtonWrap>
          <Button onClick={closeModal}>Cancel</Button>{' '}
          <Dropdown
            menu={{
              items
            }}
          >
            <Button type={'primary'}>
              <Space>
                Download Report
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </ButtonWrap>
      ]}
      width={1230}
    >
      <Container ref={pdfRef}>
        <MainHeader>
          <img src={Logo} alt="logo" />
        </MainHeader>
        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Amendment reports</CustomTitleText>
        </HeadingWrapper>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </Container>
    </Modal>
  );
};

export default AmmendReportDownload;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 10px;
  margin-bottom: 5px;

  .arrowFlex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
