import { Button, Modal, Form, Input, notification } from 'antd';
import { FC } from 'react';
import { TextArea } from 'src/components';
import { useAddRawMaterialMutation } from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const AddModal: FC<IModal> = ({ open, closeModal }) => {
  const [handleAddRawMaterial, { isLoading }] = useAddRawMaterialMutation();

  const [form] = Form.useForm();

  const handleSubmit = (payload: { name: string; description: string }) => {
    handleAddRawMaterial(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a raw material.'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
        closeModal();
      });
  };
  return (
    <Modal
      open={open}
      title="Add Raw material"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
          loading={isLoading}
        >
          Add Raw material
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name of raw material"
          key={'1'}
          rules={[{ required: true, message: 'Raw material name is required' }]}
        >
          <Input placeholder="Enter raw material name" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          key={'1'}
          rules={[{ required: true, message: 'Add a Description to continue' }]}
        >
          <TextArea placeholder="Add description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
