import { Response } from 'src/store/genericInterface';
import { baseApi } from '../baseApi';
import { tagTypes } from '../baseApi/tagTypes';
import { IBlenderChart, IBlenderStat, IBlenderStock } from './interface';

const blendersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlenderChart: builder.query<Response<IBlenderChart[]>, string | void>({
      query: (query) => ({
        url: `/blenders/chart-data?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.BlenderStats }]
    }),
    getBlenderStats: builder.query<Response<IBlenderStat>, string | void>({
      query: () => ({
        url: `/blenders/stat`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.BlenderStats }]
    }),
    getBlenderStocksDetails: builder.query<
      Response<IBlenderStock[]>,
      string | void
    >({
      query: () => ({
        url: `/blenders/stock`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.BlenderStats }]
    })
  })
});

export const {
  useGetBlenderChartQuery,
  useGetBlenderStatsQuery,
  useGetBlenderStocksDetailsQuery
} = blendersApi;
