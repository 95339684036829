import { Button, Space, Tag } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StatusContainer } from 'src/pages/blender/indent/style';
import {
  IIndentRequest,
  IndentRequestStatus,
  IndentRequestStatusConfig,
  useGetAllIndentRequestQuery
} from 'src/store/api/indent-request';
import { dateTimeFormater, generateUrl } from 'src/utils';

export const useIndentTableLogic = () => {
  const [viewIndentRequest, setViewIndentRequest] =
    useState<IIndentRequest | null>(null);
  const [deleteIndentRequest, setDeleteIndentRequest] =
    useState<IIndentRequest | null>(null);
  const [editIndent, setEditIndent] = useState<IIndentRequest | null>(null);
  const [viewComment, setviewComment] = useState<IIndentRequest | null>(null);
  const [amendOrders, setAmendOrders] = useState<IIndentRequest | null>(null);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { hash } = useLocation();
  const status = useMemo(() => hash.replace('#', ''), [hash]);
  const queryParams = useMemo(
    () =>
      generateUrl({
        search,
        status
      }),
    [search, status]
  );
  const { data: indentRequests, isLoading } =
    useGetAllIndentRequestQuery(queryParams);

  const dataSource = useMemo(() => {
    const data = indentRequests?.data.map((item) => {
      return {
        ...item,
        date: dateTimeFormater(item.createdAt, 'll'),
        time: dateTimeFormater(item.createdAt, 'LT'),
        update: `${item.updatedCount} Update(s)`,
        orders: item.orders
      };
    });

    return data;
  }, [indentRequests]);

  const commentColumn =
    status === IndentRequestStatusConfig.rejected.name.toLowerCase()
      ? [
          {
            title: 'Comment',
            key: 'comment',
            render: (_: unknown, request: IIndentRequest) => (
              <Button type="link" onClick={() => setviewComment(request)}>
                See comment
              </Button>
            )
          }
        ]
      : [];
  const updatedColumn = [
    {
      title: 'Raw Material Request ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },

    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
          </StatusContainer>
        );
      }
    },
    {
      title: 'Update',
      dataIndex: 'update',
      key: 'update'
    },
    {
      title: 'Comment',
      key: 'comment',
      render: (_: unknown, request: IIndentRequest) => (
        <Button type="link" onClick={() => setviewComment(request)}>
          See comment
        </Button>
      )
    }
  ];
  const columns = [
    {
      title: 'Raw Material Request ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },

    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
          </StatusContainer>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time'
    },
    ...commentColumn,
    {
      title: 'Action',
      key: 'action',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <Space size="small">
            <Button
              type="link"
              onClick={
                ![
                  IndentRequestStatus.pending,
                  IndentRequestStatus.rejected
                ].includes(request.status)
                  ? () =>
                      navigate(`/raw-Material-request/${request.id}`, {
                        state: { indentRequest: request }
                      })
                  : () => setViewIndentRequest(request)
              }
            >
              Details
            </Button>
            |
            <Button type="text" onClick={() => setEditIndent(request)}>
              Edit Logistics
            </Button>
            {IndentRequestStatusConfig.completed.name !== status.name && <>|</>}
            {[
              IndentRequestStatusConfig.approved.name,
              IndentRequestStatusConfig['off-taking'].name
            ].includes(status.name) && !request.hasPendingAmendment ? (
              <Button type="text" onClick={() => setAmendOrders(request)}>
                Amend orders
              </Button>
            ) : null}
            {[IndentRequestStatusConfig.pending.name].includes(status.name) ? (
              <Button
                danger
                type="text"
                onClick={() => setDeleteIndentRequest(request)}
              >
                Delete
              </Button>
            ) : null}
          </Space>
        );
      }
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return {
    viewIndentRequest,
    commentColumn,
    updatedColumn,
    onSearch,
    dataSource,
    isLoading,
    navigate,
    amendOrders,
    deleteIndentRequest,
    editIndent,
    setAmendOrders,
    setDeleteIndentRequest,
    setViewIndentRequest,
    setEditIndent,
    viewComment,
    indentRequests,
    columns,
    setviewComment,
    setSearch,
    status
  };
};
