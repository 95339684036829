import { RcFile, UploadFile } from 'antd/es/upload';
import { localCloudName, localPresetKey } from 'src/store';

interface ICloudinaryResponse {
  asset_id: string;
  public_id: string;
  version: number;
  version_id: string;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: string;
  tags: string[];
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  folder: string;
  access_mode: string;
  original_filename: string;
  original_extension: string;
}

export const cloudinaryUpload = async (fileList: RcFile) => {
  try {
    const formData = new FormData();

    formData.append('file', fileList);
    formData.append('cloud_name', localCloudName);
    formData.append('upload_preset', localPresetKey);

    const res: ICloudinaryResponse = await fetch(
      `https://api.cloudinary.com/v1_1/${localCloudName}/image/upload`,
      { method: 'POST', body: formData }
    ).then((response: Response) => response.json());

    return res;
  } catch (error) {
    throw error;
  }
};

export const fileSizeInMegabytes = (
  fileList: UploadFile<File>[] | undefined
) => {
  let currentSize = 0;

  if (fileList) {
    currentSize = fileList.reduce((acc, file) => {
      if (file.size !== undefined) {
        return acc + file.size;
      } else {
        return acc;
      }
    }, 0);
  }

  const sizeInMegabytes = (currentSize / 1000000).toFixed(2);

  return parseFloat(sizeInMegabytes);
};
