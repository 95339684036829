import { Button, Form, Input, Modal, Select, notification } from 'antd';
import { FC, useState } from 'react';
import {
  IAddBlender,
  useAddBlenderMutation,
  useGetAllSitesQuery
} from 'src/store/api';
import { generateUrl, useDebounce } from 'src/utils';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const AddBlendersModal: FC<IModal> = ({ open, closeModal }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [addBlendersMutation, { isLoading }] = useAddBlenderMutation();
  const [input, setInput] = useState('');

  const debouncedInput = useDebounce(input);

  const { data: blendingSite } = useGetAllSitesQuery(
    generateUrl({
      limit: 20,
      search: debouncedInput,
      page: 1
    })
  );

  const handleSubmit = (payload: IAddBlender) => {
    addBlendersMutation(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a Blender.'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const handleSearch = (value: string) => {
    setInput(value);
  };

  return (
    <Modal
      open={open}
      title="Add Blender"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          key="submit"
          loading={isLoading}
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
        >
          Add Blender
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name"
          key={'1'}
          rules={[{ required: true, message: 'Blender name is required' }]}
        >
          <Input placeholder="Enter Blender name" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email address"
          key={'3'}
          rules={[{ required: true, message: 'Blender email is required' }]}
        >
          <Input placeholder="Enter address" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="phone"
          hasFeedback
          label="Phone number"
          key={'4'}
          rules={[
            {
              required: true,
              message: 'Blender phone number is required'
            },
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input placeholder="Enter phone number" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="blendingSite"
          label="Blending site"
          rules={[{ required: true, message: 'blending site is required' }]}
          key={'5'}
        >
          <Select
            placeholder="Select blending site"
            allowClear
            showSearch
            onSearch={handleSearch}
            filterOption={false}
            onClear={() => setInput('')}
          >
            {blendingSite?.data.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
