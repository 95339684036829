import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  notification
} from 'antd';
import { FC, useState } from 'react';
import greenTick from 'src/assets/greenTick.svg';
import orangeQuantity from 'src/assets/orangeQuestion.svg';
import { NumberInput, TextArea } from 'src/components';
import { IUser, Role, useAppSelector } from 'src/store';
import {
  AmendStatus,
  IIndentRequest,
  IOffTakings,
  IOrderAmend,
  useAcceptAmendmentMutation,
  useGetRawMaterialsQuery
} from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

interface IModal {
  amendOrder: IOrderAmend;
  open: boolean;
  closeModal: () => void;
  detailOfftake: IOffTakings | undefined;
  indent?: IIndentRequest;
}

interface AmendPayload {
  approvedOrders: Array<{
    rawMaterial: string;
    estimatedQuantity: number;
  }>;
  nsiaFeedback: string;
}

export const AmendRequest: FC<IModal> = ({
  amendOrder,
  open,
  closeModal,
  detailOfftake,
  indent
}) => {
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  const user = useAppSelector((store) => store.auth.user) as IUser;
  const getMaterialName = (id: string) => {
    const material = rawMaterials?.data.find((material) => material.id === id);
    return material ? material.name : 'N/A';
  };

  const getAmendedStatus = (id: string) => {
    const amendedmaterial = amendOrder.orders.find(
      (material) => material.rawMaterial.id === id
    );

    return (
      amendedmaterial?.approvedQuantity === amendedmaterial?.requestedQuantity
    );
  };

  const [amendStatus, setAmendStatus] = useState('');

  const [updateAmend, { isLoading: isUpdating }] = useAcceptAmendmentMutation();

  const getOfftaken = (id: string) => {
    if (detailOfftake?.totalOfftakingGroupedByRawMaterial?.[id]) {
      return detailOfftake?.totalOfftakingGroupedByRawMaterial?.[id]
        ?.totalOfftakenQuantity;
    } else {
      return 0;
    }
  };
  const [form] = Form.useForm();
  const handleSubmit = (values: unknown) => {
    const payload = values as AmendPayload;
    updateAmend({
      body: {
        status: amendStatus,
        approvedOrders:
          amendStatus === 'rejected' ? [] : payload.approvedOrders,
        nsiaFeedback: payload.nsiaFeedback,
        amendmentRequestId: amendOrder.id
      },
      id: indent?.id ?? ''
    })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Feedback sent successfully'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const initialValues = {
    approvedOrders: amendOrder.orders.map((request) => ({
      rawMaterial: request.rawMaterial.id,
      estimatedQuantity: request.requestedQuantity
    }))
  };

  const isNsiaFeedbackAllowed =
    user.role === Role.NSIA_ADMIN && amendOrder.status === AmendStatus.pending;

  return (
    <Modal
      title="Amendment request"
      open={open}
      onCancel={closeModal}
      centered
      footer={
        !isNsiaFeedbackAllowed
          ? [<Button onClick={closeModal}>Close</Button>]
          : [
              <Button
                type="primary"
                loading={amendStatus === 'approved' && isUpdating}
                onClick={() => {
                  setAmendStatus('approved');
                  form.submit();
                }}
              >
                Accept request
              </Button>,
              <Button
                loading={amendStatus === 'rejected' && isUpdating}
                danger
                onClick={() => {
                  setAmendStatus('rejected');
                  form.submit();
                }}
              >
                Reject request
              </Button>
            ]
      }
      width={996}
    >
      <>
        <Container>
          <RequestDiv>
            <TitleDiv>
              <h1>Request</h1>
            </TitleDiv>
            <RequestBody>
              <FlexDiv className="bottom">
                <h1>Raw material</h1>
                {amendOrder.status === 'pending' ? (
                  <h1>Off-taken quantity</h1>
                ) : null}
                <h1>Previous quantity</h1>
                <h1>Amendment</h1>
              </FlexDiv>
              {amendOrder.orders.map((request, index) => (
                <FlexDiv key={index}>
                  <p>{request.rawMaterial.name}</p>
                  {amendOrder.status === 'pending' ? (
                    <h1>{getOfftaken(request.rawMaterial.id)} MT</h1>
                  ) : null}
                  <h1>{formatNumber(request.previousQuantity)} MT</h1>

                  <h1>{formatNumber(request.requestedQuantity)} MT</h1>
                </FlexDiv>
              ))}
            </RequestBody>

            <StyledText
              value={amendOrder.blenderFeedback}
              disabled
              placeholder="Attached note from blending site regarding amendment request
"
            />
          </RequestDiv>
          <FeedbackDiv
            rejected={amendOrder.status === 'rejected'}
            layout="vertical"
            form={form}
            validateTrigger="onBlur"
            onFinish={handleSubmit}
          >
            <TitleDiv>
              <h1>Feedback</h1>
            </TitleDiv>
            <MaterialBody>
              {!isNsiaFeedbackAllowed ? (
                <>
                  <FlexDiv className="bottom">
                    <h1>Raw material</h1>
                    <h1>Feedbacks</h1>
                  </FlexDiv>
                  {amendOrder.orders.map((feedback, index) => {
                    const isAmend = getAmendedStatus(feedback.rawMaterial.id);

                    return (
                      <FlexDiv key={index}>
                        <p>{getMaterialName(feedback.rawMaterial.id)}</p>
                        <div className="flexIcon">
                          {isAmend ? (
                            <div className="feedback-flex">
                              <h1 className="greenText">
                                {formatNumber(feedback.approvedQuantity ?? 0)}{' '}
                                MT{' '}
                              </h1>
                              <StyledImage src={greenTick} alt="approved" />
                            </div>
                          ) : amendOrder.status === 'rejected' ? (
                            <h1>N/A</h1>
                          ) : (
                            <div className="feedback-flex">
                              <h1 className="orangeText">
                                {formatNumber(feedback.approvedQuantity ?? 0)}{' '}
                                MT{' '}
                              </h1>
                              <StyledImage src={orangeQuantity} alt="amend" />
                            </div>
                          )}
                        </div>
                      </FlexDiv>
                    );
                  })}
                </>
              ) : (
                <>
                  <FlexDiv className="bottom">
                    <h1>Raw material</h1>
                    <h1>Quantity</h1>
                  </FlexDiv>
                  <Form.List
                    name={'approvedOrders'}
                    initialValue={initialValues.approvedOrders}
                  >
                    {(fields) => (
                      <>
                        {fields.map((field, _index) => (
                          <Row
                            key={field.key}
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                            align="middle"
                          >
                            <Col span={12}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'rawMaterial']}
                              >
                                <Select
                                  disabled
                                  placeholder="Select Raw Material"
                                >
                                  {rawMaterials?.data.map((item) => (
                                    <Select.Option key={item.id}>
                                      {item.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[field.name, 'estimatedQuantity']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Quantity  is required'
                                  }
                                ]}
                              >
                                <NumberInput
                                  min={1}
                                  placeholder=" Enter quantity"
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                </>
              )}
            </MaterialBody>

            <Form.Item name={'nsiaFeedback'}>
              <StyledText
                value={amendOrder.nsiaFeedback ?? ''}
                disabled={!isNsiaFeedbackAllowed}
                placeholder="Attached note from NSIA regarding amendment request
"
              />
            </Form.Item>
          </FeedbackDiv>
        </Container>
        <Divider />
      </>
    </Modal>
  );
};

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledText = styled(TextArea)`
  margin-top: 20px;
  border-radius: 8px;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  margin: 20px 0;
`;

const MaterialBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow-y: scroll;
  background-color: #fafafa;
  gap: 10px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  padding: 10px;
  border-radius: 8px;
  margin-top: 15px;
`;

const RequestBody = styled(MaterialBody)`
  background-color: #ffffff;
  gap: 5px;
`;

const RequestDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f0f0f0;
  flex: 1;
  border-radius: 8px;
  .bottom {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
  }
`;
const FeedbackDiv = styled(Form)<{ rejected: boolean }>`
  border: 1px solid #f4ffb8;
  background-color: ${({ rejected }) => (!rejected ? 'f6ffed' : '#f5b1ae')};
  .bottom {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
  }
  .feedback-flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
`;
const TitleDiv = styled.div`
  padding: 5px;
  border-bottom: 1px solid #d9d9d9;
  h1 {
    font-weight: 600;
    font-size: 14px;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    color: #52c41a;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
  h1 {
    color: #000000;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
`;
