import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal, Space, Table } from 'antd';

import styled from 'styled-components';

import Logo from 'src/assets/authAssets/logo.png';

import { FC, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import shortArrow from 'src/assets/shortArrow.svg';
import { Title } from 'src/components';
import { Container } from 'src/pages/styles';
import { useSummaryQuantity } from '../useSummaryQuantity';
import BalanceSummary from './BalanceSummary';
import { CSVLink } from 'react-csv';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const SummaryQuantityDownloadModal: FC<IModal> = ({
  open,
  closeModal
}) => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const csvRef = useRef<any>(null);

  const {
    columns,
    closingBalance,
    releaseSource,
    offTakeSource,
    deliverySource,
    openingBalance
  } = useSummaryQuantity();
  const [viewOfftake, setViewOfftake] = useState(true);
  const reactToPrintContent = () => {
    return pdfRef.current;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Summary Quantity Reports',

    pageStyle: `@media print {
      @page {
        size: A4 landscape;
        margin: 24px;
      }
    }`
  });

  const onDownloadClick = () => {
    csvRef.current?.link.click();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
    },
    {
      key: '2',
      label: (
        <>
          <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
          <CSVLink
            data={deliverySource}
            asyncOnClick={true}
            filename={`Summary Quantity Reports ${new Date().toUTCString()}.csv`}
            headers={columns.map((column) => ({
              label: column.title,
              key: column.dataIndex
            }))}
            ref={csvRef}
          ></CSVLink>
        </>
      )
    }
  ];

  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <ButtonWrap>
          <Button onClick={closeModal}>Cancel</Button>{' '}
          <Dropdown
            menu={{
              items
            }}
          >
            <Button type={'primary'}>
              <Space>
                Download Report
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </ButtonWrap>
      ]}
      width={1230}
    >
      <Container ref={pdfRef}>
        <MainHeader>
          <img src={Logo} alt="logo" />
        </MainHeader>
        <BalanceSummary
          openingBalance={openingBalance}
          closingBalance={closingBalance}
        />

        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Raw Materials Deliveries</CustomTitleText>
        </HeadingWrapper>
        <Table
          columns={columns}
          dataSource={deliverySource}
          pagination={false}
          rowClassName={(record) =>
            record.yearMonth === 'Total' ? 'total-row' : ''
          }
        />

        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Offtake/Sales</CustomTitleText>
          <ClickDiv
            isClicked={viewOfftake}
            onClick={() => setViewOfftake(!viewOfftake)}
          >
            <img src={shortArrow} alt="" />
          </ClickDiv>
        </HeadingWrapper>
        {viewOfftake && (
          <Table
            columns={columns}
            dataSource={offTakeSource}
            pagination={false}
            rowClassName={(record) =>
              record.yearMonth === 'Total' ? 'total-row' : ''
            }
          />
        )}
        <HeadingWrapper className="top">
          <CustomTitleText level={5}>Release reports</CustomTitleText>
        </HeadingWrapper>

        <Table
          columns={columns}
          dataSource={releaseSource}
          pagination={false}
          rowClassName={(record) =>
            record.yearMonth === 'Total' ? 'total-row red' : ''
          }
        />
      </Container>
    </Modal>
  );
};

const ClickDiv = styled.div<{ isClicked: boolean }>`
  transform: ${({ isClicked }) => (isClicked ? 'rotate(90deg)' : 'rotate(0)')};
  cursor: pointer;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 10px;

  .arrowFlex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

const ButtonWrap = styled.div``;

export const CustomTitleText = styled(Title)`
  margin: 0 !important;
`;

export const CustomButton = styled.div`
  background: #ffffff;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
  padding-inline: 5px;
`;
export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    align-self: center;
    margin-block: 33px 47px;
    object-fit: contain;
  }
`;
