import { Body, CardRow, Container, CustomHeader } from './style';
import { AnalyticsCard, Title } from 'src/components';
import {
  RawMaterialInStock,
  RawMaterialsChart,
  RecentRequest
} from './components';
import styled from 'styled-components';
import { useGetCmportStatsQuery } from 'src/store/api/cmport/dashboard';
import WarehouseIcon from 'src/assets/dashboardIcons/warehouseIcon.svg';

export const Dashboard = () => {
  const { data: getCmportStats } = useGetCmportStatsQuery();
  return (
    <Container>
      <CustomHeader>
        <Title>Collateral Manager</Title>
      </CustomHeader>
      <Body>
        <CardDiv>
          <CardRow>
            <ChartContainer>
              <RawMaterialsChart />
            </ChartContainer>
            <AsideDetails>
              <AnalyticsCard
                width="100%"
                title="Warehouses"
                icon={WarehouseIcon}
                value={getCmportStats?.data.warehouses}
              />
              <RawMaterialInStock />
            </AsideDetails>
          </CardRow>
        </CardDiv>
        <RecentRequest />
      </Body>
    </Container>
  );
};

const CardDiv = styled.div`
  margin: 40px 0px;
`;
const AsideDetails = styled.div`
  width: 29.5%;
`;
const ChartContainer = styled.div`
  width: 69.5%;
`;
