import {
  IForgetPasswordPayload,
  useForgotPasswordMutation
} from 'src/store/api';
import { Alert, Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { AuthWrap } from 'src/components';
import { SpaceBelow } from 'src/components/layout/layout.style';
import { IApiError } from 'src/store/genericInterface';

export const ForgotPassword = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [handleForgotPassword, { isLoading, data, error, isError, isSuccess }] =
    useForgotPasswordMutation();

  const handleSubmit = (values: IForgetPasswordPayload) => {
    handleForgotPassword(values)
      .unwrap()
      .finally(() => {
        setOpen(true);
      });
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    }

    return () => {};
  }, [open]);

  return (
    <div>
      <AuthWrap
        title="Forgot Password"
        subtitle="Enter the registered email associated with your account below to request a password reset."
      >
        {open && (isError || isSuccess) && (
          <SpaceBelow space="1em">
            <Alert
              message={
                isSuccess
                  ? data?.message
                  : (error as IApiError | undefined)?.error
              }
              type={isError ? 'error' : 'success'}
              showIcon
            />
          </SpaceBelow>
        )}
        <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
          <Form.Item
            label="Email Address"
            name={'email'}
            rules={[
              {
                required: true,
                message: 'Email address is required'
              },
              {
                type: 'email',
                message: 'Email address is invalid'
              }
            ]}
          >
            <Input
              placeholder="johndoe@pfi.com.ngr"
              type="email"
              autoComplete="off"
            />
          </Form.Item>
          <Button loading={isLoading} htmlType={'submit'} type="primary" block>
            Submit
          </Button>
        </Form>
      </AuthWrap>
    </div>
  );
};
