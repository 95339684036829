import { Table, Tag, Spin } from 'antd';
import { Typography } from 'antd';
import { FC, useMemo } from 'react';
import { Container, TableBody } from './style';
import { Link } from 'react-router-dom';
import { dateTimeFormater, generateUrl } from 'src/utils';
import {
  IIndentRequest,
  IndentRequestStatusConfig,
  useGetAllIndentRequestQuery
} from 'src/store/api';
import { StatusContainer, TableHeader } from 'src/pages/styles';

const { Title } = Typography;

export const RecentRequests: FC = () => {
  const queryParams = useMemo(
    () =>
      generateUrl({
        limit: 4
      }),
    []
  );
  const { data: indentList, isLoading } =
    useGetAllIndentRequestQuery(queryParams);

  const dataSource = useMemo(() => {
    return indentList?.data.map((item, index) => ({
      ...item,
      sn: `${(index + 1).toString().padStart(3, '0')}`,
      indentId: `#${item.id.slice(0, 10)}`,
      status: item.status,
      date: dateTimeFormater(item?.createdAt),
      issuedBy: item.updatedBy ? `${item.updatedBy.name}` : '---------------'
    }));
  }, [indentList]);

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'sn',
      key: 'sn'
    },

    {
      title: 'Indent ID',
      dataIndex: 'indentId',
      key: 'indentId'
    },
    {
      title: 'Issued by',
      dataIndex: 'issuedBy',
      key: 'issuedBy'
    },

    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
          </StatusContainer>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      <Container>
        <TableBody>
          <TableHeader>
            <Title level={5}>Recent Requests</Title>
            <Link to="/raw-material-request">See all</Link>
          </TableHeader>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </TableBody>
      </Container>
    </Spin>
  );
};
