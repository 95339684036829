import { Button, Form, Modal, Tabs } from 'antd';
import { FC, useState } from 'react';
import { IPurchaseOrder } from 'src/store/api';
import { DischargeForm } from './DischargeForm';
import { IDischargeRawMaterial } from './interface';

interface IModal {
  open: boolean;
  closeModal: () => void;
  purchaseOrder: IPurchaseOrder;
  setOpenSummaryModal: (data: {
    summaryDetails: IDischargeRawMaterial[];
    portId: string;
  }) => void;
}

export const DischargeVesselsModal: FC<IModal> = ({
  open,
  closeModal,
  purchaseOrder,
  setOpenSummaryModal
}) => {
  const [portId, setPort] = useState(purchaseOrder.portQuantities[0].port.id);
  const [form] = Form.useForm<{ vesselUpdate: IDischargeRawMaterial[] }>();
  const onChange = (key: string) => {
    setPort(key);
  };
  const handleNextButton = () => {
    form
      .validateFields()
      .then((res) => {
        setOpenSummaryModal({
          summaryDetails: res.vesselUpdate,
          portId: portId
        });
        closeModal();
      })
      .catch(() => {});
  };

  return (
    <Modal
      open={open}
      title="Discharge Vessel  Update"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,

        <Button onClick={handleNextButton} htmlType="submit" type="primary">
          Next
        </Button>
      ]}
      width={884}
    >
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        items={purchaseOrder.portQuantities.map((item) => ({
          label: item.port.name,
          key: item.port.id,
          children: (
            <DischargeForm
              form={form}
              purchaseOrder={purchaseOrder}
              portId={portId}
            />
          )
        }))}
      />
    </Modal>
  );
};
