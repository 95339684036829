import { Typography } from 'antd';
import React from 'react';
import { CenterItem, SpaceBelow } from '../layout/layout.style';

interface AuthWrapProps {
  children?: React.ReactNode;
  title: string;
  subtitle: string;
}

export const AuthWrap = ({ children, title, subtitle }: AuthWrapProps) => {
  const { Title, Text } = Typography;
  return (
    <div>
      <SpaceBelow space="2.3em">
        <Title style={{ textAlign: 'center',}}>{title}</Title>
        <CenterItem>
          <Text type="secondary">{subtitle}</Text>
        </CenterItem>
      </SpaceBelow>
      {children}
    </div>
  );
};
