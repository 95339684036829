import { Card, Col, Divider, Modal, Row, Typography } from 'antd';
import moment from 'moment';
import { FC, useMemo } from 'react';
import {
  ConfirmCardContainer,
  MaterialInfo,
  TextDetails
} from 'src/pages/nsia-admin/purchaseOrder/styles';
import { IIndentRequest } from 'src/store/api/indent-request';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

const { Text } = Typography;
interface IModal {
  open: boolean;
  indentRequest: IIndentRequest;
  closeModal: () => void;
}
export const ViewIndentRequest: FC<IModal> = ({
  open,
  closeModal,
  indentRequest
}) => {
  const tonnage = useMemo(
    () =>
      indentRequest.orders.reduce(
        (acc, curr) => acc + Number(curr.estimatedQuantity),
        0
      ),
    [indentRequest]
  );
  return (
    <Modal
      open={open}
      title="Request details"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      width={660}
      footer={[]}
    >
      <Divider />
      <ConfirmCardContainer>
        <MaterialInfo>
          <TextDetails>
            <Text disabled>Blending Site:</Text>
            <CustomText>{indentRequest.blender?.blendingSite?.name}</CustomText>
          </TextDetails>
          <TextDetails>
            <Text disabled>Locations:</Text>
            <Text>{indentRequest.blender?.blendingSite?.location?.name}</Text>
          </TextDetails>
          <TextDetails>
            <Text disabled>Tonnage:</Text>
            <CustomText>{`${formatNumber(tonnage)}MT`}</CustomText>
          </TextDetails>
          <TextDetails>
            <Text disabled>Date:</Text>
            <CustomText>
              {moment(indentRequest.createdAt).format('DD-MM-YYYY')}
            </CustomText>
          </TextDetails>
        </MaterialInfo>
        <div className="site-card-wrapper">
          <Row gutter={16}>
            <Col span={12}>
              <Card
                title="Raw Material"
                extra={<EstimateQuantity>Quantity</EstimateQuantity>}
                className="card"
              >
                {indentRequest.orders.map((item) => (
                  <Row
                    key={item.rawMaterial?.id}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  >
                    <Col span={12}>
                      <RawMaterialName>
                        {item?.rawMaterial?.name}
                      </RawMaterialName>
                    </Col>
                    <Col span={12}>
                      <RawMaterialQuantity>{`${formatNumber(
                        item.estimatedQuantity
                      )}MT`}</RawMaterialQuantity>
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>

            <Col span={12}>
              <Card title="Transporter" className="card2">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <Keys>Transporter company</Keys>
                  </Col>
                  <Col span={12}>
                    <Values>{indentRequest.logistics.length}</Values>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <Keys>Consent Letters</Keys>
                  </Col>
                  <Col span={12}>
                    <Values>{indentRequest.logistics.length}</Values>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </ConfirmCardContainer>
    </Modal>
  );
};

const Keys = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
`;
const Values = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;
const EstimateQuantity = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
`;
const RawMaterialName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
`;
const RawMaterialQuantity = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  display: flex;
  justify-content: flex-end;
`;
const CustomText = styled(Text)`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
