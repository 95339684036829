import styled from 'styled-components';

export const TableBody = styled.div`
  background-color: white;
`;
export const Container = styled.div`
  width: '100%';
  height: '100%';
  padding: 32px;
`;
export const TableHeader = styled.div`
  width: '100%';
  justify-content: space-between;
  display: flex;
  height: 50px;
  padding: 10px 20px;
`;
