import { Button, Form, Modal, Steps, notification } from 'antd';
import { FC, useMemo, useState } from 'react';
import { IPurchaseOrder, useCreatePurchaseOrderMutation } from 'src/store/api';
import styled from 'styled-components';
import {
  Confirm,
  IPurchaseOrderSummary,
  PurchaseDetails,
  SelectRawMaterial
} from '.';

interface IModal {
  open: boolean;
  closeModal: () => void;
  openSummary: (summary: IPurchaseOrder) => void;
}

export const InitiatePurchase: FC<IModal> = ({
  open,
  closeModal,
  openSummary
}) => {
  const [current, setCurrent] = useState(0);
  const [orderSummary, setOrderSummary] = useState<IPurchaseOrderSummary>();
  const [createPurchaseOrder, { isLoading }] = useCreatePurchaseOrderMutation();
  const next = () => {
    form
      .validateFields()
      .then((res) => {
        setOrderSummary((prev) => ({ ...prev, ...res }));
        setCurrent(current + 1);
      })
      .catch(() => {});
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCreate = () => {
    const payload = {
      ...orderSummary!,
      arrivalTime: orderSummary!.arrivalTime.toISOString()
    };
    createPurchaseOrder(payload)
      .unwrap()
      .then((res) => {
        notification['success']({
          message: 'Your purchase order has been sent successfully.'
        });
        closeModal();
        openSummary(res.data);
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const [form] = Form.useForm();

  const steps = useMemo(
    () => [
      {
        key: 'Raw Material',
        title: 'Raw Material',
        content: <SelectRawMaterial />
      },
      {
        key: 'Raw Material',
        title: 'Purchase Details',
        content: <PurchaseDetails />
      },
      {
        key: 'Raw Material',
        title: 'Confirm',
        content: <Confirm orderSummary={orderSummary!} />
      }
    ],
    [orderSummary]
  );

  return (
    <>
      <Modal
        visible={open}
        title={`${
          current === steps.length - 1
            ? 'Purchase Summary'
            : 'Initiate Purchase'
        }`}
        onOk={closeModal}
        onCancel={closeModal}
        centered
        footer={[
          <>
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Back
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button loading={isLoading} type="primary" onClick={handleCreate}>
                Create Purchase
              </Button>
            )}
          </>
        ]}
        width={790}
      >
        <Form layout={'vertical'} form={form} validateTrigger={'onBlur'}>
          <StepWrapper>
            <Steps current={current} items={steps} />
          </StepWrapper>
          {steps[current].content}
        </Form>
      </Modal>
    </>
  );
};

const StepWrapper = styled.div`
  margin-bottom: 20px;
`;
