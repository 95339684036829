import { DatePicker, Spin, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import { Title } from 'src/components';
import { TableBody, TableHeader } from 'src/pages/cmBlendingPlant/report/style';
import { ReportStatusConfig } from 'src/store/api';
import {
  ICmbDataItem,
  useGetCMBlenderReportsQuery
} from 'src/store/api/cmBlender/reports';
import { dateTimeFormater, generateUrl } from 'src/utils';
import { endOfMonth, startOfMonth } from 'src/utils/dateRange';
import styled from 'styled-components';
import { ConfirmModal } from './components/ConfirmModal';

const { RangePicker } = DatePicker;

export const DailyProducts = () => {
  const [queryParams, setParams] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString(),
    page: 1,
    limit: 10
  });
  const [confirmModal, setConfirmModal] = useState<ICmbDataItem | null>(null);

  const { data: dailyReports, isLoading } = useGetCMBlenderReportsQuery(
    generateUrl({
      ...queryParams
    })
  );
  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] && datejs[1]) {
      setParams((prev) => ({
        ...prev,
        startDate: datejs[0]!.toISOString(),
        endDate: datejs[1]!.toISOString()
      }));
    }
  };
  const columns = [
    {
      title: 'S/N',
      dataIndex: 'sn',
      key: 'sn'
    },
    {
      title: 'NPK Produced',
      dataIndex: 'npkType',
      key: 'npkType'
    },
    {
      title: 'Raw Materials Used',
      dataIndex: 'rawMaterialUsed',
      key: 'rawMaterialUsed'
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, record: ICmbDataItem) => {
        const status = ReportStatusConfig[record.status];

        return <StyledTag color={status.color}>{status.name}</StyledTag>;
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, record: ICmbDataItem) => {
        return <Button onClick={() => setConfirmModal(record)}>Details</Button>;
      }
    }
  ];

  const dataSource = useMemo(() => {
    return dailyReports?.data.map((item, index) => {
      return {
        ...item,
        npkType: item.npkProduced.length ?? 0,
        rawMaterialUsed: item.rawMaterialUtilized.length ?? 0,
        date: dateTimeFormater(item.createdAt) ?? '',
        sn: `${index + 1}`.padStart(2, '0')
      };
    });
  }, [dailyReports]);

  return (
    <>
      <TitleRow>
        <Title level={2}>Daily Productions</Title>
      </TitleRow>
      <Header>
        <RangePicker
          defaultValue={[startOfMonth(), endOfMonth()]}
          onChange={(datejs) => handleDateChange(datejs)}
        />
      </Header>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <TableBody>
          <Table
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: dailyReports?.pagination?.totalCount,
              onChange: (page) => {
                setParams((prev) => ({ ...prev, page }));
              }
            }}
            columns={columns}
            dataSource={dataSource}
          />
        </TableBody>
      </Spin>
      {confirmModal && (
        <ConfirmModal
          open={!!confirmModal}
          closeModal={() => setConfirmModal(null)}
          reports={confirmModal}
        />
      )}
    </>
  );
};

const StyledTag = styled(Tag)`
  text-transform: capitalize;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #1890ff;
`;

const Header = styled(TableHeader)`
  justify-content: flex-end;
`;

const TitleRow = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 24px;
  height: 102px;
`;
