import { Input, Space, Table, Tag, Typography } from 'antd';
import { StatusContainer } from 'src/pages/styles';

import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useAppSelector } from 'src/store';
import {
  AdminType,
  IAdmin,
  IClearingAgent,
  useGetAllClearingAgentsQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';
import { ActionItem, Container, TableBody, TableHeader } from '../style';
import {
  AddClearingAgents,
  DeleteClearingAgentsModal,
  EditClearingAgents
} from './components';

type IAddClearingAgent = {
  setAddClearingAgent: Dispatch<SetStateAction<boolean>>;
  addClearingAgent: boolean;
};
const { Title } = Typography;
const { Search } = Input;

export const ClearingAgents: FC<IAddClearingAgent> = ({
  setAddClearingAgent,
  addClearingAgent
}) => {
  const [deleteModal, setDeleteModal] = useState<IClearingAgent | null>(null);
  const [editModal, setEditModal] = useState<IClearingAgent | null>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const user = useAppSelector((store) => store.auth.user!) as IAdmin;
  const { data: getClearingAgent } = useGetAllClearingAgentsQuery(
    generateUrl({
      limit: 10,
      search,
      page
    })
  );

  const clearingAgent = useMemo(() => {
    return (
      getClearingAgent?.data.map((item, index) => ({
        key: index,
        portName: item.port?.name,
        ...item
      })) ?? []
    );
  }, [getClearingAgent]);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Ports',
      dataIndex: 'portName',
      key: 'portName'
    },
    {
      title: 'Status',
      key: 'status',
      render: (agent: IClearingAgent) => {
        return (
          <StatusContainer>
            {agent.isOnboarded ? (
              <Tag color="green">Onboarded</Tag>
            ) : (
              <Tag color="gold">Awaiting</Tag>
            )}
          </StatusContainer>
        );
      }
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_: unknown, clickedAgent: IClearingAgent) => (
        <Space size="small">
          {user.type === AdminType.SUPER_ADMIN && (
            <>
              <ActionItem onClick={() => setEditModal(clickedAgent)}>
                Edit
              </ActionItem>
              |
              <ActionItem
                color={'rgba(245, 34, 45, 1)'}
                onClick={() => {
                  setDeleteModal(clickedAgent);
                }}
              >
                Delete
              </ActionItem>
            </>
          )}
        </Space>
      )
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      {addClearingAgent && (
        <AddClearingAgents
          open={addClearingAgent}
          closeModal={() => setAddClearingAgent(false)}
        />
      )}
      {!!deleteModal && (
        <DeleteClearingAgentsModal
          open={!!deleteModal}
          agentDetails={deleteModal}
          closeModal={() => setDeleteModal(null)}
        />
      )}
      {!!editModal && (
        <EditClearingAgents
          open={!!editModal}
          agentDetails={editModal}
          closeModal={() => setEditModal(null)}
        />
      )}
      <TableBody>
        <TableHeader>
          <Title level={4}>Clearing Agent</Title>
          <Search
            onSearch={onSearch}
            onChange={(e) => !e.target.value && setSearch('')}
            placeholder="Search"
            style={{ width: 264 }}
          />
        </TableHeader>
        <Table
          pagination={{
            defaultCurrent: 1,
            pageSize: 10,
            total: getClearingAgent?.pagination?.totalCount,
            onChange: (page) => {
              setPage(page);
            }
          }}
          columns={columns}
          dataSource={clearingAgent}
        />
      </TableBody>
    </Container>
  );
};
