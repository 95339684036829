import { FormInstance, notification, Form, Spin, Row, Col, Select } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/store';
import {
  IIndentRequest,
  useGetRawMaterialsQuery,
  useLazyGetAllWarehousesQuery,
  useGetAllPortsQuery,
  useUpdateIndentRequestMutation
} from 'src/store/api';
import { useDebounce, generateUrl } from 'src/utils';

interface IValues {
  port?: string;
  collateralManager?: string;
  feedback?: string;
  orders?: {
    id: string;
    updatedQuantity: number;
    port: string;
    warehouse: string;
  }[];
}

type IFormProps = {
  form: FormInstance;
  orderId: string;
  setIsLoading: (value: boolean) => void;
  closeModal: () => void;
  indentRequest: IIndentRequest;
};

export const AcceptForm: FC<IFormProps> = ({
  form,
  orderId,
  closeModal,
  setIsLoading,
  indentRequest
}) => {
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  const [getPortWarehouses, { data: warehouses }] =
    useLazyGetAllWarehousesQuery();
  const [input, setInput] = useState('');
  const [warehouseInputs, setWarehouseInputs] = useState<string>('');
  const debouncedInput = useDebounce(input);
  const debouncedWarehouse = useDebounce(warehouseInputs);
  const { data: ports, isLoading: isPortsLoading } = useGetAllPortsQuery(
    generateUrl({ search: debouncedInput })
  );

  const { orders, feedback, isUpdate } = useAppSelector(
    (store) => store.indentRequest
  );

  const handleSearch = (value: string) => {
    setInput(value);
  };

  const handleWarehouseValues = (port: string, value?: string) => {
    if (value) {
      setWarehouseInputs(value);
      getPortWarehouses(generateUrl({ port, search: debouncedWarehouse }));
    } else {
      getPortWarehouses(generateUrl({ port }));
    }
  };

  const [acceptOrder] = useUpdateIndentRequestMutation();
  const navigate = useNavigate();

  const onFinish = (values: IValues) => {
    const data = {
      status: 'approved',
      orders: values.orders,
      feedback: feedback
    };
    const payload = { orderId, data };
    setIsLoading(true);
    acceptOrder(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully Sent a release order'
        });
        navigate('/raw-material-request#approved');
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const initialValues = !isUpdate
    ? indentRequest.orders.map((item) => ({
        id: item.rawMaterial.id,
        quantity: item.estimatedQuantity
      }))
    : orders.map((item) => ({
        id: item.id,
        quantity: item.quantity
      }));

  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      initialValues={{ orders: initialValues }}
    >
      <Spin spinning={isPortsLoading}>
        <Form.List name="orders">
          {(fields) => (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {fields.map(({ key, name, ...restField }, index) => (
                <React.Fragment key={key}>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      label="Raw Material"
                      name={[name, 'id']}
                    >
                      <Select disabled placeholder="Select Raw Material">
                        {rawMaterials?.data?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      label="Port"
                      name={[name, 'port']}
                      rules={[
                        { required: true, message: 'Please select a port' }
                      ]}
                    >
                      <Select
                        showSearch
                        onSearch={handleSearch}
                        placeholder="Select port"
                        allowClear
                        onSelect={(port: string) =>
                          handleWarehouseValues(port, warehouseInputs[index])
                        }
                      >
                        {ports?.data.map((port) => (
                          <Select.Option key={port.id} value={port.id}>
                            {port.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'warehouse']}
                      label="Warehouse"
                      rules={[
                        { required: true, message: 'Please select a warehouse' }
                      ]}
                    >
                      <Select
                        showSearch
                        onSearch={(value) =>
                          handleWarehouseValues(
                            form.getFieldValue(['orders', name, 'port']),
                            value
                          )
                        }
                        placeholder="Select Warehouse"
                        allowClear
                      >
                        {warehouses?.data.map((warehouse) => (
                          <Select.Option
                            key={warehouse.id}
                            value={warehouse.id}
                          >
                            {warehouse.code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          )}
        </Form.List>
      </Spin>
    </Form>
  );
};
