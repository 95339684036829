import { Modal, Spin } from 'antd';
import { FC } from 'react';
import { IWarehouse, useGetWarehouseStockQuery } from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  warehouse: IWarehouse;
}

export const ViewWarehouseStock: FC<IModal> = ({
  open,
  closeModal,
  warehouse
}) => {
  const { data, isLoading } = useGetWarehouseStockQuery(warehouse.id);
  const rawMaterials = data?.data[0]?.rawMaterials;
  const totalMaterials = rawMaterials?.reduce(
    (acc, curr) => acc + curr.availableQuantity,
    0
  );

  return (
    <Modal
      open={open}
      onOk={closeModal}
      onCancel={closeModal}
      centered
      width={577}
      title="Raw Material Details"
    >
      <Spin spinning={isLoading}>
        <MaterialWrap>
          <Header>
            <h1>Total available raw Materials:</h1>
            <p>{formatNumber(totalMaterials ?? 0)}MT</p>
          </Header>
          {rawMaterials && rawMaterials?.length > 0 && (
            <div className="grid">
              {data?.data[0]?.rawMaterials.map((stock, index) => (
                <MaterialTag key={index}>
                  <p>{stock.name} -</p>
                  <h3>{formatNumber(stock.availableQuantity)}MT</h3>
                </MaterialTag>
              ))}
            </div>
          )}
        </MaterialWrap>
      </Spin>
    </Modal>
  );
};

const MaterialWrap = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 15px;
  background-color: #e6f7ff;
  border-radius: 8px;

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    background-color: #c0ebff;
    border-radius: 8px;
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  h1 {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }
  p {
    color: #595959;
    font-size: 14px;
    font-weight: 600;
  }
`;

const MaterialTag = styled.div`
  display: flex;
  gap: 4px;
  background-color: #ffffff;
  border: 1px solid #91d5ff;
  align-items: center;
  padding: 2px 6px;
  border-radius: 4px;
  min-width: 133px;
  height: 32px;
  p {
    color: #1890ff;
    font-size: 14px;
    font-weight: 700;
  }
  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }
`;
