import { DownOutlined } from '@ant-design/icons';
import { Modal, Button, Table, Dropdown, Space, MenuProps } from 'antd';
import {
    ButtonWrap,
    CustomButton,
    DownloadWrapper,
    MainHeader,
    TextWrap,
    HeadingWrapper
} from './style';
import { FC, useMemo, useRef, useState } from 'react';
import Logo from 'src/assets/authAssets/logo.png';
import { useAppSelector, IUser, Role } from 'src/store';
import { useGetWarehouseReceiptsReportQuery } from 'src/store/api';
import { dateTimeFormater, formatNumber } from 'src/utils';
import { TableHeader } from '../style';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import { Text, Title } from 'src/components';

interface IModal {
    open: boolean;
    urlQueryParam: string;
    closeModal: () => void;
}

export const WarehouseReceiptsDownloadModal: FC<IModal> = ({
    open,
    closeModal,
    urlQueryParam
}) => {
    const user = useAppSelector((store) => store.auth.user) as IUser;
    const csvRef = useRef<any>(null);
    const pdfRef = useRef<HTMLDivElement>(null);
    const [showPagination, setShowPagination] = useState(true);

    const { data: getWarehouseReceiptsReport } =
        useGetWarehouseReceiptsReportQuery(urlQueryParam);

    const warehouseReports = useMemo(() => {
        return (
            getWarehouseReceiptsReport?.data.map((item, index) => ({
                key: item.id,
                ...item,
                serialNum: index + 1,
                collateralManager: item.collateralManager.name,
                rawMaterial: item.rawMaterial.name,
                date: dateTimeFormater(item.createdAt),
                warehouse: item.warehouse.name,
                vesselName: item.purchaseOrder.vesselName,
                quantity: formatNumber(`${item.quantityReceived} MT`)
            })) ?? []
        );
    }, [getWarehouseReceiptsReport]);

    const nsiaColumn =
        user.role === Role.NSIA_ADMIN
            ? [
                {
                    title: 'Collateral Manager',
                    dataIndex: 'collateralManager',
                    key: 'collateralManager'
                }
            ]
            : [];

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'serialNum',
            key: 'serialNum'
        },
        {
            title: 'Vessel Name',
            dataIndex: 'vesselName',
            key: 'vesselName'
        },
        {
            title: 'Warehouse',
            dataIndex: 'warehouse',
            key: 'warehouse'
        },
        ...nsiaColumn,
        {
            title: 'Raw Material',
            dataIndex: 'rawMaterial',
            key: 'rawMaterial'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        }
    ];

    const onDownloadClick = () => {
        csvRef.current?.link.click();
    };

    const reactToPrintContent = () => {
        return pdfRef.current;
    };

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: 'Warehouse Receipts Reports',
        async onBeforeGetContent() {
            setShowPagination(false);
        },
        onAfterPrint() {
            setShowPagination(true);
        },
        pageStyle: `@media print {
      @page {
        size: A4 landscape;
        margin: 24px;
      }
    }`
    });

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
        },
        {
            key: '2',
            label: (
                <>
                    <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
                    <CSVLink
                        data={warehouseReports}
                        asyncOnClick={true}
                        filename={`Warehouse Receipts ${new Date().toUTCString()}.csv`}
                        headers={columns.map((column) => ({
                            label: column.title,
                            key: column.dataIndex
                        }))}
                        ref={csvRef}
                    ></CSVLink>
                </>
            )
        },
    ];

    return (
        <Modal
            open={open}
            closable={false}
            centered
            footer={[
                <ButtonWrap>
                    <Button onClick={closeModal}>Cancel</Button>{' '}
                    <Dropdown
                        menu={{
                            items
                        }}
                    >
                        <Button type={'primary'}>
                            <Space>
                                Download report
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </ButtonWrap>
            ]}
            width={1230}
        >
            <DownloadWrapper ref={pdfRef}>
                <MainHeader>
                    <img src={Logo} alt="logo" />
                </MainHeader>

                <HeadingWrapper>
                    <TextWrap>
                        <Title level={5}>Warehouse Receipts Reports</Title>
                        <Text>Generated by</Text>
                    </TextWrap>

                    <TextWrap>
                    <Text>{`Date: ${dateTimeFormater(new Date())} `} </Text>
                        <Title level={5}>{user.name}</Title>
                    </TextWrap>
                </HeadingWrapper>

                <TableHeader>
                    <Title level={5}>View Report</Title>
                </TableHeader>
                <Table
                    pagination={
                        showPagination
                            ? {
                                total: getWarehouseReceiptsReport?.pagination?.totalCount,
                                defaultCurrent: 1,
                                pageSize: 10
                            }
                            : false
                    }
                    columns={columns}
                    dataSource={warehouseReports}
                />
            </DownloadWrapper>
        </Modal>
    );
};
