import { Button, Form, Input, message } from 'antd';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { AuthWrap } from 'src/components';
import { useResetPasswordMutation } from 'src/store/api';
import { IApiError } from 'src/store/genericInterface';

export const ResetPassword = () => {
  const location = useLocation();
  const token = location.search.replace('?token=', '');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [handleResetPassword, { isLoading }] = useResetPasswordMutation();
  const handleSubmit = (values: {
    password: string;
    confirmPassword: string;
  }) => {
    handleResetPassword({ token, password: values.password })
      .unwrap()
      .then((res) => {
        message.success(res.message);
        navigate('/', { replace: true });
      })
      .catch((err) => {
        message.error((err as IApiError).error);
      });
  };
  if (!token) {
    return <Navigate replace to={'/'} />;
  }

  return (
    <AuthWrap
      title="Reset Password"
      subtitle="Enter your new password to continue."
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
        <Form.Item
          name={'password'}
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Password is required'
            },
            {
              min: 5,
              message: 'Password must be at least 5 characters'
            }
          ]}
        >
          <Input.Password placeholder="Input password" autoComplete='off' />
        </Form.Item>

        <Form.Item
          name={'confirmPassword'}
          rules={[
            {
              validator: async (_, value) => {
                if (!value) {
                  throw new Error('Password is required');
                } else if (value !== form.getFieldValue('password')) {
                  throw new Error('Password does not match');
                }
              }
            }
          ]}
          label="Confirm Password"
        >
          <Input.Password placeholder="Input password" autoComplete='off' />
        </Form.Item>

        <Button loading={isLoading} htmlType={'submit'} type="primary" block>
          Reset
        </Button>
      </Form>
    </AuthWrap>
  );
};
