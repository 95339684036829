import { Button, Form, Input, Modal, notification } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FC } from 'react';
import { useAddSupplierMutation } from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const AddSupplierModal: FC<IModal> = ({ open, closeModal }) => {
  const [handleAddSupplier, { isLoading }] = useAddSupplierMutation();
  const [form] = Form.useForm();

  const handleSubmit = (payload: { name: string; code: string }) => {
    handleAddSupplier(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a supplier.'
        });
        form.resetFields();
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
        closeModal();
      });
  };
  return (
    <Modal
      open={open}
      title="Add Supplier"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Add Supplier
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        <FormItem name="name" label="Suppliers Name" key={'1'}>
          <Input placeholder="Enter suppliers name" />
        </FormItem>
        <FormItem name="code" label="Suppliers Code" key={'2'}>
          <Input placeholder="Enter  code" />
        </FormItem>
      </Form>
    </Modal>
  );
};
