import { Form, FormInstance, Input, notification, Select } from 'antd';
import { FC, useState } from 'react';
import {
  IAddCollateralManagerPlant,
  useAddCollateralManagerBlendingPlantMutation,
  useGetAllSitesQuery
} from 'src/store/api';
import { generateUrl, useDebounce } from 'src/utils';
const { Option } = Select;

interface IAddCMBlendingPlantProps {
  closeModal: () => void;
  form: FormInstance<IAddCollateralManagerPlant>;
  setCMLoading: (value: React.SetStateAction<boolean>) => void;
}

export const AddCMBlendingPlant: FC<IAddCMBlendingPlantProps> = ({
  form,
  closeModal,
  setCMLoading
}) => {
  const [addCollateralManager] = useAddCollateralManagerBlendingPlantMutation();

  const [input, setInput] = useState('');

  const debouncedInput = useDebounce(input);

  const { data: blendingSites } = useGetAllSitesQuery(
    generateUrl({
      limit: 20,
      search: debouncedInput,
      page: 1
    })
  );
  const handleSubmit = (payload: IAddCollateralManagerPlant) => {
    setCMLoading(true);
    addCollateralManager(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a collateral manager.'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      })
      .finally(() => {
        setCMLoading(false);
      });
  };

  const handleSearch = (value: string) => {
    setInput(value);
  };
  return (
    <Form
      layout={'vertical'}
      form={form}
      validateTrigger={['onBlur']}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="name"
        label="Name"
        key={'1'}
        rules={[
          { required: true, message: 'Collateral manager name is required' }
        ]}
      >
        <Input placeholder="Enter Collateral manager name" autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email address"
        key={'3'}
        rules={[
          { required: true, message: 'Collateral manager email is required' }
        ]}
      >
        <Input placeholder="Enter address" autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="phone"
        hasFeedback
        label="Phone number"
        key={'4'}
        rules={[
          {
            required: true,
            message: 'Collateral manager phone number is required'
          },
          {
            pattern: /^((\+234)|0)[0-9]{10}$/,
            message: 'please enter a valid number'
          }
        ]}
      >
        <Input placeholder="Enter phone number" autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="blendingSites"
        label="Blending Plant"
        rules={[{ required: true, message: 'blending plant is required' }]}
        key={'5'}
      >
        <Select
          placeholder="Select blending plant"
          mode="tags"
          allowClear
          showSearch
          onSearch={handleSearch}
          filterOption={false}
          onClear={() => setInput('')}
          tokenSeparators={[',']}
        >
          {blendingSites?.data.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
