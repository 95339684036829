import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { FC, useMemo, useState } from 'react';
import { NumberInput, Title } from 'src/components';
import { AddFieldContainer } from 'src/pages/styles';
import {
  useGetAllClearingAgentsQuery,
  useGetAllPortsQuery,
  useGetAllSuppliersQuery
} from 'src/store/api';
import { generateUrl, useDebounce } from 'src/utils';
import styled from 'styled-components';
const { Option } = Select;

export const PurchaseDetails: FC = () => {
  const { data: ports } = useGetAllPortsQuery();
  const { data: caAgents } = useGetAllClearingAgentsQuery();
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input);

  const { data: suppliers } = useGetAllSuppliersQuery(
    generateUrl({
      limit: 20,
      search: debouncedInput,
      page: 1
    })
  );

  const handleSearch = (value: string) => {
    setInput(value);
  };

  const suppliersList = useMemo(() => {
    return suppliers?.data ?? [];
  }, [suppliers]);
  const portQuantities = [{ port: '', quantity: '' }];
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true, message: 'field is required' }]}
            name="vesselName"
            label="Vessel name"
          >
            <Input placeholder="Enter name" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true, message: 'field is required' }]}
            name={'arrivalTime'}
            label="Estimated time of arrival"
          >
            <StyledDate
              onChange={(date) => {
                date?.toDate();
              }}
              disabledDate={(d) => {
                const dateToday = new Date();
                return !d || d.isBefore(dateToday);
              }}
              format={'YYYY-MM-DD'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        rules={[{ required: true, message: 'field is required' }]}
        name={'clearingAgent'}
        label="Clearing agent"
      >
        <Select
          showArrow
          showSearch
          placeholder="Select clearing agent"
          allowClear
        >
          {caAgents?.data.map((item) => (
            <Option value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'field is required' }]}
        name={'supplier'}
        label="Supplier"
      >
        <Select
          showSearch
          onSearch={handleSearch}
          filterOption={false}
          onClear={() => setInput('')}
          showArrow
          placeholder="Select supplier"
          allowClear
        >
          {suppliersList.map((item) => (
            <Option value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Title level={3}>Port of discharge</Title>
      <Form.List name={'portQuantities'} initialValue={portQuantities}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row
                key={index}
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                align="middle"
              >
                <Col span={11}>
                  <Form.Item
                    rules={[{ required: true, message: 'field is required' }]}
                    name={[field.name, 'port']}
                    label="Port of discharge"
                  >
                    <Select allowClear showArrow placeholder="Select port">
                      {ports?.data.map((item) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    name={[field.name, 'quantity']}
                    label="Estimated Quantity (MT)"
                    rules={[{ required: true, message: 'field is required' }]}
                  >
                    <NumberInput
                      type={'number'}
                      placeholder=" Enter quantity"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                {fields.length > 1 && (
                  <Col>
                    <ExButton onClick={() => remove(index)} />
                  </Col>
                )}
              </Row>
            ))}
            <AddFieldContainer>
              <Button
                title="Add field"
                icon={<PlusOutlined />}
                onClick={() => add(portQuantities[0])}
              >
                Add Field
              </Button>
            </AddFieldContainer>
          </>
        )}
      </Form.List>
    </>
  );
};

const StyledDate = styled(DatePicker)`
  width: 100%;
`;
const ExButton = styled(CloseOutlined)`
  font-size: 20px;
  color: #ff7875;
  display: flex;
  justify-content: flex-end;
  margin-left: 30px;
  align-content: center;
  padding-right: 2px;
`;
