import { DownOutlined } from '@ant-design/icons';
import { Modal, Button, Table, Dropdown, Space, MenuProps } from 'antd';
import {
    ButtonWrap,
    CustomButton,
    DownloadWrapper,
    MainHeader,
    TextWrap,
    HeadingWrapper
} from './style';
import { FC, useMemo, useRef, useState } from 'react';
import Logo from 'src/assets/authAssets/logo.png';
import { useAppSelector, IUser, Role } from 'src/store';
import { useGetReleaseReportsQuery } from 'src/store/api';
import { dateTimeFormater, formatNumber, } from 'src/utils';
import { TableHeader } from '../style';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import { Text, Title } from 'src/components';

interface IModal {
    open: boolean;
    urlQueryParam: string;
    closeModal: () => void;
}

export const ReleaseReportDownloadModal: FC<IModal> = ({
    open,
    closeModal,
    urlQueryParam
}) => {
    const user = useAppSelector((store) => store.auth.user) as IUser;
    const csvRef = useRef<any>(null);
    const pdfRef = useRef<HTMLDivElement>(null);
    const [showPagination, setShowPagination] = useState(true);

    const { data: getReleaseReports } = useGetReleaseReportsQuery(urlQueryParam);

    const nsiaColumn =
        user.role === Role.NSIA_ADMIN
            ? [
                {
                    title: 'Blender (Offtaker)',
                    dataIndex: 'blender',
                    key: 'blender'
                }
            ]
            : [];
    const CMPortColumn =
        user.role === Role.COLLATERAL_MANAGER
            ? [
                {
                    title: 'Blender (Offtaker)',
                    dataIndex: 'blender',
                    key: 'blender'
                }
            ]
            : [];
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'serialNumber',
            key: 'serialNumber'
        },
        {
            title: 'Release ID',
            dataIndex: 'releaseId',
            key: 'releaseId'
        },
        {
            title: 'Raw material',
            dataIndex: 'rawMaterials',
            key: 'rawMaterials'
        },
        ...nsiaColumn,
        ...CMPortColumn,
        {
            title: 'Warehouse',
            dataIndex: 'warehouse',
            key: 'warehouse'
        },
        {
            title: 'Qty Released',
            dataIndex: 'qtyReleased',
            key: 'qtyReleased'
        },
        {
            title: 'Qty Offtaken',
            dataIndex: 'qtyOfftaken',
            key: 'qtyOfftaken'
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        }
    ];

    const onDownloadClick = () => {
        csvRef.current?.link.click();
    };

    const ReleaseReport = useMemo(() => {
        return (
            getReleaseReports?.data.map((item, index) => ({
                key: index,
                ...item,
                serialNumber: index + 1,
                releaseId: item.indentRequest.releasedId,
                rawMaterials: item.rawMaterial.name,
                blender: item.blender.name,
                warehouse: item.warehouse.name,
                balance: item.rawMaterial.balance,
                date: dateTimeFormater(item.offtaking.createdAt),
                qtyReleased: formatNumber(`${item.rawMaterial.quantityReleased}MT`),
                qtyOfftaken: formatNumber(`${item.rawMaterial.quantityOfftaken} MT`)
            })) ?? []
        );
    }, [getReleaseReports]);

    const reactToPrintContent = () => {
        return pdfRef.current;
    };

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: 'Release Reports',
        async onBeforeGetContent() {
            setShowPagination(false);
        },
        onAfterPrint() {
            setShowPagination(true);
        },
        pageStyle: `@media print {
      @page {
        size: A4 landscape;
        margin: 24px;
      }
    }`
    });

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
        },
        {
            key: '2',
            label: (
                <>
                    <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
                    <CSVLink
                        data={ReleaseReport}
                        asyncOnClick={true}
                        filename={`Release Report ${new Date().toUTCString()}.csv`}
                        headers={columns.map((column) => ({
                            label: column.title,
                            key: column.dataIndex
                        }))}
                        ref={csvRef}
                    ></CSVLink>
                </>
            )
        },
    ];

    return (
        <Modal
            open={open}
            closable={false}
            centered
            footer={[
                <ButtonWrap>
                    <Button onClick={closeModal}>Cancel</Button>{' '}
                    <Dropdown
                        menu={{
                            items
                        }}
                    >
                        <Button type={'primary'}>
                            <Space>
                                Download report
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </ButtonWrap>
            ]}
            width={1230}
        >
            <DownloadWrapper ref={pdfRef}>
                <MainHeader>
                    <img src={Logo} alt="logo" />
                </MainHeader>

                <HeadingWrapper>
                    <TextWrap>
                        <Title level={5}>Release Reports</Title>
                        <Text>Generated by</Text>
                    </TextWrap>

                    <TextWrap>
                    <Text>{`Date: ${dateTimeFormater(new Date())} `} </Text>
                        <Title level={5}>{user.name}</Title>
                    </TextWrap>
                </HeadingWrapper>

                <TableHeader>
                    <Title level={5}>View Report</Title>
                </TableHeader>
                <Table
                    pagination={
                        showPagination
                            ? {
                                total: getReleaseReports?.pagination?.totalCount,
                                defaultCurrent: 1,
                                pageSize: 10
                            }
                            : false
                    }
                    columns={columns}
                    dataSource={ReleaseReport}
                />
            </DownloadWrapper>
        </Modal>
    );
};
