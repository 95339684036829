import { FC, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

import {
  IIndentRequest,
  IndentRequestStatus,
  useGetIndentRequestByIdQuery
} from 'src/store/api/indent-request';

import styled from 'styled-components';
import { ButtonWrap } from './style';
import { Title } from 'src/components';
import { ApprovedRequest, PendingRequest } from './components';
import { useAppDispatch, useAppSelector } from 'src/store';
import { IndentRequestAction, setSelectedAction } from 'src/store/slices';
import { PageHeader } from '@ant-design/pro-layout';
import { useGetAllStockQuery } from 'src/store/api';
import { NegativeReleaseModal } from './components/negativeReleaseModal';

export const IndentRequestDetail: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showUpdateFields } = useAppSelector((store) => store.indentRequest);
  const { state } = useLocation();

  const { id, releaseId } = useMemo(() => state as IIndentRequest, [state]);
  const { data: indentRequest, isLoading } = useGetIndentRequestByIdQuery(id);
  const { data: allStock } = useGetAllStockQuery();
  const [popUp, setPopUp] = useState(false);

  const handleAcceptance = () => {
    const matchMaterials = allStock?.data;

    if (matchMaterials && indentRequest?.data) {
      const insufficientMaterials = indentRequest.data.orders.filter(
        (order) => {
          const stockItem = matchMaterials.find(
            (item: { rawMaterial: { name: string } }) =>
              item.rawMaterial.name === order.rawMaterial.name
          );

          const stockQuantity = stockItem?.quantity || 0;

          return stockQuantity < order.estimatedQuantity;
        }
      );

      if (insufficientMaterials.length === 0) {
        dispatch(setSelectedAction(IndentRequestAction.Accept));
      } else {
        setPopUp(true);
      }
    }
  };

  return (
    <>
      <CustomHeader>
        <SmallTitle>
          <SMText>Release order / </SMText>
          <SMTextId>Request ID - {releaseId}</SMTextId>
        </SmallTitle>

        <HeaderWrap>
          <TitleRow>
            <Title level={3}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{ marginRight: '17px' }}
              />{' '}
              Request ID - {releaseId}
            </Title>
          </TitleRow>
          {indentRequest?.data.status === IndentRequestStatus.pending && (
            <ButtonWrap>
              <Button
                type="primary"
                disabled={showUpdateFields}
                onClick={handleAcceptance}
              >
                Accept
              </Button>
              <Button
                type="primary"
                disabled={showUpdateFields}
                danger
                onClick={() =>
                  dispatch(setSelectedAction(IndentRequestAction.Reject))
                }
              >
                Reject
              </Button>
            </ButtonWrap>
          )}
        </HeaderWrap>
      </CustomHeader>

      <Spin spinning={isLoading} tip="Loading" size="large">
        {!!indentRequest?.data ? (
          <>
            {[
              IndentRequestStatus.pending,
              IndentRequestStatus.rejected
            ].includes(indentRequest.data.status) ? (
              <PendingRequest
                setPopUp={setPopUp}
                indentRequest={indentRequest.data}
              />
            ) : (
              <ApprovedRequest indentRequest={indentRequest?.data} />
            )}
          </>
        ) : null}
      </Spin>

      {popUp && (
        <NegativeReleaseModal
          open={popUp}
          closeModal={() => setPopUp(false)}
          onRelease={() =>
            dispatch(setSelectedAction(IndentRequestAction.Accept))
          }
        />
      )}
    </>
  );
};

const SmallTitle = styled.div`
  display: flex;
`;
const SMText = styled.div`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
`;
const SMTextId = styled.div`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
  padding: 5px 45px 5px 20px;
`;
const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
`;
