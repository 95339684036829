import { Button, Modal, Form, Tabs } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { MANAGER_TYPE } from './enum';
import { AddCMPort } from './port';
import { IBaseModal } from 'src/pages/interface';
import { AddCMBlendingPlant } from './blending-plant';

export const AddCollateralManager: FC<
  IBaseModal & { managerType: MANAGER_TYPE }
> = ({ open, closeModal, managerType }) => {
  const [form] = Form.useForm();
  const [activeTab, setActive] = useState(managerType);
  const [isCMLoading, setIsCMLoading] = useState(false)
  return (
    <Modal
      open={open}
      title="Add Collateral manager"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={() => form.submit()} htmlType="submit" disabled={isCMLoading} loading={isCMLoading} type="primary">
          Add Collateral manager
        </Button>
      ]}
      width={660}
    >
      <CustomTab
        defaultActiveKey={activeTab}
        onChange={(activeKey) => {
          setActive(activeKey as MANAGER_TYPE);
          form.resetFields();
        }}
        items={[
          {
            label: `Ports`,
            key: MANAGER_TYPE.PORT,
            children:
              activeTab === MANAGER_TYPE.PORT ? (
                <AddCMPort closeModal={closeModal} form={form} setCMLoading={setIsCMLoading} />
              ) : null
          },
          {
            label: `Blending-plant`,
            key: MANAGER_TYPE.BLENDING_PLANT,
            children:
              activeTab === MANAGER_TYPE.BLENDING_PLANT ? (
                <AddCMBlendingPlant form={form} closeModal={closeModal} setCMLoading={setIsCMLoading} />
              ) : null
          }
        ]}
      />
    </Modal>
  );
};

const CustomTab = styled(Tabs)``;
