import { useMemo } from 'react';
import { Space, Tag, Table, Spin } from 'antd';
import { FC } from 'react';
import { Title } from 'src/components';
import { Container, TableBody, ViewButton } from './style';
import { TableHeader } from 'src/pages/styles';
import { Link, useNavigate } from 'react-router-dom';
import {
  IIndentRequest,
  IndentRequestStatusConfig,
  useGetAllIndentRequestQuery
} from 'src/store/api';
import { dateTimeFormater, generateUrl } from 'src/utils';

export const RecentOrder: FC = () => {
  const navigate = useNavigate();
  const queryParams = useMemo(
    () =>
      generateUrl({
        limit: 3
      }),
    []
  );

  const { data: indentRequests, isLoading } =
    useGetAllIndentRequestQuery(queryParams);

    const dataSource = useMemo(() => {
      return indentRequests?.data.map((item) => ({
        ...item,
        key: item.releaseId,
        status: item.status,
        date: dateTimeFormater(item.createdAt, 'll'),
        time: dateTimeFormater(item.createdAt, 'LT'),
        blendingSite: item.blender?.blendingSite?.name
      }));
    }, [indentRequests]);

  const columns = [
    {
      title: 'Raw material Request ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },
    {
      title: 'Blending site',
      dataIndex: 'blendingSite',
      key: 'blendingSite'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <Space>
            <Tag color={status.color}>{status.name}</Tag>
          </Space>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: IIndentRequest) => (
        <ViewButton
          onClick={() =>
            navigate(`/raw-Material-request/${record.id}`, {
              state: record
            })
          }
        >
          View{' '}
        </ViewButton>
      )
    }
  ];

  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      <Container>
        <TableBody>
          <TableHeader>
            <Title level={5}>Recent Raw material Request</Title>
            <Link to="/raw-material-request">See all</Link>
          </TableHeader>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </TableBody>
      </Container>
    </Spin>
  );
};
