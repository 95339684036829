import {
  Button,
  Modal,
  Typography,
  Upload,
  UploadFile,
  notification
} from 'antd';
import { FC, useMemo, useState } from 'react';
import {
  CustomTable,
  QuantityDetails,
  TextDetails
} from 'src/pages/clearingAgents/purchaseOrder/styles';
import { IPurchaseOrder } from 'src/store/api';
import { formatNumber } from 'src/utils';
import { IDischargeRawMaterial } from './interface';
import { ConfirmClearVessel } from './confirmClearVessel';
import { RcFile } from 'antd/es/upload';
import { DraggerDiv } from 'src/pages/blender/indent/style';
import { UploadOutlined } from '@ant-design/icons';
import { fileSizeInMegabytes } from 'src/utils/uploadToCloudinary';

const { Text } = Typography;

interface IModal {
  open: boolean;
  summary?: { summaryDetails: IDischargeRawMaterial[]; portId: string };
  closeModal: () => void;
  purchaseOrder: IPurchaseOrder;
}
export const ClearVesselsModal: FC<IModal> = ({
  open,
  closeModal,
  purchaseOrder
}) => {
  const [viewConfirmVesselModal, setViewConfirmVesselModal] = useState(false);
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState<UploadFile<File>[]>();
  const [buttonActive, setButtonActive] = useState<boolean>(false);

  const onRemove = (file: UploadFile) => {
    if (!fileList) return;
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList([]);
    setButtonActive(newFileList.length > 0);
  };

  const beforeUpload = (file: RcFile) => {
    if (fileList) {
      setFileList([...fileList, file]);
      setButtonActive(true);
    } else {
      setFileList([file]);
      setButtonActive(true);
    }
    return false;
  };

  const isFileListValid = (fileList?.length ?? 0) >= 1;

  const source = useMemo(() => {
    return purchaseOrder.orders.map((item) => {
      const dischargedQuantity = purchaseOrder.dischargeOrders.reduce(
        (acc, current) => {
          if (current.rawMaterial.id === item.rawMaterial.id) {
            acc += Number(current.quantityDischarged);
          }
          return acc;
        },
        0
      );
      return {
        rawMaterial: item.rawMaterial.name,
        estimatedQuantity: item.estimatedQuantity,
        dischargedQuantity,
        formattedDischargedQuantity: formatNumber(`${dischargedQuantity}MT`),
        formattedEstimatedQuantity: formatNumber(`${item.estimatedQuantity}MT`)
      };
    });
  }, [purchaseOrder]);

  const { totalDischarge, totalEstimatedTonnage } = useMemo(
    () =>
      source.reduce(
        (acc, current) => {
          acc.totalDischarge += current.dischargedQuantity;
          acc.totalEstimatedTonnage += Number(current.estimatedQuantity);
          return acc;
        },
        { totalEstimatedTonnage: 0, totalDischarge: 0 }
      ),
    [source]
  );
  const columns = [
    {
      title: 'Raw materials',
      dataIndex: 'rawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Estimated Quantity (MT)',
      dataIndex: 'formattedEstimatedQuantity',
      key: 'estimatedQuantity'
    },
    {
      title: 'Discharged quantity',
      dataIndex: 'formattedDischargedQuantity',
      key: 'dischargedQuantity'
    }
  ];
  const fileSize = fileSizeInMegabytes(fileList);

  const handleClick = () => {
    if (fileSize < 10) {
      setViewConfirmVesselModal(true);
    } else {
      notification['error']({
        message: 'File size exceeds limit'
      });
    }
  };

  return (
    <Modal
      open={!!open}
      title="Clear vessel"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Back</Button>,

        <Button
          disabled={!buttonActive}
          onClick={handleClick}
          htmlType="submit"
          type="primary"
        >
          Clear vessel
        </Button>
      ]}
      width={660}
    >
      <QuantityDetails>
        <TextDetails>
          <Text disabled>Estimated Tonnage</Text>
          <Text strong>{`${formatNumber(totalEstimatedTonnage)}MT`}</Text>
        </TextDetails>
        <TextDetails>
          <Text disabled>Discharged</Text>
          <Text strong>{`${formatNumber(totalDischarge)}MT`}</Text>
        </TextDetails>
        <TextDetails>
          <Text disabled>Difference</Text>
          <Text strong>{`${formatNumber(
            totalEstimatedTonnage - totalDischarge
          )}MT`}</Text>
        </TextDetails>
      </QuantityDetails>
      <CustomTable dataSource={source} columns={columns} pagination={false} />
      <Text strong>Statement of facts</Text>
      <Dragger
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
      >
        <DraggerDiv>
          <UploadOutlined />
          <p>Click to Upload</p>
          {isFileListValid ? (
            <p>
              {fileSize} {''}MB
            </p>
          ) : null}
        </DraggerDiv>
        <Text strong>
          Must not exceed file size 10MB (.pdf, .doc, .docx, .jpg, .jpeg, .png)
        </Text>
      </Dragger>
      {viewConfirmVesselModal && (
        <ConfirmClearVessel
          purchaseOrder={purchaseOrder}
          closeModal={() => setViewConfirmVesselModal(false)}
          open={viewConfirmVesselModal}
          closeClearVessel={closeModal}
          fileList={fileList ?? []}
        />
      )}
    </Modal>
  );
};
