import { useLocation } from 'react-router-dom';
import { DownloadOutlined, ReconciliationFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { Modal, Typography, Divider, Button } from 'antd';
import { DOCTYPE, IDocument, IPurchaseOrder } from 'src/store/api';
import { FC, useState } from 'react';
import { SendPaarModal } from 'src/pages/nsia-admin/purchaseOrder/components/SendPaarModal';

const { Title, Text } = Typography;

interface IModal {
  open: boolean;
  closeModal: () => void;
  viewPaar: IPurchaseOrder;
}

export const ViewShipFactModal: FC<IModal> = ({
  open,
  closeModal,
  viewPaar
}) => {
  const { state } = useLocation();
  const [openSendPaar, setOpenSendPaar] = useState<boolean>(false);
  const [purchaseOrder] = useState<IPurchaseOrder>(
    (state as { purchaseOrder: IPurchaseOrder }).purchaseOrder
  );
  return (
    <>
      {!!openSendPaar && (
        <SendPaarModal
          closeViewModal={() => setOpenSendPaar(false)}
          open={!!openSendPaar}
          purchaseOrder={purchaseOrder}
        />
      )}

      <Modal
        open={open}
        closable={false}
        centered
        footer={<Button onClick={() => closeModal()}>Close</Button>}
        width={640}
      >
        <TextWrapper>
          <Title level={3}>Statement of facts</Title>
          <PaarText>
            The Statement of facts for purchase order with the{' '}
            <strong> ID: {viewPaar.purchaseId}</strong>
            sent for discharging the order.
          </PaarText>
          <Divider />
          {viewPaar.documents?.reduce(
            (result: JSX.Element[], item: IDocument, index) => {
              if (item.documentType !== DOCTYPE.PAAR) {
                result.push(
                  <DownloadWrapper key={index}>
                    <DownloadIcon
                      rel="noreferrer"
                      target={'_blank'}
                      href={item.url}
                    >
                      <PaarIcon />
                      <Text strong className="viewText">
                        {item.name}
                      </Text>
                      <DownloadOutlined />
                    </DownloadIcon>
                  </DownloadWrapper>
                );
              }
              return result;
            },
            []
          )}

          <Divider />
        </TextWrapper>
      </Modal>
    </>
  );
};

const DownloadWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: auto;
  .viewText {
    margin-inline: 15px;
    text-transform: uppercase;
  }
`;
const PaarIcon = styled(ReconciliationFilled)`
  border: 0.5px dashed rgba(217, 217, 217, 1);
  border-radius: 8px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(250, 250, 250, 1);
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PaarText = styled.p`
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;
`;
const DownloadIcon = styled.a`
  color: #11b867;
`;
