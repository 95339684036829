import { FC } from 'react';
import { Button, Divider, Form, Input, Modal, notification } from 'antd';
import { Label } from 'src/pages/nsia-admin/indentRequest/style';
import styled from 'styled-components';
import {
  useBlenderResolveIndentMutation
} from 'src/store/api';

type IModal = {
  closeModal: () => void;
  open: boolean;
  id: string;
  indentRequestId: string;
  truckId: string;
  status: string
};
export const ConfirmOfftakeModal: FC<IModal> = ({
  closeModal,
  open,
  id,
  indentRequestId,
  truckId,
  status
}) => {
  const [form] = Form.useForm();
  const confirmInput = Form.useWatch('id', form);
  const [resolveIndent, { isLoading }] = useBlenderResolveIndentMutation();

  const handleFinish =()=>{
    if (truckId === confirmInput) {
      handleSubmit()
    }
  }
  const handleSubmit = () => {
    const payload = {
      status: status
    }
    resolveIndent({ indentRequestId, id, data: payload })
      .unwrap()
      .then(() => {
        notification['success']({
          message: status === "confirm" ? 'You have successfully confirmed the offtake' : 'You have successfully rejected the offtake'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  return (
    <Modal
      title={status === "confirm" ? 'Confirm Offtake' : 'Reject Offtake'}
      open={open}
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          type={'primary'}
          danger={status === 'reject'}
          loading={isLoading}
          disabled={truckId !== confirmInput}
          key="submit"
          onClick={() => form.submit()}
        >
          {status === 'confirm' ? 'Yes, Confirm' : 'Yes, Reject'}
        </Button>
      ]}
      width={572}
    >
      <Divider />
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        {status === 'confirm' ?
          <Label>
            Do you want to confirm truck ID'<OrderId>{truckId}</OrderId>
          </Label>
          :
          <Label>
            Do you want to reject truck ID <OrderId>{truckId}</OrderId>
          </Label>
        }

        <Form.Item name="id">
          <Input placeholder="Enter the Truck ID" autoComplete='off'/>
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  );
};
const OrderId = styled.p`
  color: rgba(17, 184, 103, 1);
  display: inline;
  margin-inline: 3px;
`;
