import {
  Dashboard,
  PurchaseOrder,
  ReleaseOrders,
  SingleReleaseOrder
} from 'src/pages/cmPort';
import { DischargeOrders } from 'src/pages/cmPort/purchase-order/components';
import { OfftakeRawMaterial } from 'src/pages/cmPort/releaseOrder';
import { ReleaseReports } from 'src/pages/reports';
import SummaryQuantity from 'src/pages/reports/SummaryQuantity';

export const cmPort = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/purchase-order/',
    element: <PurchaseOrder />
  },
  {
    path: '/purchase-order/:id',
    element: <DischargeOrders />
  },
  {
    path: '/release-instructions',
    element: <ReleaseOrders />
  },
  {
    path: '/release-instructions/:id',
    element: <SingleReleaseOrder />
  },
  {
    path: '/offtake-raw-materials/:id',
    element: <OfftakeRawMaterial />
  },
  {
    path: '/release-report',
    element: <ReleaseReports />
  },
  {
    path: '/reports/summaryQuantity',
    element: <SummaryQuantity />
  }
];
