import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IClearingAgent, useDeleteClearingAgentMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  agentDetails: IClearingAgent;
}

export const DeleteClearingAgentsModal: FC<IModal> = ({
  open,
  closeModal,
  agentDetails
}) => {
  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(false);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== agentDetails.name);
  }, [name, agentDetails.name]);

  const [deleteClearingAgent, { isLoading }] = useDeleteClearingAgentMutation();

  const handleSubmit = () => {
    deleteClearingAgent(agentDetails.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted a clearing agent'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
    <>
      <Modal
        open={open}
        title="Delete Clearing Agent"
        onOk={closeModal}
        onCancel={closeModal}
        centered
        footer={[
          <Button onClick={closeModal}>Cancel</Button>,
          <Button
            danger
            loading={isLoading}
            disabled={buttonDisable}
            type={'primary'}
            onClick={() => form.submit()}
          >
            Yes, Delete
          </Button>
        ]}
        width={660}
      >
        <Form onFinish={handleFinish} layout={'vertical'} form={form}>
          <Form.Item
            name={'name'}
            key={1}
            label={
              <FormLabel>
                Are you sure delete "
                <ClearingAgentTitle>{agentDetails?.name}</ClearingAgentTitle>”,
                this action cannot be undone
              </FormLabel>
            }
          >
            <Input placeholder="Enter the clearing agent name to proceed" autoComplete='off' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const ClearingAgentTitle = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
