import { Role } from 'src/store/constant';

export enum NotificationType {
  INDENT_REQUEST = 'RAW MATERIAL REQUEST',
  PURCHASE_ORDER = 'PURCHASE ORDER',
  STAKEHOLDER = 'STAKEHOLDER',
  REPORT = 'REPORT',
  OTHERS = 'OTHERS'
}
export const NotificationTypeConfig: Record<
  NotificationType,
  Record<Role, string>
> = {
  [NotificationType.INDENT_REQUEST]: {
    [Role.NSIA_ADMIN]: '/raw-Material-request',
    [Role.BLENDER]: '/raw-material-request',
    [Role.CLEARING_AGENT]: '/raw-material-request',
    [Role.COLLATERAL_MANAGER]: '/raw-material-request',
    [Role.COLLATERAL_MANAGER_BLENDER]: '/raw-material-request'
  },
  [NotificationType.PURCHASE_ORDER]: {
    [Role.NSIA_ADMIN]: '/purchase-order',
    [Role.BLENDER]: '',
    [Role.CLEARING_AGENT]: '/purchase-order',
    [Role.COLLATERAL_MANAGER]: '/purchase-order',
    [Role.COLLATERAL_MANAGER_BLENDER]: '/purchase-order'
  },
  [NotificationType.STAKEHOLDER]: {
    [Role.NSIA_ADMIN]: '/stakeholders',
    [Role.BLENDER]: '',
    [Role.CLEARING_AGENT]: '',
    [Role.COLLATERAL_MANAGER]: '',
    [Role.COLLATERAL_MANAGER_BLENDER]: ''
  },
  [NotificationType.REPORT]: {
    [Role.NSIA_ADMIN]: '/daily-production-report',
    [Role.BLENDER]: '',
    [Role.CLEARING_AGENT]: '',
    [Role.COLLATERAL_MANAGER]: '',
    [Role.COLLATERAL_MANAGER_BLENDER]: ''
  },
  [NotificationType.OTHERS]: {
    [Role.NSIA_ADMIN]: '/daily-production-report',
    [Role.BLENDER]: '',
    [Role.CLEARING_AGENT]: '',
    [Role.COLLATERAL_MANAGER]: '',
    [Role.COLLATERAL_MANAGER_BLENDER]: ''
  }
};
