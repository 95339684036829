import { Table, Tag, Spin } from 'antd';
import { Typography } from 'antd';
import { FC, useMemo } from 'react';
import { Container, TableBody } from './style';
import { Link } from 'react-router-dom';
import { TableHeader } from 'src/pages/styles';
import { ORDER_STATUS } from 'src/pages/clearingAgents/purchaseOrder/enum';
import {
  IPurchaseOrder,
  useGetAllPurchaseOrderQuery
} from 'src/store/api/purchase-order';
import { formatNumber, generateUrl, dateTimeFormater } from 'src/utils';

const { Title } = Typography;

export const RecentVessels: FC = () => {
  const { data: purchaseOrderList, isLoading } = useGetAllPurchaseOrderQuery(
    generateUrl({
      limit: 3
    })
  );
  const source = useMemo(() => {
    return purchaseOrderList?.data.map((item) => {
      let estimatedQuantity = 0;
      let rawMaterials: string[] = [];
      item.orders.forEach((order) => {
        estimatedQuantity += Number(order.estimatedQuantity);
        rawMaterials.push(order?.rawMaterial?.name);
      });
      return {
        estimatedQuantity: formatNumber(`${estimatedQuantity} MT`),
        selectedRawMaterials: rawMaterials.join(', '),
        date: dateTimeFormater(item.arrivalTime),

        ...item
      };
    });
  }, [purchaseOrderList]);
  const columns = [
    {
      title: 'Vessel name',
      dataIndex: 'vesselName',
      key: 'vesselName'
    },
    {
      title: 'Raw Material',
      dataIndex: 'selectedRawMaterials',
      key: 'rawMaterial'
    },
    {
      title: 'Estimated Quantity (MT)',
      dataIndex: 'estimatedQuantity',
      key: 'quantity'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Order status',
      dataIndex: 'orderStatus',
      render: (_: any, record: IPurchaseOrder) => {
        const status = record.status as keyof typeof ORDER_STATUS;
        return (
          <Tag color={ORDER_STATUS[status].color}>
            {ORDER_STATUS[status].name}
          </Tag>
        );
      }
    }
  ];

  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      <Container>
        <TableBody>
          <TableHeader>
            <Title level={5}>Recent purchase order</Title>
            <Link to="/purchase-order">See all</Link>
          </TableHeader>
          <Table columns={columns} dataSource={source} pagination={false} />
        </TableBody>
      </Container>
    </Spin>
  );
};
