import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  notification
} from 'antd';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NumberInput, OfftakenSummary, Option, Title } from 'src/components';
import { ButtonFlex } from 'src/pages/styles';
import { useAppSelector } from 'src/store';
import {
  ICollateralManagerPort,
  IIndentRequest,
  IOfftakeMaterial,
  IWarehouse,
  useCreateOfftakeRawMaterialMutation,
  useGetAllWarehousesQuery,
  useGetOffTakingDetailsQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';
import styled from 'styled-components';

interface IFormField {
  offTakeOrders: Array<{
    rawMaterial: string;
    quantityDischarged: number;
    warehouse: IWarehouse;
    logisticsCompany: string;
    driverName: string;
    phone: string;
    truckNo: string;
  }>;
}
export const OfftakeRawMaterial = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const user = useAppSelector(
    (state) => state.auth.user! as ICollateralManagerPort
  );
  const [releaseOrder] = useState<IIndentRequest>(
    (state as { releaseOrder: IIndentRequest }).releaseOrder
  );

  const { data: warehouses } = useGetAllWarehousesQuery(
    generateUrl({ port: user.port.id, limit: 100 })
  );
  const [createOfftakeRawMaterial, { isLoading }] =
    useCreateOfftakeRawMaterialMutation();

  const handleSubmit = (values: IFormField) => {
    const payload: IOfftakeMaterial = {
      releaseOrderId: releaseOrder.id,
      data: values
    };

    createOfftakeRawMaterial(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Raw material offtake successful.'
        });
        form.resetFields();
        navigate(-1);
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  const { data: offTakingDetail } = useGetOffTakingDetailsQuery(
    releaseOrder.id
  );
  const offTakeOrders = [
    {
      rawMaterial: '',
      quantityDischarged: '',
      warehouse: '',
      logisticsCompany: '',
      driverName: '',
      phone: '',
      truckNo: ''
    }
  ];
  const handleCancelOfftake = () => {
    form.resetFields();
    navigate(-1);
  };

  return (
    <>
      <Container>
        <HeadingContainer>
          <Breadcrumb>
            <Breadcrumb.Item>Purchase order</Breadcrumb.Item>
            <Breadcrumb.Item>ID - {releaseOrder.releaseId}</Breadcrumb.Item>
          </Breadcrumb>
          <HeadingWrapper>
            <CustomTitleText level={5}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{ marginRight: '10px' }}
              />
              Offtake raw material
            </CustomTitleText>
            <Button danger onClick={handleCancelOfftake}>
              Cancel offtake
            </Button>
          </HeadingWrapper>
        </HeadingContainer>
        <InfoSectionContainer>
          <OfftakenSummary
            offTakingDetail={offTakingDetail?.data}
            orders={releaseOrder.orders ?? []}
          />
        </InfoSectionContainer>
        <FormWrapper>
          <FormTitle level={5}>Offtake raw material</FormTitle>
          <Divider />

          <Form
            layout={'vertical'}
            onFinish={handleSubmit}
            form={form}
            validateTrigger={'onBlur'}
          >
            <Form.List name={'offTakeOrders'} initialValue={offTakeOrders}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row
                      key={index}
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      align="middle"
                    >
                      <Col span={3}>
                        <Form.Item
                          name={[field.name, 'rawMaterial']}
                          label={'Raw Material'}
                          rules={[
                            { required: true, message: 'field is required' }
                          ]}
                        >
                          <Select placeholder="Select Raw Material" allowClear>
                            {releaseOrder?.orders?.map((item) => (
                              <Option value={item.rawMaterial.id}>
                                {item.rawMaterial.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          name={[field.name, 'quantityDischarged']}
                          label=" Quantity"
                          rules={[
                            { required: true, message: 'field is required' }
                          ]}
                        >
                          <NumberInput
                            type={'number'}
                            placeholder=" Enter quantity"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'warehouse']}
                          label=" Warehouse"
                          rules={[
                            { required: true, message: 'field is required' }
                          ]}
                        >
                          <Select placeholder="Select Warehouse" allowClear>
                            {warehouses?.data.map((warehouse) => (
                              <Option value={warehouse.id}>
                                {warehouse.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'logisticsCompany']}
                          label=" Logistics Company"
                          rules={[
                            { required: true, message: 'field is required' }
                          ]}
                        >
                          <Select placeholder="Company" allowClear>
                            {releaseOrder.logistics.map((logistic) => (
                              <Option value={logistic.transporter}>
                                {logistic.transporter}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          name={[field.name, 'driverName']}
                          label=" Drivers Name"
                          rules={[
                            { required: true, message: 'field is required' }
                          ]}
                        >
                          <Input
                            placeholder="Drivers name"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          name={[field.name, 'phone']}
                          label=" Mobile Number"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: ' phone number is required'
                            },
                            {
                              pattern: /^((\+234)|0)[0-9]{10}$/,
                              message: 'please enter a valid number'
                            }
                          ]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          name={[field.name, 'truckNo']}
                          label=" Truck Number"
                          rules={[
                            { required: true, message: 'field is required' }
                          ]}
                        >
                          <Input
                            placeholder="truck number"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <RemoveField onClick={() => remove(index)} />
                      </Col>
                    </Row>
                  ))}
                  <ButtonFlex>
                    <Button
                      title="Add Field"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        add({
                          rawMaterial: '',
                          quantityDischarged: '',
                          warehouse: '',
                          logisticsCompany: '',
                          driverName: '',
                          phone: '',
                          truckNo: ''
                        })
                      }
                    >
                      Add Field
                    </Button>
                  </ButtonFlex>
                </>
              )}
            </Form.List>
            <Divider />
            <SubmitButtonWrapper>
              <Button
                loading={isLoading}
                type={'primary'}
                onClick={() => form.submit()}
              >
                Yes, Offtake
              </Button>
            </SubmitButtonWrapper>
          </Form>
        </FormWrapper>
      </Container>
    </>
  );
};
const Container = styled.div`
  height: max-content;
  margin-left: auto;
`;
const HeadingContainer = styled.div`
  background-color: #ffffff;
  padding: 18px 24px;
  margin-bottom: 32px;
`;
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;
const FormWrapper = styled.div`
  background-color: #ffffff;
  margin: 22px;
  padding: 16px 24px;
`;

const FormTitle = styled(Title)`
  margin-top: 5px;
`;
const CustomTitleText = styled(Title)`
  margin: 0;
`;

const InfoSectionContainer = styled.div`
  width: '100%';
  height: '100%';
  padding: 0 32px;
`;
const RemoveField = styled(CloseOutlined)`
  font-size: 20px;
  color: #ff7875;
  padding: 8px;
  background: #fafafa;
  border: 1px solid #f5f5f5;
  border-radius: 32px;
`;
const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
