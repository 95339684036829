import { Space, Table, Input } from 'antd';
import { Typography } from 'antd';
import { AddSiteModal } from './component/AddSiteModal';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { DeleteBlendingSiteModal } from './component/DeleteBlendingSiteModal';
import {
  ActionItem,
  Container,
  TableBody,
  TableHeader
} from 'src/pages/nsia-admin/utilities/styles';
import { IBlendingSite, useGetAllSitesQuery } from 'src/store/api';
import { generateUrl } from 'src/utils';
import { EditSiteModal } from './component/EditBlendingSiteModal';

type IAddSiteModal = {
  addBlendingSite: boolean;
  setAddBlendingSite: Dispatch<SetStateAction<boolean>>;
};
export const BlendingSite: FC<IAddSiteModal> = ({
  addBlendingSite,
  setAddBlendingSite
}) => {
  const [editSiteModal, setEditSiteModal] = useState<IBlendingSite | null>(
    null
  );
  const [deleteModal, setDeleteModal] = useState<IBlendingSite | null>(null);
  const [clickedblendingSite, setClickedblendingSite] = useState({
    name: '',
    location: '',
    isVisible: false
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { data: getSites } = useGetAllSitesQuery(
    generateUrl({
      limit: 10,
      search,
      page
    })
  );

  const sites = useMemo(() => {
    return (
      getSites?.data.map((item) => ({
        key: item.id,
        ...item,
        locationName: item?.location?.name
      })) ?? []
    );
  }, [getSites]);

  const columns = [
    {
      title: 'Blending-site Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'location'
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_: any, record: IBlendingSite) => (
        <Space size="middle">
          <ActionItem
            onClick={() => {
              setEditSiteModal(record);
            }}
          >
            Edit
          </ActionItem>
          |
          <ActionItem
            color={'rgba(245, 34, 45, 1)'}
            onClick={() => {
              setDeleteModal(record);
            }}
          >
            Delete
          </ActionItem>
        </Space>
      )
    }
  ];

  const { Title } = Typography;
  const { Search } = Input;
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      {addBlendingSite && (
        <AddSiteModal
          open={addBlendingSite}
          closeModal={() => setAddBlendingSite(false)}
          siteDetails={clickedblendingSite}
        />
      )}
      {!!editSiteModal && (
        <EditSiteModal
          open={!!editSiteModal}
          siteDetails={editSiteModal}
          closeModal={() => setEditSiteModal(null)}
        />
      )}
      <AddSiteModal
        open={clickedblendingSite.isVisible}
        closeModal={() =>
          setClickedblendingSite({
            ...clickedblendingSite,
            isVisible: false
          })
        }
        siteDetails={clickedblendingSite}
      />
      {!!deleteModal && (
        <DeleteBlendingSiteModal
          open={!!deleteModal}
          blendingSiteDetails={deleteModal}
          closeModal={() => setDeleteModal(null)}
        />
      )}
      <TableBody>
        <TableHeader>
          <Title level={4}>Blending Site</Title>
          <Search
            placeholder="Search"
            onSearch={onSearch}
            onChange={(e) => !e.target.value && setSearch('')}
            style={{ width: 264 }}
          />
        </TableHeader>
        <Table
          pagination={{
            defaultCurrent: 1,
            pageSize: 10,
            total: getSites?.pagination?.totalCount,
            onChange: (page) => {
              setPage(page);
            }
          }}
          columns={columns}
          dataSource={sites}
        />
      </TableBody>
    </Container>
  );
};
