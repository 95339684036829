import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  ICollateralManagerBlendingPlant,
  useDeleteCollateralManagerBlendingPlantMutation
} from 'src/store/api';
import styled from 'styled-components';
import { IModal } from 'src/pages/nsia-admin/stakeholders/collateralManager/interface';

export const DeleteCMBlendingPlant: FC<
  IModal<ICollateralManagerBlendingPlant>
> = ({ open, closeModal, collateralManager }) => {
  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(true);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== collateralManager.name);
  }, [name, collateralManager.name]);

  const [deleteCollateralManager, { isLoading }] =
    useDeleteCollateralManagerBlendingPlantMutation();

  const handleSubmit = () => {
    deleteCollateralManager(collateralManager.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted a collateral manager'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  const handleFinish = () =>{
    if (!buttonDisable){
      handleSubmit()
    }
  }
  return (
    <Modal
      visible={open}
      title="Delete Collateral Manager"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,

        <Button
          danger
          disabled={buttonDisable}
          loading={isLoading}
          type={'primary'}
          onClick={() => form.submit()}
        >
          Yes, Delete
        </Button>
      ]}
      width={660}
    >
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Form.Item
          key={1}
          name={'name'}
          label={
            <FormLabel>
              Are you sure delete "
              <CollateralManagerText>
                {collateralManager?.name}
              </CollateralManagerText>
              ”, this action cannot be undone
            </FormLabel>
          }
        >
          <Input placeholder="Enter the collateral manager name to proceed" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const CollateralManagerText = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
