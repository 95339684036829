import { AnalyticsCard, Title } from 'src/components';
import { Body, CardDiv, CardRow, Container, CustomHeader } from './style';
import VesselsIcon from 'src/assets/dashboardIcons/vesselsIcon.svg';
import { MaterialsDistributionChart, RecentVessels } from './components';
import { useGetClearingAgentStatsQuery } from 'src/store/api';


export const Dashboard = () => {
  const { data: getDashboardStats } = useGetClearingAgentStatsQuery();

  return (
    <Container>
      <CustomHeader>
        <Title>Clearing Agent</Title>
      </CustomHeader>
      <Body>
        <CardRow>
          <AnalyticsCard
            width="32.5%"
            value={getDashboardStats?.data?.vesselsImported}
            title="Number of vessels  imported"
            icon={VesselsIcon}
          />
          <AnalyticsCard
            width="32.5%"
            title="Vessels cleared"
            icon={VesselsIcon}
            value={getDashboardStats?.data?.vesselCleared}
          />
          <AnalyticsCard
            value={getDashboardStats?.data?.awaitingClearnace}
            width="32.5%"
            title="Vessels awaiting clearance"
            icon={VesselsIcon}
          />
        </CardRow>
        <CardDiv>
          <MaterialsDistributionChart />
        </CardDiv>
        <RecentVessels />
      </Body>
    </Container>
  );
};
