import { FC, useMemo, useState } from 'react';
import { Button, Form, Modal, Steps, notification } from 'antd';
import styled from 'styled-components';
import { useCreateIndentRequestMutation } from 'src/store/api/indent-request';
import { SelectRawMaterial, Logistics, Confirm } from '.';
import { IIndentRequest } from './interface';
import { RcFile } from 'antd/es/upload';
import { cloudinaryUpload } from 'src/utils/uploadToCloudinary';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const CreateIndent: FC<IModal> = ({ open, closeModal }) => {
  const [current, setCurrent] = useState<number>(0);
  const [payload, setPayload] = useState<IIndentRequest | undefined>();
  const [createIndent] = useCreateIndentRequestMutation();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const next = async () => {
    try {
      const res = await form.validateFields();
      setPayload((prev) => (prev ? { ...prev, ...res } : { ...res }));
      setCurrent(current + 1);
    } catch (error) {}
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCreate = async () => {
    setIsUploading(true);

    if (!payload) {
      setIsUploading(false);
      return;
    }

    try {
      const logistics = await Promise.all(
        payload.logistics.map(async (logistic) => {
          let res;

          if (
            logistic.documents.fileList &&
            logistic.documents.fileList.length > 0
          ) {
            const file = logistic.documents.fileList[0];
            res = await cloudinaryUpload(file.originFileObj as RcFile);
          }

          const { documents, ...logisticWithoutDocuments } = logistic;

          return {
            ...logisticWithoutDocuments,
            documents: [
              { name: res?.original_filename ?? '', url: res?.url ?? '' }
            ]
          };
        })
      );

      createIndent({ orders: payload.orders, logistics })
        .unwrap()
        .then(() => {
          notification['success']({
            message: 'Your raw material request has been sent successfully.'
          });
          closeModal();
        })
        .catch((err) => {
          notification['error']({
            message: err.error
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    } catch (error) {
      notification['error']({
        message: error as string
      });
      setIsUploading(false);
    }
  };

  const steps = useMemo(
    () => [
      {
        key: 'RawMaterial',
        title: 'Raw Material',
        content: <SelectRawMaterial />
      },
      {
        key: 'Logistics',
        title: 'Logistics',
        content: <Logistics />
      },
      {
        key: 'Confirm',
        title: 'Confirm',
        content: <Confirm summary={payload!} />
      }
    ],
    [payload]
  );

  return (
    <Modal
      visible={open}
      title="Add Indent"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <>
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={prev}>
              Back
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button loading={isUploading} type="primary" onClick={handleCreate}>
              Yes, Place request
            </Button>
          )}
        </>
      ]}
      width={1000}
    >
      <Form layout="vertical" form={form} validateTrigger="onBlur">
        <StepWrapper>
          <Steps current={current} items={steps} />
        </StepWrapper>
        {steps[current].content}
      </Form>
    </Modal>
  );
};

const StepWrapper = styled.div`
  margin-bottom: 20px;
`;
