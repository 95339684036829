import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Spin, Table } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import reportsIcon from 'src/assets/reportsIcon.svg';
import { Text } from 'src/components';
import { IVesselDischargeReport, useGetSingleVesselQuery } from 'src/store/api';
import { dateTimeFormater, formatNumber } from 'src/utils';
import styled from 'styled-components';
import { ButtonContainer } from '../styles';
import { Container, CustomTitleText, HeadingWrapper, Wrapper } from './style';

interface BottomBodyProps {
  isWhite?: boolean;
  isLast?: boolean;
}

const SingleDischargeReport = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [vesselReport] = useState<IVesselDischargeReport>(
    (state as { vesselReport: IVesselDischargeReport }).vesselReport
  );
  const { data: fetchSingleVessel, isLoading } = useGetSingleVesselQuery({
    id: vesselReport.purchaseOrder.id,
    port: vesselReport.port.id
  });

  const Columns = [
    { title: 'Date', key: 'date', dataIndex: 'date' },
    {
      title: 'Opening Quantity',
      key: 'openQuantity',
      dataIndex: 'openQuantity'
    },
    {
      title: 'Warehouse',
      key: 'warehouse',
      dataIndex: 'warehouse'
    },
    {
      title: 'Quantity Discharged',
      key: 'quantityDischarged',
      dataIndex: 'quantityDischarged',
      render: (request: number) => <Text>{formatNumber(request ?? 0)} MT</Text>
    },
    {
      title: 'Balance on Ship',
      key: 'shipBalance',
      dataIndex: 'shipBalance',
      render: (request: number) => <Text>{formatNumber(request ?? 0)} MT</Text>
    }
  ];
  const dataSource = useMemo(() => {
    const dailyStockMovementData =
      fetchSingleVessel?.data.dailyStockMovement.map((item) => ({
        ...item,
        date: dateTimeFormater(item.date),
        warehouse: item.warehouse.name,
        openQuantity: `${formatNumber(item.openingQuantity ?? 0)} MT`,
        quantityDischarged: item.quantityDischarged,
        shipBalance: item.balance
      })) ?? [];

    const totalQuantityDischarged = dailyStockMovementData?.reduce(
      (acc, item) => acc + (item.quantityDischarged ?? 0),
      0
    );
    const totalBalance = dailyStockMovementData?.reduce(
      (acc, item) => acc + (item.shipBalance ?? 0),
      0
    );

    // Add the summary row
    const summaryRow = {
      date: 'Summary ship movements: (Upcountry / Into shed)',
      openQuantity: null,
      quantityDischarged: formatNumber(totalQuantityDischarged ?? 0),
      shipBalance: formatNumber(totalBalance ?? 0)
    };

    return [...dailyStockMovementData, summaryRow];
  }, [fetchSingleVessel?.data]);

  return (
    <Container>
      <HeadingWrapper>
        <div className="arrowFlex">
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ marginBottom: '10px', cursor: 'pointer' }}
          />
          <CustomTitleText level={4}>Reports</CustomTitleText>
        </div>
        <ButtonContainer>
          <Button>Download report</Button>
        </ButtonContainer>
      </HeadingWrapper>
      <Wrapper>
        <InfoCardHeader>
          <div className="flex">
            <img src={reportsIcon} alt="report" />
            <p>
              {`${vesselReport.purchaseOrder.vesselName} - ${vesselReport.rawMaterial.name} - ${vesselReport.port.name}`}
            </p>
          </div>
        </InfoCardHeader>
      </Wrapper>
      <Spin spinning={isLoading}>
        <TableBody>
          <Table
            columns={Columns}
            dataSource={dataSource}
            pagination={false}
            rowClassName={(record) =>
              record.date === 'Summary ship movements: (Upcountry / Into shed)'
                ? 'total-row'
                : ''
            }
          />
          <BottomHeader style={{ marginTop: 30 }}>
            <h1>Post Discharge Quantities Analysis</h1>
          </BottomHeader>
          <BottomBody isWhite={false}>
            <h1>Actual ship quantity</h1>
            <p>
              {formatNumber(fetchSingleVessel?.data.actualShipQuantity ?? 0)} MT
            </p>
          </BottomBody>
          <BottomBody isWhite>
            <h1>Bill of laden quantities</h1>
            <p>
              {formatNumber(fetchSingleVessel?.data.estimatedQuantity ?? 0)} MT
            </p>
          </BottomBody>
          <BottomBody isWhite={false} isLast>
            <h1>Excess quantity</h1>
            <p>
              {formatNumber(fetchSingleVessel?.data.excessQuantity ?? 0)} MT
            </p>
          </BottomBody>
        </TableBody>
      </Spin>
    </Container>
  );
};

export default SingleDischargeReport;

const TableBody = styled.div`
  padding: 30px;
`;

const BottomHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  align-items: center;
  h1 {
    font-size: 16.8px;
    font-style: normal;
    font-weight: 600;
    line-height: 26.4px;
  }
  border: 1px solid #b9d4c6;
  height: 60px;
`;

const BottomBody = styled(BottomHeader)<BottomBodyProps>`
  background-color: ${({ isWhite }) => (isWhite ? '#FFFFFF' : '#F2F5F7')};
  justify-content: space-between;
  font-family: 'Myriad Pro';
  border-bottom: ${({ isLast }) => (isLast ? '1px solid #b9d4c6' : 'none')};
  border-top: none;

  h1 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  p {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`;

const InfoCardHeader = styled.div`
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 78px;
  display: flex;
  align-items: center;
  padding: 7px 24px;
  margin-bottom: 15px;
`;
