import { Response } from 'src/store/genericInterface';
import { baseApi } from '../baseApi';
import { tagTypes } from '../baseApi/tagTypes';
import { IClearingAgentChart, IClearingAgentStat } from './interface';

const clearingAgentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClearingAgentStats: builder.query<
      Response<IClearingAgentStat>,
      string | void
    >({
      query: () => ({
        url: `/clearing-agent/stat`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.ClearingAgentStats }]
    }),
    getClearingAgentCharts: builder.query<
      Response<IClearingAgentChart[]>,
      string | void
    >({
      query: (query) => ({
        url: `/clearing-agent/chart?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.ClearingAgentStats }]
    })
  })
});

export const {
  useGetClearingAgentStatsQuery,
  useGetClearingAgentChartsQuery,
  useLazyGetClearingAgentChartsQuery
} = clearingAgentApi;
