import { FC } from 'react';
import styled from 'styled-components';

interface IStockDetails {
  rawMaterialName: string;
  totalStock: number;
  rawMaterialQuantity: number;
  color: string;
}

export const StockChart: FC<IStockDetails> = ({
  rawMaterialName,
  rawMaterialQuantity,
  color
}) => {
  return (
    <ChartWrapper>
      <RawMaterialName color={color}>{rawMaterialName}</RawMaterialName>

      <RawMaterialQuantity>{rawMaterialQuantity}</RawMaterialQuantity>
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const RawMaterialName = styled.div`
  font-family: 'Myriad Pro';
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.color};
  width: 20%;
`;

const RawMaterialQuantity = styled(RawMaterialName)`
  color: rgba(0, 0, 0, 0.45);
  width: 20%;
  text-align: right;
`;
