import { Response } from 'src/store/genericInterface';
import { baseApi } from '../baseApi';
import {
  IForgetPasswordPayload,
  ILoginPayload,
  ILoginResponse,
  IResetPasswordPayload
} from './interface';

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginPayload>({
      query: (payload) => ({
        url: '/auth/login',
        method: 'POST',
        data: payload
      })
    }),
    forgotPassword: builder.mutation<Response, IForgetPasswordPayload>({
      query: (payload) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        data: payload
      })
    }),
    resetPassword: builder.mutation<Response, IResetPasswordPayload>({
      query: (payload) => ({
        url: '/auth/reset-password',
        method: 'PUT',
        data: payload
      })
    })
  })
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
} = authApi;
