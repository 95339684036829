import { Button, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import PortIcon from 'src/assets/dashboardIcons/portsIcon.svg';
import StakeholdersIcon from 'src/assets/dashboardIcons/stakeholdersIcon.svg';
import { AnalyticsCard, RangePicker, Search } from 'src/components';
import {
  CardRow,
  CustomHeader,
  CustomMain,
  CustomTitle
} from 'src/pages/reports/style';
import { TableBody } from 'src/pages/styles';
import { IUser, Role, useAppSelector } from 'src/store';
import {
  useGetProductionSummaryReportQuery,
  useGetProductionSummaryStatsQuery
} from 'src/store/api/nsia/reports';
import {
  dateTimeFormater,
  endOfMonth,
  formatNumber,
  generateSerialNumber,
  generateUrl,
  startOfMonth
} from 'src/utils';
import { ProductionSummaryDownloadModal } from './components/ProductionSummaryModal';
import { ActionWrap } from './style';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const ProductionSummaryReport = () => {
  const [viewDownloadModal, setViewDownloadModal] = useState(false);
  const [search, setSearchParam] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [queryParam, setParam] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });
  const user = useAppSelector((store) => store.auth.user) as IUser;
  const { data: getProductionSummaryReport, isLoading } =
    useGetProductionSummaryReportQuery(
      generateUrl({
        search,
        page,
        limit: 10,
        ...queryParam
      })
    );

  const { data: getProductionSummaryStats } = useGetProductionSummaryStatsQuery(
    generateUrl({ ...queryParam, search })
  );
  const handleSearch = (searchValue: string) => {
    setSearchParam(searchValue);
  };
  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParam({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };
  const source = useMemo(() => {
    return (
      getProductionSummaryReport?.data.map((item, index) => ({
        key: item._id,
        serialNum: generateSerialNumber(page, index),
        blendingSite: item.blendingSite.name,
        npk: item.npk.name,
        quantity: formatNumber(`${item.quantiyProduced} MT`),
        date: dateTimeFormater(item.createdAt)
      })) ?? []
    );
  }, [getProductionSummaryReport, page]);

  const nsiaColumn =
    user.role === Role.NSIA_ADMIN
      ? [
          {
            title: 'Blending site',
            dataIndex: 'blendingSite',
            key: 'blendingSite'
          }
        ]
      : [];

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'serialNum',
      key: 'serialNum'
    },
    ...nsiaColumn,
    {
      title: 'NPK',
      dataIndex: 'npk',
      key: 'npk'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  return (
    <>
      {viewDownloadModal && (
        <ProductionSummaryDownloadModal
          open={viewDownloadModal}
          closeModal={() => setViewDownloadModal(false)}
          urlQueryParam={generateUrl({
            download: 'true',
            search,
            ...queryParam
          })}
        />
      )}
      <CustomHeader>
        <div className="arrowFlex">
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          />
          <CustomTitle>Production summary reports</CustomTitle>
        </div>
        <div>
          <Button onClick={() => setViewDownloadModal(true)}>
            Download report
          </Button>
        </div>
      </CustomHeader>

      <CustomMain>
        <CustomHeader>
          <CustomTitle>Report overview</CustomTitle>
          <ActionWrap>
            <RangePicker
              defaultValue={[startOfMonth(), endOfMonth()]}
              onChange={(datejs) => handleDateChange(datejs)}
            />
            <Search
              onChange={(e) => !e.target.value && setSearchParam('')}
              placeholder="Search"
              style={{ width: 264 }}
              onSearch={handleSearch}
            />
          </ActionWrap>
        </CustomHeader>

        <CardRow>
          {user.role === Role.NSIA_ADMIN ? (
            <AnalyticsCard
              title="Blending plants"
              value={getProductionSummaryStats?.data.blendingPlants}
              icon={StakeholdersIcon}
              width="80%"
            />
          ) : null}
          <AnalyticsCard
            title="NPK types"
            value={getProductionSummaryStats?.data.npkTypes}
            icon={PortIcon}
            width={user.role === Role.NSIA_ADMIN ? '80%' : '100%'}
          />
          <AnalyticsCard
            title="Total Production Volume"
            value={getProductionSummaryStats?.data.totalQty}
            icon={PortIcon}
            width={user.role === Role.NSIA_ADMIN ? '80%' : '100%'}
          />
        </CardRow>

        <TableBody>
          {/* <TableHeader>
            <Title level={4}></Title>
          </TableHeader> */}
          <Spin spinning={isLoading} tip="Loading" size="large">
            <Table
              dataSource={source}
              columns={columns}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                total: getProductionSummaryReport?.pagination.totalCount ?? 0,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </Spin>
        </TableBody>
      </CustomMain>
    </>
  );
};
