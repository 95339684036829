import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NSIA, Role } from '../constant';

export interface IUser {
  email: string;
  role: Role;
  name: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}
const initialState: {
  user: IUser | null;
  remeberMe: boolean;
  access_token: string | null;
} = {
  remeberMe: false,
  user: null,
  access_token: null
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload;
      const storage = state.remeberMe ? localStorage : sessionStorage;
      storage.setItem(NSIA.NSIA_TOKEN, JSON.stringify(state.access_token));
      return state;
    },
    setUser: (state, action: PayloadAction<IUser | null>) => {
      const storage = state.remeberMe ? localStorage : sessionStorage;
      if (action.payload) {
        state.user = action.payload;
        storage.setItem(NSIA.NSIA_USER, JSON.stringify(state.user));
      } else {
        state = initialState;
        storage.clear();
      }
      return state;
    },
    setRemeber: (state, action: PayloadAction<boolean>) => {
      state.remeberMe = action.payload;
      localStorage.setItem(NSIA.NSIA_REMEMBER_ME, `${+action.payload}`);
      return state;
    }
  }
});

export const { setUser, setRemeber, setToken } = authSlice.actions;
