import {
  CloseCircleOutlined,
  CloseOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { Drawer, List, Spin } from 'antd';
import parse from 'html-react-parser';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import sanitize from 'sanitize-html';
import { useAppSelector } from 'src/store';
import {
  INotification,
  useGetAllNotificationsQuery,
  useUpdateNotificationMutation
} from 'src/store/api/navigation';
import { dateTimeFormater } from 'src/utils';
import styled from 'styled-components';
import { NotificationTypeConfig } from './enum';
import { generateUrl } from 'src/utils';
interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const NotificationDrawer: FC<IModal> = ({ open, closeModal }) => {
  const { data: notifications, isLoading } = useGetAllNotificationsQuery(
    generateUrl({ isRead: false })
  );

  return (
    <Drawer
      title={
        <TopRow>
          <p>Notification</p>
          <IconRow>
            <CloseCircleOutlined onClick={closeModal} />
          </IconRow>
        </TopRow>
      }
      placement={'right'}
      closable={false}
      onClose={closeModal}
      open={open}
      width={'517px'}
      closeIcon={<CloseOutlined />}
    >
      <Spin spinning={isLoading}>
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={notifications?.data}
          renderItem={(notification) => (
            <Notification
              notification={notification}
              closeDrawer={closeModal}
            />
          )}
        />
      </Spin>
    </Drawer>
  );
};

const Notification: FC<{
  notification: INotification;
  closeDrawer: () => void;
}> = ({ notification, closeDrawer }) => {
  const [updateNotification] = useUpdateNotificationMutation();
  const userRole = useAppSelector((state) => state.auth.user!.role);
  const route = NotificationTypeConfig[notification.type]?.[userRole];
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(route);

    updateNotification({ notifications: [notification.id] })
      .unwrap()
      .then(() => {
        closeDrawer();
      })
      .catch(() => {});
  };
  return (
    <ListContainer onClick={handleClick}>
      <IconContainer isRead={notification.isRead}>
        <NotificationOutlined
          size={50}
          color={notification.isRead ? '#000' : '#006C33'}
        />
      </IconContainer>
      <TextSection>
        <TopRow>
          <ListHeader isRead={notification.isRead}>
            {notification.type}
          </ListHeader>
          <NotificationDate>
            {dateTimeFormater(notification.createdAt)}
          </NotificationDate>
        </TopRow>
        <ListDescription>
          {parse(sanitize(notification.message))}
        </ListDescription>
      </TextSection>
    </ListContainer>
  );
};
const ListContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  margin: 16px 0;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--neutral-4, #f0f0f0);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
`;
const IconContainer = styled.div<{ isRead: boolean }>`
  padding: 14px;
  border-radius: 60px;
  border: 1px solid #d9d9d9;
  background: #f0ffd6;
  width: 20px;
  height: 20px;
`;
const TextSection = styled.div`
  width: 100%;
`;
const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NotificationDate = styled.p`
  margin: 0;
  color: var(--neutral-7, #8c8c8c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
const ListHeader = styled.h4<{ isRead: boolean }>`
  margin: 0;
  color: ${({ isRead }) => (isRead ? '#000' : '#006C33')};
`;
const ListDescription = styled.p`
  margin: 0;
`;
const IconRow = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
`;
