import { Button, Modal, Form, Input, notification } from 'antd';
import { FC } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { GooglePlace } from 'src/components';
import { IAddPortPayload, IPort, useEditPortMutation } from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
  portDetails: IPort;
}

export const EditPortModal: FC<IModal> = ({
  open,
  closeModal,
  portDetails
}) => {
  const [handleEditPorts, { isLoading }] = useEditPortMutation();
  const [form] = Form.useForm();
  const handleSelected = async (data: {
    label: string;
    value: { place_id: string };
  }) => {
    const [res] = await geocodeByPlaceId(data.value.place_id);
    form.setFieldValue('location', {
      name: res.formatted_address,
      latitude: res.geometry.location.lat(),
      longitude: res.geometry.location.lng()
    });
  };

  const handleSubmit = (payload: IAddPortPayload) => {
    const payloadData: Partial<IAddPortPayload> = {};
    if (payload.name !== portDetails.name) {
      payloadData.name = payload.name;
    }
    if (payload.operator !== portDetails.operator) {
      payloadData.operator = payload.operator;
    }
    if (
      payload.location.name.toLowerCase() !==
      portDetails.location.name.toLowerCase()
    ) {
      payloadData.location = payload.location;
    }
    if (!portDetails.id) {
      return;
    }
    handleEditPorts({ body: payloadData, id: portDetails.id })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated port details'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  return (
    <Modal
      open={open}
      title="Edit Port"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Update
        </Button>
      ]}
      width={660}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          name: portDetails.name,
          location: { name: portDetails.location.name },
          operator: portDetails.operator
        }}
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label="Name" key={'1'}>
          <Input placeholder="Enter port name" autoComplete='off' />
        </Form.Item>
        <Form.Item name="location" label="Location" key={'2'}>
          <GooglePlace
            defaultInputValue={portDetails.location.name}
            onChange={handleSelected}
          />
        </Form.Item>
        <Form.Item name="operator" label="Operator" key={'3'}>
          <Input placeholder="Enter Operator" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};
