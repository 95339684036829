import { PageHeader } from '@ant-design/pro-layout';
import { Select, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text, Title } from 'src/components';
import { RawMaterialTag } from 'src/pages/styles';
import { useAppSelector } from 'src/store';
import {
  ICollateralManagerPort,
  useGetAllWarehousesQuery,
  useGetWarehouseStockSummaryQuery
} from 'src/store/api';
import { formatNumber, generateUrl } from 'src/utils';
import styled from 'styled-components';
import { ReleaseTable } from './component';

export const ReleaseOrders = () => {
  const navigate = useNavigate();
  const onChange = (key: string) => {
    navigate(`/release-instructions#${key}`);
  };
  const user = useAppSelector(
    (state) => state.auth.user! as ICollateralManagerPort
  );
  const { hash } = useLocation();
  const status = useMemo(() => hash.replace('#', ''), [hash]);
  const { data: warehouses } = useGetAllWarehousesQuery(
    generateUrl({ port: user.port.id, limit: 100 })
  );
  const [warehouse, setWareHouse] = useState('');
  const { data: warehouseStockSummary } = useGetWarehouseStockSummaryQuery(
    generateUrl({ warehouse })
  );
  const handleChange = (value: string) => {
    setWareHouse(value);
  };
  const summary = useMemo(() => {
    if (Array.isArray(warehouseStockSummary?.data)) {
      return warehouseStockSummary?.data.find(
        (value) => value.warehouse.id === warehouse
      );
    }
  }, [warehouseStockSummary, warehouse]);
  useEffect(() => {
    if (warehouses?.data) {
      setWareHouse(warehouses.data?.[0]?.id ?? '');
    }
  }, [warehouses]);

  return (
    <>
      <CustomHeader>
        <Title>Raw Materials Request</Title>
      </CustomHeader>
      <WarehouseStockContainer>
        <Select
          value={warehouse}
          style={{ width: 120 }}
          onChange={handleChange}
          options={warehouses?.data.map((warehouse) => ({
            value: warehouse.id,
            label: warehouse.name
          }))}
        />
        <div>
          {summary?.rawMaterials?.map(({ name, availableQuantity }, index) => (
            <RawMaterialTag color="blue" key={index}>
              {name} :<Text>{formatNumber(availableQuantity ?? 0)}MT</Text>
            </RawMaterialTag>
          ))}
        </div>
      </WarehouseStockContainer>
      <CustomTab
        defaultActiveKey={status}
        onChange={onChange}
        items={[
          {
            label: `All Requests`,
            key: '',
            children: <ReleaseTable />
          },
          {
            label: `Active`,
            key: 'off-taking',
            children: <ReleaseTable />
          },
          {
            label: `Approved`,
            key: 'approved',
            children: <ReleaseTable />
          },
          {
            label: `Completed`,
            key: 'completed',
            children: <ReleaseTable />
          }
        ]}
      />
    </>
  );
};

const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
const WarehouseStockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 8px 48px 16px 24px;
`;
