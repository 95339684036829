export enum PURCHASE_ORDER_STATUS {
  ORDER_PLACED = 'order_placed',
  DISCHARGED = 'discharging',
  VESSEL_ARRIVED = 'vessel_arrived',
  VESSSEL_CLEARED = 'vessel_cleared'
}
export const ORDER_STATUS = {
  order_placed: {
    name: 'Order placed',
    color: 'default',
    status: 'order_placed',
    buttonText: 'Confirm Arrival'
  },
  discharging: {
    name: 'Discharging',
    color: 'gold',
    status: 'discharging',
    buttonText: 'Discharge vessel'
  },
  vessel_arrived: {
    name: 'Vessel arrived',
    color: 'default',
    status: 'vessel_arrived',
    buttonText: 'Discharge vessel'
  },
  vessel_cleared: {
    name: 'Vessel cleared',
    color: 'green',
    status: 'vessel_cleared',
    buttonText: 'Discharge vessel'
  }
};

export const PAAR_STATUS = {
  sent: { name: 'Recieved', color: 'green', disableViewPaar: false },
  not_sent: { name: 'Not Recieved', color: 'default', disableViewPaar: true }
};
export const DISCHARGE_ORDER_STATUS = {
  flagged: {
    color: 'red',
    name: 'Flagged',
    update: true
  },
  confirmed: {
    color: 'green',
    name: 'Confirmed',
    update: false
  },
  discharged: {
    color: 'default',
    name: 'Discharged',
    update: false
  }
};

export const FLAGGED_DISCHARGE_ORDER = {
  confirm: { title: 'confirm', state: false },
  resolve: { title: 'resolve', state: true }
};
