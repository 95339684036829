import {
  CustomHeader,
  CustomTitle,
  CardRow,
  CustomMain,
  ActionWrap
} from 'src/pages/reports/style';
import { TableBody, TableHeader } from 'src/pages/styles';
import { Spin, Table } from 'antd';
import PortIcon from 'src/assets/dashboardIcons/portsIcon.svg';
import { Button, Dropdown, Space } from 'antd';
import { AnalyticsCard, RangePicker, Search, Title } from 'src/components';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import {
  startOfMonth,
  endOfMonth,
  generateUrl,
  generateSerialNumber,
  dateTimeFormater,
  formatNumber
} from 'src/utils';
import {
  useGetAllWarehousesQuery,
  useReportsWarehouseOfftakesQuery
} from 'src/store/api';
import { WarehouseOfftakesDownloadModal } from './components/WarehouseOfftakesDownloadModal';

export const WarehouseOfftakeReport = () => {
  const [viewDownloadModal, setViewDownloadModal] = useState(false);
  const [search, setSearchParam] = useState('');
  const [page, setPage] = useState(1);
  const [queryParam, setParam] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString()
  });
  const { data: warehouses, isLoading: isWarehouseLoading } =
    useGetAllWarehousesQuery();

  const [warehouse, setWarehouse] = useState<string>('');
  const { data: warehouseOfftakes, isLoading } =
    useReportsWarehouseOfftakesQuery(
      generateUrl({ search, page, ...queryParam, warehouse })
    );
  const warehouseDropDownItems = useMemo(() => {
    const obj: {
      [key: string]: {
        label: string;
        key: string;
      };
    } = {};
    warehouses?.data.forEach((item) => {
      obj[item.id] = {
        label: item.name,
        key: item.id
      };
    });
    setWarehouse(Object.keys(obj)[0] ?? '');
    return obj;
  }, [warehouses]);

  const source = useMemo(
    () =>
      warehouseOfftakes?.data?.map((item, index) => ({
        ...item,
        serialNum: generateSerialNumber(page, index),
        offtaker: item.blender.name,
        key: item.offtaking.id,
        collateralManager: item.collateralManager.name,
        rawMaterial: item.rawMaterial.name,
        quantity: formatNumber(`${item.offtaking.quantity} MT`),
        date: dateTimeFormater(item.offtaking.createdAt)
      })) ?? [],
    [warehouseOfftakes, page]
  );

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'serialNum',
      key: 'serialNum'
    },
    {
      title: 'Blender (Offtaker)',
      dataIndex: 'offtaker',
      key: 'offtaker'
    },
    {
      title: 'Collateral Manager',
      dataIndex: 'collateralManager',
      key: 'collateralManager'
    },
    {
      title: 'Raw Material',
      dataIndex: 'rawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  const handleSearch = (searchValue: string) => {
    setSearchParam(searchValue);
  };
  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setParam({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };

  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      {viewDownloadModal && (
        <WarehouseOfftakesDownloadModal
          open={viewDownloadModal}
          urlQueryParam={generateUrl({
            download: 'true',
            search,
            warehouse,
            ...queryParam
          })}
          closeModal={() => setViewDownloadModal(false)}
        />
      )}
      <CustomHeader>
        <CustomTitle>Warehouse report (Offtakes)</CustomTitle>

        <Button onClick={() => setViewDownloadModal(true)}>
          Download report
        </Button>
      </CustomHeader>
      <CustomMain>
        <CustomHeader>
          <CustomTitle>Offtake Report</CustomTitle>
          <ActionWrap>
            <Dropdown
              menu={{
                items: Object.values(warehouseDropDownItems),
                onClick: (item) => setWarehouse(item.key)
              }}
            >
              <Button loading={isWarehouseLoading}>
                <Space>
                  {warehouseDropDownItems[warehouse]?.label || 'Warehouse'}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <RangePicker
              defaultValue={[startOfMonth(), endOfMonth()]}
              onChange={(datejs) => handleDateChange(datejs)}
            />
            <Search
              onChange={(e) => !e.target.value && setSearchParam('')}
              placeholder="Search"
              style={{ width: 264 }}
              onSearch={handleSearch}
            />
          </ActionWrap>
        </CustomHeader>
        <CardRow>
          <AnalyticsCard
            title="Total Raw Materials Offtaken"
            value={`${warehouseOfftakes?.totalRawMaterialOfftaken} MT`}
            icon={PortIcon}
            width="100%"
          />
        </CardRow>

        <TableBody>
          <TableHeader>
            <Title level={4}>View Reports</Title>
          </TableHeader>
          <Table
            dataSource={source}
            columns={columns}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: warehouseOfftakes?.pagination?.totalCount,
              onChange: (page) => {
                setPage(page);
              }
            }}
          />
        </TableBody>
      </CustomMain>
    </Spin>
  );
};
