import styled from 'styled-components';

export const TableBody = styled.div`
  background-color: white;
`;
export const Container = styled.div`
  width: '100%';
  height: '100%';
  padding-block-end: 32px;
`;
export const ViewButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #1890ff;
`;