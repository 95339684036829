import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Spin, Table, Tag } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  OfftakenSummary,
  Text,
  Title,
  ViewShippingDocument
} from 'src/components';
import LogisticsAccordion from 'src/components/LogisticsAccordion';
import { StatusContainer } from 'src/pages/cmPort/purchase-order/styles';
import {
  Container,
  CustomHeader,
  RejectButton,
  ViewButton
} from 'src/pages/styles';
import {
  BlenderOfftakingStatusConfig,
  IIndentRequest,
  IOffTakingDetail,
  IOrderAmend,
  OfftakingStatus,
  useGetAmendOrdersQuery,
  useGetIndentRequestByIdQuery,
  useGetOffTakingDetailsQuery
} from 'src/store/api';
import { dateTimeFormater, formatNumber } from 'src/utils';
import styled from 'styled-components';
import { ConfirmOfftakeModal } from './components';
import { AmendOrderTable } from './components/AmmendOrderTable';
import { AmendRequest } from './components/AmmendRequestModal';

export const ApprovedIndentOrder = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [indentRequest] = useState<IIndentRequest>(
    (state as { indentRequest: IIndentRequest }).indentRequest
  );
  const { data: indentData } = useGetIndentRequestByIdQuery(indentRequest.id);

  const [openConfirmModal, setOpenConfirmModal] = useState<{
    record: IOffTakingDetail;
    status: 'confirm' | 'reject';
  } | null>(null);
  const { data: offTakingDetail, isLoading } = useGetOffTakingDetailsQuery(
    indentRequest.id
  );

  const [activePanel, setActivePanel] = useState<number>(0);

  const [viewOrders, setViewOrders] = useState(false);

  const [viewShippingDocuments, setViewShippingDocuments] =
    useState<IIndentRequest | null>(null);

  const { data: amendedOrderDetail, isLoading: isAmending } =
    useGetAmendOrdersQuery(indentRequest.id);

  const [viewTable, setViewTable] = useState(true);
  const [viewFeedback, setViewFeedback] = useState(false);

  const [viewRequest, setViewRequest] = useState<IOrderAmend | null>(null);

  const dataSource = useMemo(
    () =>
      offTakingDetail?.data.offTakings.map((offtaking, index) => ({
        ...offtaking,
        sn: index + 1,
        key: offtaking._id,
        tableRawMaterial: offtaking.rawMaterial.name,
        tableQuantityDischarged: `${formatNumber(
          offtaking.quantityDischarged
        )}MT`,
        tableWarehouse: offtaking.warehouse.name,
        driversName: offtaking.driverName,
        driversMobileNo: offtaking.phone,
        truckId: offtaking.truckNo,
        tableQuantityRecieved: offtaking.quantityReceived
          ? `${formatNumber(offtaking.quantityReceived)}MT`
          : 'N/A',
        date: dateTimeFormater(offtaking.createdAt)
      })),
    [offTakingDetail]
  );

  const buttonStyle = {
    color: viewTable ? 'red' : 'blue',
    border: 'none'
  };

  const handleView = (
    record: IOffTakingDetail,
    status: 'confirm' | 'reject'
  ) => {
    setOpenConfirmModal({ record, status });
  };
  const columns = [
    {
      title: 'Raw materials',
      dataIndex: 'tableRawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Warehouse',
      dataIndex: 'tableWarehouse',
      key: 'warehouse'
    },
    {
      title: 'Quantity discharged',
      dataIndex: 'tableQuantityDischarged',
      key: 'quantityDischarged'
    },
    {
      title: 'Driver name',
      dataIndex: 'driversName',
      key: 'driversName'
    },
    {
      title: 'Mobile number',
      dataIndex: 'driversMobileNo',
      key: 'driversMobileNo'
    },
    {
      title: 'Truck ID',
      dataIndex: 'truckId',
      key: 'truckId'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, record: IOffTakingDetail) => {
        const status = BlenderOfftakingStatusConfig[record.offTakingStatus];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
            {record.offTakingStatus === OfftakingStatus.AWAITING_TRUCK_ID && (
              <>
                <RejectButton onClick={() => handleView(record, 'reject')}>
                  Reject
                </RejectButton>
                <ViewButton onClick={() => handleView(record, 'confirm')}>
                  {status.action}
                </ViewButton>
              </>
            )}
          </StatusContainer>
        );
      }
    }
  ];

  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      <CustomHeader>
        <SmallTitle>
          <SMText>Release Order / </SMText>
          <SMTextId>{' ' + indentRequest.releaseId}</SMTextId>
        </SmallTitle>

        <HeaderWrap>
          <AlteredHeaderText>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginRight: '17px' }}
            />{' '}
            Release order - {indentRequest.releaseId}
          </AlteredHeaderText>
          <Button onClick={() => setViewShippingDocuments(indentRequest)}>
            View document
          </Button>
        </HeaderWrap>
      </CustomHeader>
      <Container>
        <OfftakenSummary
          offTakingDetail={offTakingDetail?.data}
          orders={indentData?.data.orders!}
        />
        <LogisticsAccordion
          activePanel={activePanel}
          indentRequest={indentRequest}
          setActivePanel={setActivePanel}
          setViewOrders={setViewOrders}
          viewOrders={viewOrders}
        />
        <TableTitle>
          <Title level={4}>Offtake tracking</Title>
        </TableTitle>

        <Table pagination={false} columns={columns} dataSource={dataSource} />
        {amendedOrderDetail && amendedOrderDetail?.data.length > 0 && (
          <>
            <AmendTitle>
              <Title level={4}>Amendent Tracker</Title>

              <Button
                style={buttonStyle}
                onClick={() => setViewTable(!viewTable)}
              >
                {viewTable ? 'Collapse' : 'Expand'}
              </Button>
            </AmendTitle>
            {viewTable ? (
              <AmendOrderTable
                isLoading={isAmending}
                amendedOrderDetails={amendedOrderDetail?.data}
                setViewRequest={setViewRequest}
              />
            ) : null}
          </>
        )}
        {indentRequest?.feedback ? (
          <>
            <AmendTitle>
              <Title level={4}>Update Feedback</Title>

              <Button
                style={buttonStyle}
                onClick={() => setViewFeedback(!viewFeedback)}
              >
                {viewFeedback ? 'Collapse' : 'Expand'}
              </Button>
            </AmendTitle>
            {viewFeedback && (
              <FeedbackDiv>
                <div className="textBox">{indentRequest.feedback}</div>
              </FeedbackDiv>
            )}
          </>
        ) : null}
      </Container>
      {!!viewRequest && (
        <AmendRequest
          detailOfftake={offTakingDetail?.data}
          open={!!viewRequest}
          closeModal={() => setViewRequest(null)}
          amendOrder={viewRequest}
        />
      )}
      {!!openConfirmModal && (
        <ConfirmOfftakeModal
          open={!!openConfirmModal}
          closeModal={() => setOpenConfirmModal(null)}
          indentRequestId={openConfirmModal.record.indentRequest}
          id={openConfirmModal.record._id}
          truckId={openConfirmModal.record.truckNo}
          status={openConfirmModal.status}
        />
      )}
      {!!viewShippingDocuments && (
        <ViewShippingDocument
          open={!!viewShippingDocuments}
          closeModal={() => setViewShippingDocuments(null)}
          shippingDocuments={viewShippingDocuments}
        />
      )}
    </Spin>
  );
};

const FeedbackDiv = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 40px;
  background-color: #ffffff;
  .textBox {
    display: flex;
    padding: 21px 27px;
    flex-wrap: wrap;
    border: 1px solid rgba(24, 144, 255, 0.2);
    width: 100%;
  }
`;

const SmallTitle = styled.div`
  display: flex;
`;
const AmendTitle = styled(SmallTitle)`
  margin-top: 30px;
  background-color: #f6ffed;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
`;
const SMText = styled.div`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
`;
const SMTextId = styled.div`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;
const TableTitle = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  justify-content: space-between;
  h4 {
    margin: 0;
    padding: 16px;
  }
`;
export const Wrap = styled.div`
  width: '100%';
  height: '100%';
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
  margin-block: 32px;
`;
export const BlendingSiteContainer = styled.div`
  flex: 2;
  background-color: #fafafa;
`;
const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AlteredHeaderText = styled(Text)`
  margin-block: 22px;
  font-family: Myriad Pro;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0em;
`;
