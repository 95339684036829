import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';
import { useGetCmportChartQuery } from 'src/store/api/cmport/dashboard/api';
import { generateUrl } from 'src/utils';

export const useGetRawMaterialChart = () => {
  const [queryParam, setQueryParam] = useState({
    startDate: dayjs(new Date()).startOf('month').toISOString(),
    endDate: dayjs(new Date()).endOf('month').toISOString()
  });
  const { data: chartResponse } = useGetCmportChartQuery(
    generateUrl(queryParam)
  );

  const data = useMemo(() => {
    const res: {
      name: string;
      rawmaterial: string;
      amount: number;
    }[] = [];

    chartResponse?.data.forEach((item) => {
      res.push({
        name: 'RELEASED',
        rawmaterial: item.name,
        amount: item.released
      });
      res.push({
        name: 'OFFTAKE',
        rawmaterial: item.name,
        amount: item.offtaken
      });
    });
    return res;
  }, [chartResponse]);

  const config = {
    data,
    isGroup: true,
    xField: 'rawmaterial',
    yField: 'amount',
    seriesField: 'name',
    color: ['#52C41A', '#EB2F96'],
    marginRatio: 0.4,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    }
  };

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] !== null && datejs[1] !== null) {
      setQueryParam({
        startDate: datejs[0].toISOString(),
        endDate: datejs[1].toISOString()
      });
    }
  };

  return {
    handleDateChange,
    config
  };
};
