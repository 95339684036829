import { Tag } from 'antd';
import { FC } from 'react';
import { Text } from 'src/components';
import { IOffTakings, IRawMaterial, IWarehouse } from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

type IOfftakenSummary = {
  orders: {
    rawMaterial: IRawMaterial;
    estimatedQuantity: number;
    id: string;
    warehouse: IWarehouse;
    previousQuantity?: number | undefined;
  }[];

  offTakingDetail?: IOffTakings;
};
export const OfftakenSummary: FC<IOfftakenSummary> = ({
  orders,
  offTakingDetail
}) => {
  return (
    <Container>
      <InfoSection>
        <QuantityInfo>
          <TextDetails>
            <Text className="titleText">
              Total released tonnage :{' '}
              <Text strong>
                {formatNumber(offTakingDetail?.totalTonnage ?? 0)}MT
              </Text>{' '}
            </Text>
            <Text strong></Text>
          </TextDetails>
        </QuantityInfo>
        <QuantityInfo>
          <TextDetails>
            <Text className="titleText">
              Total offtaken tonnage :{' '}
              <Text strong>
                {formatNumber(offTakingDetail?.totalOffTaking ?? 0)}MT
              </Text>{' '}
            </Text>
            <Text strong></Text>
          </TextDetails>
        </QuantityInfo>
        <QuantityInfo rightAlign>
          <TextDetails>
            <Text className="titleText">Warehouse</Text>
            <Text strong></Text>
          </TextDetails>
        </QuantityInfo>
      </InfoSection>
      <InfoSection>
        <QuantityInfo>
          <div className="materialColumn">
            {orders?.map((order, index) => (
              <RawMaterialTag color="blue" key={index}>
                {order.rawMaterial.name} -{' '}
                <Text>{formatNumber(order.estimatedQuantity ?? 0)}MT</Text>
              </RawMaterialTag>
            ))}
          </div>
        </QuantityInfo>
        <QuantityInfo>
          <div className="materialColumn">
            {orders?.map((order, index) => (
              <RawMaterialTag color="blue" key={index}>
                {order.rawMaterial.name} -{' '}
                <Text>
                  {formatNumber(
                    offTakingDetail?.totalOfftakingGroupedByRawMaterial?.[
                      order.rawMaterial.id
                    ]?.totalOfftakenQuantity ?? 0
                  )}
                  MT
                </Text>
              </RawMaterialTag>
            ))}
          </div>
        </QuantityInfo>
        <QuantityInfo rightAlign>
          {orders?.map((order, index) => (
            <TextDetails isGrey key={index}>
              {order.warehouse?.code}
            </TextDetails>
          ))}
        </QuantityInfo>
      </InfoSection>
    </Container>
  );
};
const Container = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: #f0f5ff;
  border: 1px solid #adc6ff;
  margin-bottom: 40px;
`;
const QuantityInfo = styled.div<{ rightAlign?: boolean }>`
  flex-direction: column;
  min-width: fit-content;
  border-radius: 4px;
  padding: 12px 16px;
  column-gap: 12px;
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: ${({ rightAlign }) => (rightAlign ? 'flex-end' : 'flex-start')};
  row-gap: 16px;
  .materialColumn {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const TextDetails = styled.div<{ isGrey?: boolean }>`
  background-color: ${({ isGrey }) => (isGrey ? '#F5F5F5' : 'inherit')};
  display: flex;
  border-radius: 4px;
  padding: ${({ isGrey }) => (isGrey ? '10px' : '0px')};
  justify-content: space-between;
  font-weight: 600;
  column-gap: 4px;
  .titleText {
    color: #8c8c8c;
  }
`;

const InfoSection = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 0px;
  border-radius: 8px;
  background-color: #fff;
  row-gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const RawMaterialTag = styled(Tag)`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border-radius: 4px;
  padding: 6px 8px;
  background-color: #e6f7ff;
`;
