import { NSIA } from '../constant';
import { IUser } from '../slices';

export const getPreloadedState = () => {
  const remeberMe =
    localStorage.getItem(NSIA.NSIA_REMEMBER_ME) === '1' ? true : false;
  const storage = remeberMe ? localStorage : sessionStorage;
  const defalutValue = {
    auth: {
      remeberMe,
      user: (storage.getItem(NSIA.NSIA_USER)
        ? JSON.parse(storage.getItem(NSIA.NSIA_USER)!)
        : null) as IUser | null,
      access_token: (storage.getItem(NSIA.NSIA_TOKEN)
        ? JSON.parse(storage.getItem(NSIA.NSIA_TOKEN)!)
        : null) as string | null
    }
  };
  return defalutValue;
};
