import { tagTypes } from 'src/store/api/baseApi/tagTypes';
import { baseApi } from 'src/store/api/baseApi';
import { ICMPortChart, ICMPortStats, ICMPortStock } from './interface';
import { Response } from 'src/store/genericInterface';

const cmpApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCmportChart: builder.query<Response<ICMPortChart[]>, string | void>({
      query: (query) => ({
        url: `/collateral-managers/chart-data?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Chart }]
    }),
    getCmportStats: builder.query<Response<ICMPortStats>, string | void>({
      query: () => ({
        url: '/collateral-managers/stat',
        method: 'GET'
      })
    }),
    getCmPortStock: builder.query<Response<ICMPortStock[]>, string | void>({
      query: () => ({
        url: '/collateral-managers/stock',
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Stock }]
    })
  })
});

export const {
  useGetCmportChartQuery,
  useGetCmportStatsQuery,
  useGetCmPortStockQuery
} = cmpApi;
