import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import axios from 'axios';
import { baseApi } from '../api/baseApi';
import { authSlice, indentRequest, setUser } from '../slices';
import { getPreloadedState } from './getPreloadedState';

export const store = configureStore({
  preloadedState: getPreloadedState(),
  reducer: {
    api: baseApi.reducer,
    auth: authSlice.reducer,
    indentRequest: indentRequest.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware)
});
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response && error?.response.status === 401) {
      store.dispatch(setUser(null));
    }
    return Promise.reject(error);
  }
);
