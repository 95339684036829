import { Card } from 'antd';
import { TextWrap } from 'src/pages/styles';
import styled from 'styled-components';
import { RawMaterialName, RawMaterialQuantity } from 'src/pages/styles';

export const ProductionCharts = () => {
  return (
    <StyledCard title="Raw materials in stock">
      <TextWrap>
        <RawMaterialName>DAP</RawMaterialName>
        <RawMaterialQuantity>0MT</RawMaterialQuantity>
      </TextWrap>
    </StyledCard>
  );
};
const StyledCard = styled(Card)`
  margin-bottom: 20px;
  width: 40%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
