import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Tabs } from 'antd';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Title } from 'src/components';
import { useGetRawMaterialsQuery } from 'src/store/api';
import styled from 'styled-components';
import { BlendingSite } from './blending-site';
import { Ports } from './ports';
import { RawMaterial } from './raw-material';
import { Warehouse } from './warehouse';
import { Supplier } from './supplier';

export const Utilites = () => {
  const [addPortModalVisible, setAddPortModalVisible] = useState(false);

  const navigate = useNavigate();
  const { hash } = useLocation();

  const onChange = (key: string) => {
    navigate(key);
  };
  const [addWarehouse, setAddWarehouse] = useState(false);
  const [addSupplier, setAddSupplier] = useState(false);
  const [addBlendingSite, setAddBlendingSite] = useState(false);
  const [addRawMaterial, setAddRawMaterial] = useState(false);
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  const operations = {
    '#ports': (
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setAddPortModalVisible(true)}
      >
        Add Ports
      </Button>
    ),
    '#raw-material':
      (rawMaterials?.data?.length ?? 0) < 7 ? (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setAddRawMaterial(true)}
        >
          Add raw material
        </Button>
      ) : null,
    '#warehouse': (
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setAddWarehouse(true);
        }}
      >
        Add warehouse
      </Button>
    ),
    '#blending-site': (
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setAddBlendingSite(true);
        }}
      >
        Add blending site
      </Button>
    ),
    '#supplier': (
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setAddSupplier(true);
        }}
      >
        Add Supplier
      </Button>
    )
  };
  type operationsKey = keyof typeof operations;
  return (
    <>
      <CustomHeader>
        <Title>Utilities</Title>
      </CustomHeader>

      <CustomTab
        tabBarExtraContent={operations[(hash as operationsKey) || '#ports']}
        defaultActiveKey={hash}
        onChange={onChange}
        items={[
          {
            label: `Ports`,
            key: '#ports',
            children: (
              <Ports
                setAddPortModalVisible={setAddPortModalVisible}
                addPortModalVisible={addPortModalVisible}
              />
            )
          },
          {
            label: `Raw material`,
            key: '#raw-material',
            children: (
              <RawMaterial
                closeAddModal={() => setAddRawMaterial(false)}
                openAddModal={addRawMaterial}
              />
            )
          },
          {
            label: `Warehouse`,
            key: '#warehouse',
            children: (
              <Warehouse
                add={addWarehouse}
                closeModal={() => setAddWarehouse(false)}
              />
            )
          },
          {
            label: `Blending site`,
            key: '#blending-site',
            children: (
              <BlendingSite
                addBlendingSite={addBlendingSite}
                setAddBlendingSite={setAddBlendingSite}
              />
            )
          },
          {
            label: `Supplier`,
            key: '#supplier',
            children: (
              <Supplier
                add={addSupplier}
                closeModal={() => setAddSupplier(false)}
              />
            )
          }
        ]}
      />
    </>
  );
};

const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
