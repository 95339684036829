import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import LogisticsFieldPanel from './LogisticsPanel';

export const Logistics: FC = () => {
  const [activePanel, setActivePanel] = useState<number>(0);

  return (
    <Form.List
      key={2}
      name={'logistics'}
      initialValue={[
        { transporter: '', contactPerson: '', phone: '', documents: null }
      ]}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <LogisticsFieldPanel
              field={field}
              index={index}
              key={field.key}
              remove={remove}
              value={activePanel}
              onchange={setActivePanel}
            />
          ))}
          <ButtonFlex>
            <Button
              title="Add Field"
              icon={<PlusOutlined />}
              onClick={() => {
                add({
                  transporter: '',
                  contactPerson: '',
                  phone: '',
                  documents: null
                });
                setActivePanel(fields.length);
              }}
            >
              Add Logistics
            </Button>
          </ButtonFlex>
        </>
      )}
    </Form.List>
  );
};

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
`;

export const DraggerDiv = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  height: 12px;
`;
