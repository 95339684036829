import { Button, Form, Input, Modal, Select, notification } from 'antd';
import { FC } from 'react';
import {
  IAddCleringAgent,
  useAddClearingAgentMutation,
  useGetAllPortsQuery
} from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
}

export const AddClearingAgents: FC<IModal> = ({ open, closeModal }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { data: ports } = useGetAllPortsQuery();
  const [addClearingAgentMutation, { isLoading }] =
    useAddClearingAgentMutation();
  const handleSubmit = (payload: IAddCleringAgent) => {
    addClearingAgentMutation(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a Clearing Agent.'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  return (
    <Modal
      open={open}
      title="Add Clearing agent"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button
          loading={isLoading}
          onClick={() => form.submit()}
          htmlType="submit"
          type="primary"
        >
          Add Clearing Agent
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name"
          key={'1'}
          rules={[
            { required: true, message: 'Clearing agent name is required' }
          ]}
        >
          <Input placeholder="Enter Clearing agent name" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email address"
          key={'3'}
          rules={[
            { required: true, message: 'Clearing agent email is required' }
          ]}
        >
          <Input placeholder="Enter address" autoComplete="off" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="phone"
          label="Phone number"
          key={'4'}
          rules={[
            {
              required: true,
              message: 'Clearing agent phone number is required'
            },
            {
              pattern: /^((\+234)|0)[0-9]{10}$/,
              message: 'please enter a valid number'
            }
          ]}
        >
          <Input placeholder="Enter phone number" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="port"
          label="Port"
          rules={[{ required: true, message: 'port is required' }]}
          key={'5'}
        >
          <Select
            placeholder="Select Port"
            showSearch
            allowClear
            //  filerOption={(input, option) => (option?.label ?? '').includes(input)}
          >
            {ports?.data.map((port) => (
              <Option key={port.id} value={port.id}>
                {port.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
