import { Button, Modal, Form, Input, Select, notification } from 'antd';
import { FC, useState } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import {
  IAddWarehousePayload,
  IWarehouse,
  useEditWarehouseMutation,
  useGetAllPortsQuery
} from 'src/store/api';
import { generateUrl } from 'src/utils';
import { GooglePlace } from 'src/components';
interface IModal {
  open: boolean;
  closeModal: () => void;
  wareHouseDetails: IWarehouse;
}

export const EditWarehouseModal: FC<IModal> = ({
  open,
  closeModal,
  wareHouseDetails
}) => {
  const { data: getPorts } = useGetAllPortsQuery(generateUrl({}));
  const [handleEditWarehouse, { isLoading: isEditLoading }] =
    useEditWarehouseMutation();

  const handleOk = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };
  const handleSelected = async (data: {
    label: string;
    value: { place_id: string };
  }) => {
    const [res] = await geocodeByPlaceId(data.value.place_id);
    form.setFieldValue('location', {
      name: res.formatted_address,
      latitude: res.geometry.location.lat(),
      longitude: res.geometry.location.lng()
    });
  };

  const handleSubmit = (payload: IAddWarehousePayload) => {
    const payloadData: Partial<IAddWarehousePayload> = {};
    if (payload.name !== wareHouseDetails.name) {
      payloadData.name = payload.name;
    }
    if (
      payload.location?.name.toLowerCase() !==
      wareHouseDetails.location?.name?.toLowerCase()
    ) {
      payloadData.location = payload.location;
    }
    if (payload.port.toLowerCase() !== wareHouseDetails.port.id) {
      payloadData.port = payload.port;
    }
    handleEditWarehouse({ body: payloadData, id: wareHouseDetails.id })
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully updated warehouse details'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  const [nameValue, setNameValue] = useState('');
  const [code, setCode] = useState('');

  const { Option } = Select;

  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      title="Edit Warehouse"
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={isEditLoading}
        >
          Update
        </Button>
      ]}
      width={660}
    >
      <Form
        layout={'vertical'}
        initialValues={{
          name: wareHouseDetails.name,
          location: { name: wareHouseDetails.location?.name },
          port: wareHouseDetails.port.id,
          code: wareHouseDetails.code
        }}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name"
          key={'1'}
          rules={[{ required: true, message: 'Warehouse name is required' }]}
        >
          <Input
            placeholder="Enter Warehouse Name"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="code"
          label="Warehouse code"
          key={'1'}
          rules={[{ required: true, message: 'Warehouse code is required' }]}
        >
          <Input
            placeholder="Enter Warehouse code"
            disabled
            value={code}
            onChange={(e) => setCode(e.target.value)}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="location"
          label="Location"
          key={'2'}
          rules={[
            { required: true, message: 'Warehouse location is required' }
          ]}
        >
          <GooglePlace
            defaultInputValue={wareHouseDetails.location?.name}
            onChange={handleSelected}
          />
        </Form.Item>
        <Form.Item
          name="port"
          label="Port"
          key={'3'}
          rules={[{ required: true, message: 'Port name is required' }]}
        >
          <Select placeholder="Select Port" allowClear>
            {getPorts?.data.map((port, index) => (
              <Option key={index} value={port.id}>
                {port.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
