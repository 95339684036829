import styled from 'styled-components';
import background from '../../assets/authAssets/background.png';

export const AuthBg = styled.div`
  background-image: url(${background});
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  margin: auto;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CenterItem = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
`;

export const SpaceBelow = styled.div`
  margin-bottom: ${(props: { space?: string }) => props?.space || '16px'};
`;
