import { Button, Modal, Form, Input, notification } from 'antd';
import { FC } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { GooglePlace } from 'src/components';
import { IAddBlendingSitePayload, useAddSitesMutation } from 'src/store/api';

interface IModal {
  open: boolean;
  closeModal: () => void;
  siteDetails?: {
    name: string;
    location: string;
    isVisible: boolean;
  };
}

export const AddSiteModal: FC<IModal> = ({ open, closeModal }) => {
  const [handleAddBlendingSite, { isLoading }] = useAddSitesMutation();

  const handleSelected = async (data: {
    label: string;
    value: { place_id: string };
  }) => {
    const [res] = await geocodeByPlaceId(data.value.place_id);
    form.setFieldValue('location', {
      name: res.formatted_address,
      latitude: res.geometry.location.lat(),
      longitude: res.geometry.location.lng()
    });
  };
  const handleSubmit = (payload: IAddBlendingSitePayload) => {
    handleAddBlendingSite(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully added a blending site.'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const [form] = Form.useForm();
  return (
    <>
      <Modal
        open={open}
        title="Add Blending Site"
        onOk={closeModal}
        onCancel={closeModal}
        centered
        footer={[
          <Button
            key="submit"
            loading={isLoading}
            type="primary"
            onClick={() => form.submit()}
          >
            Add Blending site
          </Button>
        ]}
        width={660}
      >
        <Form layout={'vertical'} form={form} onFinish={handleSubmit}>
          <Form.Item
            name="name"
            label="Name"
            key={'1'}
            rules={[
              { required: true, message: 'Blending site name is required' }
            ]}
          >
            <Input placeholder="Enter blending site name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="code"
            label="Blending site Code"
            key={'1'}
            rules={[
              { required: true, message: 'Blending site code is required' }
            ]}
          >
            <Input placeholder="Enter blending site code" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            key={'2'}
            rules={[{ required: true, message: 'Site location is required' }]}
          >
            <GooglePlace onChange={handleSelected} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
