import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, UploadFile, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FC } from 'react';
import {
  IPurchaseOrder,
  IUploadStatement,
  useClearVesselClearingAgentMutation
} from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  closeModal: () => void;
  open: boolean;
  purchaseOrder: IPurchaseOrder;
  closeClearVessel: () => void;
  fileList: UploadFile<File>[];
}

export const ConfirmClearVessel: FC<IModal> = ({
  open,
  closeModal,
  purchaseOrder,
  closeClearVessel,
  fileList
}) => {
  const [clearVessel, { isLoading }] = useClearVesselClearingAgentMutation();

  const handleSubmit = () => {
    const files = new FormData();
    for (const file of fileList) {
      files.append('files', file as RcFile);
    }
    const payload: IUploadStatement = {
      id: purchaseOrder.id,
      files
    };
    clearVessel(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Vessel cleared successfully'
        });
        closeModal();
        closeClearVessel();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  return (
    <Modal
      open={open}
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button type="primary" loading={isLoading} onClick={handleSubmit}>
          Yes, clear vessel
        </Button>,

        <Button onClick={closeModal}>Cancel</Button>
      ]}
      width={432}
    >
      <InfoContainer>
        <ExclamationCircleOutlined
          style={{ fontSize: '20px', color: '#FFC53D' }}
        />
        <div>
          <EmphasizedText>Clear vessel</EmphasizedText>
          <InfoText>
            Are you sure you want to clear this vessel? Note that this action
            cannot be undone
          </InfoText>
        </div>
      </InfoContainer>
    </Modal>
  );
};

const EmphasizedText = styled.h4`
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: start;
  column-gap: 16px;
`;

const InfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.85);
`;
