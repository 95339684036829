import { FC } from 'react';
import {
  ICollateralManagerBlendingPlant,
  ICollateralManagerPort
} from 'src/store/api';
import { DeleteCMBlendingPlant } from './blending-plant';
import { MANAGER_TYPE } from './enum';
import { IModalWithType } from './interface';
import { DeleteCMPort } from './port';

export const DeleteCollateralManager: FC<IModalWithType> = ({
  type,
  collateralManager,
  ...props
}) => {
  return type === MANAGER_TYPE.PORT ? (
    <DeleteCMPort
      collateralManager={collateralManager as ICollateralManagerPort}
      {...props}
    />
  ) : (
    <DeleteCMBlendingPlant
      collateralManager={collateralManager as ICollateralManagerBlendingPlant}
      {...props}
    />
  );
};
