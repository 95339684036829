import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Dropdown, Spin, Table, Tag, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DISCHARGE_ORDER_STATUS } from 'src/pages/clearingAgents/purchaseOrder/enum';
import { StatusContainer } from 'src/pages/cmPort/purchase-order/styles';
import {
  Container,
  CustomHeader,
  InfoSection,
  MaterialInfo,
  QuantityInfo,
  TextDetails
} from 'src/pages/styles';
import {
  IPurchaseOrder,
  ITrackDischarge,
  useGetDischargeOrdersQuery,
  useGetSinglePurchaseOrderQuery
} from 'src/store/api';
import { dateTimeFormater, formatNumber, generateUrl } from 'src/utils';
import styled from 'styled-components';
import { ViewShipFactModal } from '../../../components/ViewShippingFact';
import { ViewPaarModal } from './components';
import { SendPaarModal } from './components/SendPaarModal';
import { PAAR_STATUS, PurchaseOrderStatus } from './enum';
const { Title, Text } = Typography;

export const SingleOrders = () => {
  const { state } = useLocation();
  const [openSendPaar, setOpenSendPaar] = useState<boolean>(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>(
    (state as { purchaseOrder: IPurchaseOrder }).purchaseOrder
  );
  const [openViewPaar, setOpenViewPaar] = useState<boolean>(false);
  const [viewFactModal, setViewFactModal] = useState(false);

  const { data: fetchDischargeOrders } = useGetDischargeOrdersQuery({
    purchaseId: purchaseOrder.id,
    query: generateUrl({
      limit: 10,
      page
    })
  });
  const paarStatus = purchaseOrder.paarStatus as keyof typeof PAAR_STATUS;

  const ports = useMemo(() => {
    return purchaseOrder?.portQuantities
      ?.map(
        (portQuantity) =>
          `${portQuantity.port.name} : ${formatNumber(portQuantity.quantity)}MT`
      )
      .join(',  ');
  }, [purchaseOrder]);

  const totalDischarged = useMemo(() => {
    return (
      purchaseOrder?.dischargeOrders?.reduce(
        (acc, current) => acc + Number(current.quantityDischarged),
        0
      ) ?? 0
    );
  }, [purchaseOrder]);
  const source = useMemo(() => {
    return fetchDischargeOrders?.data.map((item) => {
      return {
        tableRawMaterial: item.rawMaterial.name,
        tableCollateralManager: item.collateralManager.name,
        tableQuantityDischarged: formatNumber(`${item.quantityDischarged} MT`),
        tableWarehouse: item.warehouse.name,
        key: item.id,
        ports: item.warehouse.port.name,
        date: dateTimeFormater(item.createdAt, 'll'),
        ...item
      };
    });
  }, [fetchDischargeOrders]);

  const { data, isLoading } = useGetSinglePurchaseOrderQuery(purchaseOrder.id);

  useEffect(() => {
    if (data) {
      setPurchaseOrder(data.data);
    }
  }, [data]);

  const columns = [
    {
      title: 'Raw materials',
      dataIndex: 'tableRawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Quantity discharged',
      dataIndex: 'tableQuantityDischarged',
      key: 'quantity'
    },
    {
      title: 'Ports',
      dataIndex: 'ports',
      key: 'ports'
    },
    {
      title: 'Warehouse',
      dataIndex: 'tableWarehouse',
      key: 'warehouse'
    },
    {
      title: 'Collateral manager',
      dataIndex: 'tableCollateralManager',
      key: 'CollateralManager'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, record: ITrackDischarge) => {
        const status = record.status as keyof typeof DISCHARGE_ORDER_STATUS;
        return (
          <StatusContainer>
            <Tag color={DISCHARGE_ORDER_STATUS[status].color}>
              {DISCHARGE_ORDER_STATUS[status].name}
            </Tag>
          </StatusContainer>
        );
      }
    }
  ];

  return (
    <>
      {!!openSendPaar && (
        <SendPaarModal
          closeViewModal={() => setOpenSendPaar(false)}
          open={!!openSendPaar}
          purchaseOrder={purchaseOrder}
        />
      )}

      {!!openViewPaar && (
        <ViewPaarModal
          open={openViewPaar}
          setOpenSendPaar={setOpenSendPaar}
          closeModal={() => {
            setOpenViewPaar(false);
          }}
          viewPaar={purchaseOrder}
        />
      )}

      <CustomHeader>
        <SmallTitle>
          <SMText>Purchase order / </SMText>
          <SMTextId>{` ID - ${purchaseOrder.purchaseId} `}</SMTextId>
        </SmallTitle>

        <HeaderWrap>
          <Title level={3}>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginRight: '17px' }}
            />{' '}
            View Purchase order ID - {purchaseOrder.purchaseId}
          </Title>
          {purchaseOrder.status !== PurchaseOrderStatus.VESSEL_CLEARED ? (
            <Button
              type="primary"
              onClick={
                paarStatus === 'sent'
                  ? () => setOpenViewPaar(true)
                  : () => setOpenSendPaar(true)
              }
            >
              {PAAR_STATUS[paarStatus].text}
            </Button>
          ) : (
            <Dropdown
              menu={{
                items: [
                  {
                    key: 1,
                    label: PAAR_STATUS[paarStatus].text,
                    onClick: () => setOpenViewPaar(true)
                  },
                  {
                    key: 2,
                    label: 'View Statement of Fact',
                    onClick: () => setViewFactModal(true)
                  }
                ]
              }}
              placement="bottomRight"
            >
              <Button>Documents</Button>
            </Dropdown>
          )}
        </HeaderWrap>
      </CustomHeader>

      <Spin spinning={isLoading} tip="Loading" size="large">
        <Container>
          <InfoSection>
            <QuantityInfo>
              <TextDetails>
                <Text disabled>Ports: </Text>
                <Text strong>{ports}</Text>
              </TextDetails>
            </QuantityInfo>
            <MaterialInfo>
              {purchaseOrder.orders.map((order) => {
                return (
                  <TextDetails key={order.rawMaterial.id}>
                    <Text disabled>{order.rawMaterial.name}</Text>
                    <Text strong>{`${formatNumber(
                      order.estimatedQuantity
                    )} MT`}</Text>
                  </TextDetails>
                );
              })}
              <TextDetails>
                <Text disabled>Discharged</Text>
                <Text strong>{`${formatNumber(totalDischarged)}MT`}</Text>
              </TextDetails>
            </MaterialInfo>
          </InfoSection>

          <TableTitle>
            <Title level={4}>Discharge details</Title>
          </TableTitle>

          <Table
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              onChange: (page) => {
                setPage(page);
              }
            }}
            columns={columns}
            dataSource={source}
          />
        </Container>
        {viewFactModal && (
          <ViewShipFactModal
            open={viewFactModal}
            closeModal={() => setViewFactModal(false)}
            viewPaar={purchaseOrder}
          />
        )}
      </Spin>
    </>
  );
};

const SmallTitle = styled.div`
  display: flex;
`;
const SMText = styled.div`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
`;
const SMTextId = styled.div`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;
const TableTitle = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  h4 {
    margin: 0;
    padding: 16px;
  }
`;
const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
