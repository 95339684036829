import {
  DownloadOutlined,
  ReconciliationFilled,
  UploadOutlined
} from '@ant-design/icons';
import { Button, Divider, Modal, Typography } from 'antd';
import { FC } from 'react';
import Logo from 'src/assets/authAssets/logo.png';
import { DOCTYPE, IDocument, IPurchaseOrder } from 'src/store/api';
import styled from 'styled-components';

const { Title, Text } = Typography;

interface IModal {
  open: boolean;
  closeModal: () => void;
  viewPaar: IPurchaseOrder;
  setOpenSendPaar: (value: boolean) => void;
}

export const ViewPaarModal: FC<IModal> = ({
  open,
  closeModal,
  viewPaar,
  setOpenSendPaar
}) => {
  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <ButtonWrap>
          <Button onClick={closeModal}>Close</Button>{' '}
          <Button
            icon={<UploadOutlined />}
            type="primary"
            onClick={() => {
              closeModal();
              setOpenSendPaar(true);
            }}
          >
            Upload Shipping Documents
          </Button>{' '}
        </ButtonWrap>
      ]}
      width={640}
    >
      <TextWrapper>
        <MainHeader>
          <img src={Logo} alt="logo" />
        </MainHeader>

        <Title level={3}>Order Shipping Documents</Title>
        <PaarText>
          The shipping documents for purchase order with the
          <strong> ID: {viewPaar.purchaseId}</strong> received for discharging
          the order.
        </PaarText>
        <Divider />
        {viewPaar.documents?.reduce<JSX.Element[]>(
          (result, item: IDocument, index) => {
            if (item.documentType === DOCTYPE.PAAR) {
              result.push(
                <DownloadWrapper key={index}>
                  <DownloadIcon
                    rel="noreferrer"
                    target={'_blank'}
                    href={item.url}
                  >
                    <PaarIcon />
                    <Text strong className="viewText">
                      {item.name}
                    </Text>
                    <DownloadOutlined />
                  </DownloadIcon>
                </DownloadWrapper>
              );
            }
            return result;
          },
          []
        )}

        <Divider />
      </TextWrapper>
    </Modal>
  );
};

export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    align-self: center;
    margin-block: 33px 47px;
    object-fit: contain;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: auto;
  .viewText {
    margin-inline: 15px;
    text-transform: uppercase;
  }
`;
const PaarIcon = styled(ReconciliationFilled)`
  border: 0.5px dashed rgba(217, 217, 217, 1);
  border-radius: 8px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(250, 250, 250, 1);
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PaarText = styled.p`
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;
`;
const ButtonWrap = styled.div``;
const DownloadIcon = styled.a`
  color: #11b867;
`;
