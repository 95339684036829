import { PageHeader } from '@ant-design/pro-layout';
import { Spin, Table, Tabs, Tag } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, Title } from 'src/components';
import { IPurchaseOrder, useGetAllPurchaseOrderQuery } from 'src/store/api';
import { PaginatedResponse } from 'src/store/genericInterface';
import { dateTimeFormater, formatNumber, generateUrl } from 'src/utils';
import styled from 'styled-components';
import { ORDER_STATUS, PAAR_STATUS } from './enum';
import { Container, TableBody, TableHeader, ViewButton } from './styles';

interface ITableProps {
  columns: any[];
  purchaseOrderList: PaginatedResponse<IPurchaseOrder> | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  source: IPurchaseOrder[] | undefined;
  onSearch: (value: string) => void;
  setSearch: (value: React.SetStateAction<string>) => void;
}
export const PurchaseOrder = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { hash } = useLocation();
  const status = hash.replace('#', '');
  const { data: purchaseOrderList, isLoading } = useGetAllPurchaseOrderQuery(
    generateUrl({
      status: status,
      limit: 10,
      page,
      search
    })
  );

  const onChange = (key: string) => {
    navigate(`/purchase-order#${key}`);
  };
  const source = useMemo(() => {
    return purchaseOrderList?.data.map((purchaseOrder) => {
      let estimatedQuantity = Number(
        purchaseOrder.orders?.[0].estimatedQuantity ?? 0
      );
      let rawMaterials = purchaseOrder.orders?.[0].rawMaterial?.name ?? '';
      return {
        selectedPort: purchaseOrder.portQuantities
          .map((portQuantity) => portQuantity.port.name)
          .join(', '),
        estimatedQuantity: formatNumber(`${estimatedQuantity}MT`),
        key: purchaseOrder.purchaseId,
        selectedRawMaterials: rawMaterials,
        date: dateTimeFormater(purchaseOrder.arrivalTime, 'll'),
        ...purchaseOrder
      };
    });
  }, [purchaseOrderList]);
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'purchaseId',
      key: 'purchaseId'
    },
    {
      title: 'Ports',
      dataIndex: 'selectedPort',
      key: 'ports'
    },
    {
      title: 'Vessel name',
      dataIndex: 'vesselName',
      key: 'vesselName'
    },
    {
      title: 'Raw Material',
      dataIndex: 'selectedRawMaterials',
      key: 'rawMaterial'
    },
    {
      title: 'Estimated Quantity (MT)',
      dataIndex: 'estimatedQuantity',
      key: 'quantity'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Shipping Documents Status',
      dataIndex: 'paarStatus',
      render: (_: any, record: IPurchaseOrder) => {
        const paarStatus = record.paarStatus as keyof typeof PAAR_STATUS;
        return (
          <Tag color={PAAR_STATUS[paarStatus].color}>
            {PAAR_STATUS[paarStatus].name}
          </Tag>
        );
      }
    },
    {
      title: 'Order status',
      dataIndex: 'orderStatus',
      render: (_: any, record: IPurchaseOrder) => {
        const status = record.status as keyof typeof ORDER_STATUS;
        return (
          <Tag color={ORDER_STATUS[status].color}>
            {ORDER_STATUS[status].name}
          </Tag>
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, record: IPurchaseOrder) => {
        return (
          <ViewButton
            onClick={() =>
              navigate(`/purchase-order/${record.id}`, {
                state: { purchaseOrder: record }
              })
            }
          >
            Details
          </ViewButton>
        );
      }
    }
  ];
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <CustomHeader>
        <Title>Purchase order</Title>
      </CustomHeader>

      <Spin spinning={isLoading} tip="Loading" size="large">
        <CustomTab
          defaultActiveKey={status}
          activeKey={status}
          onChange={onChange}
          items={[
            {
              label: 'All Orders',
              key: '',

              children: (
                <OrderTable
                  columns={columns}
                  onSearch={onSearch}
                  purchaseOrderList={purchaseOrderList}
                  setPage={setPage}
                  setSearch={setSearch}
                  source={source}
                />
              )
            },
            {
              label: 'Active',
              key: 'active',

              children: (
                <OrderTable
                  columns={columns}
                  onSearch={onSearch}
                  purchaseOrderList={purchaseOrderList}
                  setPage={setPage}
                  setSearch={setSearch}
                  source={source}
                />
              )
            },
            {
              label: 'Completed',
              key: 'completed',

              children: (
                <OrderTable
                  columns={columns}
                  onSearch={onSearch}
                  purchaseOrderList={purchaseOrderList}
                  setPage={setPage}
                  setSearch={setSearch}
                  source={source}
                />
              )
            }
          ]}
        />
      </Spin>
    </>
  );
};

const OrderTable: FC<ITableProps> = ({
  onSearch,
  setSearch,
  source,
  columns,
  purchaseOrderList,
  setPage
}) => {
  return (
    <Container>
      <TableBody>
        <TableHeader>
          <Title level={4}>Orders</Title>
          <Search
            onSearch={onSearch}
            onChange={(e) => !e.target.value && setSearch('')}
            placeholder="Search"
            style={{ width: 264 }}
          />{' '}
        </TableHeader>

        <Table
          dataSource={source}
          columns={columns}
          pagination={{
            defaultCurrent: 1,
            pageSize: 10,
            total: purchaseOrderList?.pagination?.totalCount,
            onChange: (page) => {
              setPage(page);
            }
          }}
        />
      </TableBody>
    </Container>
  );
};
const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
