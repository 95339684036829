import { DownloadOutlined, ReconciliationFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { Modal, Typography, Button, Divider } from 'antd';
import { DOCTYPE, IDocument, IPurchaseOrder } from 'src/store/api';
import { FC } from 'react';
import { TextWrapper } from 'src/pages/nsia-admin/purchaseOrder/styles';
import Logo from 'src/assets/authAssets/logo.png';

const { Title, Text } = Typography;

interface IModal {
  open: boolean;
  closeModal: () => void;
  viewPaar: IPurchaseOrder;
}

export const ViewPaarModal: FC<IModal> = ({ open, closeModal, viewPaar }) => {
  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[<Button onClick={closeModal}>Close</Button>]}
      width={640}
    >
      <TextWrapper>
        <MainHeader>
          <img src={Logo} alt="logo" />
        </MainHeader>
        <Title level={3}>Shipping Documents Received</Title>
        <WriteUp>
          The shipping documents for purchase order with the{' '}
          <strong> ID : {viewPaar.purchaseId}</strong> received for discharging
          the order.
        </WriteUp>
        <Divider />
        {viewPaar.documents?.reduce(
          (result: JSX.Element[], item: IDocument, index) => {
            if (item.documentType === DOCTYPE.PAAR) {
              result.push(
                <DownloadWrapper key={index}>
                  <DownloadIcon
                    rel="noreferrer"
                    target={'_blank'}
                    href={item.url}
                  >
                    <PaarIcon />
                    <Text strong className="viewText">
                      {item.name}
                    </Text>
                    <DownloadOutlined />
                  </DownloadIcon>
                </DownloadWrapper>
              );
            }
            return result;
          },
          []
        )}
        <Divider />
      </TextWrapper>
    </Modal>
  );
};

export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    align-self: center;
    margin-block: 33px 47px;
    object-fit: contain;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: auto;
`;
const PaarIcon = styled(ReconciliationFilled)`
  border: 0.5px dashed rgba(217, 217, 217, 1);
  border-radius: 8px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(250, 250, 250, 1);
`;
const DownloadIcon = styled.a`
  color: #11b867;
  display: flex;
  gap: 10px;
  align-items: center;
`;
const WriteUp = styled.p`
  margin-left: 4px;
  margin-right: 4px;
`;
