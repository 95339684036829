import { PageHeader } from '@ant-design/pro-layout';
import { Divider, Space, Table, Tag } from 'antd';
import { Text } from 'src/components';
import styled from 'styled-components';

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  width: '100%';
  height: '100%';
  padding: 32px;
  .total-row {
    background-color: #e6f7ff;
  }
  .top {
    margin-top: 20px;
    height: 52px;
  }
`;

export const MaterialInfo = styled.div`
  background: #d9f7be;
  border-radius: 4px;
  padding: 12px 16px;
  column-gap: 12px;
  display: flex;
  flex: 1;
  min-width: fit-content;
  justify-content: space-between;
`;
export const QuantityInfo = styled.div`
  flex-direction: column;
  min-width: fit-content;
  border-radius: 4px;
  padding: 12px 16px;
  column-gap: 12px;
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  row-gap: 16px;
`;

export const TextDetails = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: space-between;
  font-weight: 600;
  column-gap: 4px;
  padding: 5px;
  color: #ffffff;
  .titleText {
    color: #8c8c8c;
  }
`;

export const CustomText = styled(Text)`
  max-width: 250px;
`;
export const ViewButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #1890ff;
`;
export const RejectButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #ff4d4f;
`;
export const TableBody = styled.div`
  background-color: white;
`;

export const TableHeader = styled.div`
  width: '100%';
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 88px;
  padding: 20px 10px;
`;

export const ActionItem = styled.h4`
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : '')};
`;
export const ActionSite = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
`;
export const InfoSection = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0px;
  background-color: #fff;
  row-gap: 10px;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
`;

export const TrackingInfoSection = styled(InfoSection)`
  align-items: center;
`;

export const QuantityDetails = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;
export const TrackingMaterialInfo = styled(MaterialInfo)`
  flex: 0.7;
`;
export const AddFieldContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;
`;
export const CustomTable = styled(Table)`
  border: 0.5px dashed #8c8c8c;
  margin-bottom: 24px;
`;
export const CustomSpace = styled(Space)`
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
`;
export const CustomCard = styled.div`
  background-color: rgba(245, 245, 245, 1);
  display: flex;
  column-gap: 5px;
  padding: 12px 25px; ;
`;
export const CustomDivider = styled(Divider)`
  margin-top: 12px; ;
`;
export const SubHeading = styled.div`
  margin-top: 40px;
`;
export const GreyText = styled.div`
  text-transform: uppercase;
`;
export const StatusContainer = styled.div`
  display: flex;
  column-gap: 23px;
`;
export const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-block-end: 8px;
  width: 100%;
  flex-wrap: nowrap;
`;
export const RawMaterialName = styled(Text)`
  font-family: 'Myriad Pro';
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.color};
`;
export const RawMaterialQuantity = styled(RawMaterialName)`
  color: rgba(0, 0, 0, 0.45);
`;
export const DownloadDocument = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #1890ff !important;
`;
export const RawMaterialTag = styled(Tag)`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  column-gap: 4px;
  border-radius: 4px;
  padding: 6px 8px;
  display: inline-flex;
`;
export const Seperate = styled.div`
  background-color: #8c8c8c;
  width: 1px;
`;
export const BlenderInfo = styled.div`
  min-width: fit-content;
  border-radius: 4px;
  padding: 12px 16px;
  column-gap: 12px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  row-gap: 16px;
`;
