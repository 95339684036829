import { Layout } from 'antd';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector } from '../../store';
import {
  BlenderCollateralManagerSideBar,
  BlenderSideBar,
  ClearingAgentSideBar,
  CollateralManagerWarehouseSideBar,
  HeaderNavigation,
  NsiaSideBar
} from './components';
import { Role } from 'src/store/constant';

const { Content } = Layout;
export const MainLayout = () => {
  const { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.auth);
  const sidebar = {
    [Role.NSIA_ADMIN]: <NsiaSideBar />,
    [Role.CLEARING_AGENT]: <ClearingAgentSideBar />,
    [Role.COLLATERAL_MANAGER]: <CollateralManagerWarehouseSideBar />,
    [Role.BLENDER]: <BlenderSideBar />,
    [Role.COLLATERAL_MANAGER_BLENDER]: <BlenderCollateralManagerSideBar />
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [pathname]);

  if (!user) {
    return <Navigate to="/" replace />;
  }
  return (
    <Layout style={{ height: '100vh', overflowY: 'hidden' }}>
      <HeaderNavigation />
      <Layout>
        {sidebar[user.role]}
        <CustomContent>
          <Outlet />
        </CustomContent>
      </Layout>
    </Layout>
  );
};

const CustomContent = styled(Content)`
  height: 100%;
  overflow-y: auto;
  background-color: #f7fafc;
`;
