import { Button, Modal, Form, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IRawMaterial, useDeleteRawMaterialMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  rawMaterial: IRawMaterial;
}

export const DeleteModal: FC<IModal> = ({ open, closeModal, rawMaterial }) => {
  const [deleteRawMaterial, { isLoading: isDeleteLoading }] =
    useDeleteRawMaterialMutation();

  const handleSubmit = () => {
    deleteRawMaterial(rawMaterial.id)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You have successfully deleted a raw material.'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };

  const [form] = Form.useForm();
  const name = Form.useWatch('name', form);

  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (name === rawMaterial.name) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [name, rawMaterial.name]);
  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
    <Modal
      open={open}
      title="Delete raw material"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          danger
          type="primary"
          disabled={buttonDisable}
          onClick={() => form.submit()}
          loading={isDeleteLoading}
        >
          Yes, Delete
        </Button>
      ]}
      width={660}
    >
      <Form form={form} layout={'vertical'} onFinish={handleFinish}>
        <Form.Item
          label={
            <FormLabel>
              Do you want to delete "<PortTitle>{rawMaterial.name}</PortTitle>”
              raw material, this action cannot be undone
            </FormLabel>
          }
          name={'name'}
          key={'1'}
        >
          <Input placeholder="Enter the raw material name to proceed" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const PortTitle = styled.h4`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
