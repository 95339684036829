import { Card } from 'antd';
import { Outlet } from 'react-router-dom';
import { AuthBg, CenterItem, SpaceBelow } from './layout.style';
import logo from '../../assets/authAssets/logo.png';

const cardStyle = {
  width: '100%',
  maxWidth: 520,
  padding: '1.7em',
  borderRadius: 8
};

export const AuthLayout = () => {
  return (
    <AuthBg>
      <div>
        <Card style={cardStyle}>
          <SpaceBelow>
            <CenterItem>
              <img src={logo} alt="logo" />
            </CenterItem>
          </SpaceBelow>

          <Outlet />
        </Card>
      </div>
    </AuthBg>
  );
};
