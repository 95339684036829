import { Card } from 'antd';
import styled from 'styled-components';
import { Text } from 'src/components';

export const NpkProductionCharts = () => {
  return (
    <StyledCard title="NPK production">
      <TextWrapper>
        <RawMaterialName> NPK stock unavailable</RawMaterialName>
      </TextWrapper>
    </StyledCard>
  );
};
const StyledCard = styled(Card)`
  margin: 20px 0;
  width: 100%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafbfc;
  height: 80px;
`;
const RawMaterialName = styled(Text)`
  font-family: 'Myriad Pro';
  font-size: 12px;
  line-height: 20px;
  color: #1f1f1f;
`;
