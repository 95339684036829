import { FC } from 'react';
import { IIndentRequest } from 'src/store/api';
import styled from 'styled-components';

interface IProps {
  indentRequest: IIndentRequest;
}

export const RenderOrders: FC<IProps> = ({ indentRequest }) => {
  return (
    <OrdersFlex>
      <MaterialBody>
        <FlexDiv className="bottom">
          <h1>Raw material</h1>
          <h1>Quantity</h1>
        </FlexDiv>
        {indentRequest.orders
          .map((order, index) => {
            return (
              <FlexDiv key={index}>
                <p>{order.rawMaterial.name}</p>
                <h1>{order.estimatedQuantity}</h1>
              </FlexDiv>
            );
          })
          .splice(0, 3)}
      </MaterialBody>
      {indentRequest.orders.length > 3 ? (
        <MaterialBody>
          <FlexDiv className="bottom">
            <h1>Raw material</h1>
            <h1>Quantity</h1>
          </FlexDiv>
          {indentRequest.orders
            .map((order, index) => {
              return (
                <FlexDiv key={index}>
                  <p>{order.rawMaterial.name}</p>
                  <h1>{order.estimatedQuantity}</h1>
                </FlexDiv>
              );
            })
            .splice(3, 3)}
        </MaterialBody>
      ) : null}
      {indentRequest.orders.length > 6 ? (
        <MaterialBody>
          <FlexDiv className="bottom">
            <h1>Raw material</h1>
            <h1>Quantity</h1>
          </FlexDiv>
          {indentRequest.orders
            .map((order, index) => {
              return (
                <FlexDiv key={index}>
                  <p>{order.rawMaterial.name}</p>
                  <h1>{order.estimatedQuantity}</h1>
                </FlexDiv>
              );
            })
            .splice(6, 3)}
        </MaterialBody>
      ) : null}
    </OrdersFlex>
  );
};

const OrdersFlex = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const MaterialBody = styled.div`
  display: flex;
  height: 200px;
  flex-direction: column;
  overflow-y: auto;
  background-color: #ffffff;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  border-radius: 8px;
  flex: 1;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    color: #52c41a;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
  h1 {
    color: #000000;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px;
  }
`;
