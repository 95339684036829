import { IUser } from 'src/store/slices';

export enum AdminType {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN'
}
export interface IAdmin extends IUser {
  isVerified: boolean;
  type: AdminType;
  isOnboarded: boolean;
}

export interface IInviteAdminPayload {
  email: string;
  name: string;
  phone: string;
}
export interface IEditAdminPayload extends Partial<IInviteAdminPayload> {}
