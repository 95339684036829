import { FC, useMemo } from 'react';
import { Table, Tag, Typography, Spin } from 'antd';
import { TableBody } from './style';
import { Link } from 'react-router-dom';
import { dateTimeFormater, generateUrl } from 'src/utils';
import {
  IIndentRequest,
  IndentRequestStatusConfig,
  useGetAllIndentRequestQuery
} from 'src/store/api';
import { StatusContainer, TableHeader } from 'src/pages/styles';

const { Title } = Typography;

export const RecentRequest: FC = () => {
  const queryParams = useMemo(
    () =>
      generateUrl({
        limit: 4
      }),
    []
  );
  const { data: indentRequests, isLoading } =
    useGetAllIndentRequestQuery(queryParams);

  const dataSource = useMemo(() => {
    return indentRequests?.data.map((item) => ({
      ...item,
      key: item.releaseId,
      date: dateTimeFormater(item.createdAt, 'll'),
      time: dateTimeFormater(item.createdAt, 'LT')
    }));
  }, [indentRequests]);

  const columns = [
    {
      title: 'Indent ID',
      dataIndex: 'releaseId',
      key: 'releaseId'
    },

    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, request: IIndentRequest) => {
        const status = IndentRequestStatusConfig[request.status];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
          </StatusContainer>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time'
    }
  ];

  return (
    <Spin spinning={isLoading} tip="Loading" size="large">
      <TableBody>
        <TableHeader>
          <Title level={5}>Recent order</Title>
          <Link to="/raw-material-request">See all</Link>
        </TableHeader>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </TableBody>
    </Spin>
  );
};
