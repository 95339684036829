import { useGetProductionSummaryReportQuery, } from "src/store/api";
import { DownOutlined } from '@ant-design/icons';
import { Modal, Button, Table, Dropdown, Space, MenuProps } from 'antd';
import {
    ButtonWrap,
    CustomButton,
    DownloadWrapper,
    MainHeader,
    TextWrap,
    HeadingWrapper
} from './style';
import { FC, useMemo, useRef, useState } from 'react';
import Logo from 'src/assets/authAssets/logo.png';
import { useAppSelector, IUser, Role } from 'src/store';
import { dateTimeFormater, formatNumber, } from 'src/utils';
import { TableHeader } from '../style';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import { Text, Title } from 'src/components';

interface IModal {
    open: boolean;
    urlQueryParam: string;
    closeModal: () => void;
}


export const ProductionSummaryDownloadModal: FC<IModal> = ({ open, closeModal, urlQueryParam }) => {
    const user = useAppSelector((store) => store.auth.user) as IUser;
    const csvRef = useRef<any>(null);
    const pdfRef = useRef<HTMLDivElement>(null);
    const [showPagination, setShowPagination] = useState(true);

    const { data: getProductionSummaryReport } = useGetProductionSummaryReportQuery(urlQueryParam);


    const source = useMemo(() => {
        return (
            getProductionSummaryReport?.data.map((item, index) => ({
                key: item._id,
                serialNum: index + 1,
                blendingSite: item.blendingSite.name,
                npk: item.npk.name,
                quantity: formatNumber(`${item.quantiyProduced} MT`),
                date: dateTimeFormater(item.createdAt)
            })) ?? []
        );
    }, [getProductionSummaryReport,]);


    const onDownloadClick = () => {
        csvRef.current?.link.click();
    };

    const nsiaColumn =
        user.role === Role.NSIA_ADMIN
            ? [
                {
                    title: 'Blending site',
                    dataIndex: 'blendingSite',
                    key: 'blendingSite'
                }
            ]
            : [];

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'serialNum',
            key: 'serialNum'
        },
        ...nsiaColumn,
        {
            title: 'NPK',
            dataIndex: 'npk',
            key: 'npk'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        }
    ];


    const reactToPrintContent = () => {
        return pdfRef.current;
    };

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: 'Production Summary Reports',
        async onBeforeGetContent() {
            setShowPagination(false);
        },
        onAfterPrint() {
            setShowPagination(true);
        },
        pageStyle: `@media print {
      @page {
        size: A4 landscape;
        margin: 24px;
      }
    }`
    });



    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <CustomButton onClick={handlePrint}>Download as PDF</CustomButton>
        },
        {
            key: '2',
            label: (
                <>
                    <CustomButton onClick={onDownloadClick}>Download as CSV</CustomButton>
                    <CSVLink
                        data={source}
                        asyncOnClick={true}
                        filename={`Production Report ${new Date().toUTCString()}.csv`}
                        headers={columns.map((column) => ({
                            label: column.title,
                            key: column.dataIndex
                        }))}
                        ref={csvRef}
                    ></CSVLink>
                </>
            )
        },
    ];






    return (
        <Modal
            open={open}
            closable={false}
            centered
            footer={[
                <ButtonWrap>
                    <Button onClick={closeModal}>Cancel</Button>{' '}
                    <Dropdown
                        menu={{
                            items,
                        }}

                    >
                        <Button type={'primary'}>
                            <Space>
                                Download Report
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </ButtonWrap>
            ]}
            width={1230}
        >
            <DownloadWrapper ref={pdfRef}>
                <MainHeader>
                    <img src={Logo} alt="logo" />
                </MainHeader>

                <HeadingWrapper>
                    <TextWrap>
                        <Title level={5}>Production Summary Reports</Title>
                        <Text>Generated by</Text>
                    </TextWrap>

                    <TextWrap>
                    <Text>{`Date: ${dateTimeFormater(new Date())} `} </Text>
                        <Title level={5}>{user.name}</Title>
                    </TextWrap>

                </HeadingWrapper>

                <TableHeader>
                    <Title level={5}>View Report</Title>
                </TableHeader>
                <Table
                    pagination={
                        showPagination
                            ? {
                                total: getProductionSummaryReport?.pagination?.totalCount,
                                defaultCurrent: 1,
                                pageSize: 10
                            }
                            : false
                    }
                    dataSource={source}
                    columns={columns}
                />

            </DownloadWrapper>

        </Modal>
    );
};