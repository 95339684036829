import { FormInstance, notification, Form } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextArea } from 'src/components';
import { IIndentRequest, useUpdateIndentRequestMutation } from 'src/store/api';

interface IValues {
  port?: string;
  collateralManager?: string;
  feedback?: string;
  orders?: { id: string; updatedQuantity: number }[];
}
type IFormProps = {
  form: FormInstance;
  orderId: string;
  setIsLoading: (value: boolean) => void;
  closeModal: () => void;
  indentRequest: IIndentRequest;
};
export const RejectForm: FC<IFormProps> = ({
  form,
  orderId,
  closeModal,
  setIsLoading
}) => {
  const [rejectOrder] = useUpdateIndentRequestMutation();
  const navigate = useNavigate();
  const onFinish = (values: IValues) => {
    const data = {
      status: 'rejected',
      ...values
    };
    const payload = { orderId, data: data };
    setIsLoading(true);
    rejectOrder(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'You comment was sent successfully.'
        });
        navigate('/raw-Material-request#rejected');
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Form.Item name={'feedback'} label={'State the reason for rejection'}>
        <TextArea rows={1} placeholder="Add comment"></TextArea>
      </Form.Item>
    </Form>
  );
};
