import { Card } from 'antd';
import { ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import { useGetBlenderStocksDetailsQuery } from 'src/store/api/blender/api';
import { StockChart } from 'src/components';
import { RawMaterialTextColors } from 'src/utils';

export const ProductionCharts = () => {
  const { data: getBlenderStock } = useGetBlenderStocksDetailsQuery();

  const totalStock = useMemo(() => {
    return (
      getBlenderStock?.data.reduce(
        (acc, current) => acc + Number(current.availableQuantity),
        0
      ) ?? 0
    );
  }, [getBlenderStock]);

  return (
    <StyledCard title="Raw materials in stock">
      {getBlenderStock?.data.reduce((acc, current, index) => {
        return [
          ...acc,
          <StockChart
            rawMaterialName={current.name}
            key={index}
            totalStock={totalStock}
            rawMaterialQuantity={current.availableQuantity}
            color={RawMaterialTextColors[index % 4]}
          />
        ];
      }, [] as ReactElement[])}
    </StyledCard>
  );
};
const StyledCard = styled(Card)`
  margin-block-start: 20px;
  width: 100%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
