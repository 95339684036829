import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FC, useState } from 'react';
import {
  IIndentRequest,
  ILogistics,
  useEditIndentRequestMutation
} from 'src/store/api/indent-request';
import { cloudinaryUpload } from 'src/utils/uploadToCloudinary';
import { ButtonFlex } from './Logistics';
import LogisticsFieldPanel from './LogisticsPanel';
import { IDocument } from './interface';
interface IModal {
  open: boolean;
  closeModal: () => void;
  singleIndent: IIndentRequest;
}
interface IUploadLogistic extends Omit<ILogistics, 'documents'> {
  documents: { url: string; name: string }[] | IDocument;
}

export const EditLogistics: FC<IModal> = ({
  open,
  closeModal,
  singleIndent
}) => {
  const [activePanel, setActivePanel] = useState<number>(0);
  const mappedLogistics = singleIndent?.logistics;
  const [form] = Form.useForm();
  const [editLogistics] = useEditIndentRequestMutation();
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = async () => {
    setIsEditing(true);
    const logisticList: IUploadLogistic[] = form.getFieldValue('logistics');

    if (!logisticList) {
      return;
    }

    try {
      const dataBody = await Promise.all(
        logisticList.map(async (singleLog) => {
          if (
            !Array.isArray(singleLog.documents) &&
            singleLog.documents.fileList
          ) {
            const res = await cloudinaryUpload(
              singleLog.documents.fileList[0].originFileObj as RcFile
            );

            const { documents, ...logisticsWithoutDocument } = singleLog;

            return {
              ...logisticsWithoutDocument,
              documents: [{ name: res.original_filename, url: res.url }]
            };
          } else {
            return singleLog;
          }
        })
      );
      editLogistics({ body: { logistics: dataBody }, id: singleIndent?.id })
        .unwrap()
        .then(() => {
          notification['success']({
            message: 'Your raw material request has been sent successfully.'
          });
          closeModal();
        })
        .catch((err) => {
          notification['error']({
            message: err.error
          });
        });
    } catch (error: any) {
      notification['error']({
        message: error.message
      });
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <Modal
      open={open}
      title={`Edit Logistics for ${singleIndent.releaseId}`}
      onOk={closeModal}
      onCancel={closeModal}
      centered
      width={800}
      footer={[
        <>
          <Button onClick={closeModal}>Back</Button>
          <Button
            type="primary"
            loading={isEditing}
            onClick={() => form.submit()}
          >
            Update Logistics
          </Button>
        </>
      ]}
    >
      <Form
        layout="vertical"
        validateTrigger="onBlur"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          logistics:
            mappedLogistics?.map((singleLog) => ({
              contactPerson: singleLog.contactPerson,
              transporter: singleLog.transporter,
              phone: singleLog.phone,
              documents: singleLog.documents
            })) || []
        }}
      >
        <Form.List key={1} name={'logistics'}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <LogisticsFieldPanel
                  field={field}
                  index={index}
                  key={field.key}
                  remove={remove}
                  value={activePanel}
                  onchange={setActivePanel}
                />
              ))}
              <ButtonFlex>
                <Button
                  title="Add Field"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    add({
                      transporter: '',
                      contactPerson: '',
                      phone: '',
                      documents: null
                    });
                    setActivePanel(fields.length);
                  }}
                >
                  Add Logistics
                </Button>
              </ButtonFlex>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
