import { FC } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import styled from 'styled-components';

type IGooglePlace = {
  defaultInputValue?: string;
  onChange: (data: { label: string; value: { place_id: string } }) => void;
};
export const GooglePlace: FC<IGooglePlace> = ({
  defaultInputValue,
  onChange
}) => {
  return (
    <PlaceWrapper>
      <GooglePlacesAutocomplete
        apiOptions={{ region: 'NG' }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ['ng']
          }
        }}
        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!}
        selectProps={{
          defaultInputValue,
          onChange
        }}
      />
    </PlaceWrapper>
  );
};
const PlaceWrapper = styled.div`
  div div div div {
    width: 100% !important;
  }
  input {
    width: inherit !important;
  }
`;
export default GooglePlace;
