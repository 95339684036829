import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography, Table, Button, Space, Tag } from 'antd';
import { StatusContainer } from 'src/pages/styles';
import {
  ButtonContainer,
  Container,
  CustomHeader,
  TableBody,
  TitleRow
} from 'src/pages/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CreateAdminModal,
  DeleteAdminModal,
  UpdateAdminModal
} from './component';
import {
  AdminType,
  IAdmin,
  useGetAllAdminQuery
} from 'src/store/api/nsia/admin';
import { generateUrl } from 'src/utils';
import { useAppSelector } from 'src/store';

const { Title } = Typography;

export const AdminManagement = () => {
  const [addNewAdminModal, setAddNewAdminModal] = useState(false);
  const [viewEditAdmin, setViewEditAdmin] = useState<IAdmin | null>(null);
  const [deleteAdmin, setDeleteAdmin] = useState<IAdmin | null>(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data: allAdmin } = useGetAllAdminQuery(generateUrl({ page }));
  const user = useAppSelector((store) => store.auth.user!) as IAdmin;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone number',
      dataIndex: 'phone',
      key: 'phonenumber'
    },
    {
      title: 'Status',
      key: 'status',
      render: (agent: IAdmin) => {
        return (
          <StatusContainer>
            {agent.isOnboarded ? (
              <Tag color="green">Onboarded</Tag>
            ) : (
              <Tag color="gold">Awaiting</Tag>
            )}
          </StatusContainer>
        );
      }
    },
    user.type === AdminType.SUPER_ADMIN
      ? {
          title: 'Action',
          key: 'action',
          dataIndex: 'action',
          render: (_: unknown, record: IAdmin) => {
            return (
              <Space size="small">
                <Button onClick={() => setViewEditAdmin(record)} type={'link'}>
                  Edit
                </Button>
                |
                <Button
                  danger
                  type="text"
                  onClick={() => setDeleteAdmin(record)}
                >
                  Delete
                </Button>
              </Space>
            );
          }
        }
      : {}
  ];

  return (
    <>
      <CustomHeader>
        <TitleRow>
          <Title level={3}>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginRight: '17px' }}
            />
            Admin management
          </Title>
          {user.type === AdminType.SUPER_ADMIN && (
            <>
              <ButtonContainer>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setAddNewAdminModal(true)}
                >
                  Add new admin
                </Button>
              </ButtonContainer>
            </>
          )}
        </TitleRow>
      </CustomHeader>
      <Container>
        <TableBody>
          <Table
            columns={columns}
            rowKey={'email'}
            dataSource={allAdmin?.data ?? []}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              onChange: (page) => {
                setPage(page);
              }
            }}
          />
        </TableBody>
      </Container>
      {addNewAdminModal && (
        <CreateAdminModal
          open={addNewAdminModal}
          closeModal={() => setAddNewAdminModal(false)}
        />
      )}
      {!!viewEditAdmin && (
        <UpdateAdminModal
          open={!!viewEditAdmin}
          admin={viewEditAdmin}
          closeModal={() => setViewEditAdmin(null)}
        />
      )}
      {!!deleteAdmin && (
        <DeleteAdminModal
          open={!!deleteAdmin}
          admin={deleteAdmin}
          closeModal={() => setDeleteAdmin(null)}
        />
      )}
    </>
  );
};
