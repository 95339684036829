import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Tabs, Typography } from 'antd';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPurchaseOrder } from 'src/store/api';
import styled from 'styled-components';
import { InitiatePurchase, OrderTable, SummaryResult } from './components';

const { Title } = Typography;

export const PurchaseOrder = () => {
  const [initiatePurchase, setInitiatePurchase] = useState(false);
  const [, setViewPurchaseOrder] = useState<IPurchaseOrder | null>(null);
  const [summary, setSummary] = useState<IPurchaseOrder>();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const status = hash.replace('#', '');
  const onChange = (key: string) => {
    navigate(`/purchase-order#${key}`);
  };

  const operations = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => setInitiatePurchase(true)}
    >
      Initiate Purchase
    </Button>
  );

  return (
    <>
      {initiatePurchase && (
        <InitiatePurchase
          open={initiatePurchase}
          closeModal={() => setInitiatePurchase(false)}
          openSummary={setSummary}
        />
      )}
      {!!summary && (
        <SummaryResult
          closeSummary={() => setSummary(undefined)}
          summary={summary}
          open={!!summary}
        />
      )}
      <CustomHeader>
        <Title>Purchase Order</Title>
      </CustomHeader>

      <CustomTab
        tabBarExtraContent={operations}
        defaultActiveKey={status}
        activeKey={status}
        onChange={onChange}
        items={[
          {
            label: `All Orders`,
            key: '',
            children: <OrderTable setViewPurchaseOrder={setViewPurchaseOrder} />
          },
          {
            label: `Active`,
            key: 'active',
            children: <OrderTable setViewPurchaseOrder={setViewPurchaseOrder} />
          },
          {
            label: `Completed `,
            key: 'completed',
            children: <OrderTable setViewPurchaseOrder={setViewPurchaseOrder} />
          }
        ]}
      />
    </>
  );
};

const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
