import { DownOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Dropdown, Space } from 'antd';
import { FC } from 'react';
import { BarChart } from 'src/components';
import { endOfMonth, startOfMonth } from 'src/utils';
import styled from 'styled-components';
import { ChartTab } from './enum';
import useRawMaterialDistributionData from './useRawMaterialDistributionData';

const { RangePicker } = DatePicker;

export const RawMaterialsDistribution: FC = () => {
  const { dropDown, isLoading, handleDateChange, chartConfig } =
    useRawMaterialDistributionData({ activeTab: ChartTab.BlendingSite });
  return (
    <StyledCard
      title={
        <>
          Blending site raw materials distribution
          <DropdownWrap>
            <Dropdown
              menu={{
                items: Object.values(dropDown.menu),
                onClick: (item) => dropDown.onClick(item.key)
              }}
            >
              <Button loading={isLoading}>
                <Space>
                  {dropDown.label}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <RangePicker
              defaultValue={[startOfMonth(), endOfMonth()]}
              onChange={handleDateChange}
            />
          </DropdownWrap>
        </>
      }
    >
      <BarChart {...chartConfig} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-top: 40px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;

const DropdownWrap = styled.div`
  display: flex;
  gap: 0.8rem;
`;
