import { Space, Table, Tag } from 'antd';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, Title } from 'src/components';
import {
  Container,
  ViewButton
} from 'src/pages/nsia-admin/purchaseOrder/styles';
import { IPurchaseOrder, useGetAllPurchaseOrderQuery } from 'src/store/api';
import { dateTimeFormater, formatNumber, generateUrl } from 'src/utils';
import { ORDER_STATUS, PAAR_STATUS } from '../enum';
import { TableBody, TableHeader } from '../styles';

interface IProps {
  setViewPurchaseOrder: Dispatch<SetStateAction<IPurchaseOrder | null>>;
}

export const OrderTable: FC<IProps> = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { data: purchaseOrderList } = useGetAllPurchaseOrderQuery(
    generateUrl({
      status: hash.replace('#', ''),
      limit: 10,
      page,
      search
    })
  );

  const onSearch = (value: string) => {
    setSearch(value);
  };
  const source = useMemo(() => {
    return purchaseOrderList?.data.map((item) => {
      let estimatedQuantity = Number(item.orders?.[0].estimatedQuantity ?? 0);
      let rawMaterials = item.orders?.[0].rawMaterial?.name ?? '';
      return {
        date: dateTimeFormater(item.createdAt, 'll'),
        estimatedQuantity: `${formatNumber(estimatedQuantity)}MT`,
        key: item.id,
        selectedRawMaterials: rawMaterials,
        supply: item.supplier.name,
        ...item
      };
    });
  }, [purchaseOrderList]);
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'purchaseId',
      key: 'purchaseId'
    },

    {
      title: 'Vessel name',
      dataIndex: 'vesselName',
      key: 'vesselName'
    },
    {
      title: 'Raw Material',
      dataIndex: 'selectedRawMaterials',
      key: 'rawMaterial'
    },
    {
      title: 'Supplier',
      dataIndex: 'supply',
      key: 'supply'
    },
    {
      title: 'Estimated Quantity (MT)',
      dataIndex: 'estimatedQuantity',
      key: 'quantity'
    },

    {
      title: 'Shipping Documents Status',
      dataIndex: 'paarStatus',
      key: 'paarStatus',
      render: (_: any, record: IPurchaseOrder) => {
        const paarStatus = record.paarStatus as keyof typeof PAAR_STATUS;
        return (
          <Space>
            <Tag color={PAAR_STATUS[paarStatus].color}>
              {PAAR_STATUS[paarStatus].name}
            </Tag>
          </Space>
        );
      }
    },
    {
      title: 'Order Status',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      render: (_: any, record: IPurchaseOrder) => {
        const status = record.status as keyof typeof ORDER_STATUS;
        return (
          <Tag color={ORDER_STATUS[status].color}>
            {ORDER_STATUS[status].name}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: IPurchaseOrder) => (
        <ViewButton
          onClick={() =>
            navigate(`/purchase-order/${record.id}`, {
              state: { purchaseOrder: record }
            })
          }
        >
          Details{' '}
        </ViewButton>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  return (
    <Container>
      <TableBody>
        <TableHeader>
          <Title level={4}>Orders</Title>
          <Search
            onChange={(e) => !e.target.value && setSearch('')}
            placeholder="Search by vessel name"
            style={{ width: 264 }}
            onSearch={onSearch}
          />
        </TableHeader>
        <Table
          dataSource={source}
          columns={columns}
          pagination={{
            defaultCurrent: 1,
            pageSize: 10,
            total: purchaseOrderList?.pagination?.totalCount,
            onChange: (page) => {
              setPage(page);
            }
          }}
        />
      </TableBody>
    </Container>
  );
};
