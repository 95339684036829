import {
  AdminManagement,
  Dashboard,
  IndentRequest,
  IndentRequestDetail,
  PurchaseOrder,
  SingleOrders,
  Stakeholders,
  Utilites
} from 'src/pages/nsia-admin';
import {
  DischargeVesselReport,
  ProductionSummaryReport,
  RawMaterialReceipt,
  RawMaterialUsage,
  ReleaseReports,
  WarehouseOfftakeReport,
  WarehouseReceiptsReport
} from 'src/pages/reports';
import { AmendmentReports } from 'src/pages/reports/AmendmentReports';
import MasterReport from 'src/pages/reports/MasterReport';
import SingleDischargeReport from 'src/pages/reports/SingleDischargeVessel';
import SummaryQuantity from 'src/pages/reports/SummaryQuantity';
import WarehouseSummary from 'src/pages/reports/WarehouseSummary';

export const nsiaRoutes = [
  { path: '/', element: <Dashboard /> },
  {
    path: '/stakeholders',
    element: <Stakeholders />
  },
  {
    path: '/utilities',
    element: <Utilites />
  },
  {
    path: '/purchase-order',
    element: <PurchaseOrder />
  },
  {
    path: '/purchase-order/:id',
    element: <SingleOrders />
  },
  {
    path: '/raw-Material-request',
    element: <IndentRequest />
  },
  {
    path: '/raw-Material-request/:id',
    element: <IndentRequestDetail />
  },
  {
    path: '/admin-management',
    element: <AdminManagement />
  },
  {
    path: '/reports',
    element: <MasterReport />
  },
  {
    path: '/reports/vessel-discharge-report',
    element: <DischargeVesselReport />
  },
  {
    path: '/reports/vessel-discharge-report/:id',
    element: <SingleDischargeReport />
  },
  {
    path: '/reports/release-reports',
    element: <ReleaseReports />
  },
  {
    path: '/reports/raw-material-receipt',
    element: <RawMaterialReceipt />
  },
  {
    path: '/reports/raw-material-usage',
    element: <RawMaterialUsage />
  },
  {
    path: '/reports/warehouse-report-receipt',
    element: <WarehouseReceiptsReport />
  },
  {
    path: '/reports/warehouse-report-offtakes',
    element: <WarehouseOfftakeReport />
  },
  {
    path: '/reports/release-report',
    element: <ReleaseReports />
  },
  {
    path: '/reports/production-summary-report',
    element: <ProductionSummaryReport />
  },
  {
    path: '/reports/summaryQuantity',
    element: <SummaryQuantity />
  },
  {
    path: '/reports/warehouseSummary',
    element: <WarehouseSummary />
  },
  {
    path: '/reports/amendment-reports',
    element: <AmendmentReports />
  }
];
