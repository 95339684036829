import { Button, Spin, Table, Tag } from 'antd';
import { FC, useMemo, useState } from 'react';
import { OfftakenSummary, Title } from 'src/components';
import LogisticsAccordion from 'src/components/LogisticsAccordion';
import { AmendOrderTable } from 'src/pages/blender/indent/components/AmmendOrderTable';
import { StatusContainer } from 'src/pages/styles';
import {
  IIndentRequest,
  IOffTakingDetail,
  IOrderAmend,
  OfftakingStatusConfig,
  useGetAmendOrdersQuery,
  useGetOffTakingDetailsQuery
} from 'src/store/api';
import { dateTimeFormater, formatNumber } from 'src/utils';
import styled from 'styled-components';
import { AmendRequest } from './AmmendRequestModal';

type IApprovedRequestProp = {
  indentRequest: IIndentRequest;
};
export const ApprovedRequest: FC<IApprovedRequestProp> = ({
  indentRequest
}) => {
  const { data: offTakingDetail, isLoading } = useGetOffTakingDetailsQuery(
    indentRequest.id
  );

  const [activePanel, setActivePanel] = useState<number>(0);

  const [viewTable, setViewTable] = useState(true);

  const [viewRequest, setViewRequest] = useState<IOrderAmend | null>(null);
  const { data: amendedOrderDetail, isLoading: isAmending } =
    useGetAmendOrdersQuery(indentRequest.id);

  const [viewOrders, setViewOrders] = useState(false);
  const [viewOfftracking, setViewOfftracking] = useState(true);

  const dataSource = useMemo(
    () =>
      offTakingDetail?.data.offTakings.map((offtaking, index) => ({
        sn: index + 1,
        key: offtaking._id,
        tableRawMaterial: offtaking.rawMaterial.name,
        offtake: `${formatNumber(offtaking.quantityDischarged)}MT`,
        tableWarehouse: offtaking.warehouse.name,
        tableQuantityRecieved: offtaking.quantityReceived
          ? `${formatNumber(offtaking.quantityReceived)}MT`
          : '------',
        date: dateTimeFormater(offtaking.createdAt),
        ...offtaking
      })),
    [offTakingDetail]
  );
  const columns = [
    {
      title: 'Raw material',
      dataIndex: 'tableRawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Offtake',
      dataIndex: 'offtake',
      key: 'offtake'
    },
    {
      title: 'Quantity Recieved',
      dataIndex: 'tableQuantityRecieved',
      key: 'quantityRecieved'
    },
    {
      title: 'Warehouse',
      dataIndex: 'tableWarehouse',
      key: 'Warehouse'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Order status',
      dataIndex: 'status',
      render: (_: any, record: IOffTakingDetail) => {
        const status = OfftakingStatusConfig[record.offTakingStatus];
        return (
          <StatusContainer>
            <Tag color={status.color}>{status.name}</Tag>
          </StatusContainer>
        );
      }
    }
  ];

  return (
    <Container>
      {!!viewRequest && (
        <AmendRequest
          detailOfftake={offTakingDetail?.data}
          open={!!viewRequest}
          closeModal={() => setViewRequest(null)}
          amendOrder={viewRequest}
          indent={indentRequest}
        />
      )}

      {offTakingDetail && (
        <OfftakenSummary
          offTakingDetail={offTakingDetail.data}
          orders={indentRequest.orders}
        />
      )}

      <LogisticsAccordion
        activePanel={activePanel}
        indentRequest={indentRequest}
        setActivePanel={setActivePanel}
        setViewOrders={setViewOrders}
        viewOrders={viewOrders}
      />
      <TableTitle>
        <Title level={4}>Off-take tracking</Title>
        <HideButton
          status={!viewOfftracking}
          onClick={() => setViewOfftracking(!viewOfftracking)}
        >
          {viewOfftracking ? 'Collapse' : 'Expand'}
        </HideButton>
      </TableTitle>
      {viewOfftracking ? (
        <Spin spinning={isLoading} tip="Loading" size="large">
          <Table columns={columns} dataSource={dataSource} />
        </Spin>
      ) : null}

      {amendedOrderDetail && amendedOrderDetail.data.length > 0 && (
        <>
          <HeaderWrap>
            <Title level={4}>Amendment Tracker</Title>

            <HideButton
              status={!viewTable}
              onClick={() => setViewTable(!viewTable)}
            >
              {viewTable ? 'Collapse' : 'Expand'}
            </HideButton>
          </HeaderWrap>

          {viewTable ? (
            <AmendOrderTable
              isLoading={isAmending}
              amendedOrderDetails={amendedOrderDetail?.data}
              setViewRequest={setViewRequest}
            />
          ) : null}
        </>
      )}
    </Container>
  );
};

export const BlendingSiteContainer = styled.div`
  flex: 2;
  background-color: #fafafa;
`;

export const OrdersBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #d9d9d9;
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  .arrows {
    cursor: pointer;
    height: 12px;
    width: 12px;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const HideButton = styled(Button)<{ status: boolean }>`
  color: ${({ status }) => (status ? 'green' : 'red')};
  border: none;
`;

export const Wrap = styled.div`
  width: '100%';
  height: '100%';
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
  margin-block: 32px;
`;

const Container = styled.div`
  padding: 20px;
  .bottom {
    border-bottom: 1px solid #d9d9d9;
  }
  .flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const TableTitle = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 60px;
  padding: 10px;
  h4 {
    margin: 0;
  }
`;
