import { Tag } from 'antd';
import styled from 'styled-components';

export const TableBody = styled.div`
  background-color: white;
`;
export const Container = styled.div`
  width: '100%';
  height: '100%';
  margin: 32px;
  background: #ffffff;
`;
export const TableHeader = styled.div`
  width: '100%';
  justify-content: space-between;
  display: flex;
  height: 88px;
  padding: 20px 10px;
`;

export const ActionItem = styled.h4`
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : '')};
`;

export const RawMaterialTag = styled(Tag)`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border-radius: 4px;
  padding: 6px 8px;
  background-color: #e6f7ff;
  cursor: pointer;
`;
