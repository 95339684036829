import { Response } from 'src/store/genericInterface';
import { baseApi } from '../baseApi';
import { tagTypes } from '../baseApi/tagTypes';
import { IStock } from './interface';

const adminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStock: builder.query<Response<IStock[]>, string | void>({
      query: (query = '') => ({
        url: `/stock?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [{ type: tagTypes.Stock, query }]
    })
  })
});

export const { useGetAllStockQuery } = adminApi;
