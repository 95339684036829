import { Button, Modal, Form, Input, Divider, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useCompleteOfftakeMutation } from 'src/store/api';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  indentId: string;
  releaseId: string;
}
export const CompleteOfftake: FC<IModal> = ({
  open,
  closeModal,
  releaseId,
  indentId
}) => {
  const [completeOfftake, { isLoading }] = useCompleteOfftakeMutation();
  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(true);
  const name = Form.useWatch('name', form);

  useEffect(() => {
    setButtonDisable(name !== releaseId);
  }, [name, releaseId]);

  const handleSubmit = () => {
    completeOfftake(indentId)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'offtake successfully completed'
        });
        closeModal();
      })
      .catch((error) => {
        notification['error']({
          message: error.error
        });
      });
  };
  const handleFinish =()=>{
    if (!buttonDisable) {
      handleSubmit()
    }
  }
  return (
    <Modal
      open={open}
      title="Complete offtake"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,

        <Button
          danger
          disabled={buttonDisable}
          loading={isLoading}
          type={'primary'}
          onClick={() => form.submit()}
        >
          Yes, Confirm
        </Button>
      ]}
      width={432}
    >
      <Divider />
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <Form.Item
          key={1}
          name={'name'}
          label={
            <FormLabel>
              Are you sure you want to complete this off-take? To continue
              please confirm the release order ID{' '}
              <GreenText>{releaseId}</GreenText> to complete this off-take
            </FormLabel>
          }
        >
          <Input placeholder="release order ID" autoComplete='off' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;
const GreenText = styled.strong`
  color: rgba(17, 184, 103, 1);
  display: inline-block;
`;
