import { FC } from 'react';
import { Button, Form, Select, Row, Col } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonFlex } from 'src/pages/styles';
import { useGetRawMaterialsQuery } from 'src/store/api';
import { NumberInput } from 'src/components';

export const SelectRawMaterial: FC = () => {
  const orders = [{ rawMaterial: '', estimatedQuantity: '' }];
  const { data: rawMaterials } = useGetRawMaterialsQuery();
  return (
    <Form.List key={1} name={'orders'} initialValue={orders}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Row
              key={field.key}
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              align="middle"
            >
              <Col span={11}>
                <Form.Item
                  {...field}
                  name={[field.name, 'rawMaterial']}
                  label={'Raw Material'}
                  rules={[
                    {
                      required: true,
                      message: 'Raw material is required'
                    }
                  ]}
                >
                  <Select placeholder="Select Raw Material" allowClear>
                    {rawMaterials?.data.map((item) => (
                      <Select.Option key={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name={[field.name, 'estimatedQuantity']}
                  label="Estimated Quantity (MT)"
                  rules={[
                    {
                      required: true,
                      message: 'Quantity  is required'
                    }
                  ]}
                >
                  <NumberInput
                    min={1}
                    placeholder=" Enter quantity"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <CloseCircleOutlined
                  style={{
                    fontSize: '1.8rem',
                    color: '#FF7875',
                    paddingTop: '10px'
                  }}
                  onClick={() => remove(index)}
                />
              </Col>
            </Row>
          ))}
          <ButtonFlex>
            <Button
              title="Add Field"
              icon={<PlusOutlined />}
              onClick={() => add({ rawMaterial: '', estimatedQuantity: '' })}
            >
              Add Field
            </Button>
          </ButtonFlex>
        </>
      )}
    </Form.List>
  );
};
