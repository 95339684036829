import {
  ClockCircleFilled,
  HomeFilled,
  ReconciliationFilled,
  SnippetsFilled
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import leftTrigger from 'src/assets/leftTrigger.svg';
import rightTrigger from 'src/assets/rightTrigger.svg';
import styled from 'styled-components';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Dashboard', '', <HomeFilled />),
  getItem('Report Manager', '/reports', <SnippetsFilled />, [
    getItem('Release Report', '/release-report'),
    getItem('Summary Quantity', '/reports/summaryQuantity')
  ]),
  getItem('Purchase Order', '/purchase-order', <ReconciliationFilled />),
  getItem(
    'Raw Material Request',
    '/release-instructions',
    <ClockCircleFilled />
  )
];

export const CollateralManagerWarehouseSideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  return (
    <CustomSider
      trigger={null}
      theme="light"
      collapsed={collapsed}
      collapsible
      onCollapse={(value) => setCollapsed(value)}
    >
      <SideBarWrapper>
        <UpperLevelMenu>
          <div className="logo" />
          <CustomMenu
            defaultSelectedKeys={['1']}
            mode={'inline'}
            items={items}
            onClick={(menuInfo) => {
              navigate(menuInfo.key);
            }}
          />
        </UpperLevelMenu>
        <TriggerButtonContainer>
          <TriggerButton onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <TriggerImg src={leftTrigger} />
            ) : (
              <TriggerImg src={rightTrigger} />
            )}
          </TriggerButton>
        </TriggerButtonContainer>
      </SideBarWrapper>
    </CustomSider>
  );
};

const CustomSider = styled(Sider)`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;
const SideBarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
const UpperLevelMenu = styled.div``;
const CustomMenu = styled(Menu)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
const TriggerButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: -13px;
  z-index: 20;
  z-index: 20;
`;
const TriggerButton = styled.div``;
const TriggerImg = styled.img``;
