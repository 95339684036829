import { PageHeader } from '@ant-design/pro-layout';
import { Tabs } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Title } from 'src/components';
import styled from 'styled-components';
import { IndentTable } from './components';

export const IndentRequest: FC = () => {
  const navigate = useNavigate();
  const onChange = (key: string) => {
    navigate(`${key}`);
  };
  const { hash } = useLocation();

  return (
    <>
      <CustomHeader>
        <Title>Raw Materials Request</Title>
      </CustomHeader>

      <CustomTab
        defaultActiveKey={hash}
        activeKey={hash}
        onChange={onChange}
        items={[
          {
            label: `All Requests`,
            key: '',
            children: <IndentTable />
          },
          {
            label: `Active`,
            key: '#off-taking',
            children: <IndentTable />
          },
          {
            label: `Approved`,
            key: '#approved',
            children: <IndentTable />
          },
          {
            label: `Rejected`,
            key: '#rejected',
            children: <IndentTable />
          },
          {
            label: `Completed`,
            key: '#completed',
            children: <IndentTable />
          }
        ]}
      />
    </>
  );
};

const CustomHeader = styled(PageHeader)`
  background-color: #ffffff;
`;
const CustomTab = styled(Tabs)`
  background: #f7fafc;
  .ant-tabs-nav {
    background-color: #ffffff;
    padding: 0 24px;
  }
`;
