import { Typography } from 'antd';
import BlendingSiteIcon from 'src/assets/dashboardIcons/blendingSitesIcon.svg';
import PortIcon from 'src/assets/dashboardIcons/portsIcon.svg';
import StakeholdersIcon from 'src/assets/dashboardIcons/stakeholdersIcon.svg';
import TrucksIcon from 'src/assets/dashboardIcons/trucksIcon.svg';
import VesselsIcon from 'src/assets/dashboardIcons/vesselsIcon.svg';
import WarehouseIcon from 'src/assets/dashboardIcons/warehouseIcon.svg';
import { AnalyticsCard } from 'src/components/card';
import { useGetAdminStatsQuery } from 'src/store/api';
import {
  BlendingSiteLocation,
  NpkProduction,
  RawMateirals,
  RawMaterialsDistribution
} from './components/charts';
import { WarehouseDistribution } from './components/charts/WarehouseDistribution';
import { RecentOrder } from './components/table/RecentOrder';
import {
  Body,
  CardDiv,
  ChartAnalyticsCardDiv,
  ChartDiv,
  Container,
  CustomHeader
} from './style';
const { Title } = Typography;

export const Dashboard = () => {
  const { data: statsData } = useGetAdminStatsQuery();

  return (
    <Container>
      <CustomHeader>
        <Title>Dashboard</Title>
      </CustomHeader>
      <Body>
        <CardDiv>
          <AnalyticsCard
            title="Stakeholders"
            value={statsData?.data?.stackholders}
            icon={StakeholdersIcon}
          />
          <AnalyticsCard
            title="Ports"
            value={statsData?.data?.ports}
            icon={PortIcon}
          />
          <AnalyticsCard
            title="Warehouse"
            value={statsData?.data?.warehouses}
            icon={WarehouseIcon}
          />
          <AnalyticsCard
            title="Blending Sites"
            value={statsData?.data?.blendingsites}
            icon={BlendingSiteIcon}
          />
        </CardDiv>

        <WarehouseDistribution />
        <RawMaterialsDistribution />

        <BlendingSiteLocation />
        <ChartDiv>
          <RawMateirals />
          <NpkProduction />
          <ChartAnalyticsCardDiv>
            <AnalyticsCard
              title="Vessels imported"
              icon={VesselsIcon}
              value={statsData?.data.vesselImported}
              width={'312px'}
              height={'82px'}
            />
            <AnalyticsCard
              title="Trucks in transit"
              icon={TrucksIcon}
              value={statsData?.data.onTransit}
              width={'312px'}
              height={'82px'}
            />
          </ChartAnalyticsCardDiv>
        </ChartDiv>
        <RecentOrder />
      </Body>
    </Container>
  );
};
