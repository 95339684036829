import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Tag,
  Typography,
  Upload,
  UploadFile,
  notification
} from 'antd';
import { RcFile } from 'antd/es/upload';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import Logo from 'src/assets/authAssets/logo.png';
import {
  ConfirmCard,
  CustomCard,
  FooterWrapper,
  PurchaseInfoRow,
  TextWrapper,
  MainHeader
} from 'src/pages/nsia-admin/purchaseOrder/styles';
import {
  IPurchaseOrder,
  IUploadImage,
  useUploadPaarMutation
} from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';
import { PAAR_STATUS } from '../enum';

const { Dragger } = Upload;
const { Title } = Typography;
interface VModal {
  open: boolean;
  closeViewModal: () => void;
  purchaseOrder: IPurchaseOrder;
}

export const SendPaarModal: FC<VModal> = ({
  open,
  closeViewModal,
  purchaseOrder
}) => {
  const [fileList, setFileList] = useState<UploadFile<File>[]>([]);
  const paarStatus = purchaseOrder.paarStatus as keyof typeof PAAR_STATUS;
  const estimatedTonnage = useMemo(() => {
    return purchaseOrder?.orders.reduce(
      (acc, current) => acc + Number(current.estimatedQuantity),
      0
    );
  }, [purchaseOrder]);

  const [uploadFile, { isLoading }] = useUploadPaarMutation();
  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };
  const beforeUpload = (file: RcFile) => {
    setFileList([...fileList, file]);
    return false;
  };
  const handleSendPaar = () => {
    const files = new FormData();
    for (const file of fileList) {
      files.append('files', file as RcFile);
    }
    const payload: IUploadImage = { id: purchaseOrder!.id, files };

    uploadFile(payload)
      .unwrap()
      .then(() => {
        closeViewModal();
        notification['success']({
          message: `You have successfully sent shipping documents for order ${purchaseOrder.purchaseId}.`
        });
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };
  return (
    <Modal
      closable={false}
      open={open}
      centered={true}
      width={730}
      footer={[
        <FooterWrapper>
          <Button onClick={closeViewModal}>Close</Button>
          <Button
            type="primary"
            disabled={!fileList.length}
            loading={isLoading}
            onClick={handleSendPaar}
          >
            Send Shipping Documents
          </Button>
        </FooterWrapper>
      ]}
    >
      <TextWrapper>
        <MainHeader>
          <img src={Logo} alt="logo" />
        </MainHeader>
        <CustomTitle level={3}>Purchase order</CustomTitle>
        <p>
          Purchase order with ID <strong>{purchaseOrder.purchaseId}</strong> was
          issued and approved by <strong>{purchaseOrder.createdBy.name}</strong>{' '}
          on
          <strong>{` ${moment(purchaseOrder.createdAt).format(
            'Do MMMM YYYY'
          )}. `}</strong>
          Details of the order are listed below and all the operators involved
          as well. if there is any issue revert to the proper channel.
        </p>
      </TextWrapper>

      <ConfirmCard>
        <div className="site-card-wrapper">
          <PurchaseInfoRow>
            <CustomCard
              title="Raw Material"
              extra={
                <EstimateQuantity>Estimated Quantity (MT)</EstimateQuantity>
              }
            >
              {purchaseOrder?.orders.map((item, index) => (
                <OrderContainer key={index}>
                  <RawMaterialName>
                    {item.rawMaterial.name.toUpperCase()}
                  </RawMaterialName>
                  <RawMaterialQuantity>
                    {formatNumber(item.estimatedQuantity)} MT
                  </RawMaterialQuantity>
                </OrderContainer>
              ))}
            </CustomCard>

            <CustomCard title="Purchase Details">
              <OrderContainer>
                <Keys>Ports of discharge</Keys>
                <Values>{purchaseOrder?.portQuantities.length}</Values>
              </OrderContainer>
              <OrderContainer>
                <Keys>Vessel name</Keys>
                <Values>{purchaseOrder?.vesselName}</Values>
              </OrderContainer>
              <OrderContainer>
                <Keys>Estimated tonnage</Keys>
                <Values>{formatNumber(estimatedTonnage)} MT</Values>
              </OrderContainer>
              <OrderContainer>
                <Keys>Estimated time of arrival</Keys>
                <Values>
                  {moment(purchaseOrder?.arrivalTime).format('YYYY-MM-DD')}
                </Values>
              </OrderContainer>

              <OrderContainer>
                <Keys>Clearing agent</Keys>
                <Values>{purchaseOrder?.clearingAgent.name}</Values>
              </OrderContainer>
            </CustomCard>
          </PurchaseInfoRow>
        </div>
      </ConfirmCard>

      <PaarStatusContainer>
        <PaarStatusTitle>Shipping Documents Status</PaarStatusTitle>
        <Tag color={PAAR_STATUS[paarStatus].color}>
          {PAAR_STATUS[paarStatus].name}
        </Tag>
      </PaarStatusContainer>
      <Dragger
        multiple
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        accept=".pdf,  .jpg, .jpeg, .png"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload (.pdf, .doc, .docx, .jpg,
          .jpeg, .png)
        </p>
        <p className="ant-upload-text">File size must not exceed 10MB</p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Dragger>
    </Modal>
  );
};

const CustomTitle = styled(Title)`
  margin-top: 0;
`;
const Keys = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;
const Values = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
`;

const EstimateQuantity = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
`;

const RawMaterialName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #52c41a;
`;
const RawMaterialQuantity = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
`;
const OrderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PaarStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PaarStatusTitle = styled.h5`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
