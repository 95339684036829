export enum NSIA {
  NSIA_REMEMBER_ME = 'NSIA_REMEMBER_ME',
  NSIA_USER = 'NSIA_USER',
  NSIA_TOKEN = 'NSIA_TOKEN'
}
export enum Role {
  NSIA_ADMIN = 'NSIA_ADMIN',
  CLEARING_AGENT = 'CLEARING_AGENT',
  COLLATERAL_MANAGER = 'COLLATERAL_MANAGER',
  BLENDER = 'BLENDER',
  COLLATERAL_MANAGER_BLENDER = 'COLLATERAL_MANAGER_BLENDER'
}

export const RoleConfig = {
  [Role.NSIA_ADMIN]: { title: 'NSIA ADMIN' },
  [Role.CLEARING_AGENT]: { title: 'CLEARING AGENT' },
  [Role.BLENDER]: { title: 'BLENDER' },
  [Role.COLLATERAL_MANAGER_BLENDER]: { title: 'C.M BLENDING SITE' },
  [Role.COLLATERAL_MANAGER]: { title: 'C.M PORTS' }
};

export const localCloudName = process.env.REACT_APP_CLOUD_NAME ?? '';
export const localPresetKey = process.env.REACT_APP_PRESET_KEY ?? '';
