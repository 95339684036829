import styled from 'styled-components';
import { Card } from 'antd';

export const TableBody = styled.div`
  background-color: white;
`;
export const Container = styled.div`
  width: '100%';
  height: '100%';
  padding: 32px;
`;
export const TableHeader = styled.div`
  width: '100%';
  justify-content: space-between;
  display: flex;
  height: 88px;
  padding: 20px 10px;
  align-items: center;
`;

export const ActionItem = styled.h4`
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : '')};
`;

export const customFlex = styled.div`
  border: 2px solid red;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
    align-self: center;
    margin-block: 33px 47px;
    object-fit: contain;
  }
`;

export const SummaryWrapper = styled(Card)`
  margin-bottom: 24px;
  background: #fcfcfc;
`;

export const CustomCard = styled(Card)`
  width: 100%;
  border: 0.5px dashed #8c8c8c;
  border-radius: 8px;
`;

export const ConfirmCardContainer = styled.div`
  .card {
    width: 100%;
    border-radius: 8px;
    border: none;
  }
  .card2 {
    border-radius: 8px;
    border: none;
  }
`;

export const ConfirmCard = styled.div`
  .card {
    width: 100%;
    border: 0.5px dashed #8c8c8c;
    border-radius: 8px;
  }
  .card2 {
    border: 0.5px dashed #8c8c8c;
    border-radius: 8px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const ViewButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #1890ff;
`;
export const PurchaseInfoRow = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 40px;
`;
export const MaterialInfo = styled.div`
  background: #d9f7be;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
export const TrackingMaterialInfo = styled(MaterialInfo)`
  flex: 0.7;
`;
export const TextDetails = styled.div`
  display: flex;
  column-gap: 4px;
`;
