import { Response } from 'src/store/genericInterface';
import { baseApi } from 'src/store/api/baseApi';
import { tagTypes } from 'src/store/api/baseApi/tagTypes';
import {
  IAdminRawMaterialDistribution,
  IBlendingPlants,
  IStats
} from './interface';

const adminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminStats: builder.query<Response<IStats>, string | void>({
      query: () => ({
        url: `/admin/stat`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Admin }]
    }),
    getBlendingPlants: builder.query<
      Response<IBlendingPlants[]>,
      string | void
    >({
      query: () => ({
        url: `/admin/blending-plants`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.BlendingPlants }]
    }),
    getAdminChart: builder.query<
      Response<IAdminRawMaterialDistribution[]>,
      string | void
    >({
      query: (query = '') => ({
        url: `/admin/chart?${query}`,
        method: 'GET'
      }),
      providesTags: [{ type: tagTypes.Chart }]
    })
  })
});

export const {
  useGetAdminStatsQuery,
  useGetBlendingPlantsQuery,
  useLazyGetAdminChartQuery
} = adminApi;
