import { Card } from 'antd';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { TextWrap } from 'src/pages/styles';
import { useGetAllStockQuery } from 'src/store/api';
import { RawMaterialName, RawMaterialQuantity } from 'src/pages/styles';
import { formatNumber } from 'src/utils';

export const RawMateirals: FC = () => {
  const { data: allStock } = useGetAllStockQuery();
  const data = useMemo(
    () =>
      allStock?.data.map((stock) => ({
        rawMaterial: stock.rawMaterial,
        quantity: stock.quantity
      })) ?? [],
    [allStock]
  );

  return (
    <StyledCard title="Raw materials">
      {data.map((item) => (
        <TextWrap key={item.rawMaterial.id}>
          <RawMaterialName>{item.rawMaterial.name}</RawMaterialName>
          <RawMaterialQuantity>
            {formatNumber(item.quantity)}MT
          </RawMaterialQuantity>
        </TextWrap>
      ))}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  flex: 1;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`;
