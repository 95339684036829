import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Dropdown,
  Input,
  Spin,
  Table,
  Tag,
  Typography
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewShipFactModal } from 'src/components/ViewShippingFact';
import {
  IPurchaseOrder,
  ITrackDischarge,
  useGetDischargeOrdersQuery,
  useGetSinglePurchaseOrderQuery
} from 'src/store/api';
import { dateTimeFormater, formatNumber, generateUrl } from 'src/utils';
import {
  ClearVesselsModal,
  ConfirmVesselArrival,
  DischargeVesselsModal,
  DischargeVesselsSummaryModal,
  IDischargeRawMaterial,
  UpdateDischargeModal,
  ViewPaarModal
} from './components';
import {
  DISCHARGE_ORDER_STATUS,
  ORDER_STATUS,
  PAAR_STATUS,
  PURCHASE_ORDER_STATUS
} from './enum';
import {
  ButtonContainer,
  Container,
  CustomHeader,
  InfoSection,
  MaterialInfo,
  QuantityInfo,
  StatusContainer,
  TableBody,
  TableHeader,
  TextDetails,
  TitleRow,
  ViewButton
} from './styles';

const { Title, Text } = Typography;
const { Search } = Input;
export const SinglePurchaseOrder = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>(
    (state as { purchaseOrder: IPurchaseOrder }).purchaseOrder
  );
  const [viewClearVesselModal, setViewClearVesselModal] = useState(false);
  const [viewPaarModal, setViewPaarModal] = useState(false);
  const purchaseOrderStatus = purchaseOrder.status as keyof typeof ORDER_STATUS;
  const paarStatus = purchaseOrder.paarStatus as keyof typeof PAAR_STATUS;
  const { data, isLoading } = useGetSinglePurchaseOrderQuery(purchaseOrder.id);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { data: fetchDischargeOrders } = useGetDischargeOrdersQuery({
    purchaseId: purchaseOrder.id,
    query: generateUrl({
      limit: 10,
      search,
      page
    })
  });
  const [viewDischargeVessel, setViewDischargeVessel] = useState(false);
  const [viewFactModal, setViewFactModal] = useState(false);
  const [confirmVesselArrival, setConfirmVesselArrival] = useState(false);
  const [openSummaryModal, setOpenSummaryModal] = useState<{
    summaryDetails: IDischargeRawMaterial[];
    portId: string;
  } | null>(null);

  const [updateDischarge, setUpdateDischarge] =
    useState<ITrackDischarge | null>(null);

  const totalDischarged = useMemo(() => {
    return (
      purchaseOrder?.dischargeOrders?.reduce(
        (acc, current) => acc + Number(current.quantityDischarged),
        0
      ) ?? 0
    );
  }, [purchaseOrder]);

  const ports = useMemo(() => {
    return purchaseOrder?.portQuantities
      ?.map(
        (portQuantity) =>
          `${portQuantity.port.name} : ${formatNumber(portQuantity.quantity)}MT`
      )
      .join(',  ');
  }, [purchaseOrder]);

  const handleView = () => {
    if (purchaseOrderStatus === ORDER_STATUS.order_placed.status) {
      return setConfirmVesselArrival(true);
    }
    setViewDischargeVessel(true);
  };

  const source = useMemo(() => {
    return fetchDischargeOrders?.data.map((item) => {
      return {
        tableRawMaterial: item?.rawMaterial?.name,
        tableCollateralManager: item?.collateralManager?.name,
        tableQuantityDischarged: formatNumber(`${item.quantityDischarged}MT`),
        tableWarehouse: item.warehouse.name,
        ports: item.warehouse.port.name,
        key: item.id,
        date: dateTimeFormater(item.createdAt, 'll'),
        ...item
      };
    });
  }, [fetchDischargeOrders]);

  const columns = [
    {
      title: 'Raw materials',
      dataIndex: 'tableRawMaterial',
      key: 'rawMaterial'
    },
    {
      title: 'Quantity discharged',
      dataIndex: 'tableQuantityDischarged',
      key: 'quantity'
    },
    {
      title: 'Ports',
      dataIndex: 'ports',
      key: 'ports'
    },
    {
      title: 'Warehouse',
      dataIndex: 'tableWarehouse',
      key: 'warehouse'
    },
    {
      title: 'Collateral manager',
      dataIndex: 'tableCollateralManager',
      key: 'CollateralManager'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, record: ITrackDischarge) => {
        const status = record.status as keyof typeof DISCHARGE_ORDER_STATUS;
        return (
          <StatusContainer>
            <Tag color={DISCHARGE_ORDER_STATUS[status].color}>
              {DISCHARGE_ORDER_STATUS[status].name}
            </Tag>
            {DISCHARGE_ORDER_STATUS[status].update && (
              <ViewButton onClick={() => setUpdateDischarge(record)}>
                Update
              </ViewButton>
            )}
          </StatusContainer>
        );
      }
    }
  ];
  useEffect(() => {
    if (data) {
      setPurchaseOrder(data.data);
    }
  }, [data]);
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <CustomHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Purchase order</Breadcrumb.Item>
          <Breadcrumb.Item>ID - {purchaseOrder.purchaseId}</Breadcrumb.Item>
        </Breadcrumb>
        <TitleRow>
          <Title level={3}>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{ marginRight: '17px' }}
            />
            Purchase order ID - {purchaseOrder.purchaseId}
          </Title>
          <ButtonContainer>
            {ORDER_STATUS[purchaseOrderStatus].status !==
              PURCHASE_ORDER_STATUS.VESSSEL_CLEARED && (
              <>
                <Button
                  type="primary"
                  disabled={
                    PAAR_STATUS[paarStatus].disableViewPaar ||
                    ORDER_STATUS[purchaseOrderStatus].status ===
                      PURCHASE_ORDER_STATUS.VESSSEL_CLEARED
                  }
                  onClick={handleView}
                >
                  {ORDER_STATUS[purchaseOrderStatus].buttonText}
                </Button>
                {ORDER_STATUS[purchaseOrderStatus].status !==
                  PURCHASE_ORDER_STATUS.ORDER_PLACED && (
                  <Button
                    disabled={
                      ORDER_STATUS[purchaseOrderStatus].status ===
                      PURCHASE_ORDER_STATUS.VESSSEL_CLEARED
                    }
                    onClick={() => setViewClearVesselModal(true)}
                  >
                    Clear Vessel
                  </Button>
                )}
              </>
            )}
            <Dropdown
              menu={{
                items: [
                  {
                    key: 0,
                    label: 'Actions',
                    disabled: true
                  },
                  {
                    key: 1,
                    label: 'View Shipping Documents',
                    disabled: PAAR_STATUS[paarStatus].disableViewPaar,
                    onClick: () => setViewPaarModal(true)
                  },
                  {
                    key: 2,
                    label: 'View Statement of Fact',
                    onClick: () => setViewFactModal(true)
                  }
                ]
              }}
              placement="bottomRight"
            >
              <Button>...</Button>
            </Dropdown>
          </ButtonContainer>
        </TitleRow>
      </CustomHeader>

      <Spin spinning={isLoading} tip="Loading" size="large">
        <Container>
          <InfoSection>
            <QuantityInfo>
              <TextDetails>
                <Text disabled>Ports : </Text>
                <Text strong>{ports}</Text>
              </TextDetails>
            </QuantityInfo>
            <MaterialInfo>
              {purchaseOrder.orders.map((order) => {
                return (
                  <>
                    <TextDetails>
                      <Text disabled>{order.rawMaterial.name}</Text>
                      <Text strong>
                        {formatNumber(`${order.estimatedQuantity}MT`)}
                      </Text>
                    </TextDetails>
                  </>
                );
              })}

              <TextDetails>
                <Text disabled>Discharged</Text>
                <Text strong>{`${formatNumber(totalDischarged)}MT`}</Text>
              </TextDetails>
            </MaterialInfo>
          </InfoSection>
          <TableBody>
            <TableHeader>
              <Title level={4}>Discharge tracking</Title>
              <Search
                placeholder="Search"
                onSearch={onSearch}
                onChange={(e) => !e.target.value && setSearch('')}
                style={{ width: 264 }}
              />
            </TableHeader>
            <Table
              columns={columns}
              dataSource={source}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </TableBody>
        </Container>
      </Spin>
      {viewFactModal && (
        <ViewShipFactModal
          open={viewFactModal}
          closeModal={() => setViewFactModal(false)}
          viewPaar={purchaseOrder}
        />
      )}
      {viewPaarModal && (
        <ViewPaarModal
          open={viewPaarModal}
          closeModal={() => setViewPaarModal(false)}
          viewPaar={purchaseOrder}
        />
      )}
      {viewClearVesselModal && (
        <ClearVesselsModal
          open={viewClearVesselModal}
          closeModal={() => setViewClearVesselModal(false)}
          purchaseOrder={purchaseOrder}
        />
      )}
      {viewDischargeVessel && (
        <DischargeVesselsModal
          purchaseOrder={purchaseOrder}
          open={viewDischargeVessel}
          setOpenSummaryModal={(data) => setOpenSummaryModal(data)}
          closeModal={() => setViewDischargeVessel(false)}
        />
      )}
      {!!updateDischarge && (
        <UpdateDischargeModal
          open={!!updateDischarge}
          closeModal={() => setUpdateDischarge(null)}
          purchaseOrder={purchaseOrder}
          dischargeDetails={updateDischarge}
        />
      )}
      {confirmVesselArrival && (
        <ConfirmVesselArrival
          open={confirmVesselArrival}
          closeModal={() => setConfirmVesselArrival(false)}
          purchaseOrder={purchaseOrder}
        />
      )}
      {openSummaryModal && (
        <DischargeVesselsSummaryModal
          open={!!openSummaryModal}
          summary={openSummaryModal}
          closeSummaryModal={() => setOpenSummaryModal(null)}
          purchaseOrder={purchaseOrder}
        />
      )}
    </>
  );
};
