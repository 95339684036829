import { DownloadOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { DownloadWrapper } from 'src/pages/reports/components/style';
import { IIndentRequest } from 'src/store/api';
import styled from 'styled-components';
import { Text, Title } from './Typography';

interface ILogDetails {
  indentRequest: IIndentRequest;
  viewOrders: boolean;
  setViewOrders: Dispatch<SetStateAction<boolean>>;
  activePanel: number;
  setActivePanel: Dispatch<SetStateAction<number>>;
}

const { Panel } = Collapse;
const LogisticsAccordion: FC<ILogDetails> = ({
  indentRequest,
  viewOrders,
  setViewOrders,
  activePanel,
  setActivePanel
}) => {
  return (
    <Wrap>
      <BlendingSiteContainer>
        <>
          <TableTitle>
            <Title level={4}>Logistics details</Title>
            <HideButton
              status={!viewOrders}
              onClick={() => setViewOrders(!viewOrders)}
            >
              {viewOrders ? 'Collapse' : 'Expand'}
            </HideButton>
          </TableTitle>
          {viewOrders ? (
            <>
              <DetailContainer>
                <DetailsDiv>
                  {indentRequest.logistics.map((log, index) => (
                    <Fragment key={index}>
                      <Collapse
                        accordion
                        onChange={(activeKey) =>
                          setActivePanel(Number(activeKey))
                        }
                        expandIconPosition="end"
                        style={{ marginBottom: '10px', width: '100%' }}
                        activeKey={
                          activePanel === index ? String(index) : undefined
                        }
                      >
                        <Panel
                          key={index}
                          header={`Logistics company ${index + 1}`}
                        >
                          <LogisticsDiv>
                            <TextDetails>
                              <Text type="secondary">Company Name </Text>
                              <Text strong>{log.transporter}</Text>
                            </TextDetails>
                            <TextDetails>
                              <Text type="secondary">Contact Person</Text>
                              <Text strong>{log.contactPerson}</Text>
                            </TextDetails>
                            <TextDetails>
                              <Text type="secondary">Phone Number </Text>
                              <Text strong>{log.phone}</Text>
                            </TextDetails>

                            <TextDetails>
                              <Text type="secondary">Document</Text>
                              <DownloadWrapper
                                as={'a'}
                                href={log.documents[0].url}
                                target={'_blank'}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: 5
                                }}
                              >
                                <Text strong>{log.documents[0].name}</Text>
                                <DownloadOutlined />
                              </DownloadWrapper>
                            </TextDetails>
                          </LogisticsDiv>
                        </Panel>
                      </Collapse>
                    </Fragment>
                  ))}
                </DetailsDiv>
              </DetailContainer>
            </>
          ) : null}
        </>
      </BlendingSiteContainer>
    </Wrap>
  );
};

export default LogisticsAccordion;

const TableTitle = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  justify-content: space-between;
  h4 {
    margin: 0;
    padding: 16px;
  }
`;

export const TextDetails = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: space-between;
  font-weight: 600;
  column-gap: 4px;
  padding: 5px;
  .titleText {
    color: #8c8c8c;
  }
`;

const LogisticsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  background-color: #fafafa;
`;

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const HideButton = styled(Button)<{ status: boolean }>`
  color: ${({ status }) => (status ? 'green' : 'red')};
  border: none;
`;

export const Wrap = styled.div`
  width: '100%';
  height: '100%';
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
  margin-block: 32px;
`;
export const BlendingSiteContainer = styled.div`
  flex: 2;
  background-color: #fafafa;
`;
