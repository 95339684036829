import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Upload, Typography, UploadFile, Form } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { IIndentRequestPayload } from 'src/store/api';

const { Dragger } = Upload;
const { Title } = Typography;
interface IProps {
  setPayload: Dispatch<SetStateAction<IIndentRequestPayload>>;
}
export const UploadIndent: FC<IProps> = ({ setPayload }) => {
  const [fileList, setFileList] = useState<UploadFile<File>[]>();

  const onRemove = (file: UploadFile) => {
    if (!fileList) return;
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };
  const beforeUpload = (file: RcFile) => {
    if (fileList) {
      setFileList([...fileList, file]);
    } else {
      setFileList([file]);
    }
    return false;
  };
  useEffect(() => {
    if (fileList) {
      setPayload((prev) => ({ ...prev, files: fileList }));
    }
  }, [fileList, setPayload]);
  return (
    <Form.Item>
      <Title level={5}>Upload Consent Letter</Title>
      <Dragger
        multiple
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        accept=".pdf,  .jpg, .jpeg, .png"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload (docx, pdf, jpg, jpeg, png)
        </p>
      </Dragger>
    </Form.Item>
  );
};
