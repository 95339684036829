import { Form, Input, Space, Checkbox, Button, Alert } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthWrap } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/store';
import { ILoginPayload, useLoginMutation } from 'src/store/api';
import { IApiError } from 'src/store/genericInterface';
import { setRemeber, setToken, setUser } from 'src/store/slices';

export const Login = () => {
  const dispatch = useAppDispatch();
  const { remeberMe } = useAppSelector((state) => state.auth);
  const [handleLogin, { isLoading, error, isError }] = useLoginMutation();
  const [form] = Form.useForm<ILoginPayload>();
  const navigate = useNavigate();
  const handleRememberMe = (e: CheckboxChangeEvent) => {
    dispatch(setRemeber(e.target.checked));
  };
  const handleSubmit = (values: ILoginPayload) => {
    handleLogin(values)
      .unwrap()
      .then((res) => {
        dispatch(setUser(res.user));
        dispatch(setToken(res.access_token));
        navigate('/');
      });
  };
  return (
    <AuthWrap
      title="Welcome back"
      subtitle="Enter your details below to continue"
    >
      <Form
        form={form}
        layout={'vertical'}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        {isError && (
          <CustomAlert
            message={(error as IApiError | undefined)?.error}
            type="error"
            showIcon
          />
        )}
        <FormItem
          name="email"
          label="Email Address"
          rules={[
            {
              required: true,
              message: 'Email address is required'
            },
            {
              type: 'email',
              message: 'Email address is invalid'
            }
          ]}
        >
          <Input
            placeholder="johndoe@pfi.com.ngr"
            type="email"
            autoComplete="off"
          />
        </FormItem>

        <FormItem
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Password is required'
            }
          ]}
        >
          <Input.Password placeholder="input password" autoComplete="off" />
        </FormItem>

        <Space
          direction="horizontal"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px'
          }}
        >
          <Checkbox checked={remeberMe} onChange={handleRememberMe}>
            Remember me
          </Checkbox>

          <Link to="/forgot-password">Forgot password?</Link>
        </Space>

        <Button loading={isLoading} htmlType="submit" type="primary" block>
          Login
        </Button>
      </Form>
    </AuthWrap>
  );
};

const CustomAlert = styled(Alert)`
  margin-bottom: 32px;
`;
const FormItem = styled(Form.Item)`
  label {
    font-size: 16px !important;
    font-weight: 600;
    line-height: 24px;
  }
`;
