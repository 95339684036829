import { Button, Divider, Modal, Spin } from 'antd';
import { FC, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Logo from 'src/assets/authAssets/logo.png';
import { IOffTakingDetail } from 'src/store/api';
import { dateTimeFormater } from 'src/utils';
import {
  DashedDiv,
  DetailsDiv,
  DetailsHeader,
  DetailsValue,
  InfoDiv,
  ModalTitle
} from './style';

interface IProps {
  open: boolean;
  closeModal: () => void;
  offtakenDetail: IOffTakingDetail[] | undefined;
  loading: boolean;
  id: string;
  singleOfftake: IOffTakingDetail | undefined;
}

export const DispatchNote: FC<IProps> = ({
  open,
  closeModal,
  offtakenDetail,
  loading,
  id,
  singleOfftake
}) => {
  const targetRef = useRef(null);

  const dispatchNote = `Dispatch note by ${
    singleOfftake?.warehouse.name
  } on ${dateTimeFormater(singleOfftake?.updatedAt ?? '')}.pdf`;

  const reactToPrintContent = () => {
    return targetRef.current;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: dispatchNote,

    pageStyle: `@media print {
  @page {
    size: A4 Portrait;
    margin: 24px;
  }
}`
  });

  const renderOfftakeDetails = () => {
    if (!offtakenDetail) {
      return <Spin spinning={loading} size="small" />;
    }

    const filteredDetails = offtakenDetail.filter(
      (detail) => detail._id === id
    );

    return filteredDetails.map((detail, index) => (
      <div key={index}>
        <DetailsDiv>
          <DetailsHeader>
            <p>Warehouse</p>
          </DetailsHeader>
          <DetailsValue>
            <p>{detail?.warehouse?.name}</p>
          </DetailsValue>
        </DetailsDiv>
        <DetailsDiv>
          <DetailsHeader>
            <p>Location</p>
          </DetailsHeader>
          <DetailsValue>
            <p>{detail?.warehouse?.location?.name ?? ''}</p>
          </DetailsValue>
        </DetailsDiv>
        <DetailsDiv>
          <DetailsHeader>
            <p>Logistics Company</p>
          </DetailsHeader>
          <DetailsValue>
            <p>{detail?.logistics.transporter}</p>
          </DetailsValue>
        </DetailsDiv>
        <DetailsDiv>
          <DetailsHeader>
            <p>Raw material</p>
          </DetailsHeader>
          <DetailsValue>
            <p>{detail?.rawMaterial?.name}</p>
          </DetailsValue>
        </DetailsDiv>
        <DetailsDiv>
          <DetailsHeader>
            <p>Quantity discharged</p>
          </DetailsHeader>
          <DetailsValue>
            <p>
              {detail?.quantityDischarged} {''}MT
            </p>
          </DetailsValue>
        </DetailsDiv>
        <DetailsDiv>
          <DetailsHeader>
            <p>Driver details</p>
          </DetailsHeader>
          <DetailsValue>
            <p>{detail?.driverName}</p>
            <p>{detail?.phone}</p>
          </DetailsValue>
        </DetailsDiv>
        <DetailsDiv>
          <DetailsHeader>
            <p>Truck ID</p>
          </DetailsHeader>
          <DetailsValue>
            <p>{detail.truckNo}</p>
          </DetailsValue>
        </DetailsDiv>
        <DetailsDiv>
          <DetailsHeader>
            <p>Date</p>
          </DetailsHeader>
          <DetailsValue>
            <p>{dateTimeFormater(detail.updatedAt, 'll')}</p>
            <p>{dateTimeFormater(detail.updatedAt, 'LT')}</p>
          </DetailsValue>
        </DetailsDiv>
      </div>
    ));
  };

  return (
    <Modal
      open={open}
      centered
      width={640}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal}>Close</Button>,
        <Button type="primary" onClick={handlePrint}>
          Download Note
        </Button>
      ]}
    >
      <div ref={targetRef}>
        <ModalTitle>
          <img src={Logo} alt="logo" />
        </ModalTitle>
        <ModalTitle>
          <p>Dispatch Note</p>
        </ModalTitle>
        <InfoDiv>
          <p>
            This note contains every information as regard to this particular
            offake
          </p>
        </InfoDiv>
        <DashedDiv>
          <DetailsDiv>
            <DetailsValue>
              <p>Offtake Details</p>
            </DetailsValue>
          </DetailsDiv>
          <Divider />
          {renderOfftakeDetails()}
        </DashedDiv>
        <Divider />
      </div>
    </Modal>
  );
};
