import { Button, DatePicker, Spin, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { FC, useMemo, useState } from 'react';
import { Title } from 'src/components';
import { TableBody, TableHeader } from 'src/pages/cmBlendingPlant/report/style';
import { ReportStatus, ReportStatusConfig } from 'src/store/api';
import {
  ICmbDataItem,
  useGetCMBlenderReportsQuery
} from 'src/store/api/cmBlender/reports';
import { dateTimeFormater, generateUrl } from 'src/utils';
import { endOfMonth, startOfMonth } from 'src/utils/dateRange';
import styled from 'styled-components';
import {
  AddFieldsModal,
  DeleteFieldsModal,
  ViewReportsModal
} from './components';
const { RangePicker } = DatePicker;

export const Reports: FC = () => {
  const [queryParams, setParams] = useState({
    startDate: startOfMonth().toISOString(),
    endDate: endOfMonth().toISOString(),
    page: 1,
    limit: 10
  });
  const { data: reports, isLoading } = useGetCMBlenderReportsQuery(
    generateUrl({
      ...queryParams
    })
  );
  const [viewFieldModal, setViewFieldModal] = useState(false);
  const [deleteReportsModal, setDeleteReportsModal] =
    useState<ICmbDataItem | null>(null);
  const [viewReportsModal, setViewReportsModal] = useState<ICmbDataItem | null>(
    null
  );
  const columns = [
    {
      title: 'S/N',
      dataIndex: 'sn',
      key: 'sn'
    },
    {
      title: 'NPK Produced',
      dataIndex: 'npkType',
      key: 'npkType'
    },
    {
      title: 'Raw material',
      dataIndex: 'rawMaterialUsed',
      key: 'rawMaterialUsed'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, record: ICmbDataItem) => {
        const status = ReportStatusConfig[record.status];

        return <StyledTag color={status.color}>{status.name}</StyledTag>;
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, record: ICmbDataItem) => {
        return (
          <>
            <ViewButton
              onClick={() => {
                setViewReportsModal(record);
              }}
            >
              Details
            </ViewButton>
            {ReportStatus.confirmed !== record.status && (
              <>
                |
                <DeleteButton
                  onClick={() => {
                    setDeleteReportsModal(record);
                  }}
                >
                  Delete
                </DeleteButton>
              </>
            )}
          </>
        );
      }
    }
  ];

  const dataSource = useMemo(() => {
    return reports?.data?.map((item, index) => {
      return {
        ...item,
        npkType: item.npkProduced.length ?? 0,
        rawMaterialUsed: item.rawMaterialUtilized.length ?? 0,
        date: dateTimeFormater(item.createdAt) ?? '',
        sn: `${index + 1}`.padStart(2, '0')
      };
    });
  }, [reports]);

  const handleDateChange = (datejs: RangeValue<dayjs.Dayjs>) => {
    if (datejs && datejs[0] && datejs[1]) {
      setParams((prev) => ({
        ...prev,
        startDate: datejs[0]!.toISOString(),
        endDate: datejs[1]!.toISOString()
      }));
    }
  };

  return (
    <>
      {viewReportsModal && (
        <ViewReportsModal
          open={!!viewReportsModal}
          closeModal={() => setViewReportsModal(null)}
          reports={viewReportsModal}
        />
      )}
      {viewFieldModal && (
        <AddFieldsModal
          closeModal={() => setViewFieldModal(false)}
          open={viewFieldModal}
        />
      )}
      {!!deleteReportsModal && (
        <DeleteFieldsModal
          closeModal={() => setDeleteReportsModal(null)}
          open={!!deleteReportsModal}
          report={deleteReportsModal}
        />
      )}
      <TitleRow>
        <Title level={2}>Daily Production</Title>
        <Button onClick={() => setViewFieldModal(true)} type="primary">
          Add Report
        </Button>
      </TitleRow>
      <TableHeader>
        <Title level={4}></Title>
        <RangePicker
          defaultValue={[startOfMonth(), endOfMonth()]}
          onChange={(datejs) => handleDateChange(datejs)}
        />
      </TableHeader>
      <Spin spinning={isLoading} tip="Loading" size="large">
        <TableBody>
          <Table
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: reports?.pagination?.totalCount,
              onChange: (page) => {
                setParams((prev) => ({ ...prev, page }));
              }
            }}
            columns={columns}
            dataSource={dataSource}
          />
        </TableBody>
      </Spin>
    </>
  );
};
export const TitleRow = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 24px;
  height: 102px;
`;
const StyledTag = styled(Tag)`
  text-transform: capitalize;
`;
const DeleteButton = styled.button`
  border: none;
  background-color: #fff;
  color: #f5222d;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #fafafa;
  }
`;
const ViewButton = styled(DeleteButton)`
  color: #1890ff;
`;
