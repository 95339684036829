import { AnalyticsCard, Title } from 'src/components';
import {
  Body,
  CardDiv,
  CardRow,
  Container,
  SideDetails,
  CustomHeader
} from './style';
import StakeholdersIcon from 'src/assets/dashboardIcons/stakeholdersIcon.svg';
import TrucksIcon from 'src/assets/dashboardIcons/trucksIcon.svg';
import {
  MaterialsDistributionChart,
  NpkProductionCharts,
  ProductionCharts,
  RecentRequest
} from './components';
import { useGetBlenderStatsQuery } from 'src/store/api/blender/api';


export const Dashboard = () => {
  const { data: getBlendersStat } = useGetBlenderStatsQuery();

  return (
    <Container>
      <CustomHeader>
        <Title>Blender</Title>
      </CustomHeader>
      <Body>
        <CardDiv>
          <MaterialsDistributionChart />
          <SideDetails>
            <CardRow>
              <AnalyticsCard
                width="48.5%"
                title="Total release"
                icon={StakeholdersIcon}
                value={getBlendersStat?.data.totalReleased}
              />
              <AnalyticsCard
                width="48.5%"
                title={'Goods in transit'}
                value={getBlendersStat?.data.goodsInTransit}
                icon={TrucksIcon}
              />
            </CardRow>
            <NpkProductionCharts />
            <ProductionCharts />
          </SideDetails>
        </CardDiv>
        <RecentRequest />
      </Body>
    </Container>
  );
};
