import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, notification } from 'antd';
import { FC } from 'react';

import { Text } from 'src/components';
import { GreyText } from 'src/pages/styles';
import {
  IOffTakingDetail,
  IResolveIndent,
  useCmBlendResolveIndentRequestMutation
} from 'src/store/api';
import { formatNumber } from 'src/utils';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  closeModal: () => void;
  offtakeDetails: IOffTakingDetail;
}

export const ConfirmOfftake: FC<IModal> = ({
  open,
  closeModal,
  offtakeDetails
}) => {
  const [handleResolveConflict, { isLoading }] =
    useCmBlendResolveIndentRequestMutation();
  const [form] = Form.useForm();
  const confirmInput = Form.useWatch('truckId', form);

  const handleSubmit = () => {
    const payload: IResolveIndent = {
      offTakingId: offtakeDetails._id,
      id: offtakeDetails.indentRequest,
      data: {
        status: 'confirm'
      }
    };
    handleResolveConflict(payload)
      .unwrap()
      .then(() => {
        notification['success']({
          message: 'Order confirmed'
        });
        closeModal();
      })
      .catch((err) => {
        notification['error']({
          message: err.error
        });
      });
  };

  const handleFinish = () => {
    if (offtakeDetails.truckNo === confirmInput) {
      handleSubmit();
    }
  };
  return (
    <Modal
      open={open}
      title="Confirm Offtake raw material"
      onOk={closeModal}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        <Button
          loading={isLoading}
          onClick={() => form.submit()}
          disabled={offtakeDetails.truckNo !== confirmInput}
          htmlType="submit"
          type={'primary'}
        >
          Confirm
        </Button>
      ]}
      width={469}
    >
      <Divider />
      <Form layout={'vertical'} form={form} onFinish={handleFinish}>
        <AlterText>
          Raw material discharged
          <Divider className="thick" />
        </AlterText>
        <CustomCard>
          <GreyText>{offtakeDetails.rawMaterial.name}</GreyText>
          <Text>{`${formatNumber(offtakeDetails.quantityDischarged)} MT`}</Text>
          <CheckCircleFilled style={{ color: '#52C41A' }} />
        </CustomCard>
        <Form.Item name={'truckId'} label={<FormLabel>Truck ID</FormLabel>}>
          <Input placeholder="Enter truck ID" autoComplete="off" />
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  );
};

const FormLabel = styled.span`
  display: inline-block;
`;

const AlterText = styled(Text)`
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  .thick {
    height: 1px;
    background-color: #d9d9d9;
    margin-block: 20px;
  }
`;

export const CustomCard = styled.div`
  background-color: rgba(245, 245, 245, 1);
  display: flex;
  column-gap: 5px;
  padding: 12px 25px;
  justify-content: center;
  margin-bottom: 40px;
`;
