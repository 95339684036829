import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { MainLayout, AuthLayout } from '../components';
import { useAppSelector } from '../store';
import { routes } from './routes';

const Validate = () => {
  const { access_token } = useAppSelector((state) => state.auth);
  return !access_token ? <Navigate to="/" replace /> : null;
};
const MainRouter = () => {
  const { user } = useAppSelector((state) => state.auth);


  return (
    <Router>
      <Routes>
        {!user ? (
          <Route path="/" element={<AuthLayout />}>
            {routes.auth.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
        ) : (
          <Route path="/" element={<MainLayout />}>
            {routes[user?.role].map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route path={'*'} element={null} />
          </Route>
        )}
        <Route path={'*'} element={<Validate />} />
      </Routes>
    </Router>
  );
};

export default MainRouter;
