import { PaginatedResponse, Response } from 'src/store/genericInterface';
import { baseApi } from '../../baseApi';
import { tagTypes } from '../../baseApi/tagTypes';
import { IAdmin, IEditAdminPayload, IInviteAdminPayload } from './interface';

const adminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAdmin: builder.query<PaginatedResponse<IAdmin>, string | void>({
      query: (query) => ({
        url: `/admin/all?${query}`,
        method: 'GET'
      }),
      providesTags: (_result, _err, query) => [
        { type: tagTypes.AdminManagement, query }
      ]
    }),
    deleteAdmin: builder.mutation<Response<IAdmin>, string>({
      query: (id) => ({
        url: `/admin/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: tagTypes.AdminManagement }]
    }),
    updateAdmin: builder.mutation<
      Response<IAdmin>,
      { id: string; data: IEditAdminPayload }
    >({
      query: (payload) => ({
        url: `/admin/${payload.id}`,
        method: 'PUT',
        data: payload.data
      }),
      invalidatesTags: [{ type: tagTypes.AdminManagement }]
    }),
    inviteAdmin: builder.mutation<Response<IAdmin>, IInviteAdminPayload>({
      query: (payload) => ({
        url: `/admin/invite`,
        method: 'POST',
        data: payload
      }),
      invalidatesTags: [{ type: tagTypes.AdminManagement }]
    })
  })
});

export const {
  useGetAllAdminQuery,
  useDeleteAdminMutation,
  useUpdateAdminMutation,
  useInviteAdminMutation
} = adminApi;
