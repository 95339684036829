import {
  CloseOutlined,
  DownloadOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { Modal, Typography, Divider } from 'antd';
import { IIndentRequest } from 'src/store/api';
import { FC } from 'react';
import { Title } from 'src/components';

const { Text } = Typography;

interface IModal {
  open: boolean;
  closeModal: () => void;
  shippingDocuments: IIndentRequest;
}

export const ViewShippingDocument: FC<IModal> = ({
  open,
  closeModal,
  shippingDocuments
}) => {
  const logisticsDocuments = shippingDocuments.logistics.flatMap(
    (item) => item.documents
  );

  return (
    <Modal open={open} closable={false} centered footer={[]} width={640}>
      <TextWrapper>
        <TitleRow>
          <ShippingTitle level={5}>Document Uploaded</ShippingTitle>
          <CloseOutlined onClick={closeModal} />
        </TitleRow>
        <Divider />
        {logisticsDocuments?.map((item, index) => {
          return (
            <DownloadWrapper key={index}>
              <div>
                <PaarIcon />
                <Text strong className="viewText">
                  {item.name}
                </Text>
              </div>
              <DownloadIcon rel="noreferrer" target={'_blank'} href={item.url}>
                <DownloadOutlined />
              </DownloadIcon>
            </DownloadWrapper>
          );
        })}
        <Divider />
      </TextWrapper>
    </Modal>
  );
};

const DownloadWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: auto;
  .viewText {
    margin-inline: 15px;
    text-transform: uppercase;
  }
`;
const PaarIcon = styled(PaperClipOutlined)`
  border: 0.5px dashed rgba(217, 217, 217, 1);
  border-radius: 8px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(250, 250, 250, 1);
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ShippingTitle = styled(Title)`
  margin-top: 8px;
`;
const DownloadIcon = styled.a`
  color: #11b867;
`;
